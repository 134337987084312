
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoxelItSelfieComponent } from './voxel-it-selfie.component';
import { ImageCompressService, ResizeOptions, ImageUtilityService } from 'ng2-image-compress';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';
import { VoxelButtonModule } from '@voxel/mobile';
import { VoxelItSelfieService } from './voxel-it-selfie.service';

@NgModule({
  imports: [
    CommonModule,
    VoxelButtonModule,
  ],
  declarations: [
    VoxelItSelfieComponent,
  ],
  providers: [
    ImageCompressService,
    ResizeOptions,
    LatamItLoaderService,
    VoxelItSelfieService,
  ],
  exports: [
    VoxelItSelfieComponent,
  ]
})
export class VoxelItSelfieModule {}

