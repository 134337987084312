import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[ngOptionTmp]' })
export class NgOptionTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ngOptgroupTmp]' })
export class NgOptgroupTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ngLabelTmp]' })
export class NgLabelTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ngMultiLabelTmp]' })
export class NgMultiLabelTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ngHeaderTmp]' })
export class NgHeaderTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ngFooterTmp]' })
export class NgFooterTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ngNotfoundTmp]' })
export class NgNotFoundTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ngTypetosearchTmp]' })
export class NgTypeToSearchTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ngLoadingtextTmp]' })
export class NgLoadingTextTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[ngTagTmp]' })
export class NgTagTemplateDirective {
  constructor(public template: TemplateRef<any>) {}
}
