export class ClosingDateModel {
  ClosingDateId: string;
  ClosingDateNumber: string;
  ClosingDateSelected: boolean;

  constructor(id: string, value: string, selected: boolean) {
    this.ClosingDateId = id;
    this.ClosingDateNumber = value;
    this.ClosingDateSelected = selected;
  }
}
