import { Subject } from 'rxjs';
import { AddressCommonService } from 'src/app/address/shared/address-common.service';
import { AbreCuentasRoutesEnum } from 'src/app/shared/enum/abre-cuentas-routes.enum';
import { AdminListEnum } from 'src/app/shared/services/admin-lists/enum/admin-list.enum';
import { DocumentPhotosCommonService } from './document-photos-common.service';
import { DocumentPhotoApproach } from './enums/routes-document-photos-enum';

export interface DocumentPhoto {

  label: string;
  checked: boolean;
  elementId: number;
  code: DocumentPhotoApproach;
}


export class DocumentPhotosCommon {

  loaderIcon: boolean;
  eventsSubject: Subject<void> = new Subject<void>();
  errorRoute = AbreCuentasRoutesEnum.CAMERA_ERROR;
  noPermissionRoute = AbreCuentasRoutesEnum.PERMISSION_REQUIRED;

    // Params from admin
  maxImageLenght: number;
  compressedResolutionHeight: number;

  choice: DocumentPhotoApproach;

  uploadParams: any;
  isFileSelected: boolean;
  isBtnDisabled: boolean;
  file: any;
  base64: any;

  constructor(
        protected commonService: DocumentPhotosCommonService,
        protected addressCommonService: AddressCommonService) {}


  setFileUploadParams() {
    if (this.commonService.paramsUpload) {
      this.compressedResolutionHeight = this.commonService.paramsUpload[0].resolution_size;
      this.maxImageLenght = this.commonService.paramsUpload[0].max_file_size;
    }
  }

  initializeFileUploadOption() {
    this.isFileSelected = false;
    this.uploadParams = this.addressCommonService.getAdminList(AdminListEnum.FILE_UPLOAD);
    this.resolveButton(this.isFileSelected);
  }

  resolveButton(base64) {
    this.isBtnDisabled = !base64;
  }

  fileSelected(event: any) {
    this.file = event.file;
    this.base64 = event.base64;
    this.resolveButton(event.base64);
    this.isFileSelected = event.isFileSelected;
  }
}
