<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='2'></header-oca>

  <main class="delivery-address__main">
    <h1 class="delivery-address__header-container">
      <span *ngIf="cardSelected==false">¿Dónde te gustaría recibir tu tarjeta de débito?</span>
      <span *ngIf="cardSelected">¿Dónde te gustaría recibir tus tarjetas?</span>
    </h1>
    <div>
      <span class="delivery-address__subtitle" *ngIf="cardSelected==false">
        Por seguridad, solo te entregaremos la tarjeta a vos.
      </span>
      <span class="delivery-address__subtitle" *ngIf="cardSelected">
        Por seguridad, solo te entregaremos las tarjetas de débito y de crédito a vos.
      </span>
    </div>

    <div class="delivery-address__title">
      <div class="delivery-address__title">
        <div *ngFor="let choice of deliveryChoiceModel"
            [ngClass]="{'delivery-address__button--selected': choice.selected}"
            class="delivery-address__button"
            [id]="choice.id"
            (click)='changeDeliveryChoice(choice)'>
            <i class="icon delivery-address__icons {{choice.icon}}"></i>
            <div class="delivery-description" id="{{choice.id}}-description">{{choice.description}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="optionPickupCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'stateId'"
        [items]="pickupCenterState"
        [label]="'Departamento'"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="pickupCenterStateSelected"
        (selectChange)="changePickupCenterState($event)">
      </voxel-select>
    </div>
    <div *ngIf="optionPickupCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'pickupCenterId'"
        [items]="pickupCenterAvailable"
        [label]="pickupCenterLabel"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="pickupCenterSelected"
        (selectChange)="changePickupCenter($event)">
      </voxel-select>
      <div class="pickupCenter__container" *ngIf="pickUpAvailability">
        <div class="delivery-address__direction">
            Dirección seleccionada:
        </div>
        <div class="pickupCenter__information">
          <div>{{pickUpCenterStreet}}</div>
          <div class="pickupCenter__availability">{{pickUpAvailability}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="optionOtherAddress" class="delivery-address__container">
      <address-form
        [stateSelected]='deliveryStateSelected'
        [citySelected]='deliveryCitySelected'
        [street]='deliveryAddress.street'
        [states]='states'
        [cities]='cities'
        (validationForm)="enableButton($event)">
      </address-form>
    </div>

    <div *ngIf="optionOcaCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'stateId'"
        [items]="ocaCenterState"
        [label]="'Departamento'"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="ocaCenterStateSelected"
        (selectChange)="changeOcaCenterState($event)">
      </voxel-select>
    </div>
    <div *ngIf="optionOcaCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'ocaCenterId'"
        [items]="ocaCenterAvailable"
        [label]="'Sucursales disponibles'"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="ocaCenterSelected"
        (selectChange)="changeOcaCenter($event)">
      </voxel-select>
      <div class="pickupCenter__container" *ngIf="ocaAvailability">
        <div class="delivery-address__direction">
            Dirección seleccionada:
        </div>
        <div class="pickupCenter__information">
          <div>{{ocaCenterStreet}}</div>
          <div class="pickupCenter__availability">{{ocaAvailability}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="optionTataCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'stateId'"
        [items]="tataCenterState"
        [label]="'Departamento'"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="tataCenterStateSelected"
        (selectChange)="changeTataCenterState($event)">
      </voxel-select>
    </div>
    <div *ngIf="optionTataCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'tataCenterId'"
        [items]="tataCenterAvailable"
        [label]="'Sucursales disponibles'"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="tataCenterSelected"
        (selectChange)="changeTataCenter($event)">
      </voxel-select>
      <div class="pickupCenter__container" *ngIf="tataAvailability">
        <div class="delivery-address__direction">
            Dirección seleccionada:
        </div>
        <div class="pickupCenter__information">
          <div>{{tataCenterStreet}}</div>
          <div class="pickupCenter__availability">{{tataAvailability}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="showDeliveryTime">
      <p id="title" class="address-header">
        Hora de entrega
      </p>
      <div class="radio-button">
        <voxel-radio-button-group>
          <voxel-radio-button
            *ngFor="let deliveryTime of deliveryTimes"
            id="expectedBalance"
            [radioName]="' '"
            [value]="deliveryTime.code"
            [checked]="deliveryTime.checked"
            (radioButtonChange)='changeDeliveryTime(deliveryTime)'>
            {{deliveryTime.label}}
          </voxel-radio-button>
        </voxel-radio-button-group>
      </div>
    </div>

  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    previousHook="true"
    (hookNextAction)="continuarButton()"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="addressNavigationService"
    (hookPreviousAction)="doPrevious()">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>
