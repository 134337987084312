<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='setAtual()'></header-oca>

  <main>

    <h1 class="title-container">
      ¿Cuál es tu <strong>domicilio particular</strong>?
    </h1>

    <voxel-additional-information
      id='addtionalInformationId'
      message="{{hintObject.message}}"
      idHint="hintid"
      [reverseHint]="true">
    </voxel-additional-information>

    <address-form
        [stateSelected]="stateSelected"
        [citySelected]="citySelected"
        [street]="street"
        [states]="states"
        [cities]="cities"
        (validationForm)="enableButton($event)">
    </address-form>
  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="addressNavigationService"
    (hookNextAction)="next()">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>
