<div [attr.headerTitle]="headerTitle" [attr.headerSubTitle]="headerSubTitle" [attr.routeToNavigate]="routeToNavigate">

  <header *ngIf="suportGetUserMediaComponent" class="selfie-header">
    <div class="header-text">
      <p class="title">{{ headerTitle }}</p>
      <p class="sub-title">{{ headerSubTitle }}</p>
    </div>
  </header>

  <div [hidden]="userMediaPictureTaken">
    <!-- Get User Media before taking picture-->
    <div [hidden]="!suportGetUserMediaComponent">
      <video id="gum-local" autoplay playsinline class="cssWebCam"></video>
      <div class="btn-div">
        <div class="wrapper-btn selfie-buttons">
          <div class="fl">
            <voxel-button
              id="selfie-volver"
              label="{{volverLabel}}"
              typeButton="secondary"
              (click)="sendToPrevious()">
            </voxel-button>
          </div>
          <div class="fr">
            <voxel-button
              id="selfie-tomar-foto"
              icon="maquina_fotografica_outline"
              label="{{takePictureLabel}}"
              [disabled]="disableTakePicture"
              (click)="snapshot()">
            </voxel-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Native -->
    <div [hidden]="suportGetUserMediaComponent">
      <input type="file" accept="image/*" id="native-front-input" capture="user" (change)="uploadFile($event)" style="visibility:hidden;">
      <input type="file" accept="image/*" id="native-back-input" capture="environment" (change)="uploadFile($event)" style="visibility:hidden;">

      <div class="btn-div" [hidden]="nativeImage">
        <div class="retry-photo-text">
          <div class="error-title">No fue posible recibir tu foto.</div>
          <div class="error-subTitle">{{ errorMessage }}</div>
          <i class="icon icon-itaufonts_seta_down"></i>
        </div>
        <voxel-button id="selfie-repetir" [label]="repeatPictureLabel" typeButton="secondary" (click)="openNativeCam()"></voxel-button>
      </div>
    </div>
    <div id="errorMsg"></div>
  </div>

  <!-- Get User Media after taking picture-->
  <div [hidden]="!userMediaPictureTaken">
    <canvas style="display:none;"></canvas>
    <img id="imgSelfie" class="cssWebCam" src="">
    <div class="btn-div">
      <div class="wrapper-btn">
        <div class="fl">
          <voxel-button id="selfie-repetir" [label]="repeatPictureLabel" typeButton="secondary" (click)="deletePhoto()"></voxel-button>
        </div>
        <div class="fr">
          <voxel-button id="selfie-usar-photo" [label]="usePictureLabel" (click)="savePhoto()"></voxel-button>
        </div>
      </div>
    </div>
  </div>
</div>
<script src="./lib/adapter.js"></script>
