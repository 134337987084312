import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Analytics } from '../../shared/analytics';
import { GetReadyModel } from '../models/get-ready-model';
import { RoutesGetReadyEnum } from '../shared/enums/routes-get-ready-enum';
import { GetReadyCommonService } from '../shared/get-ready-common.service';
import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';

@Component({
  selector: 'contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  pageId = '04_contactInfo';
  isBtnDisabled = false;

  phoneNumber: string;
  email: string;
  emailErrorMessage: string;
  EmailErrorText: string;
  getReadyForm: GetReadyModel;
  client: boolean;
  errorPhoneMessage: string;

  confirmEmail: string;
  confirmEmailErrorMessage: string;
  timeOutEmail;
  timeOutEmailConfirm;
  specialCharsErrorText;
  forbiddenChars;

  mask = [/[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/];

  form = new FormGroup({
    phoneNumber: new FormControl(),
    email: new FormControl(),
    confirmEmail: new FormControl()
  });

  constructor(
    private getReadyFormService: GetReadyFormService,
    public navigationService: GetReadyNavigationService,
    private commonService: GetReadyCommonService,
    private analyticsLib: Analytics
  ) {}

  ngOnInit() {
    this.navigationService.setStage(RoutesGetReadyEnum.CONTACT);
    this.phoneNumber = this.getReadyFormService.getProperty('phone') || '';
    this.email = this.getReadyFormService.getProperty('email') || '';
    this.confirmEmail = this.getReadyFormService.getProperty('confirmEmail') || '';

    this.form.controls['email'].setValue(this.email);
    this.form.controls['confirmEmail'].setValue(this.confirmEmail);

    this.confirmEmailErrorMessage = '';
    this.emailErrorMessage = '';
    this.errorPhoneMessage = '';
    this.EmailErrorText = 'Ops, ingresá un e-mail válido para seguir';
    this.specialCharsErrorText = `Debés ingresar un e-mail válido para continuar. Verificá no incluir
                                  caracteres especiales, “ñ”  ni tildes`;

    this.timeOutEmail = null;
    this.timeOutEmailConfirm = null;
    this.forbiddenChars = ['á', 'à', 'â', 'ã', 'é', 'è', 'ê', 'í', 'ì', 'î', 'ó', 'ò', 'ô', 'õ', 'ú', 'ù', 'û', 'ñ'];

    this.refreshButton();
  }

  changePhone(event) {
    if (event.slice(0, 3) !== '090') {
      this.phoneNumber = event;
      this.getReadyFormService.setProperty('phone', this.phoneNumber);

      if (event.length !== 9) {
        this.errorPhoneMessage = 'Número inválido. Intentá nuevamente, por favor.';
        this.form.controls['phoneNumber'].setErrors({ incorrect: true });
      }

      this.refreshButton();
    } else {
      this.errorPhoneMessage = 'No debe comenzar con 090';
      this.form.controls['phoneNumber'].setErrors({ incorrect: true });
    }
  }

  validateEmail(event, isOnChange: boolean) {
    if (isOnChange) {
      this.email = event ? event : null;
      this.refreshButton();
      this.emailErrorMessage = '';
      if (this.timeOutEmail) {
        clearTimeout(this.timeOutEmail);
      }
      this.timeOutEmail = setTimeout(() => {
        this.checkEmailError();
        this.refreshButton();
      }, 750);
    } else {
      this.refreshButton();
      this.checkEmailError();
    }
  }

  setConfirmEmail(event: any, isOnChange: boolean) {
    if (isOnChange) {
      this.confirmEmail = event ? event : null;
    }
    if (isOnChange && this.confirmEmail !== null && this.email !== null) {
      if (this.timeOutEmailConfirm) {
        clearTimeout(this.timeOutEmailConfirm);
      }
      this.checkConfirmationEmailError();
      if (this.confirmEmail.length < this.email.length) {
        this.confirmEmailErrorMessage = '';
        this.emailErrorMessage = '';
        if (this.timeOutEmail) {
          clearTimeout(this.timeOutEmail);
        }
        this.timeOutEmailConfirm = setTimeout(() => {
          this.setConfirmEmail(event, false);
        }, 1500);
      }
    } else {
      this.checkConfirmationEmailError();
    }
    this.refreshButton();
  }

  checkConfirmationEmailError() {
    this.timeOutEmailConfirm = null;
    this.timeOutEmail = null;
    if (this.confirmEmail === this.email) {
      this.getReadyFormService.setProperty('confirmEmail', this.confirmEmail);
      this.confirmEmailErrorMessage = '';
    } else {
      this.confirmEmailErrorMessage = 'Los emails no coinciden.';
    }
  }

  checkEmailError() {
    if (this.isValidFormat()) {
      if (this.hasSpecialChars()) {
        this.emailErrorMessage = this.specialCharsErrorText;
      } else {
        this.getReadyFormService.setProperty('email', this.email);
        this.emailErrorMessage = '';
      }
    } else {
      this.emailErrorMessage = this.EmailErrorText;
    }

    if (this.confirmEmail) {
      this.checkConfirmationEmailError();
    }
  }

  validateConfirmationEmail(event: any, isOnChange: boolean) {
    if (isOnChange) {
      this.confirmEmail = event ? event : null;
      this.refreshButton();
      this.confirmEmailErrorMessage = '';
      if (this.timeOutEmailConfirm) {
        clearTimeout(this.timeOutEmailConfirm);
      }
      this.timeOutEmailConfirm = setTimeout(() => {
        this.checkConfirmationEmailError();
        this.refreshButton();
      }, 750);
    } else {
      this.refreshButton();
      this.checkConfirmationEmailError();
    }
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.doPost();
    }
  }

  isValidFormat() {
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(this.email);
  }

  hasSpecialChars() {
    if (this.email) {
      const email = this.email.toLowerCase();
      for (const char of email) {
        if (this.forbiddenChars.indexOf(char) > -1) {
          return true;
        }
      }
    }
    return false;
  }

  refreshButton() {
    this.isBtnDisabled =
      this.phoneNumber.length !== 9 ||
      !this.isValidFormat() ||
      this.hasSpecialChars() ||
      this.phoneNumber[0] !== '0' ||
      !this.getReadyFormService.getProperty('confirmEmail') ||
      this.email !== this.confirmEmail;
  }

  doPost() {
    this.navigationService.sendToNext();
  }

  newInputChange(input: string) {
    if (input === 'phoneNumber') {
      this.changePhone(this.form.controls[input].value);
    } else if (input === 'email') {
      this.validateEmail(this.form.controls[input].value, true);
    }
  }
}
