import { Injectable } from '@angular/core';
import { NetworkService } from '@latam/network';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

import { RouterSignature } from '../../router.signature';
import { RouterServiceEnum } from '../enum/router-service-enum';
import { AnalyticsInfo } from '../models/analytics_info';
import { DeviceInformation } from '../models/device-information';
import { CommunicatorService } from '../services/communicator.service';
import { AdminListService } from './admin-lists/admin-list-service/admin-list.service';

@Injectable()
export class LoginService {
  private NOT_AUTHORIZED = 401;
  private FORBIDDEN = 403;
  options: RouterSignature;
  incompatibleBrowser = false;
  safariSuggestion = false;
  deviceInformation = new DeviceInformation();
  analyticsInfo = new AnalyticsInfo();
  accessDeniedUrl: string;

  window = typeof window !== 'undefined' ? window : null;

  maintenance = false;

  constructor(
    private communicatorService: CommunicatorService,
    private latamItLoaderService: LatamItLoaderService,
    private adminListService: AdminListService,
    private networkService: NetworkService
  ) {}

  doLogon(callback: any) {
    this.options = new RouterSignature();
    this.options.body = {
      portal: 'abrecuentas-latam-oca',
      is_client: this.isClient().toString()
    };

    this.networkService.preLogin('api-logon/doLogon', this.options, { 'pre-login-flow': 'abrecuentas-latam-oca' }).subscribe(
      res => {
        console.log(res);
        const body = JSON.parse(res.body);
        console.log(body);
        console.log(this.options);
        this.analyticsInfo = body.data.analytics_google_info;
        this.accessDeniedUrl = body.data.access_denied_url;

        if (body.statusCode === this.NOT_AUTHORIZED) {
          window.location.href = body.data.access_desktop_denied_url;
        } else if (body.data.external_maintenance === 'true') {
          this.maintenance = true;
          callback();
        } else if (body.statusCode === this.FORBIDDEN) {
          this.incompatibleBrowser = true;
          callback();
        } else {
          if (body.data.suggest_safari_browser === 'true') {
            this.safariSuggestion = true;
          }

          this.deviceInformation = body.data.device_info;

          this.communicatorService.saveOPs(body.flow);

          this.adminListService.getAdminListsPost(RouterServiceEnum.GET_LIST_BUSINESS_CONFIGURATION).subscribe(adminListRes => {
            this.adminListService.adminLists = adminListRes.data;
            if (this.adminListService.adminLists['params_upload_oca'] !== undefined) {
              const index = this.adminListService.adminLists['params_upload_oca'].findIndex(elem => elem.is_image);
              if (index > -1) {
                this.adminListService.adminLists['params_upload_oca'] = [
                  this.adminListService.adminLists['params_upload_oca'][index]
                ];
              }
            }
            callback();
          });
        }
      },
      err => {
        console.log('POST error', err);
      },
      () => {
        this.latamItLoaderService.hide();
      }
    );
  }

  isClient(): boolean {
    return (
      window.location.href.indexOf('token') > -1 ||
      window.location.href.indexOf('session') > -1 ||
      ((localStorage.getItem('token') != null || localStorage.getItem('sessionId') != null) &&
        window.location.hash.indexOf('/sucursales-vendedor') === -1 &&
        localStorage.getItem('seller') == null &&
        window.location.hash.indexOf('/preparate-bienvenida') === -1)
    );
  }
}
