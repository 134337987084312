export const Info = {
  incompatibleBrowser: {
    title: 'Tu navegador aún no es compatible',
    subtitle: 'Para obtener una experiencia óptima, asegurate de tener la última versión de Safari o Chrome.',
  },
  safariSuggestion: {
    title: 'Te sugerimos continuar en Safari',
    subtitle:
      // tslint:disable-next-line:max-line-length
      'Para asegurarte una experiencia óptima, te sugerimos continuar tu solicitud en Safari. De lo contrario, entrá en las configuraciones del navegador y permití el acceso a la cámara.',
  },
};
