<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='setAtual()'></header-oca>

  <main>

    <h1 class="paddingless" id="titleId">
      ¿Cuál es tu
      <strong>estado civil</strong>?
    </h1>

    <div class="radio-button">

      <voxel-radio-button-group>
        <voxel-radio-button
          *ngFor="let civilState of civilStates"
          id="radio1"
          [radioName]="' '"
          [value]="civilState.code"
          [checked]="civilState.checked"
          (radioButtonChange)='changeCivilState($event)'>
          {{civilState.label}}
        </voxel-radio-button>
      </voxel-radio-button-group>

    </div>
  </main>
</div>

<footer>
  <button-sidebyside
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>

<facebook-pixel></facebook-pixel>