export class MoneyFull {

  currency: string;
  amount: number;
  decimals: number;

  constructor() {
    this.currency = '';
    this.amount = 0;
    this.decimals = 0;
  }
}
