<div (mousedown)="handleMousedown($event)" [class.ng-has-value]="hasValue" class="latam-it-search-input-select-container">
  <div class="ng-value-container">
      <div class="ng-placeholder">{{placeholder}}</div>

      <ng-container *ngIf="!multiLabelTemplate && selectedItems.length > 0">
          <div [class.ng-value-disabled]="item.disabled" class="ng-value" *ngFor="let item of selectedItems">
              <ng-template #defaultLabelTemplate>
                  <span class="ng-value-icon left" (click)="unselect(item);" aria-hidden="true">×</span>
                  <span class="ng-value-label">{{item.label}}</span>
              </ng-template>

              <ng-template
                  [ngTemplateOutlet]="labelTemplate || defaultLabelTemplate"
                  [ngTemplateOutletContext]="{ item: item.value, clear: clearItem, label: item.label }">
              </ng-template>
          </div>
      </ng-container>

      <ng-template *ngIf="multiLabelTemplate && selectedValues.length > 0"
              [ngTemplateOutlet]="multiLabelTemplate"
              [ngTemplateOutletContext]="{ items: selectedValues, clear: clearItem }">
      </ng-template>

      <div class="ng-input">
          <input #filterInput
                 type="text"
                 [attr.autocomplete]="labelForId ? 'off' : dropdownId"
                 [attr.id]="labelForId"
                 [attr.tabindex]="tabIndex"
                 [readOnly]="!searchable"
                 [disabled]="disabled"
                 [attr.placeholder]="placeholder"
                 [value]="filterValue ? filterValue : ''"
                 (input)="filter(filterInput.value)"
                 (focus)="onInputFocus($event)"
                 (blur)="onInputBlur($event)"
                 (change)="$event.stopPropagation()"
                 role="combobox"
                 [attr.aria-expanded]="isOpen"
                 [attr.aria-owns]="isOpen ? dropdownId : null"
                 [attr.aria-activedescendant]="isOpen ? itemsList?.markedItem?.htmlId : null">
      </div>
  </div>

  <div class="ng-spinner-loader" *ngIf="loading"></div>

  <span *ngIf="showClear()" class="ng-clear-wrapper" title="{{clearAllText}}">
      <span class="ng-clear" aria-hidden="true">×</span>
  </span>

  <span class="ng-arrow-wrapper">
      <span class="ng-arrow"></span>
  </span>

  <ng-dropdown-panel *ngIf="isOpen"
  class="ng-dropdown-panel"
  [virtualScroll]="virtualScroll"
  [bufferAmount]="bufferAmount"
  [appendTo]="appendTo"
  [position]="dropdownPosition"
  [headerTemplate]="headerTemplate"
  [footerTemplate]="footerTemplate"
  [filterValue]="filterValue"
  [items]="itemsList.filteredItems"
  [markedItem]="itemsList.markedItem"
  (update)="viewPortItems = $event"
  (scroll)="scroll.emit($event)"
  (scrollToEnd)="scrollToEnd.emit($event)"
  (outsideClick)="close()"
  [class.latam-it-search-input-select-multiple]="multiple"
  [ngClass]="classes"
  [id]="dropdownId">

  <ng-container>
      <div class="ng-option" [attr.role]="item.children ? 'group' : 'option'" (click)="toggleItem(item)" (mouseover)="onItemHover(item)"
              *ngFor="let item of viewPortItems"
              [class.ng-option-disabled]="item.disabled"
              [class.ng-option-selected]="item.selected"
              [class.ng-optgroup]="item.children"
              [class.ng-option]="!item.children"
              [class.ng-option-child]="!!item.parent"
              [class.ng-option-marked]="item === itemsList.markedItem"
              [attr.aria-selected]="item.selected"
              [attr.id]="item?.htmlId">

          <ng-template #defaultOptionTemplate>
              <span class="ng-option-label">{{item.label}}</span>
          </ng-template>

          <ng-template
              [ngTemplateOutlet]="item.children ? (optgroupTemplate || defaultOptionTemplate) : (optionTemplate || defaultOptionTemplate)"
              [ngTemplateOutletContext]="{ item: item.value, item$:item, index: item.index, searchTerm: filterValue }">
          </ng-template>
      </div>

      <div class="ng-option" [class.ng-option-marked]="!itemsList.markedItem" (mouseover)="itemsList.unmarkItem()" role="option" (click)="selectTag()" *ngIf="showAddTag">
          <ng-template #defaultTagTemplate>
              <span><span class="ng-tag-label">{{addTagText}}</span>"{{filterValue}}"</span>
          </ng-template>

          <ng-template
              [ngTemplateOutlet]="tagTemplate || defaultTagTemplate"
              [ngTemplateOutletContext]="{ searchTerm: filterValue }">
          </ng-template>
      </div>
  </ng-container>

  <ng-container *ngIf="showNoItemsFound()">
      <ng-template #defaultNotFoundTemplate>
          <div class="ng-option ng-option-disabled">{{notFoundText}}</div>
      </ng-template>

      <ng-template
          [ngTemplateOutlet]="notFoundTemplate || defaultNotFoundTemplate"
          [ngTemplateOutletContext]="{ searchTerm: filterValue }">
      </ng-template>
  </ng-container>

  <ng-container *ngIf="showTypeToSearch()">
      <ng-template #defaultTypeToSearchTemplate>
          <div class="ng-option ng-option-disabled">{{typeToSearchText}}</div>
      </ng-template>

      <ng-template
          [ngTemplateOutlet]="typeToSearchTemplate || defaultTypeToSearchTemplate">
      </ng-template>
  </ng-container>

  <ng-container *ngIf="loading && itemsList.filteredItems.length === 0">
      <ng-template #defaultLoadingTextTemplate>
          <div class="ng-option ng-option-disabled">{{loadingText}}</div>
      </ng-template>

      <ng-template
          [ngTemplateOutlet]="loadingTextTemplate || defaultLoadingTextTemplate"
          [ngTemplateOutletContext]="{ searchTerm: filterValue }">
      </ng-template>
  </ng-container>

</ng-dropdown-panel>
</div>

<div>


</div>
