import { Injectable } from '@angular/core';
import { NetworkService } from '@latam/network';
import { RouterSignature } from 'src/app/router.signature';

import { RouterServiceEnum } from '../../../enum/router-service-enum';
import { CommunicatorService } from '../../communicator.service';
import { AdminListEnum } from '../enum/admin-list.enum';

@Injectable()
export class AdminListService {
  adminLists = new Object();

  constructor(private communicatorService: CommunicatorService, private networkService: NetworkService) {}

  getAdminListsPost(key: RouterServiceEnum) {
    const objOP = this.communicatorService.getOP(key.toString());
    const body = this.transformEnumToArray();

    // tslint:disable-next-line: early-exit
    if (objOP) {
      const options = new RouterSignature();
      options.op = objOP.op;
      options.body = body;

      return this.networkService.post(options.op, options, { op: options.op });
    }
  }

  transformEnumToArray(): string[] {
    const keys = Object.keys(AdminListEnum).filter(key => {
      return AdminListEnum[key] === AdminListEnum[key].toLowerCase();
    });

    return keys.map(key => AdminListEnum[key]);
  }

  getAdminList(listName: AdminListEnum): any[] {
    return this.adminLists[listName];
  }
}
