import { Component, OnInit } from '@angular/core';

import { LoginService } from '../services/login.service';
import { ErrorTryLaterService } from './error-try-later.service';

@Component({
  selector: 'error-try-later',
  templateUrl: './error-try-later.component.html',
  styleUrls: ['./error-try-later.component.scss'],
  providers: [ErrorTryLaterService],
})
export class ErrorTryLaterComponent implements OnInit {
  errorMessage1: string;
  errorMessage2: string;
  errorInfo: any[];

  constructor(private errorTryLaterService: ErrorTryLaterService, private loginService: LoginService) {
    this.errorMessage1 = `<p>Estamos trabajando para reestablecer el servicio a la mayor brevedad posible.</p>`;
    this.errorMessage2 = `<p> Por favor reintentá mas tarde. Si el error persiste, comunicate con nosotros que te vamos a ayudar. </p>`;
  }

  ngOnInit() {
    this.setErrorMessage();
    this.setErrorInfo();
  }

  setErrorMessage() {
    const message = this.errorTryLaterService.errorMessage;

    if (message) {
      this.errorMessage1 = message;
    }
  }

  setErrorInfo() {
    this.errorInfo = [
      {
        icon: 'icon-itaufonts_telefone',
        title: 'Hola OCA',
        href: 'tel:1730',
        subtitle: '1730',
      },
      {
        icon: 'icon-itaufonts_email_resp_rapida',
        href: 'mailto:ocablue-contactanos@oca.com.uy',
        subtitle: 'enviar correo electrónico',
      },
    ];
  }

  back() {
    window.location.href = this.loginService.accessDeniedUrl;
  }
}
