import {
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';

import detectPrefixes from './utils/detect-prefixes';
import detectSupportsPassive from './utils/detect-supportsPassive';
import dispatchEvent from './utils/dispatch-event.js';

@Component({
  selector: 'voxel-mb-carousel',
  templateUrl: './voxel-mb-carousel.component.html',
  styleUrls: ['./voxel-mb-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VoxelMbCarouselComponent implements OnInit {
  /**
   * Número de slides por passagem
   * @slidesToScroll {Number}
   */
  @Input() slidesToScroll = 1;

  /**
   * Tempo da animação do slide em milisegundos
   * @slideSpeed {Number}
   */
  @Input() slideSpeed = 300;

  /**
   * Slide que é mostrado ao iniciar o carousel
   * @initialIndex {Number}
   */
  @Input() initialIndex = 0;

  /**
   * Ativa o evento de mouse/touch/swipe no carousel
   * @enableMouseEvents {Boolean}
   */
  @Input() enableMouseEvents = true;

  /**
   * Retorna para o primeiro slide caso avance no último com animação de retroceder
   * Usar infinite OU rewind, nunca os dois
   * Caso os dois sejam falso, esconde o botão nos limites min/max
   * @rewind {Boolean}
   */
  @Input() rewind = false;

  /**
   * Tempo em milisegundos da animação do retroceder
   * @rewindSpeed {Number}
   */
  @Input() rewindSpeed = 600;

  /**
   * Número de slides visiveis ao voltar
   * Usar infinite OU rewind, nunca os dois
   * Caso os dois sejam falso, esconde o botão nos limites min/max
   * @infinite {Number | Boolean}
   */
  @Input() infinite: any = false;

  /**
   * Tempo em milisegundos da animação ao falhar o swipe
   * @snapBackSpeed {Number}
   */
  @Input() snapBackSpeed = 200;

  @Output() slideEvent = new EventEmitter();

  /**
   * Basic easing functions: https://developer.mozilla.org/de/docs/Web/CSS/transition-timing-function
   * cubic bezier easing functions: http://easings.net/de
   * @ease {String}
   */
  ease = 'ease';

  /**
   * Template para inserir os slides para o carousel.
   */
  @ContentChild('tplSlides', { static: true }) tplSlides: TemplateRef<any>;

  /**
   * Template para inserir o botão voltar
   */
  @ContentChild('tplPrev', { static: true }) tplPrev: TemplateRef<any>;

  /**
   * Template para inserir o botão avançar
   */
  @ContentChild('tplNext', { static: true }) tplNext: TemplateRef<any>;

  // Classes CSS do Carousel

  // Nome da classe do frame do Carousel
  classNameFrame = 'voxel-carousel-frame';

  // Nome da classe do container dos slides
  classNameSlideContainer = 'voxel-carousel-slide-container';

  // Nome da classe do botão anterior
  classNamePrevCtrl = 'voxel-carousel-btn-prev';

  // Nome da classe do botão próximo
  classNameNextCtrl = 'voxel-carousel-btn-next';

  // Nome da classe do slide ativo
  classNameActiveSlide = 'voxel-carousel-slide-active';

  /**
   * window instance
   * @window {object}
   */
  window = typeof window !== 'undefined' ? window : null;

  component = 'VoxelMbListComponent';

  slice = Array.prototype.slice;

  position: {
    x: number;
    y: number;
  };

  slidesWidth: number;
  frameWidth: number;
  slides: any[];

  /**
   * slider DOM elements
   */
  frame;
  slideContainer;
  prevCtrl;
  nextCtrl;
  prefixes;
  transitionEndCallback;

  index = 0;
  touchEventParams = detectSupportsPassive() ? { passive: true } : false;

  // events

  touchOffset;
  delta;
  isScrolling: boolean;

  // @Input()
  slider;

  showDots = true; // parametrizar

  // Variáveis dos dots
  simple_dots;
  dot_count: number;
  dot_container;
  dot_list_item;

  /**
   * Evento disparado no click/touch do carousel
   */
  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onTouchStartEvent(event) {
    this.onTouchstart(event);
  }

  /**
   * Evento disparado no swipe do carousel
   */
  @HostListener('mousemove', ['$event'])
  @HostListener('touchmove', ['$event'])
  onTouchMoveEvent(event) {
    this.onTouchmove(event);
  }

  /**
   * Evento disparado no final do click/touch
   */
  @HostListener('mouseup', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('mouseleave', ['$event'])
  onTouchEndEvent(event) {
    this.onTouchend(event);
  }

  /**
   * Evento disparado na mudança do tamanho da janela do navegador
   */
  @HostListener('window:resize', ['$event'])
  onResizeEvent(event) {
    this.onResize(event);
  }

  /**
   * Evento para tratar os botões de navegação
   */
  @HostListener('before.lory.init', ['$event'])
  @HostListener('after.lory.init', ['$event'])
  @HostListener('after.lory.slide', ['$event'])
  @HostListener('on.lory.resize', ['$event'])
  onCarouselChangeEvent(event) {
    // tslint:disable-next-line: early-exit
    if (this.showDots) {
      setTimeout(() => {
        this.updateDots(event);
      }, 0);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.setup();
    }, 0);
  }

  setup() {
    this.slider = document.querySelector('.voxel-carousel-container');
    this.dispatchSliderEvent('before', 'init', null);

    this.prefixes = detectPrefixes();

    this.index = this.initialIndex;
    this.frame = this.slider.getElementsByClassName(this.classNameFrame)[0];
    this.slideContainer = this.frame.getElementsByClassName(this.classNameSlideContainer)[0];
    this.prevCtrl = this.slider.getElementsByClassName(this.classNamePrevCtrl)[0];
    this.nextCtrl = this.slider.getElementsByClassName(this.classNameNextCtrl)[0];

    if (this.showDots) {
      this.simple_dots = document.querySelector('.voxel-carousel-container');
      this.dot_count = this.simple_dots.querySelectorAll('.carousel-slide').length;
      this.dot_container = this.simple_dots.querySelector('.js_dots');
      this.dot_list_item = document.createElement('li');
    }

    this.position = {
      x: this.slideContainer.offsetLeft,
      y: this.slideContainer.offsetTop
    };

    if (this.infinite) {
      this.slides = this.setupInfinite(this.slice.call(this.slideContainer.children));
    } else {
      this.slides = this.slice.call(this.slideContainer.children);

      if (!!!this.rewind && !!!this.infinite) {
        this.prevCtrl.classList.add('hidden');
      }
    }

    this.reset();

    if (this.classNameActiveSlide) {
      this.setActiveElement(this.slides, this.index);
    }

    this.resolveButtons();

    this.dispatchSliderEvent('after', 'init');
  }

  /**
   * Seta a classe 'active' para o slide atual
   */
  setActiveElement(slides, currentIndex) {
    slides.forEach((element, index) => {
      if (element.classList.contains(this.classNameActiveSlide)) {
        element.classList.remove(this.classNameActiveSlide);
      }
    });

    slides[currentIndex].classList.add(this.classNameActiveSlide);
  }

  /**
   * Setup do infinite
   *
   * @param  {array} slideArray
   * @return {array} array atualizado com slideContainer elements
   */
  setupInfinite(slideArray: any[]): any[] {
    const front = slideArray.slice(0, this.infinite);
    const back = slideArray.slice(slideArray.length - this.infinite, slideArray.length);

    front.forEach(element => {
      const cloned = element.cloneNode(true);
      this.slideContainer.appendChild(cloned);
    });

    back.reverse().forEach(element => {
      const cloned = element.cloneNode(true);
      this.slideContainer.insertBefore(cloned, this.slideContainer.firstChild);
    });

    this.slideContainer.addEventListener(this.prefixes.transitionEnd, this.onTransitionEnd);

    return this.slice.call(this.slideContainer.children);
  }

  /**
   * [dispatchSliderEvent description]
   * @return {[type]} [description]
   */
  dispatchSliderEvent(phase, type, detail?) {
    dispatchEvent(this.slider, `${phase}.lory.${type}`, detail);
  }

  /**
   * translates to a given position in a given time in milliseconds
   *
   * @to        {number} number in pixels where to translate to
   * @duration  {number} time in milliseconds for the transistion
   * @ease      {string} easing css property
   */
  translate(to, duration, ease?) {
    const style = this.slideContainer && this.slideContainer.style;

    // tslint:disable-next-line: early-exit
    if (style) {
      style[this.prefixes.transition + 'TimingFunction'] = ease;
      style[this.prefixes.transition + 'Duration'] = duration + 'ms';

      if (this.prefixes.hasTranslate3d) {
        style[this.prefixes.transform] = 'none';
      } else {
        style[this.prefixes.transform] = 'none';
      }
    }
  }

  /**
   * Retorna o width de um elemento
   */
  elementWidth(element) {
    return element.getBoundingClientRect().width || element.offsetWidth;
  }

  /**
   * Função de trocar o slide chamada pelo next, prev e touchend
   *
   * Determina o próximo slide e faz a troca
   *
   * @direction  {boolean}
   */
  slide(_nextIndex, direction?) {
    let nextIndex = _nextIndex;
    const duration = this.slideSpeed;

    const nextSlide = direction ? this.index + 1 : this.index - 1;
    const maxOffset = Math.round(this.slidesWidth - this.frameWidth);

    const param: any = {};
    param.index = this.index;
    param.nextSlide = nextSlide;

    this.dispatchSliderEvent('before', 'slide', param);

    if (typeof nextIndex !== 'number') {
      if (direction) {
        if (this.infinite && this.index + this.infinite * 2 !== this.slides.length) {
          nextIndex = this.index + (this.infinite - (this.index % this.infinite));
        } else {
          nextIndex = this.index + this.slidesToScroll;
        }
      } else if (this.infinite && this.index % this.infinite !== 0) {
        nextIndex = this.index - (this.index % this.infinite);
      } else {
        nextIndex = this.index - this.slidesToScroll;
      }
    }

    nextIndex = Math.min(Math.max(nextIndex, 0), this.slides.length - 1);

    if (this.infinite && direction === undefined) {
      nextIndex += this.infinite;
    }

    const nextOffset = Math.min(Math.max(this.slides[nextIndex].offsetLeft * -1, maxOffset * -1), 0);

    this.resolveButtons(nextIndex);
    /**
     * translate to the nextOffset by a defined duration and ease function
     */
    this.translate(nextOffset, duration, this.ease);

    /**
     * update the position with the next position
     */
    this.position.x = nextOffset;

    /**
     * update the this.index with the nextIndex only if
     * the offset of the nextIndex is in the range of the maxOffset
     */
    if (this.slides[nextIndex].offsetLeft <= maxOffset) {
      this.index = nextIndex;
    }

    if (
      this.infinite &&
      (nextIndex === this.slides.length - this.infinite ||
        nextIndex === this.slides.length - (this.slides.length % this.infinite) ||
        nextIndex === 0)
    ) {
      if (direction) {
        this.index = this.infinite;
      }

      if (!direction) {
        this.index = this.slides.length - this.infinite * 2;
      }

      this.position.x = this.slides[this.index].offsetLeft * -1;

      this.transitionEndCallback = () => {
        this.translate(this.slides[this.index].offsetLeft * -1, 0, undefined);
      };
    }

    if (this.classNameActiveSlide) {
      this.setActiveElement(this.slice.call(this.slides), this.index);
    }

    this.dispatchSliderEvent('after', 'slide', {
      currentSlide: this.index
    });
  }

  /**
   * Função de reset chamada pelo resize e inicialização
   */
  reset() {
    this.slidesWidth = this.elementWidth(this.slideContainer);
    this.frameWidth = this.elementWidth(this.frame);

    if (this.frameWidth === this.slidesWidth) {
      this.slidesWidth = this.slides.reduce((previousValue, slide) => {
        return previousValue + this.elementWidth(slide);
      }, 0);
    }

    // this.ease = null;
    // this.rewindSpeed = 0;

    if (this.infinite) {
      this.translate(this.slides[this.index + this.infinite].offsetLeft * -1, 0, null);

      this.index = this.index + this.infinite;
      this.position.x = this.slides[this.index].offsetLeft * -1;
    } else {
      this.translate(this.slides[this.index].offsetLeft * -1, 1, this.ease);
      this.position.x = this.slides[this.index].offsetLeft * -1;
    }

    if (this.classNameActiveSlide) {
      this.setActiveElement(this.slice.call(this.slides), this.index);
    }

    // Atualiza os dots
    this.dispatchSliderEvent('after', 'slide', {
      currentSlide: this.index
    });
  }

  slideTo(index) {
    this.slide(index);
  }

  returnIndex() {
    return this.index - this.infinite || 0;
  }

  prev() {
    this.slide(false, false);
  }

  next() {
    this.slide(false, true);
  }

  resolveButtons(index?) {
    let nextIndex;

    if (index === undefined) {
      nextIndex = this.initialIndex;
    } else {
      nextIndex = index;
    }
    // tslint:disable-next-line: early-exit
    if (!!!this.rewind && !!!this.infinite) {
      if (nextIndex + 1 === this.slides.length) {
        this.nextCtrl.classList.add('hidden');
      } else {
        this.nextCtrl.classList.remove('hidden');
      }

      if (nextIndex + 1 === 1) {
        this.prevCtrl.classList.add('hidden');
      } else {
        this.prevCtrl.classList.remove('hidden');
      }
    }
  }

  // Funções dos eventos

  onTouchstart(event) {
    const touches = event.touches ? event.touches[0] : event;

    const { pageX, pageY } = touches;

    this.touchOffset = {
      x: pageX,
      y: pageY,
      time: Date.now()
    };

    this.isScrolling = undefined;

    this.delta = {};

    this.dispatchSliderEvent('on', 'touchstart', {
      event
    });
  }

  onTouchmove(event) {
    // tslint:disable-next-line: early-exit
    if (event.touches && this.enableMouseEvents) {
      const { pageX, pageY } = event.touches[0];

      this.delta = {
        x: pageX - this.touchOffset.x,
        y: pageY - this.touchOffset.y
      };

      if (typeof this.isScrolling === 'undefined') {
        this.isScrolling = !!(this.isScrolling || Math.abs(this.delta.x) < Math.abs(this.delta.y));
      }

      if (!this.isScrolling && this.touchOffset) {
        this.translate(this.position.x + this.delta.x, 0, null);
      }

      this.dispatchSliderEvent('on', 'touchmove', {
        event
      });
    }
  }

  onTouchend(event) {
    // tslint:disable-next-line: early-exit
    if (event.touches && this.enableMouseEvents) {
      /**
       * tempo em milisegundos entre inicio e fim do touch
       * @duration {number}
       */
      const duration = this.touchOffset ? Date.now() - this.touchOffset.time : undefined;

      /**
       * é válido se:
       *
       * -> intervalo do swipe for maior que 300 ms
       * E
       * -> distância do swipe for maior que 25px
       * OU
       * -> distância do swipe for maior que 1/3 do frame
       *
       * @isValidSlide {Boolean}
       */
      const isValid = (Number(duration) < 300 && Math.abs(this.delta.x) > 35) || Math.abs(this.delta.x) > this.frameWidth / 3;

      /**
       * é fora do limite se:
       *
       * -> this.index é 0 e delta x maior que 0
       * OU
       * -> this.index é o último slide e delta é menor que 0
       *
       * @isOutOfBounds {Boolean}
       */
      const isOutOfBounds = (!this.index && this.delta.x > 0) || (this.index === this.slides.length - 1 && this.delta.x < 0);

      const direction = this.delta.x < 0;

      if (!this.isScrolling) {
        if (isValid && !isOutOfBounds) {
          this.slide(false, direction);
        } else {
          this.translate(this.position.x, this.snapBackSpeed);
        }
      }

      this.touchOffset = undefined;

      this.dispatchSliderEvent('on', 'touchend', {
        event
      });
    }
  }

  onTransitionEnd() {
    if (this.transitionEndCallback) {
      this.transitionEndCallback();
      this.transitionEndCallback = undefined;
    }
  }

  updateDots(e) {
    if (e.type === 'before.lory.init') {
      for (let i = 0, len = this.dot_count; i < len; i++) {
        const clone = this.dot_list_item.cloneNode();
        this.dot_container.appendChild(clone);
      }
      this.dot_container.childNodes[0].classList.add('active');
    }
    if (e.type === 'after.lory.init') {
      for (let i = 0, len = this.dot_count; i < len; i++) {
        this.dot_container.childNodes[i].addEventListener('click', function(event) {
          this.dot_navigation_slider.slideTo(Array.prototype.indexOf.call(this.dot_container.childNodes, event.target));
        });
      }
    }
    if (e.type === 'after.lory.slide') {
      for (let i = 0, len = this.dot_container.childNodes.length; i < len; i++) {
        this.dot_container.childNodes[i].classList.remove('active');
      }
      this.dot_container.childNodes[e.detail.currentSlide].classList.add('active');
      this.slideEvent.emit(this.index);
    }
    // tslint:disable-next-line: early-exit
    if (e.type === 'on.lory.resize') {
      for (let i = 0, len = this.dot_container.childNodes.length; i < len; i++) {
        this.dot_container.childNodes[i].classList.remove('active');
      }
      this.dot_container.childNodes[0].classList.add('active');
    }
  }

  onResize(event) {
    // tslint:disable-next-line: early-exit
    if (this.frameWidth !== this.elementWidth(this.frame)) {
      this.reset();

      this.dispatchSliderEvent('on', 'resize', {
        event
      });
    }
  }

  OnDestroy() {
    this.dispatchSliderEvent('before', 'destroy');

    // Remove eventos
    this.frame.removeEventListener(this.prefixes.transitionEnd, this.onTransitionEnd);

    // Remove clones dos slides caso inifnite esteja setado
    if (this.infinite) {
      Array.apply(null, Array(this.infinite)).forEach(() => {
        this.slideContainer.removeChild(this.slideContainer.firstChild);
        this.slideContainer.removeChild(this.slideContainer.lastChild);
      });
    }

    this.dispatchSliderEvent('after', 'destroy');
  }
}
