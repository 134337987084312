import { Injectable } from '@angular/core';

@Injectable()
export class NgSelectConfig {
  placeholder: string;
  notFoundText = '';
  typeToSearchText = 'Ingrese filtros';
  addTagText = '';
  loadingText = 'Cargando...';
  clearAllText = 'Borrar todo';
  disableVirtualScroll = true;
  openOnEnter = true;
}
