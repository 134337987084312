import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientBenefitsComissionsComponent } from './client-benefits-comissions/client-benefits-comissions.component';
import { ClientConfirmationComponent } from './client-confirmation/client-confirmation.component';
import { ClientContactInfoComponent } from './client-contact-info/client-contact-info.component';
import { ClientCreditsComponent } from './client-credits/client-credits.component';
import { ClientDeliveryAddressComponent } from './client-delivery-address/client-delivery-address.component';
import { ClientDeliveryTimeComponent } from './client-delivery-time/client-delivery-time.component';
import { ClientEndComponent } from './client-end/client-end.component';
import { ClientMoneyComponent } from './client-money/client-money.component';
import { ClientPersonalAddressComponent } from './client-personal-address/client-personal-address.component';
import { ClientPurposeComponent } from './client-purpose/client-purpose.component';


export const routes: Routes = [
  {
    path: 'cliente-beneficios',
    component: ClientBenefitsComissionsComponent
  },
  {
    path: 'cliente-datos-contacto',
    component: ClientContactInfoComponent
  },
  {
    path: 'cliente-proposito',
    component: ClientPurposeComponent
  },
  {
    path: 'cliente-saldo-promedio',
    component: ClientMoneyComponent
  },
  {
    path: 'cliente-acreditaciones-mensuales',
    component: ClientCreditsComponent
  },
  {
    path: 'cliente-domicilio-personal',
    component: ClientPersonalAddressComponent
  },
  {
    path: 'cliente-domicilio-entrega',
    component: ClientDeliveryAddressComponent
  },
  {
    path: 'cliente-hora-entrega',
    component: ClientDeliveryTimeComponent
  },
  {
    path: 'cliente-confirmacion',
    component: ClientConfirmationComponent
  },
  {
    path: 'cliente-fin',
    component: ClientEndComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ClientRoutingModule {}
