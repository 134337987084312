import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProgressBarService } from 'src/app/progressBar.service';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ClientCommonService } from '../shared/client-common.service';
import { ClientFormService } from '../shared/client-form.service';
import { ClientNavigationService } from '../shared/client-navigation.service';
import { Analytics } from './../../shared/analytics';

@Component({
  selector: 'client-purpose',
  templateUrl: './client-purpose.component.html',
  styleUrls: ['../shared/client-pld-styles.scss']
})
export class ClientPurposeComponent implements OnInit {
  pageId = 'pld-purpose';
  showAnotherReason: boolean;
  reasons: any[] = [];
  allReasons: any[] = [];
  otherReason: string;
  isBtnDisabled = false;
  hintObject: any;
  otherReasonId = 'otro motivo';
  otherReasonError: string;
  otherReasonInvalid: string;
  accPurpose = [
    {
      id: 'finanzas personales',
      specifics: ''
    }
  ];

  form = new FormGroup({
    otromotivo: new FormControl('')
  });

  constructor(
    private formService: ClientFormService,
    private commonService: ClientCommonService,
    public navigationService: ClientNavigationService,
    private analyticsLib: Analytics,
    private progressBarService: ProgressBarService
  ) {
    this.hintObject = {};
  }

  ngOnInit() {
    this.setProgressBarClient();
    this.reasons = this.allReasons = this.commonService.getAdminList(AdminListEnum.ACCOUNT_PURPOSE);
    this.otherReason = '';
    this.showAnotherReason = false;
    this.resolveHint();

    this.setData();
    this.refreshButton();
    this.otherReasonError = 'Utilizá solo letras. Evitá caracteres del tipo: -, #, &.';
    this.otherReasonInvalid = '';
    this.analyticsLib.buildData('PropositoAperturaPLD', this.pageId, '03', true);
    this.analyticsLib.trackState(null, null);
    this.commonService.scrollToTop(document);
  }

  resolveHint() {
    this.hintObject.message = `el propósito de la cuenta es el objetivo principal por el cuál estás abriendo tus cuentas con nosotros.`;
    this.hintObject.value = 'offer';
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.navigationService.sendToNext();
    }
  }

  refreshButton() {
    if (this.showAnotherReason) {
      this.isBtnDisabled =
        this.formService.getProperty('account_purpose')[0].specifics.length < 4 ||
        !this.commonService.validateUserInput(this.formService.getProperty('account_purpose')[0].specifics);
    } else {
      this.isBtnDisabled =
        !this.formService.getProperty('account_purpose') ||
        this.formService.getProperty('account_purpose')[0].id === this.otherReasonId;
    }
  }

  setData() {
    const otherReasonId = this.otherReasonId;
    if (this.formService.getProperty('account_purpose')) {
      this.accPurpose = this.formService.getProperty('account_purpose');
      this.otherReason = this.formService.getProperty('account_purpose')[0].specifics;
      if (
        this.formService.getPreviousStage() === 'ClientMoney' &&
        this.formService.getProperty('account_purpose')[0].id === otherReasonId
      ) {
        this.reasons = [this.allReasons.find(elem => elem.code.toLowerCase() === otherReasonId)];
        this.showAnotherReason = true;
      }
    } else {
      const reason = this.reasons.find(open_reason => open_reason.label.toLowerCase() === 'finanzas personales');
      if (reason) {
        this.accPurpose = [
          {
            id: reason.label,
            specifics: ''
          }
        ];
        reason.checked = true;
        this.formService.setProperty('account_purpose', this.accPurpose);
      }
    }
  }

  changeReason(reason: any) {
    this.showAnotherReason = reason.code === this.otherReasonId;
    if (!this.showAnotherReason) {
      this.otherReason = '';
    }
    this.setPurpose(reason.code, this.otherReason);
  }

  onInputValueChange(value: string) {
    this.setPurpose(this.otherReasonId, value);
    // tslint:disable-next-line: early-exit
    if (this.commonService.validateUserInput(value)) {
      this.otherReasonInvalid = '';
    } else {
      this.otherReasonInvalid = this.otherReasonError;
      this.form.controls['otromotivo'].setErrors({ incorrect: true });
      this.analyticsError(this.otherReasonInvalid);
    }
  }

  analyticsError(error: string) {
    this.analyticsLib.trackAction({
      customCategory: `Header | Warning | Top`,
      customAction: `Show`,
      customLabel: `Error`,
      errorname: error
    });
  }

  setPurpose(id: string, reason?: string) {
    this.accPurpose = [
      {
        id: id || 'otro motivo',
        specifics: reason || ''
      }
    ];

    if (id && id.toLowerCase() === this.otherReasonId) {
      this.reasons = [this.allReasons.find(elem => elem.code.toLowerCase() === id.toLowerCase())];
    }
    this.formService.setProperty('account_purpose', this.accPurpose);
    this.refreshButton();
  }

  doPrevious() {
    // tslint:disable-next-line: early-exit
    if (this.showAnotherReason) {
      this.showAnotherReason = false;
      this.reasons = this.allReasons;
      this.refreshButton();
    } else {
      this.navigationService.sendToPrevious();
    }
  }

  setProgressBarClient() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 3,
      currentLabel: 2,
      amountBar: 3,
      currentBar: 1,
      labelName: 'Uso de la cuenta'
    });
  }
}
