import { OfferPackage } from './offer-package-model';
import { AffinityGroup } from './affinity-group.model';
import { CardBrand } from './card-brand.model';

export class OffersModel {
  offer_id: string;
  offer_type: string;
  packages: OfferPackage[];
  accepted_credit: boolean;
  accepted_credit_card: boolean;
  affinity_group: AffinityGroup;
  closing_date: string;
  credit_limit: number;
  credit_card_limit: number;
  selected_package: number;
  selected_credit_card_index: number;
  selected_credit_card_id: number;
  cardBrand: CardBrand;

  constructor() {
    this.offer_id = '';
    this.offer_type = '';
    this.packages = [new OfferPackage()];
    this.affinity_group = new AffinityGroup();
    this.accepted_credit = false;
    this.accepted_credit_card = false;
    this.closing_date = '';
    this.credit_limit = Number('0');
    this.credit_card_limit = Number('0');
    this.selected_package = Number('0');
    this.selected_credit_card_index = Number('0');
    this.selected_credit_card_id = Number('0');
    this.cardBrand = new CardBrand();
  }
}
