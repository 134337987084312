import { Component } from '@angular/core';

import { LoginService } from '../services/login.service';

@Component({
  selector: 'camera-error',
  templateUrl: './camera-error.component.html',
  styleUrls: ['./camera-error.component.scss']
})
export class CameraErrorComponent {
  constructor(private loginService: LoginService) {}

  sendToLandingPage() {
    window.location.href = this.loginService.accessDeniedUrl;
  }
}
