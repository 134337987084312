<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='4'></header-oca>

  <main>

    <h1 class="paddingless" id="titleId">
      ¿Cuál es tu
      <strong>estado civil</strong>?
    </h1>

    <div class="radio-button">
      <voxel-radio-button-group>
        <voxel-radio-button
          *ngFor="let civilState of civilStates"
          id="radio1"
          [radioName]="' '"
          [value]="civilState.code"
          [checked]="civilState.checked"
          (radioButtonChange)='changeCivilState(civilState)'>
          {{civilState.label}}
        </voxel-radio-button>
      </voxel-radio-button-group>
    </div>

    <div *ngIf="showDocumentNumber" class='input-container'>
      <voxel-form-field 
        id="document_number"
        [formGroup]="formDocumentNumber" 
        label="Cédula de tu cónyugue" 
        [msgErr]="documentInvalid">
        <input 
            voxelInput 
            id="codigoId" 
            formControlName="docNumber" 
            (blur)="validateDocument()"
            [value]="partner.document.number"
            (ngModelChange)="changeNumber($event)"
            voxelMask="9.999.999-9"
            (keyup.enter)="isEnter($event)">
      </voxel-form-field>
    </div>

  </main>
</div>

<footer>
  <button-sidebyside
    previousHook="true"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="navigationService"
    (hookPreviousAction)="doPrevious()">
  </button-sidebyside>
</footer>

<facebook-pixel></facebook-pixel>