<div id='{{pageId}}'></div>
<header-oca [atual]='1'></header-oca>

<main class="main-container" *ngIf="pageReady">
  <div class="header-container">
    <h1 id="titleId" class="header">
      <strong>{{name}}</strong>
      <span *ngIf="name">,</span>
      <br/>
      <br/> Tenemos exactamente lo que necesitás :)
    </h1>
  </div>

  <div class="carousel-container">
    <voxel-mb-carousel [initialIndex]="packageSelected" (slideEvent)='slideEvent($event)'>
      <ng-template #tplSlides>
        <li *ngFor="let package of packages; let i = index" class="carousel-slide">

          <div class="package-slide">

            <div class="package-header">
              <div class="package-header__number"></div>
              <div class="package-header__title">Cuenta digital gratuita. Incluye:</div>
            </div>

            <div class="package-details">
              <li *ngFor="let desc of package.account_offers; let i = index">
                <div class="package-details__item">
                  <i class="icon icon-itaufonts_chat_enviado package-details__icon"></i>
                  <span class="package-details__item-title">
                    <strong>
                      {{desc.account_type | accountType}}
                      <span *ngIf="desc.currency"> </span>
                      {{desc.currency | currencyDescription: 'description'}}
                    </strong>
                  </span>
                </div>
              </li>
            </div>

            <div class="package-values">
              <div *ngIf="withDecimals(package.prices[0])">
                <span class="package-values__yearly">{{package.prices[0].currency | currencyDescription: 'symbol'}} {{format(package.prices[0])}},
                  <span class="inception">{{decimals(package.prices[0])}}</span>
                </span>
                <span class="package-values__monthly">por mes</span>
              </div>

              <div (click)="openPackageDetails()" class="package-values__benefits" id="benefit{{i}}">
                Ver beneficios y condiciones
              </div>

              <voxel-button id="packageButton{{i}}" label="Continuar" (click)='nextScreen()'>
              </voxel-button>
            </div>

          </div>

        </li>
      </ng-template>

      <ng-template #tplPrev>
        <span class="carousel-button carousel-button--left" id="previous">
          <img class="carousel-button__arrow carousel-button__arrow--left" [src]="'assets/images/carrossel_seta_esquerda.svg'" />
        </span>
      </ng-template>

      <ng-template #tplNext>
        <span class="carousel-button carousel-button--right" id="next">
          <img class="carousel-button__arrow carousel-button__arrow--right" [src]="'assets/images/carrossel_seta_direita.svg'" />
        </span>
      </ng-template>
    </voxel-mb-carousel>

  </div>

</main>
<facebook-pixel></facebook-pixel>