import { Component, OnInit } from '@angular/core';
import { AddressCommonService } from 'src/app/address/shared/address-common.service';
import { DocumentPhotosCommonService } from '../shared/document-photos-common.service';
import { DocumentPhotosNavigationService } from '../shared/document-photos-navigation.service';
import { DocumentPhotosCommon } from '../shared/document-photos.common';
import { DocumentPhotoApproach, RoutesDocumentPhotosEnum } from '../shared/enums/routes-document-photos-enum';



@Component({
  selector: 'document-back-photo',
  templateUrl: './document-back-photo.component.html',
  styleUrls: ['./document-back-photo.component.scss']
})
export class DocumentBackPhotoComponent extends DocumentPhotosCommon implements OnInit {

  pageId = 'document-back-photo';
  previousRoute = RoutesDocumentPhotosEnum.DOCUMENT_BACK_STAGE;
  fileType = 'selfie_life_proof';


  constructor(
    public commonService: DocumentPhotosCommonService,
    addressCommonService: AddressCommonService,
    public navigationService: DocumentPhotosNavigationService) {
    super(commonService, addressCommonService);
  }

  ngOnInit() {
    this.navigationService.setStage(RoutesDocumentPhotosEnum.DOCUMENT_BACK_PHOTO);
    this.choice = this.commonService.choice;
    if (this.choice === DocumentPhotoApproach.PHOTO) {
      this.setFileUploadParams();
    } else {
      this.initializeFileUploadOption();
    }
  }

  sendImageEvent(base64) {
    this.commonService.sendImage(base64, this.fileType);
  }

  continuarButton() {
    this.commonService.sendImage(this.base64, this.fileType);
  }
}
