import { Component, OnInit } from '@angular/core';
import { GetReadyFormFullService } from 'src/app/get-ready-full/shared/get-ready-form.service';
import { ProgressBarService } from 'src/app/progressBar.service';

import { Analytics } from '../../shared/analytics';
import { GenericModel } from '../../shared/models/generic-model';
import { AddressFullModel } from '../models/address-model';
import { AddressFullCommonService } from '../shared/address-common.service';
import { AddressFullFormService } from '../shared/address-form.service';
import { AddressFullNavigationService } from '../shared/address-navigation.service';
import { RoutesAddressFullEnum } from '../shared/enums/routes-address-enum';

@Component({
  selector: 'address-home',
  templateUrl: './address-home.component.html',
  styleUrls: ['./address-home.component.scss']
})
export class AddressHomeFullComponent implements OnInit {
  pageId: string;
  isBtnDisabled: boolean;
  hintObject: any;

  existingPersonalAddress: any;
  states: GenericModel[];
  cities: GenericModel[];

  // Vars used on address form component
  stateSelected: GenericModel;
  citySelected: GenericModel;
  street: string = null;

  constructor(
    private analyticsLib: Analytics,
    public addressNavigationService: AddressFullNavigationService,
    private addressCommonService: AddressFullCommonService,
    private addressFormService: AddressFullFormService,
    private progressBarService: ProgressBarService,
    private getReadyFormService: GetReadyFormFullService
  ) {}

  ngOnInit(): void {
    this.pageId = 'address-home';
    this.hintObject = {};
    this.resolveHint();
    this.states = this.addressCommonService.states;
    this.cities = this.addressCommonService.cities;
    this.setData();
    this.setProgressBar();
    this.getReadyFormService.setPreviousStage('PersonalAddress');
    this.addressCommonService.scrollToTop(document);
  }

  setData(): void {
    this.addressNavigationService.setStage(RoutesAddressFullEnum.ADDRESS_HOME);
    const model = this.addressFormService.getForm().personal_address;
    this.existingPersonalAddress = this.addressCommonService.personalAddress;
    if (model.is_valid) {
      this.createAddressFormModel(model);
    } else {
      this.createAddressFormModel(this.existingPersonalAddress);
    }
    this.analyticsLib.buildData('DatosDomicilio', this.pageId, '7', false);
    this.analyticsLib.trackState(null, null);
  }

  createAddressFormModel(model: AddressFullModel): void {
    this.stateSelected = new GenericModel();
    this.citySelected = new GenericModel();

    // tslint:disable-next-line: early-exit
    if (model) {
      this.stateSelected.label = model.state;
      this.citySelected.label = model.city;
      this.street = model.street;
    }
  }

  resolveHint(): void {
    // tslint:disable-next-line:max-line-length
    this.hintObject.message = `Si en alguna de las bases que consultamos  registrado tu domicilio, éste aparecerá más abajo. Actualizalo de ser necesario.`;
    this.hintObject.value = 'offer';
  }

  next(): void {
    this.addressNavigationService.sendToNext();
  }

  enableButton(addressModel: AddressFullModel): void {
    this.isBtnDisabled = !addressModel.is_valid;
    this.addressFormService.setProperty('personal_address', addressModel);
    if (addressModel.is_key_enter) {
      this.next();
    }
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 1,
      amountBar: 7,
      currentBar: 6,
      labelName: 'Datos personales',
      nextLabel: 'Siguiente: 2/4 Uso de la cuenta'
    });
  }
}
