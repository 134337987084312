import { OfferProductFull } from './offer-product-model';
import { MoneyFull } from './money-model';

export class AccountOfferFull {
  general_info: OfferProductFull;
  account_type: string;
  currency: string;
  overdraft_limit?: MoneyFull;
  icon?: string;

  constructor() {
    this.general_info = new OfferProductFull();
    this.account_type = '';
    this.currency = '';
    this.overdraft_limit = new MoneyFull();
    this.icon = '';
  }
}
