import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';

import { AddressFormService } from '../../address/shared/address-form.service';
import { RoutesAddressEnum } from '../../address/shared/enums/routes-address-enum';
import { RoutesGetReadyEnum } from './../../get-ready/shared/enums/routes-get-ready-enum';
import { RoutesFinalDataEnum } from './enums/routes-final-data-enum';

@Injectable()
export class FinalDataNavigationService extends CommonNavigationService {
  constructor(
    route: Router,
    private addressFormService: AddressFormService,
    buttonSideBySideHelperService: ButtonSideBySideHelperService,
  ) {
    super(route, buttonSideBySideHelperService);

    this.step = RoutesFinalDataEnum.CONFIRMATION;
    this.stepAmount = 2;
    this.flows = [
      {
        stage: RoutesFinalDataEnum.CONFIRMATION,
        previous: this.resolvePreviousScreenFinalData,
        next: RoutesFinalDataEnum.ULTIMATE,
      },
      {
        stage: RoutesFinalDataEnum.ULTIMATE,
        previous: null,
        next: RoutesGetReadyEnum.DOCUMENT_TYPE,
      },
    ];
  }

  resolvePreviousScreenFinalData() {
    if (
      this.addressFormService.getProperty('pickup_center') ||
      this.addressFormService.getProperty('oca_center') ||
      this.addressFormService.getProperty('tata_center')
    ) {
      return RoutesAddressEnum.DELIVERY_ADDRESS;
    }
    return RoutesAddressEnum.DELIVERY_TIME;

  }
}
