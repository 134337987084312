import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddressHomeFullComponent } from './address-home/address-home.component';
import { AddressProofFullComponent } from './address-proof/address-proof.component';
import { DeliveryAddressFullComponent } from './delivery-address/delivery-address.component';
import { AddressFacebookProofFullComponent } from './address-facebook-proof/address-facebook-proof.component';

export const routes: Routes = [
  {
    path: 'domicilio-particular-full',
    component: AddressHomeFullComponent
  },
  {
    path: 'comprobante-domicilio-full',
    component: AddressProofFullComponent
  },
  {
    path: 'domicilio-recibir-tarjeta-full',
    component: DeliveryAddressFullComponent
  },
  {
    path: 'comprobante-domicilio-facebook-full',
    component: AddressFacebookProofFullComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AddressFullRountingModule {}
