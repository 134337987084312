import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PldFullModule } from 'src/app/pld-full/pld.module';
import { LatamItLoaderComponent } from 'src/latam-it-loader/latam-it-loader.component';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';
// import { NumberHelperService } from './shared/helper/number-helper-service';
import { AddressFullModule } from './address-full/address.module';
import { AddressModule } from './address/address.module';
// import { VoxelMbWarningComponent } from 'voxel-mb-warning/voxel-mb-warning.component';
import { AppLoadService } from './app-load.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { AppService } from './app.service';
import { ClientModule } from './client/client.module';
import { DocumentPhotosModule } from './document-photos/document-photos-module';
import { FinalDataFullModule } from './final-data-full/final-data.module';
import { FinalDataModule } from './final-data/final-data.module';
import { GetReadyFullModule } from './get-ready-full/get-ready.module';
import { GetReadyModule } from './get-ready/get-ready.module';
import { OffersFullModule } from './offers-full/offers.module';
import { OffersModule } from './offers/offers.module';
import { PldModule } from './pld/pld.module';
import { SelfieModule } from './selfie/selfie-module';
import { Analytics } from './shared/analytics';
import { LatamErrorMessageComponent } from './shared/latam-error-message/latam-error-message.component';
import { LatamErrorMessageService } from './shared/latam-error-message/latam-error-message.service';
import { SharedModule } from './shared/shared.module';





export function init_app(appLoadService: AppLoadService) {
  return () => appLoadService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    LatamItLoaderComponent,
    LatamErrorMessageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    SelfieModule,
    PldModule,
    GetReadyModule,
    OffersModule,
    AddressModule,
    FinalDataModule,
    ClientModule,
    DocumentPhotosModule,
    FinalDataFullModule,
    GetReadyFullModule,
    AddressFullModule,
    OffersFullModule,
    PldFullModule
  ],
  providers: [
    Analytics,
    AppLoadService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      multi: true,
      deps: [AppLoadService]
    },
    AppService,
    // NumberHelperService,
    LatamItLoaderService,
    LatamErrorMessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
