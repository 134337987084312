import { Component, OnInit } from '@angular/core';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { SellerService } from '../../shared/services/domains/seller/seller.service';
import { SellerModel } from '../models/seller-model';
import { RoutesGetReadyEnum } from '../shared/enums/routes-get-ready-enum';
import { GetReadyCommonService } from '../shared/get-ready-common.service';
import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';

@Component({
  selector: 'voxel-home-get-ready',
  templateUrl: './home-get-ready.component.html',
  styleUrls: ['./home-get-ready.component.scss']
})
export class HomeGetReadyComponent implements OnInit {
  pageId: string;
  info: string;
  icon: string;
  branch: string;
  seller: SellerModel;
  personType: string;

  constructor(
    public getReadyNavigationService: GetReadyNavigationService,
    private getReadyFormService: GetReadyFormService,
    private sellerService: SellerService,
    private getReadyCommonService: GetReadyCommonService
  ) {
    this.pageId = 'home_get_ready';
    this.seller = new SellerModel();
    this.personType = '';
  }

  ngOnInit(): void {
    this.getReadyNavigationService.setStage(RoutesGetReadyEnum.START);
    this.getSellerStorage();
    this.setData();
  }

  getSellerStorage() {
    this.seller = JSON.parse(this.sellerService.getSeller());
    this.getReadyFormService.setProperty('seller', this.seller);
  }

  setData(): void {
    this.seller = this.getReadyFormService.getProperty('seller');
    this.personType = this.getReadyFormService.getProperty('solicitation_type');
    // tslint:disable-next-line: early-exit
    if (this.seller) {
      let branchList: any[];
      branchList = this.getReadyCommonService.getAdminList(AdminListEnum.BRANCH_ID);

      this.branch = branchList.find(item => {
        return String(item.value).trim() === this.seller.branch_id.trim();
      }).label;

      if (this.seller.seller_name) {
        this.icon = 'icon-itaufonts_usuario_perfil';
        this.info = this.seller.seller_name;
      } else {
        this.icon = 'icon-itaufonts_para_empresa';
        this.info = `Sucursal: ${this.branch}`;
      }
    }
  }

  next() {
    if (this.getReadyFormService.getProperty('solicitation_type') === undefined) {
      this.getReadyFormService.setProperty('solicitation_type', 'aperturadecuentas');
    }
    this.getReadyNavigationService.sendToNext();
  }
}
