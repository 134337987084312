import { Component, OnInit } from '@angular/core';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ArchiveService } from '../../shared/services/domains/archive/archive.service';
import { LoginService } from '../../shared/services/login.service';
import { AddressCommonService } from '../shared/address-common.service';
import { AddressFormService } from '../shared/address-form.service';
import { AddressNavigationService } from '../shared/address-navigation.service';
import { RoutesAddressEnum } from '../shared/enums/routes-address-enum';


@Component({
  selector: 'address-proof',
  templateUrl: './address-proof.component.html',
  styleUrls: ['../shared/address-styles.scss', './address-proof.component.scss'],
})
export class AddressProofComponent implements OnInit {
  pageId: string;
  isBtnDisabled: boolean;
  hintObject: any;

  // Parâmetros que vão vir da lista do admin
  uploadParams: any;
  isFileSelected: boolean;

  file = {
    type: '',
  };
  base64: string;
  postFile: boolean;
  isFacebookAndroid: boolean;

  constructor(
    private addressCommonService: AddressCommonService,
    private addressFormService: AddressFormService,
    public addressNavigationService: AddressNavigationService,
    private archiveService: ArchiveService,
    private latamItLoaderService: LatamItLoaderService,
    private loginService: LoginService,
  ) {
    this.hintObject = {};
  }



  ngOnInit() {
    this.isFacebookAndroid =
      this.loginService.deviceInformation.browser_name.toUpperCase() === 'FACEBOOK' &&
      this.loginService.deviceInformation.operation_system.toUpperCase() === 'ANDROID';
    this.pageId = 'address-proof';
    this.setFileUploadParams();
    this.resolveHint();
    this.isFileSelected = this.checkFileSelected();
    this.postFile = !this.isFileSelected;
    this.resolveButton(this.isFileSelected);
  }

  setFileUploadParams() {
    this.uploadParams = this.addressCommonService.getAdminList(AdminListEnum.FILE_UPLOAD);
  }

  resolveHint() {
    // tslint:disable-next-line:max-line-length
    this.hintObject.message = `para validar tu domicilio, debés adjuntar una factura de un servicio público o privado que esté a tu nombre.`;
    this.hintObject.value = 'offer';
  }

  checkFileSelected() {
    if (this.addressFormService.getProperty('isFileSelected')) {
      const personalAddress = this.addressFormService.getProperty('personal_address');
      const uploadedAddress = this.addressCommonService.uploadedDocumentAddress;

      if (uploadedAddress) {
        return uploadedAddress.street === personalAddress.street && uploadedAddress.state === personalAddress.state;
      }
    }
    return false;
  }

  fileSelected(event: any) {
    this.file = event.file;
    this.base64 = event.base64;
    this.resolveButton(this.base64);
    this.addressFormService.setProperty('isFileSelected', event.isFileSelected);
    this.postFile = event.isFileSelected;
    console.log('File event: ', event);
  }

  resolveButton(base64?) {
    this.isBtnDisabled = !base64;
  }

  continuarButton() {
    if (this.postFile) {
      this.post(this.archiveService.createUploadFileJson(this.base64, this.file.type.split('/')[1], 'address'));
      this.addressCommonService.setUploadedDocumentAddress();
    } else {
      this.addressNavigationService.sendToNext();
    }
  }

  goToFacebookUpload() {
    this.addressNavigationService.navigateTo(RoutesAddressEnum.ADDRESS_PROOF_FACEBOOK);
  }

  // TODO: Move the archiveService call to AddressCommonService
  post(fileJson) {
    this.latamItLoaderService.show();
    this.archiveService.doPatchSolicitationFileUpload(fileJson, false, false).subscribe(
      res => {
        if (res.statusCode === 200) {
          console.log('FileUpload post successfull', res);
          this.latamItLoaderService.hide();
          this.addressNavigationService.sendToNext();
        } else {
          console.log('FileUpload post ERROR', res);
          this.latamItLoaderService.hide();
        }
      },
      err => {
        console.log('POST error', err);
        this.latamItLoaderService.hide();
      },
    );
  }

  setAtual() {
    return 12;
  }
}
