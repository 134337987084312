import { AccountOfferFull } from './account-offers.model';
import { CardOfferFull } from './card-offers-model';
import { MoneyFull } from './money-model';
import { OfferComissionFull } from './offer-comission-model';
import { OfferBenefitFull } from './offer-benefit-model';

export class OfferPackageFull {
  package_id: string;
  package_title: string;
  package_description: string;
  account_offers: AccountOfferFull[];
  card_offers: CardOfferFull[];
  prices: MoneyFull[];
  commissions: OfferComissionFull[];
  benefits: OfferBenefitFull[];

  constructor() {
    this.package_id = '';
    this.package_title = '';
    this.package_description = '';
    this.account_offers = [new AccountOfferFull()];
    this.card_offers = [new CardOfferFull()];
    this.prices = [new MoneyFull()];
    this.commissions = [new OfferComissionFull()];
    this.benefits = [new OfferBenefitFull()];
  }
}
