import { Injectable } from '@angular/core';
import { GetReadyModelFull } from '../models/get-ready-model';
@Injectable()
export class GetReadyFormFullService {

  private getReadyForm: GetReadyModelFull;
  private previousStage: string;
  private postSolicitationBody: any;

  constructor() {
    this.getReadyForm = new GetReadyModelFull();
  }

  getFormGetReady() {
    return this.getReadyForm;
  }

  getProperty(property: any) {
    return this.getReadyForm[property];
  }

  setProperty(property: string, value: any) {
    this.getReadyForm[property] = value;
  }

  getDocumentType() {
    return this.getReadyForm.identity_documents[0].type;
  }

  setPreviousStage(stage: string) {
    this.previousStage = stage;
  }

  getPreviousStage() {
    return this.previousStage;
  }

  getPostSolicitationBody() {
    return this.postSolicitationBody;
  }

  setPostSolicitationBody(body) {
    this.postSolicitationBody = body;
  }
}
