export enum RoutesGetReadyEnum {
  START = 'preparate-bienvenida-vendor' as any,
  SALES = 'sucursales-vendedor' as any,
  DOCUMENT_TYPE = 'preparate-tipo-de-cedula' as any,
  DOCUMENT_NUMBER = 'preparate-numero-documento' as any,
  DOCUMENT_NO_CHIP = 'preparate-documento-no-chip' as any,
  DOCUMENT_CHIP = 'preparate-document-chip' as any,
  ERROR_PAGE_CEDULA_IDENTIDAD = 'preparate-pagina-erro-identidad' as any,
  ERROR_PAGE_CREATE = 'preparate-pagina-erro-crear' as any,
  ERROR_PAGE_DUPLICATE_ACCOUNT = 'preparate-pagina-erro-cuenta' as any,
  ERROR_PAGE_SOLICITATION = 'preparate-pagina-erro-solicitud' as any,
  IDENTITY_DOCUMENT = 'preparate-datos-documento' as any,
  CONTACT = 'preparate-contactamos' as any,
  MARITAL_STATUS = 'preparate-estado-civil' as any,
  PARTNER = 'preparate-conyuge' as any,
  OCCUPATION = 'preparate-actividad-laboral' as any,
  LOADING = 'preparate-loading' as any,
  INCOME = 'ingresos-liquidos' as any,
  PRINCIPAL_ACTIVITY_PJ = 'preparate-actividad-empresa' as any
}
