<div class="landscape">
  <div class="content">
    <img src="assets/images/group.svg" alt="" class="landscape-img">

    <h1 class="landscape-title">gira tu dispositivo</h1>

    <p class="landscape-text">para seguir con tu solicitud</p>
  </div>
</div>

<voxel-it-selfie
  [routeToNavigate]="previousRoute"
  [repeatPictureLabel]="'Tomar otra'"
  [compressedResolutionHeight]="compressedResolutionHeight"
  [maxImageLenght]="maxImageLenght"
  headerTitle="Selfie inclinada"
  headerSubTitle="Mirá a la cámara e incliná tu cabeza en la misma posición que la imagen."
  faceCam="true"
  [errorRoute]="errorRoute"
  [noPermissionRoute]="noPermissionRoute"
  [events]="eventsSubject.asObservable()"
  (sendImage)="sendImageEvent($event)"
  [synchronousSendImage]="false"
  analyticsPage = "ReviewSelfieInclinada"
  transactionStep = "19">
</voxel-it-selfie>
<facebook-pixel></facebook-pixel>
