export enum RoutesClientEnum {
  PLANS = 'cliente-oferta' as any,
  BENEFITS_COMISSIONS = 'cliente-beneficios' as any,
  CONTACT = 'cliente-datos-contacto' as any,
  PURPOSE = 'cliente-proposito' as any,
  MONEY = 'cliente-saldo-promedio' as any,
  CREDITS = 'cliente-acreditaciones-mensuales' as any,
  PERSONAL_ADDRESS = 'cliente-domicilio-personal' as any,
  DELIVERY_ADDRESS = 'cliente-domicilio-entrega' as any,
  DELIVERY_TIME = 'cliente-hora-entrega' as any,
  CONFIRMATION = 'cliente-confirmacion' as any,
  ULTIMATE = 'cliente-fin' as any
}
