import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesAddressFullEnum } from 'src/app/address-full/shared/enums/routes-address-enum';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';
import { DocumentTypeFullEnum } from './enums/document-type-enum';
import { RoutesGetReadyFullEnum } from './enums/routes-get-ready-enum';
import { GetReadyFormFullService } from './get-ready-form.service';


@Injectable()
export class GetReadyNavigationFullService extends CommonNavigationService {
  constructor(
    route: Router,
    private getReadyFormService: GetReadyFormFullService,
    buttonSideBySideHelperService: ButtonSideBySideHelperService
  ) {
    super(route, buttonSideBySideHelperService);

    this.stepAmount = 11;
    this.step = RoutesGetReadyFullEnum.CONTACT;
    this.flows = [
      {
        stage: RoutesGetReadyFullEnum.CONTACT,
        previous: null,
        next: RoutesGetReadyFullEnum.DOCUMENT_TYPE
      },
      {
        stage: RoutesGetReadyFullEnum.DOCUMENT_TYPE,
        previous: this.ruleDocumentTypePrevious,
        next: this.detectDocType
      },
      {
        stage: RoutesGetReadyFullEnum.DOCUMENT_NUMBER,
        previous: RoutesGetReadyFullEnum.DOCUMENT_TYPE,
        next: this.detectDocType
      },
      {
        stage: RoutesGetReadyFullEnum.DOCUMENT_CHIP,
        previous: RoutesGetReadyFullEnum.DOCUMENT_TYPE,
        next: RoutesGetReadyFullEnum.MARITAL_STATUS
      },
      {
        stage: RoutesGetReadyFullEnum.DOCUMENT_NO_CHIP,
        previous: RoutesGetReadyFullEnum.DOCUMENT_TYPE,
        next: RoutesGetReadyFullEnum.MARITAL_STATUS
      },
      {
        stage: RoutesGetReadyFullEnum.LOADING,
        previous: this.loadingPrevious,
        next: RoutesAddressFullEnum.ADDRESS_HOME
      },
      {
        stage: RoutesGetReadyFullEnum.ERROR_PAGE_CEDULA_IDENTIDAD,
        previous: null,
        next: null
      },
      {
        stage: RoutesGetReadyFullEnum.ERROR_PAGE_CREATE,
        previous: RoutesGetReadyFullEnum.LOADING,
        next: RoutesGetReadyFullEnum.ERROR_PAGE_DUPLICATE_ACCOUNT
      },
      {
        stage: RoutesGetReadyFullEnum.ERROR_PAGE_DUPLICATE_ACCOUNT,
        previous: RoutesGetReadyFullEnum.ERROR_PAGE_DUPLICATE_ACCOUNT,
        next: RoutesGetReadyFullEnum.ERROR_PAGE_DUPLICATE_ACCOUNT
      },
      {
        stage: RoutesGetReadyFullEnum.ERROR_PAGE_SOLICITATION,
        previous: RoutesGetReadyFullEnum.ERROR_PAGE_DUPLICATE_ACCOUNT,
        next: RoutesGetReadyFullEnum.DOCUMENT_TYPE
      },
      {
        stage: RoutesGetReadyFullEnum.OCCUPATION,
        previous: RoutesGetReadyFullEnum.MARITAL_STATUS,
        next: RoutesGetReadyFullEnum.LOADING
      },
      {
        stage: RoutesGetReadyFullEnum.MARITAL_STATUS,
        previous: this.detectDocType,
        next: this.rulePrincipalActivity
      },
      {
        stage: RoutesGetReadyFullEnum.INCOME,
        previous: RoutesGetReadyFullEnum.MARITAL_STATUS,
        next: RoutesGetReadyFullEnum.OCCUPATION
      }
    ];
  }

  detectDocType(): RoutesGetReadyFullEnum {
    if (this.getReadyFormService.getDocumentType() === DocumentTypeFullEnum.HASCHIP.toString()) {
      return RoutesGetReadyFullEnum.DOCUMENT_CHIP;
    }
    return RoutesGetReadyFullEnum.DOCUMENT_NO_CHIP;

  }

  returnToDocPage() {
    this.setStage(RoutesGetReadyFullEnum.DOCUMENT_NUMBER);
    this.sendToNext();
  }

  ruleCommonCivilState(): boolean {
    return (this.getReadyFormService.getProperty('marital_status') === 'C'
      || this.getReadyFormService.getProperty('marital_status') === 'X');
  }

  rulePrincipalActivity(): RoutesGetReadyFullEnum {
    return RoutesGetReadyFullEnum.OCCUPATION;
  }

  loadingPrevious(): RoutesGetReadyFullEnum {
    const previous = RoutesGetReadyFullEnum.INCOME;
    return previous ? RoutesGetReadyFullEnum.MARITAL_STATUS : RoutesGetReadyFullEnum.OCCUPATION;
  }

  ruleDocumentTypePrevious(): RoutesGetReadyFullEnum {
    return RoutesGetReadyFullEnum.CONTACT;
  }

}
