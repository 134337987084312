<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <client-header-oca [atual]='3'></client-header-oca>

  <main>
    <h1 id="titleId" class='title-container'>
      ¿Cuál es el
      propósito de la apertura de las cuentas?
    </h1>

    <div class="radio-button">
      <voxel-radio-button-group>
        <voxel-radio-button
          *ngFor="let reason of reasons"
          id="radio1"
          [radioName]="' '"
          [value]="reason.code"
          [checked]="reason.checked"
          (radioButtonChange)='changeReason(reason)'>
          {{reason.label}}
        </voxel-radio-button>
      </voxel-radio-button-group>
    </div>

  <div id='showAnotherReasonId' *ngIf="showAnotherReason" class="padding-otro-motivo">
    <voxel-form-field
        [formGroup]="form"
        label="Especificá el motivo"
        [msgErr]="otherReasonInvalid">
        <input
          voxelInput
          [id]="'otromotivo'"
          formControlName="otromotivo"
          [maxlength]="40"
          [value]="otherReason"
          (ngModelChange)="onInputValueChange($event)">
    </voxel-form-field>
    <div class="char-counter-container">
      <span class="char-counter" 
        [ngStyle]="{'color': accPurpose && accPurpose[0] && accPurpose[0].specifics && accPurpose[0].specifics.length >= 40 ? 'red' : '#605751'}">
          {{accPurpose && accPurpose[0] && accPurpose[0].specifics ? accPurpose[0].specifics.length : 0}}/40
      </span>
    </div>
  </div>

  </main>
</div>

<footer id="footer-purpose">
  <button-sidebyside
  [isBtnDisabled]="isBtnDisabled"
  previousHook="true"
  (hookPreviousAction)="doPrevious()"
  [navigationService]="navigationService">
</button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>