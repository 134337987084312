<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='8'></header-oca>

  <main>

    <h1 id="titleId" class="title-container">
      ¿Cuál es tu <strong>actividad laboral o profesión</strong>?
    </h1>

    <div class='input-container'>

      <span class="label-input">Seleccioná una actividad</span>
     
      <latam-it-search-input-select
        [label]="'seleccioná una actividad'"
        [items]="activities"
        [dropdownPosition]="'bottom'"
        (selectEvent)="changeActivityValue($event)"
        [filterValue]="activityLabel"
        notFoundText="Sin resultados">
      </latam-it-search-input-select>
      
      <div *ngIf="showIncome" style="margin-top: 2rem;">
        <form 
          class="container-folio" 
          [formGroup]="formIngresso"
          style="width: 98% !important;">
          <voxel-form-field
            label="¿Cuál es tu salario líquido en pesos?"
            msgErr="Valor inválido">
            <input
              voxelInput
              id="radio1"
              formControlName="ingreso"
              voxelMask="currency"
              [config]="currencyConnfig"
              [maxLength]="8"
              (ngModelChange)="changeIngreso($event)">
           </voxel-form-field>
          </form>
      </div>
    </div>
  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    previousHook="true"
    (hookNextAction)="doPost()"
    [isBtnDisabled]="isBtnDisabled" 
    [navigationService]="navigationService"
    (hookPreviousAction)="doPrevious()">
  </button-sidebyside>

</footer>
<facebook-pixel></facebook-pixel>
