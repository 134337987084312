<div id='{{pageId}}'>

    <main>

      <h1 id="fi-proposito-principal-title">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. At eos dolorem nam aperiam perspiciatis quia necessitatibus. Quae esse, beatae sequi exercitationem, pariatur id vel, qui saepe possimus consequuntur fuga voluptatem.
        <i id="sujeto-obligado-hint" class="orange-icon icon icon-itaufonts_informacao" (click)="modalEnable()"></i>
      </h1>

    </main>

    <voxel-modal [showModal]="modalShow" modalId="proposito-principal-modal">
      <ng-template #modalContent>
        <h2>Algo salió mal al sacar la foto… :(</h2>
        <div class="modal-text">
          <p>
            {{ errorMsg }}
          </p>
        </div>
      </ng-template>
      <ng-template #modalActions>
        <voxel-button
          label="tomar foto nuevamente"
          (click)="mainButtonClick()">
        </voxel-button>
      </ng-template>
    </voxel-modal>

  </div>
  <facebook-pixel></facebook-pixel>
