import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SelfieGetReadyComponent } from './selfie-get-ready/selfie-get-ready.component';
import { FrontalStageComponent } from './frontal-stage/frontal-stage.component';
import { FrontalPhotoComponent } from './frontal-photo/frontal-photo.component';
import { InclinedStageComponent } from './inclined-stage/inclined-stage.component';
import { InclinedPhotoComponent } from './inclined-photo/inclined-photo.component';
import { SelfieErrorComponent } from './selfie-errors/selfie-error.component';

export const routes: Routes = [

  {
    path: 'selfie-get-ready',
    component: SelfieGetReadyComponent
  },
  {
    path: 'selfie-frontal-stage',
    component: FrontalStageComponent
  },
  {
    path: 'selfie-frontal-photo',
    component: FrontalPhotoComponent
  },
  {
    path: 'selfie-inclined-stage',
    component: InclinedStageComponent
  },
  {
    path: 'selfie-inclined-photo',
    component: InclinedPhotoComponent
  },
  {
    path: 'selfie-error',
    component: SelfieErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SelfieRountingModule {}
