export class AnalyticsInfo {
  enabled: boolean;
  console_log: boolean;
  expose: boolean;
  gtmcontainerid: string;
  adobe_data_layer_name: string;
  auto_trackstate: boolean;
  environment: string;
  channel: string;

  constructor() {
    this.enabled = true;
    this.console_log = false;
    this.expose = false;
    this.gtmcontainerid = '';
    this.adobe_data_layer_name = '';
    this.auto_trackstate = false;
    this.environment = '';
    this.channel = '';
  }
}
