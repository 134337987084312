import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountTypePipe } from './accountType.pipe';
import { CurrencyDescriptionPipe } from './currencyDescription.pipe';

@NgModule({
  declarations: [
    AccountTypePipe,
    CurrencyDescriptionPipe
  ],
  imports: [CommonModule],
  exports: [
    AccountTypePipe,
    CurrencyDescriptionPipe]
})

export class AbreCuentasPipe {}
