import { Injectable } from '@angular/core';

@Injectable()
export class ProgressBarService {
  headerConfig: any;

  initializeProgressBar(config: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.headerConfig = config;
      resolve();
    });
  }

  getProgressBarOptions() {
    return this.headerConfig;
  }
}
