import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConfirmationFullComponent } from './confirmation/confirmation.component';
import { EndFullComponent } from './end/end.component';

export const routes: Routes = [
  {
    path: 'confirmacion-full',
    component: ConfirmationFullComponent
  },
  {
    path: 'confirmacion-fim-full',
    component: EndFullComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class FinalDataFullRountingModule {}
