<div class="slider voxel-carousel-container">
  <div class="frame voxel-carousel-frame">
      <ul class="voxel-carousel-slide-container">
        <ng-container *ngTemplateOutlet="tplSlides"></ng-container>
      </ul>
  </div>
  <span class="voxel-carousel-btn-prev" (click)="prev()">
      <ng-container *ngTemplateOutlet="tplPrev"></ng-container>
  </span>
  <span class="voxel-carousel-btn-next" (click)="next()">
      <ng-container *ngTemplateOutlet="tplNext"></ng-container>
  </span>
  <ul class="js_dots dots" style="display:none"></ul>
</div>
