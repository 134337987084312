<div class="landscape">
  <img src="assets/images/group.svg" alt="" class="landscape-img">

  <h1 class="landscape-title">gira tu dispositivo</h1>

  <p class="landscape-text">para seguir con tu solicitud</p>
</div>

<div class="black-bg content-with-filled-button">
  <div id='{{pageId}}'></div>

  <header-oca [atual]='3'></header-oca>

  <main class="black-bg">

    <img src="assets/images/img_selfie_inclinada.svg" alt="" class="selfie-img">

    <h1 id="selfie-inclined-title" class="selfie-title">
      Selfie inclinada
    </h1>

    <p class="selfie-text">
      Mirá a la cámara e incliná tu cabeza en la misma posición de la imagen.
    </p>

  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    previousHook="true"
    label2="Activar cámara"
    (hookNextAction)="next()"
    (hookPreviousAction)="previous()">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>
