import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesGetReadyFullEnum } from 'src/app/get-ready-full/shared/enums/routes-get-ready-enum';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';

import { AddressFullFormService } from '../../address-full/shared/address-form.service';
import { RoutesAddressFullEnum } from '../../address-full/shared/enums/routes-address-enum';
import { RoutesFinalDataEnumFull } from './enums/routes-final-data-enum';

@Injectable()
export class FinalDataFullNavigationService extends CommonNavigationService {
  constructor(
    route: Router,
    private addressFormService: AddressFullFormService,
    buttonSideBySideHelperService: ButtonSideBySideHelperService,
  ) {
    super(route, buttonSideBySideHelperService);

    this.step = RoutesFinalDataEnumFull.CONFIRMATION;
    this.stepAmount = 2;
    this.flows = [
      {
        stage: RoutesFinalDataEnumFull.CONFIRMATION,
        previous: RoutesAddressFullEnum.DELIVERY_ADDRESS,
        next: RoutesFinalDataEnumFull.ULTIMATE,
      },
      {
        stage: RoutesFinalDataEnumFull.ULTIMATE,
        previous: null,
        next: RoutesGetReadyFullEnum.DOCUMENT_TYPE,
      },
    ];
  }
}
