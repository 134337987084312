import { Component, OnInit } from '@angular/core';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { AddressCommonService } from '../shared/address-common.service';
import { AddressFormService } from '../shared/address-form.service';
import { AddressNavigationService } from '../shared/address-navigation.service';
import { RoutesAddressEnum } from '../shared/enums/routes-address-enum';

@Component({
  selector: 'delivery-time',
  templateUrl: './delivery-time.component.html',
  styleUrls: ['../shared/address-styles.scss']
})
export class DeliveryTimeComponent implements OnInit {
  pageId: string;
  isBtnDisabled: boolean;

  deliveryTimes: any[];

  constructor(
    private addressCommonService: AddressCommonService,
    private addressFormService: AddressFormService,
    public addressNavigationService: AddressNavigationService
  ) {}

  ngOnInit() {
    this.addressNavigationService.setStage(RoutesAddressEnum.DELIVERY_TIME);
    this.pageId = 'delivery_time';
    this.deliveryTimes = this.addressCommonService.getAdminList(AdminListEnum.DELIVERY_AVAILABILITY);
    this.isBtnDisabled = !this.addressFormService.getProperty('availability');

    const availability = this.addressFormService.getProperty('availability');

    // tslint:disable-next-line: early-exit
    if (availability) {
      this.deliveryTimes.forEach(element => {
        element.checked = !!(availability === element.code);
      });
    }
  }

  changeDeliveryTime(event) {
    this.addressFormService.setProperty('availability', event.value);
    this.isBtnDisabled = false;
  }

  continuarButton() {
    this.addressNavigationService.sendToNext();
  }

  setAtual() {
    return 14;
  }
}
