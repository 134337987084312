import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

import { SolicitationService } from '../solicitation/solicitation.service';
import { ArchiveMethods } from './archive-enum';

@Injectable()
export class ArchiveService {
  constructor(private appService: AppService, private solicitationService: SolicitationService) {}

  createUploadFileJson(_base64: string, extension: string, file_type: string) {
    let base64 = _base64;
    if (!!(environment as any).mock) {
      // Used in LatAm environment
      console.warn('Using mock');
      base64 = (environment as any).mock.archiveServiceBase64;
    }

    return {
      base64_content: base64,
      extension,
      type: file_type,
      metadata: [
        {
          key: 'solicitation_id',
          name: this.solicitationService.getSolicitationId()
        }
      ]
    };
  }

  doPatchSolicitationFileUpload(bodyPatch, showLoader, hideLoader) {
    return this.appService.doPost(bodyPatch, ArchiveMethods.POST_ARCHIVE, showLoader, hideLoader);
  }
}
