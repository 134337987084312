<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <header-oca [atual]='setAtual()'></header-oca>

  <main>

      <h1 id="titleId" class="paddingless">
        Ingresá los <strong>datos</strong> de tu <strong>cédula</strong>
      </h1>

        <voxel-form-field
          [formGroup]="formField"
          label="Código de seguridad"
          [msgErr]="">
          <input
            voxelInput
            id="codigoId"
            formControlName="securityCode"
            maxlength="6"
            (ngModelChange)="changeCode($event)"
            (keyup.enter)="isEnter($event)">
        </voxel-form-field>

        <div id="containetImage" class="container-image">
          <img class="new-document-image" [src]="'assets/images/cedula_nueva_dorso_v2.png'"/>
        </div>
  </main>
</div>

<footer >
  <button-sidebyside
    nextHook="true"
    (hookNextAction)="doNext()"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>

<voxel-modal [showModal]="modalShow">
  <ng-template #modalContent>
    <h2 class="voxel-text-xl">Los datos del documento no son correctos : (</h2>
    <div class='sub-text-14'>
      Por favor ingresá los datos de tu cédula nuevamente
    </div>
  </ng-template>
  <ng-template #modalActions>
    <voxel-button
      label="reintentar"
      (click)="maskAsRead()">
    </voxel-button>
    <voxel-button
      label=" "
      (click)="toggleModal()"
      typeButton="secondary">
    </voxel-button>
  </ng-template>
</voxel-modal>

<facebook-pixel></facebook-pixel>
