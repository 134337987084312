<nav
  class="steps amount-{{ amountBar }}"
  role="progressbar"
  aria-live="polite"
  aria-relevant="all"
  aria-labelledby="aria-text"

  [attr.aria-valuetext]="'Etapa ' + currentBar + ' de ' + amountBar">

  <img class="logo" [src]="'assets/images/logo-ocablue.svg'" />

  <div class="steps__amount">
    {{ labelName }}

    <div class="steps-content">
        <ul>
            <li class="steps-content__step"

            [attr.aria-current]="step + 1"
            [attr.id]="id"

            *ngFor="let step of arrayAmountBar"
            [ngClass]="{
              'steps-content__step__checked' : currentBar >= step + 1,
              'steps-content__step__current' : currentBar == step + 1
            }">
            <span>{{ step + 1 }}</span>
          </li>
        </ul>
      </div>
  </div>
</nav>
