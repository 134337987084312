import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GetReadyModel } from 'src/app/get-ready/models/get-ready-model';
import { GetReadyCommonService } from 'src/app/get-ready/shared/get-ready-common.service';
import { GetReadyFormService } from 'src/app/get-ready/shared/get-ready-form.service';
import { GetReadyNavigationService } from 'src/app/get-ready/shared/get-ready-navigation.service';

import { CurrencyValueModel } from '../../pld/shared/models/currency-value';

@Component({
  selector: 'pld-income',
  templateUrl: './income.component.html',
  styleUrls: ['../../pld/shared/pld-styles.scss']
})
export class IncomeComponent implements OnInit {
  pageId = 'pld-income';
  hintObject: any;
  getReadyForm: GetReadyModel;
  isBtnDisabled = false;
  ingresoLiquido = '';

  config = {
    allowNegative: false,
    precision: 0,
    thousands: '.',
    prefix: '$'
  };

  form = new FormGroup({
    ingresoLiquido: new FormControl()
  });

  constructor(
    private commonService: GetReadyCommonService,
    private getReadyFormService: GetReadyFormService,
    public navigationService: GetReadyNavigationService
  ) {
    this.hintObject = {};
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.doPost();
    }
  }

  ngOnInit() {
    this.resolveHint();
    this.refreshButton();
    this.getReadyForm = this.getReadyFormService.getFormGetReady();

    // tslint:disable-next-line: early-exit
    if (this.getReadyFormService.getProperty('monthly_income')) {
      this.ingresoLiquido = this.formatIngresoLiquido(this.getReadyFormService.getProperty('monthly_income').amount.toString());
    }
  }

  formatIngresoLiquido(amount) {
    let amountFormat = amount;
    const length = amount.length;

    if (length > 3) {
      if (length === 4) {
        amountFormat = amount[0] + '.' + amount.slice(1);
      } else if (length === 5) {
        amountFormat = amount.slice(0, 2) + '.' + amount.slice(2);
      } else if (length === 6) {
        amountFormat = amount.slice(0, 3) + '.' + amount.slice(3);
      }
    }

    return '$' + amountFormat;
  }

  refreshButton() {
    this.isBtnDisabled =
      !this.getReadyFormService.getProperty('monthly_income') || !this.getReadyFormService.getProperty('monthly_income').amount;
  }

  doPost() {
    this.commonService.postSolicitation();
  }

  resolveHint() {
    this.hintObject.message = `Es lo que efectivamente percibís por tu actividad laboral,
      luego de realizar deducciones por aportes e impuestos.`;
    this.hintObject.value = 'offer';
  }

  changeIngreso(event: any) {
    const balance = new CurrencyValueModel();
    const amount = event.replace('$', '');
    balance.amount = Number(amount);
    this.getReadyFormService.setProperty('monthly_income', balance);
    this.refreshButton();
  }

  newInputChange(input: string) {
    if (input === 'ingresoLiquido') {
      this.changeIngreso(this.form.controls[input].value);
    }
  }
}
