import { Component, Input, OnInit } from '@angular/core';

// import { ValidateParameterService } from 'voxel-mb-services/validate-required-parameter.service';

@Component({
  selector: 'latam-it-progress-bar',
  templateUrl: './latam-it-progress-bar.component.html',
  styleUrls: ['./latam-it-progress-bar.component.scss']
})
export class LatamlItProgressBarComponent implements OnInit {
  /**
   * Identificação do componente
   */
  @Input() id: string;

  /**
   * Quantidade total de passos (steps):
   * * **Mínimo**: 3
   * * **Máximo**: 18
   */
  @Input() amountLabel: number;

  /**
   * Número do passo atual
   */
  @Input() currentLabel = 1;

  /**
   * Quantidade total de passos (steps):
   * * **Mínimo**: 2
   * * **Máximo**: 18
   */
  @Input() amountBar: number;

  /**
   * Número do passo atual
   */
  @Input() currentBar = 1;

  @Input() labelName: string;

  component = 'LatamItProgressBarComponent';
  arrayAmountLabel: any;
  arrayAmountBar: any;

  ngOnInit() {
    /*
    this.validateParam.validateUndefinedOrNumber(this.amountBar, this.component, 'amountBar');
    this.validateParam.validateUndefinedOrNumber(this.currentBar, this.component, 'currentBar');
    this.validateParam.validateUndefinedOrNumber(this.amountLabel, this.component, 'amountLabel');
    this.validateParam.validateUndefinedOrNumber(this.currentLabel, this.component, 'currentLabel');

    this.validateParam.validateMinLength(this.amountBar, 3, this.component, 'amountBar');
    this.validateParam.validateMaxLength(this.amountBar, 18, this.component, 'amountBar');
    this.validateParam.validateMinLength(this.amountLabel, 1, this.component, 'amountLabel');
    this.validateParam.validateMaxLength(this.amountLabel, 4, this.component, 'amountLabel');

    this.validateParam.validateFirstSmallerThanSecond(
      [this.currentBar, this.amountBar],
      this.component,
      ['currentBar', 'amountBar']
    );

    this.validateParam.validateFirstSmallerThanSecond(
      [this.currentLabel, this.amountLabel],
      this.component,
      ['currentLabel', 'amountLabel']
    );

    this.arrayAmountLabel = Array.apply(null, {length: this.amountLabel}).map(Number.call, Number);

    this.arrayAmountBar = Array.apply(null, {length: this.amountBar}).map(Number.call, Number);
    */
  }
}
