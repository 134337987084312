import { ClientAddressModel } from '../shared/models/client-address-model';

export class ClientModel {
  name: string;
  phone: string;
  email: string;
  confirmEmail: string;
  account_purpose: any;
  avg_balance: any;
  amount_quantity_per_month: any;
  has_fiscal_residence: boolean;
  fiscal_residence_attributes: any;
  personal_address: any;
  delivery_address: any;
  pickup_center: string;
  availability: string;
  delivery_choice_id: string;
  is_american: boolean;
  solicitation_completed: boolean;
  client_id: any;

  constructor() {
    this.name = '';
    this.phone = '';
    this.email = '';
    this.confirmEmail = '';
    this.account_purpose = null;
    this.avg_balance = null;
    this.amount_quantity_per_month = null;
    this.has_fiscal_residence = null;
    this.fiscal_residence_attributes = [];
    this.personal_address = new ClientAddressModel();
    this.delivery_address = new ClientAddressModel();
    this.pickup_center = null;
    this.availability = null;
    this.delivery_choice_id = null;
    this.is_american = null;
    this.solicitation_completed = null;
    this.client_id = null;
  }
}
