/* istanbul ignore file */

import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ButtonSideBySideHelperService {

  emitter: EventEmitter<string> = new EventEmitter();

  changeButtonsFlag() {
    this.emitter.emit('enableDisableButtons');
  }
}
