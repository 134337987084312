<div id='{{pageId}}' class="loading">
  <div class="loading__item--top">
    <header class="header">

      <img src="assets/images/logo-ocablue.svg" alt="logo" class="header-logo">

    </header>

    <div id='msgId' class="title-text">
      Estamos analizando tus datos, aguardá un momento
    </div>
  </div>
  <div class="loading__box__wrapper">
    <div id='loadingId' class="loading__spinner">

      <voxel-loading
          *ngIf="loaderIcon === true"
          loadingTitle="Em processamento">
      </voxel-loading>

      <div class="percent-text">
        {{percent}}%
      </div>
    </div>
  </div>
  <div class="loading__item--bottom">
    <div class="card-offers" id="cardsId">
      <div class="card-offers-title" id="cardTitleId">{{cardOffers[actualCardOffers].title}}</div>
      <div class="card-offers-text" id="cardTextId">{{cardOffers[actualCardOffers].text}}</div>
    </div>
  </div>
</div>

<facebook-pixel></facebook-pixel>
