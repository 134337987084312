import { ForeignResidenceModel } from './foreign-residence-json';
import { PersonalReferenceModel } from './personal-reference-json';
import { AccountPurposeModel } from './account-purpose';
import { CurrencyValueModel } from './currency-value';

export class PldModel {
  account_purpose: AccountPurposeModel[];
  avg_balance: CurrencyValueModel = null;
  txn_count_per_month: number;
  amount_quantity_per_month = null;
  fiscal_residence_attributes: ForeignResidenceModel[] = [];
  is_american: boolean;
  reference_contact: PersonalReferenceModel[] = [];
  amount_quantity_per_month_transfers = null;
  amount_quantity_per_month_checks = null;
  amount_quantity_per_month_cash = null;
  last_year_incomes = null;
  taxpayer_type = '';
  customer_type = '';
  has_fiscal_residence: boolean;
}
