import { Component, OnInit } from '@angular/core';

import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';
import { GetReadyCommonService } from '../shared/get-ready-common.service';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ListConversionService } from 'src/app/shared/services/admin-lists/list-conversion-service/list-conversion.service';
import { GetReadyModel } from '../models/get-ready-model';
import { Analytics } from '../../shared/analytics';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';

@Component({
  selector: 'get-ready-occupation',
  templateUrl: './occupation.component.html',
  styleUrls: ['./occupation.component.scss']
})
export class OccupationComponent implements OnInit {

  pageId: string;
  activities: any[];
  otherActivity: string;
  isBtnDisabled: boolean;
  activityLabel: string;
  activityCode: string;
  getReadyForm: GetReadyModel;

  constructor(
    private formService: GetReadyFormService,
    public navigationService: GetReadyNavigationService,
    public getReadyCommonService: GetReadyCommonService,
    public conversionService: ListConversionService,
  ) {
  }

  ngOnInit() {
    this.pageId = 'occupation';
    this.getReadyForm = this.formService.getFormGetReady();
    this.activityCode = this.getReadyForm.principal_activity && this.getReadyForm.principal_activity.id ?
      this.getReadyForm.principal_activity.id : '';
    this.activities = this.conversionService.getConvertedList(AdminListEnum.PRINCIPAL_ACTIVITY);
    this.activityLabel = this.activityCode ? this.activities.find(x => x.code === this.activityCode).label : '';

    this.refreshButton();

  }

  changeActivityValue(event) {
    this.activityCode = event.code;
    this.activityLabel = event.label;
    this.refreshButton();
  }

  refreshButton() {
    this.isBtnDisabled = !this.activityCode;
    if (!this.isBtnDisabled) {
      this.getReadyForm.principal_activity = { id: this.activityCode, specifics: '' };
    }
  }

  setCaracter() {
    return 140;
  }
}
