import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Components
import { ButtonSideBySideComponent } from 'src/latam-it-buttons-side-by-side/button-sidebyside.component';
import { VoxelMbCarouselComponent } from 'src/latam-it-carousel/voxel-mb-carousel.component';
import { HeaderOCAComponent } from 'src/latam-it-header/header-oca.component';
import { FileUploadComponent } from './../../latam-it-file-upload/file-upload.component';
import { LatamlItProgressBarComponent } from './../../latam-it-progress-bar/latam-it-progress-bar.component';
import { CameraErrorComponent } from './camera-error/camera-error.component';
import { ErrorIncompatibleBrowserComponent } from './error-incompatible-browser/error-incompatible-browser.component';
import { ErrorTryLaterComponent } from './error-try-later/error-try-later.component';
import { FacebookPixelComponent } from './facebook-pixel/facebook-pixel.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PermissionRequiredComponent } from './permission-required/permission-required.component';
import { SummaryConfirmationComponent } from './summary-confirmation/summary-confirmation.component';

// Services
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';
import { NumberHelperService } from './helper/number-helper-service';
import { NgSelectConfig } from './latam-it-search-input-select/config.service';
import { ConsoleService } from './latam-it-search-input-select/console.service';
import { VirtualScrollService } from './latam-it-search-input-select/virtual-scroll.service';
import { WindowService } from './latam-it-search-input-select/window.service';

// Modules
import { VoxelItSelfieModule } from 'src/latam-it-camera-selfie/voxel-it-selfie.module';
import { LatamItSearchInputSelectModule } from './latam-it-search-input-select/latam-it-search-input-select.module';
import { AbreCuentasPipe } from './pipes/pipe.modules';
import { ServiceCommon } from './service.common';
import { ServicesModule } from './services/services.module';

import {
  VoxelAdditionalInformationModule,
  VoxelAlertModule,
  VoxelButtonModule,
  VoxelCarouselModule,
  VoxelCheckboxModule,
  VoxelDateValidatorServiceModule,
  VoxelFormFieldModule,
  VoxelIconModule,
  VoxelInput2Module,
  VoxelLinkModule,
  VoxelList2Module,
  VoxelLoadingModule,
  VoxelModalModule,
  VoxelPlatformModule,
  VoxelRadioButtonModule,
  VoxelSelectModule,
  VoxelTextareaModule,
} from '@voxel/mobile';

import { VoxelMaskModule } from '@voxel/components';
import { VclAdditionalInfoModule } from '@voxel/community';
import { NetworkModule } from '@latam/network';

const baseModules = [
  CommonModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule,
];

const voxelMobileModules = [
  VoxelAdditionalInformationModule,
  VoxelAlertModule,
  VoxelButtonModule,
  VoxelCarouselModule,
  VoxelCheckboxModule,
  VoxelDateValidatorServiceModule,
  VoxelFormFieldModule,
  VoxelIconModule,
  VoxelInput2Module,
  VoxelLinkModule,
  VoxelList2Module,
  VoxelLoadingModule,
  VoxelModalModule,
  VoxelPlatformModule,
  VoxelRadioButtonModule,
  VoxelSelectModule,
  VoxelTextareaModule,
];

const voxelComponentsModules = [
  VoxelMaskModule,
];

const voxelCommunityModules = [
  VclAdditionalInfoModule,
];

@NgModule({
  imports: [
    ...baseModules,
    ...voxelMobileModules,
    ...voxelComponentsModules,
    ...voxelCommunityModules,
    VoxelItSelfieModule,
    AbreCuentasPipe,
    LatamItSearchInputSelectModule,
    ServicesModule,
    NetworkModule,
  ],
  declarations: [
    ButtonSideBySideComponent,
    HeaderOCAComponent,
    SummaryConfirmationComponent,
    ErrorTryLaterComponent,
    ErrorIncompatibleBrowserComponent,
    PermissionRequiredComponent,
    CameraErrorComponent,
    FacebookPixelComponent,
    PdfViewerComponent,
    LatamlItProgressBarComponent,
    VoxelMbCarouselComponent,
    FileUploadComponent,
  ],
  providers: [
    NumberHelperService,
    ServiceCommon,
    ButtonSideBySideHelperService,
    NgSelectConfig,
    ConsoleService,
    VirtualScrollService,
    WindowService,
  ],
  exports: [
    ...baseModules,
    ...voxelMobileModules,
    ...voxelComponentsModules,
    ...voxelCommunityModules,
    VoxelItSelfieModule,
    ButtonSideBySideComponent,
    HeaderOCAComponent,
    SummaryConfirmationComponent,
    FacebookPixelComponent,
    PdfViewerComponent,
    LatamItSearchInputSelectModule,
    LatamlItProgressBarComponent,
    VoxelMbCarouselComponent,
    FileUploadComponent,
    NetworkModule,
  ],
})
export class SharedModule {}
