<div class="black-bg content-with-filled-button">
  <div id='{{pageId}}'></div>

  <header-oca [atual]='3'></header-oca>

  <main class="black-bg">

    <img src="assets/images/img_cedula_atras.svg" alt="" class="document-photo-img">

    <h1 id="document-back-photo-title" class="document-photo-title">
      Dorso de la cédula de identidad
    </h1>


  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    previousHook="true"
    [label2]="buttonText"
    (hookNextAction)="next()"
    (hookPreviousAction)="previous()">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>
