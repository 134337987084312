export enum RouterServiceEnum {
    GETBUSINESSCONFIGURATION = 'get_business_configuration' as any,
    PATCH_PROSPECT_PLD = 'patch_prospect_oca_pld' as any,
    PATCH_PROSPECT_GET_READY = 'patch_prospect_oca_get_ready' as any,
    PATCH_PROSPECT_TRACKING = 'patch_prospect_oca_tracking' as any,
    PATCH_PROSPECT_CONTACT = 'patch_prospect_oca_contact' as any,
    POST_SOLICITATION = 'post_solicitation_oca_get_ready' as any,
    GET_SOLICITATION = 'get_solicitation' as any,
    GET_OFFER = 'get_offer' as any,
    PATCH_OFFERS = 'patch_solicitation_oca_offer' as any,
    POST_ARCHIVE = 'post_archive_oca_address' as any,
    GET_PROSPECT = 'get_prospect' as any,
    PATCH_ADDRESS = 'patch_solicitation_oca_address' as any,
    PATCH_CONFIRMATION = 'patch_solicitation_confirmation' as any,
    POST_COMPLETE = 'post_solicitation_complete_oca' as any,
    GET_TERMS = 'get_terms' as any,
    GET_SELLER = 'get_seller' as any,
    GET_LIST_BUSINESS_CONFIGURATION = 'get_list_business_configuration' as any
}
