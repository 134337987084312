import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesOffersEnum } from 'src/app/offers/shared/enums/routes-offers-enum';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';

import { DocumentTypeEnum } from './enums/document-type-enum';
import { RoutesGetReadyEnum } from './enums/routes-get-ready-enum';
import { GetReadyFormService } from './get-ready-form.service';

@Injectable()
export class GetReadyNavigationService extends CommonNavigationService {
  constructor(
    route: Router,
    private getReadyFormService: GetReadyFormService,
    buttonSideBySideHelperService: ButtonSideBySideHelperService,
  ) {
    super(route, buttonSideBySideHelperService);

    this.stepAmount = 1;
    this.flows = [
      {
        stage: RoutesGetReadyEnum.SALES,
        previous: null,
        next: RoutesGetReadyEnum.START
      },
      {
        stage: RoutesGetReadyEnum.START,
        previous: RoutesGetReadyEnum.SALES,
        next: RoutesGetReadyEnum.CONTACT
      },
      {
        stage: RoutesGetReadyEnum.CONTACT,
        previous: RoutesGetReadyEnum.START,
        next: RoutesGetReadyEnum.DOCUMENT_TYPE
      },
      {
        stage: RoutesGetReadyEnum.DOCUMENT_TYPE,
        previous: this.ruleDocumentTypePrevious,
        next: RoutesGetReadyEnum.DOCUMENT_NUMBER
      },
      {
        stage: RoutesGetReadyEnum.DOCUMENT_NUMBER,
        previous: RoutesGetReadyEnum.DOCUMENT_TYPE,
        next: this.detectDocType
      },
      {
        stage: RoutesGetReadyEnum.DOCUMENT_CHIP,
        previous: RoutesGetReadyEnum.DOCUMENT_NUMBER,
        next: RoutesGetReadyEnum.MARITAL_STATUS
      },
      {
        stage: RoutesGetReadyEnum.DOCUMENT_NO_CHIP,
        previous: RoutesGetReadyEnum.DOCUMENT_NUMBER,
        next: RoutesGetReadyEnum.MARITAL_STATUS
      },
      {
        stage: RoutesGetReadyEnum.LOADING,
        previous: this.loadingPrevious,
        next: RoutesOffersEnum.PLANS
      },
      {
        stage: RoutesGetReadyEnum.ERROR_PAGE_CEDULA_IDENTIDAD,
        previous: null,
        next: null
      },
      {
        stage: RoutesGetReadyEnum.ERROR_PAGE_CREATE,
        previous: RoutesGetReadyEnum.LOADING,
        next: RoutesGetReadyEnum.ERROR_PAGE_DUPLICATE_ACCOUNT
      },
      {
        stage: RoutesGetReadyEnum.ERROR_PAGE_DUPLICATE_ACCOUNT,
        previous: RoutesGetReadyEnum.ERROR_PAGE_DUPLICATE_ACCOUNT,
        next: RoutesGetReadyEnum.ERROR_PAGE_DUPLICATE_ACCOUNT
      },
      {
        stage: RoutesGetReadyEnum.ERROR_PAGE_SOLICITATION,
        previous: RoutesGetReadyEnum.ERROR_PAGE_DUPLICATE_ACCOUNT,
        next: RoutesGetReadyEnum.DOCUMENT_TYPE
      },
      {
        stage: RoutesGetReadyEnum.OCCUPATION,
        previous: RoutesGetReadyEnum.INCOME,
        next: RoutesGetReadyEnum.LOADING
      },
      {
        stage: RoutesGetReadyEnum.MARITAL_STATUS,
        previous: this.detectDocType,
        next: this.ruleCivilStateNext
      },
      {
        stage: RoutesGetReadyEnum.INCOME,
        previous: this.ruleCivilStatePrevious,
        next: RoutesGetReadyEnum.OCCUPATION
      },
      {
        stage: RoutesGetReadyEnum.PARTNER,
        previous: RoutesGetReadyEnum.MARITAL_STATUS,
        next: this.rulePrincipalActivity
      },
    ];
  }

  detectDocType(): RoutesGetReadyEnum {
    if (this.getReadyFormService.getDocumentType() === DocumentTypeEnum.HASCHIP.toString()) {
      return RoutesGetReadyEnum.DOCUMENT_CHIP;
    }
    return RoutesGetReadyEnum.DOCUMENT_NO_CHIP;
  }

  returnToDocPage() {
    this.setStage(RoutesGetReadyEnum.DOCUMENT_NUMBER);
    this.sendToNext();
  }

  ruleCivilStateNext(): RoutesGetReadyEnum {
    return this.ruleCommonCivilState() ? RoutesGetReadyEnum.PARTNER : this.rulePrincipalActivity();
  }

  ruleCivilStatePrevious(): RoutesGetReadyEnum {
    return this.ruleCommonCivilState() ? RoutesGetReadyEnum.PARTNER : RoutesGetReadyEnum.MARITAL_STATUS;
  }

  ruleCommonCivilState(): boolean {
    return (
      this.getReadyFormService.getProperty('marital_status') === 'C' ||
      this.getReadyFormService.getProperty('marital_status') === 'X'
    );
  }

  rulePrincipalActivity(): RoutesGetReadyEnum {
    return RoutesGetReadyEnum.INCOME;
  }

  loadingPrevious(): RoutesGetReadyEnum {
    const previous = RoutesGetReadyEnum.INCOME;

    return previous ? RoutesGetReadyEnum.MARITAL_STATUS : RoutesGetReadyEnum.OCCUPATION;
  }

  ruleDocumentTypePrevious(): RoutesGetReadyEnum {
    return RoutesGetReadyEnum.CONTACT;
  }
}
