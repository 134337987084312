<div *ngIf="show" [@slideInOut] id="slide" class="message-warning">

  <voxel-alert
    id="warning_purple"
    [message]="errorMsg"
    template="error"
    icon="aviso_outline">
  </voxel-alert>

</div>
