<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='1'></header-oca>

  <main>

    <h1 class="header-container">
      Ingresá tus datos de
      <strong>
        vendedor OCA
      </strong>
    </h1>

    <div class='input-container'>

      <voxel-form-field
        [formGroup]="form"
        label="Código de vendedor"
        [msgErr]="vendorMessageError">
      <input
        voxelInput
        id="sellerId"
        [type]="'text'"
        [value]="seller.seller_id"
        formControlName="seller"
        maxlength="3"
        placeholder="000"
        (ngModelChange)="newInputChange('seller')">
      </voxel-form-field>

      <div class="selected-margin">

        <voxel-select
          [selectId]="'branchId'"
          [items]="branchList"
          [label]="'Sucursal'"
          [itemSelectedValue]="branchSelected"
          placeholder="Seleccioná una opción"
          (selectChange)="changeBranch($event)"
          [disabled]="branchChangesDisabled">
        </voxel-select>

      </div>
    </div>

  </main>
</div>

<footer>

  <voxel-button
    id="buttonContinuar"
    label="Continuar"
    (click)='getSeller()'
    [disabled]='isBtnDisabled'>
  </voxel-button>

</footer>

<facebook-pixel></facebook-pixel>
