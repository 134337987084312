import { Injectable } from '@angular/core';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

import { ServiceCommon } from '../../shared/service.common';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ArchiveService } from '../../shared/services/domains/archive/archive.service';
import { LoginService } from '../../shared/services/login.service';
import { SelfieNavigationService } from './selfie-navigation.service';

@Injectable()
export class SelfieCommonService {
  paramsUpload: any;

  constructor(
    private archiveService: ArchiveService,
    private latamItLoaderService: LatamItLoaderService,
    private selfieNavigationService: SelfieNavigationService,
    private serviceCommon: ServiceCommon,
    private loginService: LoginService,
  ) {
    this.paramsUpload = this.serviceCommon.getAdminList(AdminListEnum.FILE_UPLOAD);
  }

  sendImage(base64, fileType, callback) {
    this.latamItLoaderService.show();

    const imageJson = this.archiveService.createUploadFileJson(base64, 'jpeg', fileType);

    this.archiveService.doPatchSolicitationFileUpload(imageJson, false, false).subscribe(
      res => {
        if (res.statusCode === 200) {
          console.log('FileUpload post successfull', res);
          if (callback) {
            callback();
          } else {
            this.latamItLoaderService.hide();
            this.selfieNavigationService.sendToNext();
          }
        } else {
          console.log('FileUpload post ERROR', res);
          this.latamItLoaderService.hide();
          this.sendToPrevious(res.errorCount);
        }
      },
      err => {
        console.log('POST error', err);
        this.latamItLoaderService.hide();
        this.sendToPrevious(err.count);
      }
    );

  }

  scrollToTop(document: Document) {
    const scrollableElement = document.body || document.documentElement || document.scrollingElement;

    scrollableElement.scrollTop = 0;
  }

  private sendToPrevious(errorCount: number) {
    if (errorCount < 11 && (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia)) {
      this.selfieNavigationService.sendToPrevious();
    }
  }
}
