<div class="content-with-ghost-button">
  <div [id]="pageId" class="page-header">
    <div class="page-logo">
      <img [src]="'assets/images/logo-ocablue.svg'" />
    </div>
    <div class="page-header-text">
      Apertura
      <br> de cuentas
    </div>
  </div>
  <main class="info-data">
    <div *ngIf="seller && seller.seller_name || branch" class="important-content">
      <div class="important-content__icon-div">
        <i class="icon {{icon}}"></i>
      </div>
      <div class="important-content__text-div">
        <span class="important-content__text" *ngIf="seller && seller.seller_name">Bienvenido(a),<br></span>
        <span class="important-content__title">{{info}}</span><br>
        <span class="important-content__text" *ngIf="seller && seller.seller_name">Sucursal: {{branch}}</span>
      </div>
    </div>

    <div>
      <span class="content-title">¡Preparate!</span>
      <span class="content-title__text">Necesitás lo siguiente:</span>
    </div>

    <ul class="content-block content-list" *ngIf="personType == 'aperturadecuentas'">
      <li>
        <i class="icon icon-itaufonts_chat_enviado icono-inicio"></i>
        <span class="i-inicio">
        Cédula de identidad
      </span>
      </li>
      <li>
        <i class="icon icon-itaufonts_chat_enviado icono-inicio"></i>
        <span class="i-inicio">
        Comprobante de domicilio
      </span>
      </li>
      <li>
        <i class="icon icon-itaufonts_chat_enviado icono-inicio"></i>
        <span class="i-inicio">
        Acceso a la cámara
      </span>
      </li>
    </ul>

    <ul class="content-block content-list" *ngIf="personType == 'aperturadecuentaspj'">
        <li>
            RUT y cédula de identidad
          </li>
          <li>
            formularios de DGI:<br>
            <span class="sub-item">6906 y 6951 (versión web) o</span><br>
            <span class="sub-item">6351 y 6361 (versión física)</span>
          </li>
          <li>
            Comprobante de domicilio
          </li>
          <li>
            Acceso a la cámara
          </li>
      </ul>

  </main>

  <hr class="content-hr">

  <main class="important-content__main">
    <div class="important-content__container">
      <div class="important-content__icon-div">
        <i class="icon icon-itaufonts_exclamacao"></i>
      </div>
      <div class="important-content__text-div">
        <span class="information-title">Importante:</span>
        <span class="information-title__text"> debés ser mayor de 18 años y residente uruguayo. <br /><br /> La apertura de la cuenta está sujeta a verificación y aprobación.<br /><br /> Utilizamos cookies para mejorar la experiencia del usuario. Al comenzar estarás aceptando su uso.</span>
      </div>
    </div>
  </main>
</div>

<footer [ngSwitch]="!!this.seller">
  <voxel-button *ngSwitchCase="false" id="buttonContinuar" label="Comenzar" (click)="next()">
  </voxel-button>
  <button-sidebyside
    *ngSwitchCase="true"
    [navigationService]="getReadyNavigationService"
    nextHook="true"
    (hookNextAction)="next()">
  </button-sidebyside>
</footer>

<facebook-pixel></facebook-pixel>
