import { ForeignResidenceFullModel } from './foreign-residence-json';
import { PersonalReferenceFullModel } from './personal-reference-json';
import { AccountPurposeFullModel } from './account-purpose';
import { CurrencyValueFullModel } from './currency-value';

export class PldModelFull {
  account_purpose: AccountPurposeFullModel[];
  avg_balance: CurrencyValueFullModel = null;
  txn_count_per_month: number;
  amount_quantity_per_month = null;
  fiscal_residence_attributes: ForeignResidenceFullModel[] = [];
  is_american: boolean;
  reference_contact: PersonalReferenceFullModel[] = [];
  amount_quantity_per_month_transfers = null;
  amount_quantity_per_month_checks = null;
  amount_quantity_per_month_cash = null;
  last_year_incomes = null;
  taxpayer_type = '';
  customer_type = '';
  has_fiscal_residence: boolean;
}
