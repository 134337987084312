import { Injectable } from '@angular/core';

import { PldModelFull } from './models/pld-model';

@Injectable()
export class PldFullFormService {

  pldForm: PldModelFull;

  constructor() {
    this.pldForm = new PldModelFull();
  }

  getPldForm() {
    return this.pldForm;
  }

  getProperty(property: any) {
    return this.pldForm[property];
  }

  setProperty(property: string, value: any) {
    this.pldForm[property] = value;
  }

}

