import { WebRequestMethod, WebRequestOptions } from './shared/services/communicator.service';

export class RouterSignature implements WebRequestOptions {
  url = '/router-app/router/';
  method: WebRequestMethod = 'POST';
  search;
  params;
  headers;
  body;
  withCredentials;
  op;
}
