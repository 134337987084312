import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AddressFullCommonService } from 'src/app/address-full/shared/address-common.service';
import { CurrencyValueFullModel } from 'src/app/pld-full/shared/models/currency-value';
import { AdminListEnum } from 'src/app/shared/services/admin-lists/enum/admin-list.enum';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

import { AbreCuentasRoutesEnum } from '../../shared/enum/abre-cuentas-routes.enum';
import { ServiceCommon } from '../../shared/service.common';
import { ModuleLists } from '../../shared/services/admin-lists/const/module-lists.constant';
import { ListConversionService } from '../../shared/services/admin-lists/list-conversion-service/list-conversion.service';
import { ProspectMethods } from '../../shared/services/domains/prospect/prospect-enum';
import { ProspectService } from '../../shared/services/domains/prospect/prospect.service';
import { SellerService } from '../../shared/services/domains/seller/seller.service';
import { SolicitationMethods } from '../../shared/services/domains/solicitation/solicitation-enum';
import { SolicitationService } from '../../shared/services/domains/solicitation/solicitation.service';
import { LoginService } from '../../shared/services/login.service';
import { CohabitantModelFull } from '../models/cohabitant-model';
import { GetReadyModelFull } from '../models/get-ready-model';
import { AppService } from './../../app.service';
import { RoutesGetReadyFullEnum } from './enums/routes-get-ready-enum';
import { GetReadyFullConstants } from './get-ready-constants';
import { GetReadyFormFullService } from './get-ready-form.service';
import { GetReadyNavigationFullService } from './get-ready-navigation.service';

@Injectable()
export class GetReadyCommonFullService extends ServiceCommon {
  branchList: any[];
  offersResponse: any;

  private CEDULA_IDENTIDAD_BLOQUEADA = 'CEDULAIDENTIDADBLOQUEADA';
  private CEDULA_IDENTIDAD_INVALIDA = 'CEDULAIDENTIDADINVALIDA';
  private EXCEPCIONES_COMPORTAMENTALES = 'EXCEPCIONESCOMPORTAMENTALES';
  private CANAL_NO_HABILITADO = 'CANALNOHABILITADO';
  private SOLICITUD_EN_CURSO = 'SOLICITUDENCURSO';
  private CEDULA_IDENTIDAD_INVALIDA_DNIC = 'CEDULAIDENTIDADINVALIDADNIC';
  private CEDULA_IDENTIDAD_RECHAZADA = 'CEDULAIDENTIDADRECHAZADA';

  constructor(
    private getReadyNavigationService: GetReadyNavigationFullService,
    private solicitationService: SolicitationService,
    private latamItLoaderService: LatamItLoaderService,
    private prospectService: ProspectService,
    private getReadyFormService: GetReadyFormFullService,
    public listConversionService: ListConversionService,
    private addressCommonService: AddressFullCommonService,
    private sellerService: SellerService,
    loginService: LoginService,
    private appService: AppService
  ) {
    super(listConversionService, loginService);

    this.onInit();
  }

  getOffer() {
    return {
      packages: [
        {
          package_id: '1',
          package_title: 'Cuentas',
          package_description: 'Tarjeta OCA Blue',
          account_offers: [
            {
              general_info: {
                product_type: 'C',
                product_description: 'sin promedio mínimo'
              },
              account_type: 'CA',
              currency: 'UYU'
            },
            {
              general_info: {
                product_type: 'C',
                product_description: 'sin promedio mínimo'
              },
              account_type: 'CA',
              currency: 'USD'
            }
          ],
          prices: [],
          commissions: this.getAdminList(AdminListEnum.ACCOUNT_COMMISSIONS),
          benefits: this.getAdminList(AdminListEnum.ACCOUNT_BENEFITS)
        }
      ]
    };
  }

  onInit() {
    this.setEmitter();
    this.setModuleLists(ModuleLists.getReady);
    this.offersResponse = this.getOffer();
    this.getReadyFormService.setProperty('packages', this.offersResponse.packages);
  }

  setEmitter() {
    this.emissorEventos.subscribe(event => {
      this.applyEvents(event);
    });
  }

  applyEvents(event) {
    switch (event) {
      case GetReadyFullConstants.POST_SOLICITATION_ERROR: {
        this.getReadyNavigationService.returnToDocPage();
        break;
      }
    }
  }

  getSeller(seller_id: string): Observable<any> {
    const bodyPatch = this.buildBodyGetSellers(seller_id);
    return this.sellerService.getSellerRequest(bodyPatch);
  }

  buildBodyGetSellers(seller_id: string) {
    return {
      seller_id
    };
  }

  /*
   * POST Solicitation I (Document ID)
   * Returns Solicitation ID and prospect ID to be used on next requests
   */
  postSolicitation() {
    this.latamItLoaderService.show();
    const bodyPost = {
      // Create a new object so we don't overwrite the original object by removing the doc number mask
      identity_documents: JSON.parse(JSON.stringify(this.getReadyFormService.getProperty('identity_documents'))),
      solicitation_type: this.getReadyFormService.getProperty('solicitation_type')
    };

    Object.assign(bodyPost, { marital_status: this.getReadyFormService.getProperty('marital_status') });

    const cohabitant = new CohabitantModelFull();
    cohabitant.document.number = this.getReadyFormService.getProperty('cohabitant').document.number.replace(/[.-]+/g, '');

    if (this.getReadyNavigationService.ruleCommonCivilState()) {
      Object.assign(bodyPost, { cohabitant });
    }

    if (this.getReadyFormService.getProperty('monthly_income') !== null) {
      Object.assign(bodyPost, {
        monthly_income: this.toCurrency(this.getReadyFormService.getProperty('monthly_income'), 'UYU')
      });
    }

    bodyPost.identity_documents[0].number = bodyPost.identity_documents[0].number.replace(/\D/g, '');

    Object.assign(bodyPost, { channel_id: this.appService.getOrigin() });

    // tslint:disable-next-line: early-exit
    if (this.mustPostSolicitation(bodyPost)) {
      this.solicitationService.solicitationRequest(bodyPost, SolicitationMethods.POST_SOLICITATION, false, false).subscribe(
        res => {
          if (res.statusCode === 200) {
            this.getReadyFormService.setPostSolicitationBody(bodyPost);
            this.handlePostSolicitationSucess(res);
          } else {
            this.latamItLoaderService.hide();
          }
        },
        err => {
          this.handleRequestError(err);
        }
      );
    } else {
      this.patchProspectContact(this.getReadyFormService.getFormGetReady());
    }
  }

  mustPostSolicitation(bodyPost) {
    const solicitationId = this.solicitationService.getSolicitationId();
    const storedBody = this.getReadyFormService.getPostSolicitationBody();
    return (
      !solicitationId ||
      !storedBody ||
      !this.sameDocument(bodyPost, storedBody) ||
      !this.sameMaritalStatus(bodyPost, storedBody) ||
      !this.sameMonthlyIncome(bodyPost, storedBody)
    );
  }

  sameDocument(bodyPost, storedBody) {
    const bodyDocument = bodyPost.identity_documents[0];
    const storedDocument = storedBody.identity_documents[0];
    return (
      bodyDocument.number === storedDocument.number &&
      bodyDocument.type === storedDocument.type &&
      bodyDocument.series === storedDocument.series &&
      bodyDocument.folder === storedDocument.folder &&
      bodyDocument.security_code === storedDocument.security_code
    );
  }

  sameMaritalStatus(bodyPost, storedBody) {
    if (bodyPost.marital_status === storedBody.marital_status) {
      return (
        ['C', 'X'].indexOf(bodyPost.marital_status) === -1 ||
        bodyPost.cohabitant.document.number === storedBody.cohabitant.document.number
      );
    }
    return false;
  }

  sameMonthlyIncome(bodyPost, storedBody) {
    return bodyPost.monthly_income.amount === storedBody.monthly_income.amount;
  }

  toCurrency(object: CurrencyValueFullModel, currency) {
    object.decimals = 0;
    object.currency = currency;
    return object;
  }

  handlePostSolicitationSucess(body: any) {
    this.getReadyNavigationService.cleanErrors();
    this.solicitationService.setSolicitationId(body.data.solicitation_id);
    this.prospectService.setProspectId(body.data.solicitation_id);
    this.patchProspectContact(this.getReadyFormService.getFormGetReady());
  }

  /*
   * Patch Prospect Phone/Email
   */
  patchProspectContact(model: GetReadyModelFull): void {
    const bodyPatch = {
      email: model.email,
      phone: model.phone.replace(/\D/g, ''),
      prospect_id: this.prospectService.getProspectId()
    };

    this.prospectService.prospectRequest(bodyPatch, ProspectMethods.PATCH_PROSPECT_CONTACT, false, false).subscribe(
      res => {
        if (res.statusCode === 200) {
          this.getReadyNavigationService.sendToNext();
        }
        console.log('Patch Prospect Phone/Email', res);
        this.latamItLoaderService.hide();
      },
      err => {
        this.handleRequestError(err);
      }
    );
  }

  handleRequestError(err: any) {
    this.handleSystemErrors(err);
    this.latamItLoaderService.hide();
  }

  /*
   * GET Solicitation (last screen of get-ready)
   */
  getSolicitation(): void {
    const requestBody = {
      solicitation_id: this.solicitationService.getSolicitationId()
    };
    this.solicitationService.solicitationRequest(requestBody, SolicitationMethods.GET_SOLICITATION, false, false).subscribe(
      res => {
        this.handleGetSolicitationSuccess(res);
      },
      err => {
        this.handleSolicitationError(err.count);
      }
    );
  }

  /*
  - CedulaIdentidiadBloqueada -> Tela violeta (No fue posible crear tu cuenta ahora : ()
  - CedulaIdentidadInválida ->  Tela violeta (No fue posible crear tu cuenta ahora : ()
  - ExcepcionesComportamentales -> Tela violeta (No fue posible crear tu cuenta ahora : ()

  - CanalNoHabilitado -> Tela amarilla ( Nombre, ya tenés una cuenta en OCA ; ))
  - CédulaIdentidadInvalidaDNIC ->
        Error datos folio/cod seguridad ->
            Volver a tela de datos de documento ( serie y folio/código de seguridad) con el modal indicando error.
  */
  handleGetSolicitationSuccess(res: any) {
    const body = res;
    this.getReadyNavigationService.cleanErrors();
    this.solicitationResponse = body.data;
    const solicitationStatus = body.data.solicitation_status;

    // tslint:disable-next-line: early-exit
    if (body.statusCode === 200) {
      if (solicitationStatus && solicitationStatus.status_code === 'rejected') {
        this.handleRechazo(body);
      } else if (
        solicitationStatus &&
        solicitationStatus.progress_percentage === 100 &&
        solicitationStatus.status_code === 'accepted'
      ) {
        let firstName = body.data.prospects.first_name;
        if (firstName) {
          firstName = firstName.split(' ')[0];
        }
        if (firstName) {
          firstName = firstName[0].toUpperCase() + firstName.substring(1).toLowerCase();
        }
        this.prospectService.setFirstName(firstName);

        const address = body.data.prospects.personal_address;
        if (address && address.street) {
          address.street = address.street.substring(0, 40);
        }
        this.addressCommonService.setPersonalAddress(address);

        this.emitirEvento(GetReadyFullConstants.GET_SOLICITATION_SUCCESS);
      } else {
        this.emitirEvento(GetReadyFullConstants.GET_SOLICITATION_SUCCESS);
      }
    } else {
      this.handleSolicitationError(res.errorCount);
    }
  }

  handleRechazo(body: any) {
    const errorCode = body.data.solicitation_status.error_code.toUpperCase();
    if (
      errorCode === this.CEDULA_IDENTIDAD_BLOQUEADA ||
      errorCode === this.CEDULA_IDENTIDAD_INVALIDA ||
      errorCode === this.EXCEPCIONES_COMPORTAMENTALES
    ) {
      this.getReadyNavigationService.navigateTo(RoutesGetReadyFullEnum.ERROR_PAGE_CREATE);
    } else if (errorCode === this.CANAL_NO_HABILITADO) {
      this.getReadyNavigationService.navigateTo(RoutesGetReadyFullEnum.ERROR_PAGE_DUPLICATE_ACCOUNT);
    } else if (errorCode === this.SOLICITUD_EN_CURSO) {
      this.getReadyNavigationService.navigateTo(RoutesGetReadyFullEnum.ERROR_PAGE_SOLICITATION);
    } else if (errorCode === this.CEDULA_IDENTIDAD_RECHAZADA) {
      this.getReadyNavigationService.navigateTo(RoutesGetReadyFullEnum.ERROR_PAGE_CEDULA_IDENTIDAD);
    } else if (errorCode === this.CEDULA_IDENTIDAD_INVALIDA_DNIC) {
      this.handleDocumentError(body);
    } else {
      console.log('[handleRechazo] Undefined scenario', body);
    }
  }

  handleDocumentError(body: any) {
    console.log('[handleDocumentError] StatusCod', body.statusCode);
    this.getReadyNavigationService.addError(body.data.mensagem);
    this.emitirEvento(GetReadyFullConstants.POST_SOLICITATION_ERROR);
  }

  handleSolicitationError(errorCount: number) {
    if (errorCount < 11) {
      this.getReadyNavigationService.sendToPrevious();
    }
  }

  // END of GET Solicitation (last screen of get-ready)

  // PATCH Prospect principal activity
  patchProspectGetReady(): void {
    const getReadyForm = this.getReadyFormService.getFormGetReady();

    const requestBody = {
      prospect_id: this.prospectService.getProspectId()
    };

    if (this.getReadyFormService.getProperty('solicitation_type') === 'aperturadecuentas') {
      const pfBody = {
        customer_type: 'PF',
        principal_activity: this.getReadyFormService.getProperty('principal_activity')
      };
      Object.assign(requestBody, pfBody);
    } else {
      const pfBody = {
        customer_type: 'PJ'
      };
      Object.assign(requestBody, pfBody);
    }

    this.prospectService.prospectRequest(requestBody, ProspectMethods.PATCH_PROSPECT_GET_READY, true, true).subscribe(
      res => {
        // tslint:disable-next-line: early-exit
        if (res.statusCode === 200) {
          this.handlePatchSoliticitationSuccess(res.body);
        } else if (res.errorCount >= 11) {
          this.getReadyNavigationService.navigateTo(AbreCuentasRoutesEnum.TRY_AGAIN_LATER_ERROR);
        } else {
          this.getReadyNavigationService.navigateTo(this.getReadyNavigationService.rulePrincipalActivity());
        }
      },
      err => {
        this.handleSystemErrors(err);
        this.handleSolicitationError(err.count);
      }
    );
  }

  postProspect(model: GetReadyModelFull): void {
    const bodyPost = {
      email: model.email,
      phone: model.phone.replace(/\D/g, '')
    };

    this.prospectService.prospectRequest(bodyPost, ProspectMethods.POST_PROSPECT_GET_READY, false, false).subscribe(
      res => {
        console.log(res);

        if (res.statusCode === 200) {
          this.prospectService.setClientFlowId(res.data.prospect_id);
        }
        console.log('Post Prospect Phone/Email', res);
      },
      err => {
        console.log('Ha ocurrido un error' + err);
        console.log('headers do porspectRequest', err.headers);
      }
    );
    this.getReadyNavigationService.sendToNext();
  }

  handlePatchSoliticitationSuccess(body: any) {
    this.emitirEvento(GetReadyFullConstants.PATCH_PROSPECT_SUCCESS);
  }
}
