import { Component, ElementRef, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { AddressCommonService } from 'src/app/address/shared/address-common.service';
import { AdminListEnum } from 'src/app/shared/services/admin-lists/enum/admin-list.enum';
import { AbreCuentasRoutesEnum } from '../../shared/enum/abre-cuentas-routes.enum';
import { DocumentPhotosCommonService } from '../shared/document-photos-common.service';
import { DocumentPhotosNavigationService } from '../shared/document-photos-navigation.service';
import { DocumentPhotosCommon } from '../shared/document-photos.common';
import { DocumentPhotoApproach, RoutesDocumentPhotosEnum } from '../shared/enums/routes-document-photos-enum';



@Component({
  selector: 'document-front-photo',
  templateUrl: './document-front-photo.component.html',
  styleUrls: ['./document-front-photo.component.scss']
})
export class DocumentFrontPhotoComponent extends DocumentPhotosCommon implements OnInit {

  pageId = 'document-front-photo';
  previousRoute = RoutesDocumentPhotosEnum.DOCUMENT_FRONT_STAGE;
  loaderIcon: boolean;
  fileType = 'selfie';

  constructor(
    addressCommonService: AddressCommonService,
    commonService: DocumentPhotosCommonService,
    private navigationService: DocumentPhotosNavigationService) {
    super(commonService, addressCommonService);
  }

  ngOnInit() {
    this.navigationService.setStage(RoutesDocumentPhotosEnum.DOCUMENT_FRONT_PHOTO);
    this.choice = this.commonService.choice;
    if (this.choice === DocumentPhotoApproach.PHOTO) {
      this.setFileUploadParams();
    } else {
      this.initializeFileUploadOption();
    }
  }

  sendImageEvent(base64) {
    this.commonService.sendImage(base64, this.fileType);
  }

  continuarButton() {
    this.commonService.sendImage(this.base64, this.fileType);
  }
}
