export enum RoutesGetReadyFullEnum {
  START = 'preparate-bienvenida' as any,
  SALES = 'sucursales-vendedor-full' as any,
  DOCUMENT_TYPE = 'preparate-tipo-de-cedula-full' as any,
  DOCUMENT_NUMBER = 'preparate-numero-documento-full' as any,
  DOCUMENT_NO_CHIP = 'preparate-documento-no-chip-full' as any,
  DOCUMENT_CHIP = 'preparate-document-chip-full' as any,
  ERROR_PAGE_CEDULA_IDENTIDAD = 'preparate-pagina-erro-identidad-full' as any,
  ERROR_PAGE_CREATE = 'preparate-pagina-erro-crear-full' as any,
  ERROR_PAGE_DUPLICATE_ACCOUNT = 'preparate-pagina-erro-cuenta-full' as any,
  ERROR_PAGE_SOLICITATION = 'preparate-pagina-erro-solicitud-full' as any,
  IDENTITY_DOCUMENT = 'preparate-datos-documento-full' as any,
  CONTACT = 'preparate-contactamos-full' as any,
  MARITAL_STATUS = 'preparate-estado-civil-full' as any,
  PARTNER = 'preparate-conyuge-full' as any,
  OCCUPATION = 'preparate-actividad-laboral-full' as any,
  LOADING = 'preparate-loading-full' as any,
  INCOME = 'ingresos-liquidos-full' as any,
  PRINCIPAL_ACTIVITY_PJ = 'preparate-actividad-empresa-full' as any
}
