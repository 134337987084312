import { newId } from './id';
import { LatamItSearchInputSelectComponent } from './latam-it-search-input-select.component';
import { NgOption } from './latam-it-search-input-select.types';
import * as searchHelper from './search-helper';
import { SelectionModel } from './selection-model';
import { isDefined, isFunction, isObject } from './value-utils';

type OptionGroups = Map<string, NgOption[]>;

export class ItemsList {
  private _groups: OptionGroups;
  private _items: NgOption[] = [];
  private _filteredItems: NgOption[] = [];
  private _markedIndex = -1;

  constructor(private _latamInputSearch: LatamItSearchInputSelectComponent, private _selectionModel: SelectionModel) {}

  get items(): NgOption[] {
    return this._items;
  }

  get filteredItems(): NgOption[] {
    return this._filteredItems;
  }

  get markedIndex(): number {
    return this._markedIndex;
  }

  get selectedItems() {
    return this._selectionModel.value;
  }

  get markedItem(): NgOption {
    return this._filteredItems[this._markedIndex];
  }

  get noItemsToSelect(): boolean {
    return this._latamInputSearch.hideSelected && this._items.length === this.selectedItems.length;
  }

  get maxItemsSelected(): boolean {
    return this._latamInputSearch.multiple && this._latamInputSearch.maxSelectedItems <= this.selectedItems.length;
  }

  get lastSelectedItem() {
    return this.selectedItems[this.selectedItems.length - 1];
  }

  setItems(items: any[]) {
    this._items = items.map((item, index) => this.mapItem(item, index));
    if (this._latamInputSearch.groupBy) {
      this._groups = this._groupBy(this._items, this._latamInputSearch.groupBy);
      this._items = this._flatten(this._groups);
    } else {
      this._groups = new Map();
      this._groups.set(undefined, this._items);
    }
    this._filteredItems = [...this._items];
  }

  select(item: NgOption) {
    if (item.selected || this.maxItemsSelected) {
      return;
    }
    const multiple = this._latamInputSearch.multiple;
    if (!multiple) {
      this.clearSelected();
    }

    this._selectionModel.select(item, multiple, this._latamInputSearch.selectableGroupAsModel);
    if (this._latamInputSearch.hideSelected && multiple) {
      this._hideSelected(item);
    }
  }

  unselect(item: NgOption) {
    if (!item.selected) {
      return;
    }
    this._selectionModel.unselect(item, this._latamInputSearch.multiple);
    if (this._latamInputSearch.hideSelected && isDefined(item.index) && this._latamInputSearch.multiple) {
      this._showSelected(item);
    }
  }

  findItem(value: any): NgOption {
    let findBy: (item: NgOption) => boolean;
    if (this._latamInputSearch.compareWith) {
      findBy = item => this._latamInputSearch.compareWith(item.value, value);
    } else if (this._latamInputSearch.bindValue) {
      findBy = item => !item.children && this.resolveNested(item.value, this._latamInputSearch.bindValue) === value;
    } else {
      findBy = item =>
        item.value === value ||
        (!item.children && item.label && item.label === this.resolveNested(value, this._latamInputSearch.bindLabel));
    }
    return this._items.find(item => findBy(item));
  }

  addItem(item: any) {
    const option = this.mapItem(item, this._items.length);
    this._items.push(option);
    this._filteredItems.push(option);
    return option;
  }

  clearSelected() {
    this._selectionModel.clear();
    this._items.forEach(item => {
      item.selected = false;
      item.marked = false;
    });
    if (this._latamInputSearch.hideSelected) {
      this.resetFilteredItems();
    }
  }

  findByLabel(_term: string) {
    let term = _term;
    term = searchHelper.stripSpecialChars(term).toLocaleLowerCase();
    return this.filteredItems.find(item => {
      const label = searchHelper.stripSpecialChars(item.label).toLocaleLowerCase();
      return label.substr(0, term.length) === term;
    });
  }

  filter(_term: string): void {
    let term = _term;
    if (!term) {
      this.resetFilteredItems();
      return;
    }

    this._filteredItems = [];
    term = this._latamInputSearch.searchFn ? term : searchHelper.stripSpecialChars(term).toLocaleLowerCase();
    const match = this._latamInputSearch.searchFn || this._defaultSearchFn;
    const hideSelected = this._latamInputSearch.hideSelected;

    for (const key of Array.from(this._groups.keys())) {
      const matchedItems = [];
      for (const item of this._groups.get(key)) {
        if (hideSelected && ((item.parent && item.parent.selected) || item.selected)) {
          continue;
        }
        const searchItem = this._latamInputSearch.searchFn ? item.value : item;
        if (match(term, searchItem)) {
          matchedItems.push(item);
        }
      }
      // tslint:disable-next-line: early-exit
      if (matchedItems.length > 0) {
        const [last] = matchedItems.slice(-1);
        if (last.parent) {
          const head = this._items.find(x => x === last.parent);
          this._filteredItems.push(head);
        }
        this._filteredItems.push(...matchedItems);
      }
    }
  }

  resetFilteredItems() {
    if (this._filteredItems.length === this._items.length) {
      return;
    }

    if (this._latamInputSearch.hideSelected && this.selectedItems.length > 0) {
      this._filteredItems = this._items.filter(x => !x.selected);
    } else {
      this._filteredItems = this._items;
    }
  }

  unmarkItem() {
    this._markedIndex = -1;
  }

  markNextItem() {
    this._stepToItem(+1);
  }

  markPreviousItem() {
    this._stepToItem(-1);
  }

  markItem(item: NgOption) {
    this._markedIndex = this._filteredItems.indexOf(item);
  }

  markSelectedOrDefault(markDefault?: boolean) {
    if (this._filteredItems.length === 0) {
      return;
    }
    const indexOfLastSelected = this._latamInputSearch.hideSelected ? -1 : this._filteredItems.indexOf(this.lastSelectedItem);
    if (this.lastSelectedItem && indexOfLastSelected > -1) {
      this._markedIndex = indexOfLastSelected;
    } else if (this._latamInputSearch.excludeGroupsFromDefaultSelection) {
      this._markedIndex = markDefault ? this.filteredItems.findIndex(x => !x.disabled && !x.children) : -1;
    } else {
      this._markedIndex = markDefault ? this.filteredItems.findIndex(x => !x.disabled) : -1;
    }
  }

  resolveNested(option: any, key: string): any {
    if (!isObject(option)) {
      return option;
    }
    if (key.indexOf('.') === -1) {
      return option[key];
    }
    {
      const keys = key.split('.');
      let value = option;
      for (let i = 0, len = keys.length; i < len; ++i) {
        if (value == null) {
          return null;
        }
        value = value[keys[i]];
      }
      return value;
    }
  }

  mapItem(item: any, index: number): NgOption {
    const label = isDefined(item.$ngOptionLabel)
      ? item.$ngOptionLabel
      : this.resolveNested(item, this._latamInputSearch.bindLabel);
    const value = isDefined(item.$ngOptionValue) ? item.$ngOptionValue : item;
    return {
      index,
      label: isDefined(label) ? label.toString() : '',
      value,
      disabled: item.disabled,
      htmlId: newId()
    };
  }

  mapSelectedItems() {
    const multiple = this._latamInputSearch.multiple;
    for (const selected of this.selectedItems) {
      const value = this._latamInputSearch.bindValue
        ? this.resolveNested(selected.value, this._latamInputSearch.bindValue)
        : selected.value;
      const item = isDefined(value) ? this.findItem(value) : null;
      this._selectionModel.unselect(selected, multiple);
      this._selectionModel.select(item || selected, multiple, this._latamInputSearch.selectableGroupAsModel);
    }

    if (this._latamInputSearch.hideSelected) {
      this._filteredItems = this.filteredItems.filter(x => this.selectedItems.indexOf(x) === -1);
    }
  }

  private _showSelected(item: NgOption) {
    this._filteredItems.push(item);
    if (item.parent) {
      const parent = item.parent;
      const parentExists = this._filteredItems.find(x => x === parent);
      if (!parentExists) {
        this._filteredItems.push(parent);
      }
    } else if (item.children) {
      for (const child of item.children) {
        child.selected = false;
        this._filteredItems.push(child);
      }
    }

    this._filteredItems.sort((a, b) => a.index - b.index);
    this._filteredItems = [...this._filteredItems];
  }

  private _hideSelected(item: NgOption) {
    this._filteredItems = this._filteredItems.filter(x => x !== item);
    if (item.parent) {
      const children = item.parent.children;
      if (children.every(x => x.selected)) {
        this._filteredItems = this._filteredItems.filter(x => x !== item.parent);
      }
    } else if (item.children) {
      this._filteredItems = this.filteredItems.filter(x => x.parent !== item);
    }
  }

  private _defaultSearchFn(search: string, opt: NgOption) {
    const label = searchHelper.stripSpecialChars(opt.label).toLocaleLowerCase();
    return label.indexOf(search) > -1;
  }

  private _getNextItemIndex(steps: number) {
    if (steps > 0) {
      return this._markedIndex === this._filteredItems.length - 1 ? 0 : this._markedIndex + 1;
    }
    return this._markedIndex <= 0 ? this._filteredItems.length - 1 : this._markedIndex - 1;
  }

  private _stepToItem(steps: number) {
    if (this._filteredItems.length === 0 || this._filteredItems.every(x => x.disabled)) {
      return;
    }

    this._markedIndex = this._getNextItemIndex(steps);
    if (this.markedItem.disabled) {
      this._stepToItem(steps);
    }
  }

  private _groupBy(items: NgOption[], prop: string | Function): OptionGroups {
    const isFn = isFunction(this._latamInputSearch.groupBy);
    const groups = new Map<string, NgOption[]>();
    for (const item of items) {
      let key = isFn ? (prop as Function)(item.value) : item.value[prop as string];
      key = isDefined(key) ? key : undefined;
      const group = groups.get(key);
      if (group) {
        group.push(item);
      } else {
        groups.set(key, [item]);
      }
    }
    return groups;
  }

  private _flatten(groups: OptionGroups) {
    const isFn = isFunction(this._latamInputSearch.groupBy);
    const items = [];
    const withoutGroup = groups.get(undefined) || [];
    items.push(...withoutGroup);
    let i = withoutGroup.length;
    for (const key of Array.from(groups.keys())) {
      if (!isDefined(key)) {
        continue;
      }
      const parent: NgOption = {
        label: key,
        children: undefined,
        parent: null,
        index: i++,
        disabled: !this._latamInputSearch.selectableGroup,
        htmlId: newId()
      };
      const groupKey = isFn ? this._latamInputSearch.bindLabel : (this._latamInputSearch.groupBy as string);
      const groupValue = this._latamInputSearch.groupValue || (() => ({ [groupKey]: key }));
      const children = groups.get(key).map(x => {
        x.parent = parent;
        x.children = undefined;
        x.index = i++;
        return x;
      });
      parent.children = children;
      parent.value = groupValue(key, children.map(x => x.value));
      items.push(parent);
      items.push(...children);
    }
    return items;
  }
}
