<div class="main-container content-with-filled-button">
  <div class="main-container__cover">
    <img class="main-container__image" src="assets/images/img_navegador_no_compatible.svg" alt="incompatible">
  </div>
  <div class="main-container__content">
    <h1 class="title title-create">
      {{info.title}}
    </h1>

    <h2 class="sub-title sub-title-create">
      {{info.subtitle}}
    </h2>
  </div>
</div>

<footer>
  <voxel-button
    id="errors-incompatible-browser-redirect"
    label="aceptar"
    (click)="redirect()">
  </voxel-button>

  <!-- 
  <voxel-mb-button
    id="errors-incompatible-browser-redirect"
    label="aceptar"
    radioButton="true"
    (onClickButton)="redirect()">
  </voxel-mb-button>
  -->
</footer>
