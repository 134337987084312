<div [id]="pageId"></div>

<div class="img-container">
  <img class="image" src="assets/images/AF_farol_roxo.svg" alt="error_img">
</div>

<main>
  <h1 class="title title-create">
    No fue posible crear tu cuenta ahora :(
  </h1>

  <h2 class="sub-title sub-title-create">
    La solicitud no podrá ser realizada por este medio. Por favor, dirigite a una de nuestras sucursales para asistirte mejor.
  </h2>

  <hr class="between" />

  <div class="normal-text">
    <div class="icon-div">
      <i class="icon icon-itaufonts_agencia"></i>
    </div>
    <div class="text-div">
      <span>Buscá la sucursal OCA más cercana
        <a class="link-itau-font" [href]="urlBranch">haciendo clic aquí</a>
      </span>
    </div>
  </div>

</main>

<footer class="footer-page-error">
  <a class="link-itau" [href]="urlOCA">salir de apertura de cuentas</a>
</footer>

<facebook-pixel></facebook-pixel>