import { Injectable } from '@angular/core';
import { GetReadyModel } from '../models/get-ready-model';
@Injectable()
export class GetReadyFormService {

  private getReadyForm: GetReadyModel;
  private postSolicitationBody: any;

  constructor() {
    this.getReadyForm = new GetReadyModel();
  }

  getFormGetReady() {
    return this.getReadyForm;
  }

  getProperty(property: any) {
    return this.getReadyForm[property];
  }

  setProperty(property: string, value: any) {
    this.getReadyForm[property] = value;
  }

  getDocumentType() {
    return this.getReadyForm.identity_documents[0].type;
  }

  getPostSolicitationBody() {
    return this.postSolicitationBody;
  }

  setPostSolicitationBody(body) {
    this.postSolicitationBody = body;
  }

}
