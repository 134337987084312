import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OffersFullModel } from 'src/app/offers-full/models/offers-model';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ClientCommonService } from '../shared/client-common.service';
import { ClientFormService } from '../shared/client-form.service';
import { RoutesClientEnum } from '../shared/enums/routes-client-enum';
import { Analytics } from './../../shared/analytics';

@Component({
  selector: 'client-benefits-comissions',
  templateUrl: './client-benefits-comissions.component.html',
  styleUrls: ['../../offers-full/shared/offers-styles.scss', './client-benefits-comissions.component.scss']
})
export class ClientBenefitsComissionsComponent implements OnInit, OnDestroy {
  pageId = '02_benefits-comissions';
  offersModel: OffersFullModel;
  selectedOffer: number;
  externalUrl: string;
  body = document.querySelector('body');
  hyperlinkBehavior: string;
  modalShow = false;

  constructor(
    public analyticsLib: Analytics,
    public commonService: ClientCommonService,
    private formService: ClientFormService,
    private route: Router
  ) {}

  ngOnInit() {
    this.setData();
    this.commonService.scrollToTop(document);
    this.hyperlinkBehavior = this.commonService.getHyperlinkBehavior();

    this.analyticsLib.buildData('BeneficiosYCondiciones', this.pageId, null, true);
    this.analyticsLib.trackState(null, null);
  }

  analyticsDownload() {
    this.analyticsLib.trackAction({
      customCategory: `Footer | Link | Bottom`,
      customAction: `Download`,
      customLabel: `DownloadTerminosYCondiciones`
    });
  }

  analyticsVolver() {
    this.analyticsLib.trackAction({
      customCategory: `Footer | Button | Bottom`,
      customAction: `Click`,
      customLabel: `BeneficiosYCondicionesVolver`
    });
  }

  setData() {
    const adminLinkURLs = this.commonService.getAdminList(AdminListEnum.LIST_URLS);

    this.selectedOffer = this.formService.getProperty('selected_package') || 0;
    this.offersModel = this.commonService.offersResponse;
    this.externalUrl = adminLinkURLs.find(i => i.code.toUpperCase() === 'ACCOUNT_TERMS').description;
  }

  previous() {
    this.route.navigate([RoutesClientEnum.CONTACT.toString()]);
  }

  category2icon(category) {
    const iconList = this.commonService.getAdminList(AdminListEnum.ICONS);

    const icon = iconList.find(i => i.code === category);
    if (icon && icon.label) {
      return icon.label;
    }
    return 'icon-itaufonts_chat_enviado';
  }

  groupText(benefit) {
    return '<strong>' + benefit.title + '</strong> ' + benefit.benefit_description;
  }

  ngOnDestroy() {
    this.body.setAttribute('style', 'background-color: white');
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }
}
