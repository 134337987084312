import { Injectable } from '@angular/core';
import { merge } from 'rxjs';
import { distinct } from 'rxjs/operators';

import { ServiceCommon } from '../../shared/service.common';
import { ModuleLists } from '../../shared/services/admin-lists/const/module-lists.constant';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ListConversionService } from '../../shared/services/admin-lists/list-conversion-service/list-conversion.service';
import { ProspectMethods } from '../../shared/services/domains/prospect/prospect-enum';
import { ProspectService } from '../../shared/services/domains/prospect/prospect.service';
import { LoginService } from '../../shared/services/login.service';
import { AddressModel } from '../models/address-model';
import { AddressFormService } from './address-form.service';

@Injectable()
export class AddressCommonService extends ServiceCommon {
  states: any[];
  cities: any[];
  pickupCenterState: any[];
  ocaCenterState: any[];
  tataCenterState: any[];
  personalAddress: AddressModel;
  uploadedDocumentAddress: AddressModel;

  constructor(
    private addressFormService: AddressFormService,
    private prospectService: ProspectService,
    public listConversionService: ListConversionService,
    loginService: LoginService
  ) {
    super(listConversionService, loginService);
    this.cities = [];
    this.states = [];
    this.pickupCenterState = [];
    this.ocaCenterState = [];
    this.tataCenterState = [];
    this.onInit();
  }

  onInit() {
    this.setModuleLists(ModuleLists.address);
    this.createStateCitiesList();
    this.pickupCenterState = this.uniqueStatesList(
      this.listConversionService.convertListforVoxelSelect(this.getAdminList(AdminListEnum.PICKUP_CENTER), 'state', 'state')
    );
    this.ocaCenterState = this.uniqueStatesList(
      this.listConversionService.convertListforVoxelSelect(this.getAdminList(AdminListEnum.OCA_CENTER), 'state', 'state')
    );
    this.tataCenterState = this.uniqueStatesList(
      this.listConversionService.convertListforVoxelSelect(this.getAdminList(AdminListEnum.TATA_CENTER), 'state', 'state')
    );
  }

  createStateCitiesList() {
    const listDepartments = this.listConversionService.convertListforVoxelSelect(
      this.getAdminList(AdminListEnum.STATE_CITY),
      'state',
      'state'
    );
    merge(listDepartments)
      .pipe(distinct(x => x.label))
      .subscribe(y => this.states.push(y));
    this.cities = this.listConversionService.convertListforVoxelSelect(
      this.getAdminList(AdminListEnum.STATE_CITY),
      'city',
      'state'
    );
  }

  getPersonalAddress() {
    const bodyGet = {
      prospect_id: this.prospectService.getProspectId()
    };
    return this.prospectService.prospectRequest(bodyGet, ProspectMethods.GET_PROSPECT, true, true);
  }

  setPersonalAddress(existingAddress): void {
    if (existingAddress) {
      this.personalAddress = existingAddress;
      this.addressFormService.personalAddressProspect = existingAddress;
    }
  }

  setUploadedDocumentAddress() {
    this.uploadedDocumentAddress = this.addressFormService.getProperty('personal_address');
  }

  private uniqueStatesList(listVoxelSelectState) {
    const states = new Set();

    return listVoxelSelectState.filter(pickupCenter => {
      if (states.has(pickupCenter.id)) {
        return false;
      }
      states.add(pickupCenter.id);
      return true;
    });
  }
}
