import { Component, OnDestroy, OnInit } from '@angular/core';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';


import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { RoutesFinalDataEnumFull } from '../shared/enums/routes-final-data-enum';
import { FinalDataFullCommonService } from '../shared/final-data-common.service';
import { FinalDataFullFormService } from '../shared/final-data-form.service';
import { FinalDataFullNavigationService } from '../shared/final-data-navigation.service';
import { Analytics } from '../../shared/analytics';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';
import { OffersFullModel } from 'src/app/offers-full/models/offers-model';
import { CardBrandFull } from 'src/app/offers-full/models/card-brand.model';
import { OffersFullFormService } from 'src/app/offers-full/shared/offers-form.service';
import { OffersFullCommonService } from 'src/app/offers-full/shared/offers-common.service';
import { GetReadyFormFullService } from 'src/app/get-ready-full/shared/get-ready-form.service';

@Component({
  selector: 'confirmation-final-data',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationFullComponent implements OnInit, OnDestroy {
  pageId: string;
  icons: any[];
  overdraftLimit: string;
  offersModel: OffersFullModel;
  cardType: string;
  cardBrand: CardBrandFull;
  cardCreditLimit: string;
  body = document.querySelector('body');
  acceptTerms: boolean;
  urlTerms: string;
  hyperlinkBehavior: string;
  usPerson: boolean;
  hintObject: any;

  constructor(
    private analyticsLib: Analytics,
    public finalDataNavigationService: FinalDataFullNavigationService,
    private offersFormService: OffersFullFormService,
    private offersCommonService: OffersFullCommonService,
    public finalDataCommonService: FinalDataFullCommonService,
    private finalDataFormService: FinalDataFullFormService,
    private latamItLoaderService: LatamItLoaderService,
    private getReadyFormService: GetReadyFormFullService,
    private sellerService: SellerService,
  ) { this.hintObject = {}; }

  ngOnInit() {
    this.resolveHint();
    this.pageId = 'confirmation_final_data';
    this.finalDataNavigationService.setStage(RoutesFinalDataEnumFull.CONFIRMATION);
    this.setData();
    this.body.setAttribute('style', 'background-color: #EFE9E5');
    this.acceptTerms = this.finalDataFormService.getProperty('solicitation_completed') || false;
    this.usPerson = !!this.finalDataFormService.getProperty('is_american');
    this.hyperlinkBehavior = this.offersCommonService.getHyperlinkBehavior();

    if (!this.sellerService.isSeller()) {
      this.analyticsLib.buildData('Confirmacion', this.pageId, 'Checkout', false);
      this.analyticsLib.trackState(null, null);
    }
  }

  ngOnDestroy() {
    this.body.setAttribute('style', 'background-color: white');
  }

  setData() {
    const cardTypeList: any = this.offersCommonService.getAdminList(AdminListEnum.CARD_TYPE);
    const cardBrandList: any = this.offersCommonService.getAdminList(AdminListEnum.CARD_BRAND);
    this.icons = this.offersCommonService.getAdminList(AdminListEnum.ICONS);

    this.overdraftLimit = this.offersFormService.getForm().credit_limit
      ? this.offersFormService.getForm().credit_limit.toString()
      : null;
    this.offersModel = this.offersFormService.getForm();
    this.cardType = cardTypeList ? cardTypeList[0].label : null;
    this.cardBrand = new CardBrandFull();
    this.cardBrand.description = cardBrandList ? cardBrandList[0].label : null;
    this.cardCreditLimit = this.offersFormService.getForm().credit_card_limit
      ? this.offersFormService.getForm().credit_card_limit.toString()
      : null;
  }

  continuarButton() {
    this.finalDataCommonService.doPatchSolicitationProspectAddress();
  }

  checkboxChange(event: any) {
    this.acceptTerms = event.checked;
    this.finalDataFormService.setProperty('solicitation_completed', event.checked);
  }

  checkboxAmericanChange(event: any) {
    this.usPerson = event.checked;
    this.finalDataFormService.setProperty('is_american', !this.usPerson);
  }

  setAtual() {
    let atual = 15;
    if (this.getReadyFormService.getProperty('solicitation_type') === 'aperturadecuentaspj') {
      atual = atual + 3;
    }

    return atual;
  }

  resolveHint() {
    // tslint:disable-next-line:max-line-length
    this.hintObject.message = `Por el momento no es posible realizar la apertura de cuenta a ciudadanos de Estados Unidos, o US Person. Son considerados US Person todos aquellos que tienen obligaciones de pagar impuestos en los EE.UU.`;
    this.hintObject.value = 'usperson';
  }
}
