import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { RoutesGetReadyEnum } from 'src/app/get-ready/shared/enums/routes-get-ready-enum';
import { GetReadyCommonService } from 'src/app/get-ready/shared/get-ready-common.service';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ProspectService } from '../../shared/services/domains/prospect/prospect.service';
import { SocialNetworksModel } from '../models/social-networks-model';


@Component({
  selector: 'confirmation-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss'],
})
export class EndComponent implements OnInit {

  static TRACKING_PUBLICO_FIN = 'TRACKING-PUBLICO-FIN';

  pageId: string;
  name: string;
  listSocialNetworks: SocialNetworksModel[];

  constructor(
    private getReadyCommonService: GetReadyCommonService,
    private prospectService: ProspectService,
    private appService: AppService,

  ) {
    this.pageId = 'confirmation_end';
  }

  ngOnInit() {
    this.setData();
    this.getNetworks();
  }

  setData() {
    this.name = this.prospectService.getFirstName();
  }

  getNetworks() {
    const adminLinkURLs = this.getReadyCommonService.getAdminList(AdminListEnum.LIST_URLS);

    this.listSocialNetworks = [
      new SocialNetworksModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'INSTITUTIONAL_SITE').description,
        'assets/images/icon_web.png',
        'web_img',
        'end__size-img',
      ),
      new SocialNetworksModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'SOCIAL_FACEBOOK').description,
        'assets/images/icon_facebook.png',
        'facebook_img',
        'end__size-img end__margin-img',
      ),
      new SocialNetworksModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'SOCIAL_INSTAGRAM').description,
        'assets/images/icon_instagram.png',
        'instragram_img',
        'end__size-img end__margin-img',
      ),
      new SocialNetworksModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'SOCIAL_LINKEDIN').description,
        'assets/images/icon_linkedin.png',
        'linkedin_img',
        'end__size-img end__margin-img',
      ),
    ];
  }

  backToHome() {
    if (typeof window !== 'undefined') {
      window.location.href = `?_reload&origin=${this.appService.getOrigin()}#/${RoutesGetReadyEnum.SALES}`;
    }
  }
}
