<div class="black-bg content-with-filled-button">
  <div id='{{pageId}}'></div>

  <header-oca [atual]='1'></header-oca>

  <main>

    <h1 id="get-ready-title" class="document-photos-header">
      Por seguridad, necesitamos que tomes dos fotos de la cédula del cliente
    </h1>

    <div class="instructions-container">
      <ul>
        <li class="content-icons icon-1"></li>
        <li class="content-text">
          Al tomar la foto, asegurate de que los datos esten legibles.
        </li>
      </ul>
      <ul>
        <li class="content-icons icon-2"></li>
        <li class="content-text">
          Tomá las fotos en un lugar
          bien iluminado
        </li>
      </ul>
    </div>

    <p class="instructions-text">
      Solo utilizaremos las fotos para validar la identidad del cliente y asegurarnos que no haya ningún inconvenientecon su documento.
    </p>

    <h1 id="get-ready-title" class="document-photos-header">Elegí una de estas opciones</h1>

    <div class="radio-button">
      <voxel-radio-button
        *ngFor="let choice of choices"
        [id]="radio-choice.code"
        [value]="choice.code"
        [radioName]=""
        [checked]="choice.checked"
        (radioButtonChange)='changeChoice(choice)'>
        {{choice.label}}
      </voxel-radio-button>
    </div>

  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    [navigationService]="navigationService"
    (hookNextAction)="next()"
    [isBtnDisabled]="!selectedChoiceCode">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>