import { Component, OnInit } from '@angular/core';

import { SelfieNavigationService } from '../shared/selfie-navigation.service';
import { ProgressBarService } from '../../progressBar.service';
import { SelfieCommonService } from '../shared/selfie-common.service';
import { RoutesSelfieEnum } from '../shared/enums/routes-selfie-enum';
import { Analytics } from '../../shared/analytics';

@Component({
  selector: 'selfie-get-ready',
  templateUrl: './selfie-get-ready.component.html',
  styleUrls: ['./selfie-get-ready.component.scss']
})
export class SelfieGetReadyComponent implements OnInit {

  pageId = 'selfie-get-ready';
  selectedSegment = { label: 'varejo', value: 'varejo' };

  constructor(
    public navigationService: SelfieNavigationService,
    private progressBarService: ProgressBarService,
    public selfieCommonService: SelfieCommonService,
    private analyticsLib: Analytics) {}

  ngOnInit() {
    this.navigationService.setStage(RoutesSelfieEnum.GET_READY);
    this.setProgressBar();

    this.analyticsLib.buildData('IntroSelfie', this.pageId, '14', false);
    this.analyticsLib.trackState(null, null);
  }

  next() {
    this.navigationService.sendToNext();
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 3,
      amountBar: 3,
      currentBar: 1,
      labelName: 'selfie'
    });
  }

}
