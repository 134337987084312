import { OfferProduct } from './offer-product-model';
import { Money } from './money-model';

export class AccountOffer {
  general_info: OfferProduct;
  account_type: string;
  currency: string;
  overdraft_limit?: Money;
  icon?: string;

  constructor() {
    this.general_info = new OfferProduct();
    this.account_type = '';
    this.currency = '';
    this.overdraft_limit = new Money();
    this.icon = '';
  }
}
