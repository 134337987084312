import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardBrand } from 'src/app/offers/models/card-brand.model';
import { OffersModel } from 'src/app/offers/models/offers-model';
import { OffersCommonService } from 'src/app/offers/shared/offers-common.service';
import { OffersFormService } from 'src/app/offers/shared/offers-form.service';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { RoutesFinalDataEnum } from '../shared/enums/routes-final-data-enum';
import { FinalDataCommonService } from '../shared/final-data-common.service';
import { FinalDataFormService } from '../shared/final-data-form.service';
import { FinalDataNavigationService } from '../shared/final-data-navigation.service';


@Component({
  selector: 'confirmation-final-data',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  pageId: string;
  icons: any[];
  overdraftLimit: string;
  offersModel: OffersModel;
  cardType: string;
  cardBrand: CardBrand;
  cardCreditLimit: string;
  body = document.querySelector('body');
  acceptTerms: boolean;
  urlTerms: string;
  hyperlinkBehavior: string;
  usPerson: boolean;
  hintObject: any;

  constructor(
    public finalDataNavigationService: FinalDataNavigationService,
    private offersFormService: OffersFormService,
    private offersCommonService: OffersCommonService,
    public finalDataCommonService: FinalDataCommonService,
    private finalDataFormService: FinalDataFormService,
  ) { this.hintObject = {}; }

  ngOnInit() {
    this.resolveHint();
    this.pageId = 'confirmation_final_data';
    this.finalDataNavigationService.setStage(RoutesFinalDataEnum.CONFIRMATION);
    this.setData();
    this.body.setAttribute('style', 'background-color: #EFE9E5');
    this.acceptTerms = this.finalDataFormService.getProperty('solicitation_completed') || false;
    this.usPerson = this.finalDataFormService.getProperty('is_american') === false ? true : false;
    this.hyperlinkBehavior = this.offersCommonService.getHyperlinkBehavior();
  }

  ngOnDestroy() {
    this.body.setAttribute('style', 'background-color: white');
  }

  setData() {
    const cardTypeList: any = this.offersCommonService.getAdminList(AdminListEnum.CARD_TYPE);
    const cardBrandList: any = this.offersCommonService.getAdminList(AdminListEnum.CARD_BRAND);
    this.icons = this.offersCommonService.getAdminList(AdminListEnum.ICONS);

    this.overdraftLimit = this.offersFormService.getForm().credit_limit
      ? this.offersFormService.getForm().credit_limit.toString()
      : null;
    this.offersModel = this.offersFormService.getForm();
    this.cardType = cardTypeList ? cardTypeList[0].label : null;
    this.cardBrand = new CardBrand();
    this.cardBrand.description = cardBrandList ? cardBrandList[0].label : null;
    this.cardCreditLimit = this.offersFormService.getForm().credit_card_limit
      ? this.offersFormService.getForm().credit_card_limit.toString()
      : null;
  }

  continuarButton() {
    this.finalDataCommonService.doPatchSolicitationProspectAddress();
  }

  checkboxChange(event: any) {
    this.acceptTerms = event.checked;
    this.finalDataFormService.setProperty('solicitation_completed', event.checked);
  }

  checkboxAmericanChange(event: any) {
    this.usPerson = event.checked;
    this.finalDataFormService.setProperty('is_american', !this.usPerson);
  }

  setAtual() {
    return 15;
  }

  resolveHint() {
    // tslint:disable-next-line:max-line-length
    this.hintObject.message = `Por el momento no es posible realizar la apertura de cuenta a ciudadanos de Estados Unidos, o US Person. Son considerados US Person todos aquellos que tienen obligaciones de pagar impuestos en los EE.UU.`;
    this.hintObject.value = 'usperson';
  }
}
