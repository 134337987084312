import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';

import { RoutesAddressEnum } from '../../address/shared/enums/routes-address-enum';
import { GetReadyFormService } from '../../get-ready/shared/get-ready-form.service';
import { OfferPackage } from '../../offers/models/offer-package-model';
import { OffersModel } from '../../offers/models/offers-model';
import { OffersFormService } from '../../offers/shared/offers-form.service';
import { RoutesPldEnum } from './enums/routes-pld-enum';
import { RoutesDocumentPhotosEnum } from './../../document-photos/shared/enums/routes-document-photos-enum';
import { RoutesSelfieEnum } from './../../selfie/shared/enums/routes-selfie-enum';

@Injectable()
export class PldNavigationService extends CommonNavigationService {
  offersModel: OffersModel;
  selected_package: OfferPackage;

  constructor(
    route: Router,
    private offersFormService: OffersFormService,
    buttonSideBySideHelperService: ButtonSideBySideHelperService,
    private getReadyFormService: GetReadyFormService,
  ) {
    super(route, buttonSideBySideHelperService);
    this.step = RoutesPldEnum.PLD_HOME;
    this.stepAmount = 9;
    this.flows = [
      {
        stage: RoutesPldEnum.PLD_HOME,
        previous: this.previousHome,
        next: RoutesPldEnum.PURPOSE,
      },
      {
        stage: RoutesPldEnum.PURPOSE,
        previous: RoutesPldEnum.PLD_HOME,
        next: RoutesPldEnum.MONEY
      },
      {
        stage: RoutesPldEnum.MONEY,
        previous: RoutesPldEnum.PURPOSE,
        next: RoutesPldEnum.CREDITS
      },
      {
        stage: RoutesPldEnum.CREDITS,
        previous: RoutesPldEnum.MONEY,
        next: RoutesAddressEnum.ADDRESS_HOME,
      },
    ];
  }

  previousHome() {
    if (this.getReadyFormService.getProperty('seller')) {
      return RoutesDocumentPhotosEnum.DOCUMENT_BACK_STAGE;
    }
    return RoutesSelfieEnum.INCLINED_STAGE;

  }

  /*
   * Checks if a package that has conta-corrente (CC) was selected during offers workflow
   * By default, next screen is RoutesAddressEnum.ADDRESS_HOME
   * If package has CC, then RoutesPldEnum.REFERENCES is returned as next screen
   */

  isContaCorrente() {
    let isContaCorrente = false;

    this.offersModel = this.offersFormService.getForm();
    this.selected_package = this.offersModel.packages[this.offersModel.selected_package];

    // checks if package has conta corrente
    this.selected_package.account_offers.forEach(item => {
      if (item.account_type === 'CC') {
        isContaCorrente = true;
      }
    });

    return isContaCorrente;
  }
}
