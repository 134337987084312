import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';

import { SelfieNavigationService } from '../shared/selfie-navigation.service';
import { RoutesSelfieEnum } from '../shared/enums/routes-selfie-enum';
import { Analytics } from '../../shared/analytics';
import { VoxelItSelfieService } from 'src/latam-it-camera-selfie/voxel-it-selfie.service';
import { AbreCuentasRoutesEnum } from 'src/app/shared/enum/abre-cuentas-routes.enum';


@Component({
  selector: 'selfie-frontal-stage',
  templateUrl: './frontal-stage.component.html',
  styleUrls: ['../shared/selfie-styles.scss']
})
export class FrontalStageComponent implements OnInit, OnDestroy {

  pageId = 'selfie-frontal-stage';
  private successRoute = RoutesSelfieEnum.FRONTAL_PHOTO.toString();
  private permissionDeniedRoute = AbreCuentasRoutesEnum.PERMISSION_REQUIRED.toString();
  private errorRoute = AbreCuentasRoutesEnum.CAMERA_ERROR.toString();

  constructor(
    private analyticsLib: Analytics,
    public navigationService: SelfieNavigationService,
    private elementRef: ElementRef,
    private voxelItSelfieService: VoxelItSelfieService
  ) {}

  ngOnInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#353535';
    this.navigationService.setStage(RoutesSelfieEnum.FRONTAL_STAGE);

    this.analyticsLib.buildData('SelfieFrente', this.pageId, '15', false);
    this.analyticsLib.trackState(null, null);
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
  }

  next() {
    this.voxelItSelfieService.getUserMediaPermission(this.successRoute, this.permissionDeniedRoute, this.errorRoute);
  }

}
