import { Component, OnInit } from '@angular/core';
import { ProspectService } from 'src/app/shared/services/domains/prospect/prospect.service';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { SocialNetworksModel } from '../models/social-networks-model';
import { ClientCommonService } from '../shared/client-common.service';
import { ClientFormService } from '../shared/client-form.service';
import { AppService } from './../../app.service';
import { Analytics } from './../../shared/analytics';

@Component({
  selector: 'client-end',
  templateUrl: './client-end.component.html',
  styleUrls: ['./client-end.component.scss']
})
export class ClientEndComponent implements OnInit {
  static TRACKING_CLIENTE = 'TRACKING-CLIENTE-FIN';

  pageId: string;
  name: string;
  listSocialNetworks: SocialNetworksModel[];
  seller: boolean;
  duplicate: boolean;

  constructor(
    private commonService: ClientCommonService,
    private formService: ClientFormService,
    private appService: AppService,
    private analyticsLib: Analytics,
    private prospectService: ProspectService
  ) {
    this.pageId = 'confirmation_end';
  }

  ngOnInit() {
    this.setData();
    this.getNetworks();

    // tslint:disable-next-line: early-exit
    if (!this.duplicate) {
      this.analyticsLib.buildData('Exito', this.pageId, 'Complete', true);
      this.analyticsLib.trackState(null, null);
      this.doPatchProspectTracking();
    }
  }

  setData() {
    this.name = this.formService.getProperty('name');
    this.duplicate = this.formService.getProperty('duplicate');
  }

  doPatchProspectTracking() {
    const clientFlowId = this.prospectService.getClientFlowId();
    // tslint:disable-next-line: early-exit
    if (clientFlowId) {
      const body: any = {
        prospect_id: clientFlowId,
        prospect_type: ClientEndComponent.TRACKING_CLIENTE
      };
      this.commonService.doPatchProspectTracking(body);
    }
  }

  getNetworks() {
    const adminLinkURLs = this.commonService.getAdminList(AdminListEnum.LIST_URLS);

    this.listSocialNetworks = [
      new SocialNetworksModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'INSTITUTIONAL_SITE').description,
        'assets/images/icon_web.png',
        'web_img',
        'end__size-img'
      ),
      new SocialNetworksModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'SOCIAL_FACEBOOK').description,
        'assets/images/icon_facebook.png',
        'facebook_img',
        'end__size-img end__margin-img'
      ),
      new SocialNetworksModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'SOCIAL_INSTAGRAM').description,
        'assets/images/icon_instagram.png',
        'instragram_img',
        'end__size-img end__margin-img'
      ),
      new SocialNetworksModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'SOCIAL_LINKEDIN').description,
        'assets/images/icon_linkedin.png',
        'linkedin_img',
        'end__size-img end__margin-img'
      )
    ];
  }
}
