import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarService } from 'src/app/progressBar.service';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';

@Component({
  selector: 'header-oca',
  styleUrls: ['./header-oca.component.scss'],
  templateUrl: './header-oca.component.html',
})
export class HeaderOCAComponent implements OnInit {
  @Input() atual;

  progressBar: {
    amountLabel: number;
    currentLabel: number;
    amountBar: number;
    currentBar: number;
    labelName: string;
  };

  constructor(private commonNavigation: CommonNavigationService, private progressBarService: ProgressBarService) {}

  ngOnInit(): void {
    this.progressBar = this.progressBarService.getProgressBarOptions();
    this.progressBar.currentBar = this.atual;
  }

  getEtapa() {
    return this.commonNavigation.getStep();
  }

  getTotalEtapas() {
    return this.commonNavigation.getStepAmount();
  }
}
