import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesGetReadyFullEnum } from 'src/app/get-ready-full/shared/enums/routes-get-ready-enum';
import { OffersFullCommonService } from 'src/app/offers-full/shared/offers-common.service';
import { RoutesSelfieEnum } from 'src/app/selfie/shared/enums/routes-selfie-enum';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';
import { RoutesFinalDataEnumFull } from '../../final-data-full/shared/enums/routes-final-data-enum';
import { RoutesPldFullEnum } from '../../pld-full/shared/enums/routes-pld-enum';
import { AddressFullFormService } from './address-form.service';
import { RoutesAddressFullEnum } from './enums/routes-address-enum';


@Injectable()
export class AddressFullNavigationService extends CommonNavigationService {
  constructor(
    route: Router,
    private addressFormService: AddressFullFormService,
    buttonSideBySideHelperService: ButtonSideBySideHelperService,
    private offerCommonService: OffersFullCommonService,
  ) {
    super(route, buttonSideBySideHelperService);

    this.step = RoutesAddressFullEnum.ADDRESS_HOME;
    this.stepAmount = 5;
    this.flows = [
      {
        stage: RoutesAddressFullEnum.ADDRESS_HOME,
        previous: RoutesGetReadyFullEnum.OCCUPATION,
        next: this.resolveNextScreenPersonalAddress
      },
      {
        stage: RoutesAddressFullEnum.ADDRESS_PROOF,
        previous: RoutesAddressFullEnum.ADDRESS_HOME,
        next: RoutesPldFullEnum.PURPOSE
      },
      {
        stage: RoutesAddressFullEnum.DELIVERY_ADDRESS,
        previous: this.resolvePreviousScreenDeliveryAddress,
        next: RoutesFinalDataEnumFull.CONFIRMATION
      },
      {
        stage: RoutesAddressFullEnum.ADDRESS_PROOF_FACEBOOK,
        previous: RoutesAddressFullEnum.ADDRESS_PROOF,
        next: RoutesAddressFullEnum.DELIVERY_ADDRESS
      }
    ];
  }

  resolveNextScreenPersonalAddress() {
    if (this.personalAddressNotChanged()) {
      return RoutesPldFullEnum.PURPOSE;
    }
    return RoutesAddressFullEnum.ADDRESS_PROOF;

  }

  resolvePreviousScreenDeliveryAddress() {
    this.offerCommonService.restoreOfferResponse();
    return RoutesSelfieEnum.INCLINED_STAGE;
  }


  personalAddressNotChanged() {
    return (
      this.addressFormService.personalAddressProspect &&
      this.addressFormService.getForm().personal_address.street.toUpperCase() ===
        this.addressFormService.personalAddressProspect.street.toUpperCase() &&
      this.addressFormService.getForm().personal_address.state.toUpperCase() ===
        this.addressFormService.personalAddressProspect.state.toUpperCase()
    );
  }
}
