import { Component, OnInit } from '@angular/core';
import { ProgressBarService } from 'src/app/progressBar.service';
import { ProspectService } from 'src/app/shared/services/domains/prospect/prospect.service';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ClientCommonService } from '../shared/client-common.service';
import { ClientFormService } from '../shared/client-form.service';
import { ClientNavigationService } from '../shared/client-navigation.service';
import { ClientCurrencyValueModel } from '../shared/models/client-currency-value';
import { Analytics } from './../../shared/analytics';

@Component({
  selector: 'client-credits',
  templateUrl: './client-credits.component.html',
  styleUrls: ['../shared/client-pld-styles.scss']
})
export class ClientCreditsComponent implements OnInit {
  static TRACKING_CLIENTE = 'TRACKING-CLIENTE-PANTALLA-5';

  pageId = 'pld-credits';
  creditAmounts: any[] = [];
  isBtnDisabled = false;
  hintObject: any;

  constructor(
    private pldFormService: ClientFormService,
    private pldCommonService: ClientCommonService,
    public navigationService: ClientNavigationService,
    public prospectService: ProspectService,
    public commonService: ClientCommonService,
    private analyticsLib: Analytics,
    private progressBarService: ProgressBarService
  ) {
    this.hintObject = {};
  }

  ngOnInit() {
    this.setProgressBarClient();
    this.creditAmounts = this.pldCommonService.getAdminList(AdminListEnum.AMOUNT_QUANTITY_PER_MONTH);

    this.resolveHint();
    this.refreshButton();

    this.pldFormService.setPreviousStage('ClientCredits');
    this.analyticsLib.buildData('CuantoDineroRecibisPLD', this.pageId, '05', true);
    this.analyticsLib.trackState(null, null);
    this.commonService.scrollToTop(document);
    this.doPatchProspectTracking();

    // tslint:disable-next-line: early-exit
    if (this.pldFormService.getProperty('amount_quantity_per_month')) {
      this.creditAmounts.forEach(element => {
        const amount = this.pldFormService.getProperty('amount_quantity_per_month').amount;

        element.checked = !!(amount === element.code);
      });
    }
  }

  resolveHint() {
    this.hintObject.message = `Es la suma de acreditaciones/depósitos que esperás recibir generalmente en un mes en tu cuenta.
    Este valor debe incluir depósitos, transferencias, giros, etc, que realices vos, o terceros.`;
    this.hintObject.value = `offer`;
  }

  refreshButton() {
    this.isBtnDisabled =
      !this.pldFormService.getProperty('amount_quantity_per_month') ||
      !this.pldFormService.getProperty('amount_quantity_per_month').amount;
  }

  changeCreditAmount(creditAmount: any) {
    const balance = new ClientCurrencyValueModel();
    balance.amount = creditAmount.code;
    this.pldFormService.setProperty('amount_quantity_per_month', balance);
    this.refreshButton();
  }

  nextButtonPressed() {
    this.navigationService.sendToNext();
  }

  doPatchProspectTracking() {
    const clientFlowId = this.prospectService.getClientFlowId();
    // tslint:disable-next-line: early-exit
    if (clientFlowId) {
      const body: any = {
        prospect_id: clientFlowId,
        prospect_type: ClientCreditsComponent.TRACKING_CLIENTE
      };

      this.commonService.doPatchProspectTracking(body);
    }
  }

  setProgressBarClient() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 3,
      currentLabel: 2,
      amountBar: 3,
      currentBar: 3,
      labelName: 'Uso de la cuenta',
      nextLabel: 'Siguiente 3/3: Envío y confirmación'
    });
  }
}
