export class AffinityGroupFull {

  id: number;
  code: string;
  description: string;

  constructor() {
    this.id = Number('0');
    this.code = '';
    this.description = '';
  }
}
