<div [id]="pageId"></div>

<main>

  <div class="end__container-information" *ngIf="!duplicate">
    <div class="end__head-image">
      <img  src="assets/images/ilustracion-tarjeta.svg" alt="message_img" class="end__message-container">
    </div>

    <h1 class="end__title">
      {{name}}<span *ngIf="name">, </span>tu solicitud ya está en proceso : )
    </h1>
    <h2 class="end__sub-title" style="padding-bottom:0px">
      Enviaremos a tu e-mail todo lo que necesitás saber para usarlas.
    </h2>
    <h2 class="end__sub-title">
      Recordá que para acceder a información de tus nuevas cuentas deberás salir e iniciar sesión nuevamente. 
      Solo tendrás que hacer esto una vez.
    </h2>
  </div>

  <div class="end__container-information" *ngIf="duplicate">
    <div class="end__head-image">
      <img  src="assets/images/ilustracion-correo.svg" alt="message_img" class="end__message-container">
    </div>

    <h1 class="end__title">
      {{name}}<span *ngIf="name">, </span>ya tenés una solicitud ingresada.
    </h1>
    <h2 class="end__sub-title" style="padding-bottom:0px">
      Recibirás en tu e-mail una respuesta en las próximas 24 hs hábiles.
    </h2>
    <h2 class="end__sub-title">
      Recordá que para acceder a información de tus nuevas cuentas deberás salir e iniciar sesión nuevamente. 
    </h2>
  </div>

</main>

<div class="end__container-information" *ngIf="!seller">
  <div class="end__text-social-networks">
    <span>Conocé más de OCA</span>
  </div>
  <div class="end__social-networks" *ngFor="let socialNetworks of listSocialNetworks">
    <a [href]="socialNetworks.url">
      <img [src]="socialNetworks.source" [alt]="socialNetworks.alt" class="{{socialNetworks.style}}">
    </a>
  </div>
</div>
<facebook-pixel></facebook-pixel>