import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation-service/validation.service';

import { Analytics } from '../../shared/analytics';
import { GetReadyModelFull } from '../models/get-ready-model';
import { DocumentTypeFullEnum } from '../shared/enums/document-type-enum';
import { RoutesGetReadyFullEnum } from '../shared/enums/routes-get-ready-enum';
import { GetReadyCommonFullService } from '../shared/get-ready-common.service';
import { GetReadyFormFullService } from '../shared/get-ready-form.service';
import { GetReadyNavigationFullService } from '../shared/get-ready-navigation.service';

@Component({
  selector: 'preparate-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.scss']
})
export class DocumentTypeFullComponent implements OnInit {
  pageId: string;
  isBtnDisabled: boolean;
  hasChip: boolean;
  notHasChip: boolean;
  getReadyForm: GetReadyModelFull;
  documentNumber: string;
  documentInvalid: string;
  documentErrorText: string;
  showDocumentNumber: boolean;
  showNotChip: boolean;
  showChip: boolean;

  formDocumentNumber = new FormGroup({
    docNumber: new FormControl()
  });

  constructor(
    public navigationService: GetReadyNavigationFullService,
    private getReadyFormService: GetReadyFormFullService,
    public validationService: ValidationService,
    private analyticsLib: Analytics,
    private commonService: GetReadyCommonFullService
  ) {}

  ngOnInit() {
    this.pageId = 'document-type';

    this.getReadyForm = this.getReadyFormService.getFormGetReady();
    this.documentNumber = this.getReadyForm.identity_documents[0].number || '';
    this.documentInvalid = '';
    this.documentErrorText = `Número inválido. Intentá nuevamente, por favor.`;

    this.checkType(this.getReadyForm.identity_documents[0].type);
    this.refreshButton();
    this.navigationService.setStage(RoutesGetReadyFullEnum.DOCUMENT_TYPE);
    this.analyticsLib.buildData('TipoCedula', this.pageId, '2', false);
    this.analyticsLib.trackState(null, null);

    this.commonService.scrollToTop(document);
  }

  refreshButton() {
    this.isBtnDisabled =
      !this.getReadyForm.identity_documents[0].type ||
      (this.documentNumber.length < 8 || !this.validationService.DocumentNumberValidation(this.documentNumber)) ||
      this.showDocumentNumber !== true;
    this.getReadyForm.identity_documents[0].number = this.documentNumber;
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.nextActionButton();
    }
  }

  changeNumber(documentNumber: string) {
    this.documentInvalid = '';
    this.documentNumber = documentNumber;
    this.refreshButton();
  }

  validateDocument() {
    // tslint:disable-next-line: early-exit
    if (this.documentNumber.length >= 8) {
      if (this.validationService.DocumentNumberValidation(this.documentNumber)) {
        this.documentInvalid = '';
      } else {
        this.documentInvalid = this.documentErrorText;
        this.formDocumentNumber.controls['docNumber'].setErrors({ incorrect: true });
      }
    }
  }

  changeType(value) {
    this.hasChip = value.id === 'chip';
    this.notHasChip = !this.hasChip;
    this.getReadyForm.identity_documents[0].type = this.setType();
    this.showDocumentNumber = true;
    if (value.id === 'chip') {
      this.showNotChip = false;
    } else {
      this.showChip = false;
    }
    this.refreshButton();
    this.analyticsDocumentType();
  }

  analyticsDocumentType() {
    this.analyticsLib.trackAction({
      customCategory: 'Body | Radio | Center',
      customAction: 'Click',
      customLabel: 'TipoCedula',
      customValue: this.hasChip ? 'CIChip' : 'CITardicional'
    });
  }

  checkPreviousStage() {
    console.log(this.getReadyFormService.getPreviousStage());

    if (this.getReadyFormService.getPreviousStage() === 'ContactInfo') {
      this.showNotChip = true;
      this.showChip = true;
      this.showDocumentNumber = false;
    } else if (this.hasChip) {
      this.showNotChip = false;
      this.showChip = true;
      this.showDocumentNumber = true;
    } else {
      this.showNotChip = true;
      this.showChip = false;
      this.showDocumentNumber = true;
    }
  }

  nextActionButton() {
    this.navigationService.sendToNext();
  }

  doPrevious() {
    // tslint:disable-next-line: early-exit
    if (this.showDocumentNumber) {
      this.showNotChip = true;
      this.showChip = true;
      this.showDocumentNumber = false;
      this.refreshButton();
    } else {
      this.navigationService.sendToPrevious();
    }
  }

  private setType(): string {
    return this.hasChip ? DocumentTypeFullEnum.HASCHIP.toString() : DocumentTypeFullEnum.NOTHASCHIP.toString();
  }

  private checkType(type: string) {
    if (type) {
      type === DocumentTypeFullEnum.HASCHIP.toString() ? (this.hasChip = true) : (this.notHasChip = !this.hasChip);
    }
    this.checkPreviousStage();
  }
}
