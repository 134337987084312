<!-- <div class="landscape">
  <div class="content">
    <img src="assets/images/group.svg" alt="" class="landscape-img">

    <h1 class="landscape-title">gira tu dispositivo</h1>

    <p class="landscape-text">para seguir con tu solicitud</p>
  </div>
</div> -->

<voxel-it-selfie
  *ngIf="choice === 'P'"
  id="front-photo-selfie"
  [routeToNavigate]="previousRoute"
  [repeatPictureLabel]="'Tomar otra'"
  [compressedResolutionHeight]="compressedResolutionHeight"
  [maxImageLenght]="maxImageLenght"
  [errorRoute]="errorRoute"
  [noPermissionRoute]="noPermissionRoute"
  headerTitle="Frente de la cédula"
  headerSubTitle="Tomá la foto de forma legible."
  faceCam="false"
  [events]="eventsSubject.asObservable()"
  (sendImage)="sendImageEvent($event)"
  [synchronousSendImage]="false"
  analyticsPage = ""
  transactionStep = "">
</voxel-it-selfie>

<div class="content-with-filled-button" *ngIf="choice !== 'P'">
  <header-oca [atual]='2'></header-oca>
  <main>
    <div>
      <h1 class="cedula-photo-upload-title">Adjuntá la imagen de
        <strong>frente de cédula</strong>
      </h1>

      <div class="upload-component">
        <file-upload 
          [fileParameters]="uploadParams" 
          [isFileSelected]="isFileSelected" 
          title="Frente de cédula" 
          (fileSelectEvent)='fileSelected($event)'
          [accepted_extensions]="'image/*'">
        </file-upload>

        <div *ngIf="!isFileSelected && isFacebookAndroid" class="facebook-photo">
          <label for="facebookPhotoId">
            <img src="assets/images/icono-tomar-foto.svg" />
          </label>
          <button class="facebook-upload-button" id="facebookPhotoId" (click)="goToFacebookUpload()"></button>
        </div>
      </div>
    </div>
  </main>
</div>

<footer *ngIf="choice !== 'P'">
  <button-sidebyside
    [isBtnDisabled]="isBtnDisabled"
    nextHook="true"
    (hookNextAction)="continuarButton()"
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>

<facebook-pixel></facebook-pixel>
