import { EventEmitter, Injectable } from '@angular/core';

import { OffersModel } from '../offers/models/offers-model';
import { RouterSignature } from '../router.signature';
import { SolicitationResponseModel } from '../shared/models/solicitation-response';
import { AdminListEnum } from '../shared/services/admin-lists/enum/admin-list.enum';
import { ModuleLists } from './services/admin-lists/const/module-lists.constant';
import { ListConversionService } from './services/admin-lists/list-conversion-service/list-conversion.service';
import { LoginService } from './services/login.service';

@Injectable()
export class ServiceCommon {
  options: RouterSignature;
  emissorEventos: EventEmitter<string> = new EventEmitter();
  http_response_data: any;

  solicitationResponse: SolicitationResponseModel;
  offersResponse: OffersModel;

  adminlists = {};
  moduleLists: AdminListEnum[] = [];

  constructor(public listConversionService: ListConversionService, private loginService: LoginService) {
    this.solicitationResponse = new SolicitationResponseModel();
    this.offersResponse = new OffersModel();
    this.setModuleLists(ModuleLists.common);
  }

  setModuleLists(listNames: AdminListEnum[]) {
    this.moduleLists.push(...listNames);
    this.setAdminLists();
  }

  setAdminLists() {
    this.moduleLists.forEach(list => {
      this.adminlists[list] = this.listConversionService.getConvertedList(list);
    });
  }

  getAdminList(listName: AdminListEnum): any[] {
    return this.adminlists[listName];
  }

  emitirEvento(mensagem: string) {
    console.log('Mensagem Emitida: ' + mensagem);
    this.emissorEventos.emit(mensagem);
  }

  handleSystemErrors(body: any) {
    console.log('[handleDocumentError] StatusCod', body.statusCode);
  }

  scrollToTop(document: Document) {
    const scrollableElement = document.body || document.documentElement || document.scrollingElement;

    scrollableElement.scrollTop = 0;
  }

  validateUserInput(value: string): boolean {
    const onlyAlphanumerics = /^[A-Za-zÀ-ÿ ]*$/;

    return onlyAlphanumerics.test(value);
  }

  isFacebookBrowserOnAndroid() {
    return (
      this.loginService.deviceInformation.browser_name.toUpperCase() === 'FACEBOOK' &&
      this.loginService.deviceInformation.operation_system.toUpperCase() === 'ANDROID'
    );
  }

  isFacebookBrowserOniOS() {
    return (
      this.loginService.deviceInformation.browser_name.toUpperCase() === 'FACEBOOK' &&
      this.loginService.deviceInformation.operation_system.toUpperCase() === 'IOS'
    );
  }

  getHyperlinkBehavior(): string {
    let behavior: string;

    if (this.isFacebookBrowserOnAndroid()) {
      behavior = '_self';
    } else {
      behavior = '_blank';
    }

    return behavior;
  }

  compareItemsAlphabetically(item1: any, item2: any): number {
    return item1['label'].localeCompare(item2['label']);
  }
}
