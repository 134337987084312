import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OffersRountingModule } from './offers.routes';
import { SharedModule } from '../shared/shared.module';
import { ProgressBarService } from '../progressBar.service';
import { OffersCommonService } from './shared/offers-common.service';
import { OffersFormService } from './shared/offers-form.service';
import { OffersNavigationService } from './shared/offers-navigation.service';
import { BenefitsComissionsComponent } from './benefits-comissions/benefits-comissions.component';
import { ClosingDateComponent } from './closing-date/closing-date.component';
import { PlansComponent } from './plans/plans.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { AbreCuentasPipe } from '../shared/pipes/pipe.modules';

@NgModule({
  imports: [
    OffersRountingModule,
    SharedModule,
    CommonModule,
    AbreCuentasPipe
  ],
  exports: [
  ],
  providers: [
    ProgressBarService,
    OffersCommonService,
    OffersFormService,
    OffersNavigationService,
  ],
  declarations: [
    PlansComponent,
    BenefitsComissionsComponent,
    CreditCardComponent,
    ClosingDateComponent,
  ]
})

export class OffersModule {}
