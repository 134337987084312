<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='setAtual()'></header-oca>

  <main class="delivery-address__main">
    <h1 class="delivery-address__header-container">
      <span *ngIf="cardSelected==false">¿Dónde te gustaría recibir tu tarjeta OCA Blue?</span>
      <span *ngIf="cardSelected">¿Dónde te gustaría recibir tus tarjetas?</span>
    </h1>
    <div>
      <span class="delivery-address__subtitle" *ngIf="cardSelected==false">
        Por seguridad, solo te entregaremos la tarjeta a vos.
      </span>
      <span class="delivery-address__subtitle" *ngIf="cardSelected">
        Por seguridad, solo te entregaremos las tarjetas a vos.
      </span>
    </div>

    <div class="delivery-address__title">
      <div *ngIf="deliveryChoiceModel.length > 0"
          [ngClass]="{'delivery-address__button--selected': deliveryChoiceModel[0].selected}"
          class="delivery-address__button"
          [id]="deliveryChoiceModel[0].id"
          (click)='changeDeliveryChoice(deliveryChoiceModel[0])'>
          <i class="icon delivery-address__icons {{deliveryChoiceModel[0].icon}}"></i>
          <div id="{{deliveryChoiceModel[0].id}}-description">{{deliveryChoiceModel[0].description}}</div>
      </div>
      <div *ngIf="deliveryChoiceModel.length > 1"
            [ngClass]="{'delivery-address__button--selected': deliveryChoiceModel[1].selected}"
            class="delivery-address__button"
            [id]="deliveryChoiceModel[1].id"
            (click)='changeDeliveryChoice(deliveryChoiceModel[1])'>
            <i class="icon delivery-address__icons {{deliveryChoiceModel[1].icon}}"></i>
            <div id="{{deliveryChoiceModel[1].id}}-description">{{deliveryChoiceModel[1].description}}</div>
      </div>
      <div *ngIf="deliveryChoiceModel.length > 2"
            [ngClass]="{'delivery-address__button--selected': deliveryChoiceModel[2].selected}"
            class="delivery-address__button"
            [id]="deliveryChoiceModel[2].id"
            (click)='changeDeliveryChoice(deliveryChoiceModel[2])'>
            <i class="icon delivery-address__icons {{deliveryChoiceModel[2].icon}}"></i>
            <div id="{{deliveryChoiceModel[2].id}}-description">{{deliveryChoiceModel[2].description}}</div>
      </div>
      <div *ngIf="deliveryChoiceModel.length > 3"
          [ngClass]="{'delivery-address__button--selected': deliveryChoiceModel[3].selected}"
          class="delivery-address__button"
          [id]="deliveryChoiceModel[3].id"
          (click)='changeDeliveryChoice(deliveryChoiceModel[3])'>
          <i class="icon delivery-address__icons {{deliveryChoiceModel[3].icon}}"></i>
          <div id="{{deliveryChoiceModel[3].id}}-description">{{deliveryChoiceModel[3].description}}</div>
      </div>
      <div *ngIf="deliveryChoiceModel.length > 4"
          [ngClass]="{'delivery-address__button--selected': deliveryChoiceModel[4].selected}"
          class="delivery-address__button"
          [id]="deliveryChoiceModel[4].id"
          (click)='changeDeliveryChoice(deliveryChoiceModel[4])'>
          <i class="icon delivery-address__icons {{deliveryChoiceModel[4].icon}}"></i>
          <div id="{{deliveryChoiceModel[4].id}}-description">{{deliveryChoiceModel[4].description}}</div>
      </div>
    </div>

    <div *ngIf="optionPersonalAddress" class="delivery-address__container">
      <span class="delivery-address__direction">Dirección seleccionada:</span>
      <div class="delivery-address__private-home__address">
          <span>{{personalAddress.street}}</span><br>
          {{personalAddress.city}}, {{personalAddress.state}} <br>
      </div>
    </div>

    <div *ngIf="optionPickupCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'stateId'"
        [items]="pickupCenterState"
        [label]="'Departamento'"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="pickupCenterStateSelected"
        (selectChange)="changePickupCenterState($event)">
      </voxel-select>
    </div>
    <div *ngIf="optionPickupCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'pickupCenterId'"
        [items]="pickupCenterAvailable"
        [label]="pickupCenterLabel"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="pickupCenterSelected"
        (selectChange)="changePickupCenter($event)">
      </voxel-select>
      <div class="pickupCenter__container" *ngIf="pickUpAvailability">
        <div class="delivery-address__direction">
            Dirección seleccionada:
        </div>
        <div class="pickupCenter__information">
          <div>{{pickUpCenterStreet}}</div>
          <div class="pickupCenter__availability">{{pickUpAvailability}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="optionOtherAddress" class="delivery-address__container">
      <address-form
        [stateSelected]='deliveryStateSelected'
        [citySelected]='deliveryCitySelected'
        [street]='deliveryAddress.street'
        [states]='states'
        [cities]='cities'
        (validationForm)="enableButton($event)">
      </address-form>
    </div>

    <div *ngIf="optionOcaCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'stateId'"
        [items]="ocaCenterState"
        [label]="'Departamento'"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="ocaCenterStateSelected"
        (selectChange)="changeOcaCenterState($event)">
      </voxel-select>
    </div>
    <div *ngIf="optionOcaCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'ocaCenterId'"
        [items]="ocaCenterAvailable"
        [label]="'Sucursales disponibles'"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="ocaCenterSelected"
        (selectChange)="changeOcaCenter($event)">
      </voxel-select>
      <div class="pickupCenter__container" *ngIf="ocaAvailability">
        <div class="delivery-address__direction">
            Dirección seleccionada:
        </div>
        <div class="pickupCenter__information">
          <div>{{ocaCenterStreet}}</div>
          <div class="pickupCenter__availability">{{ocaAvailability}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="optionTataCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'stateId'"
        [items]="tataCenterState"
        [label]="'Departamento'"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="tataCenterStateSelected"
        (selectChange)="changeTataCenterState($event)">
      </voxel-select>
    </div>
    <div *ngIf="optionTataCenter" class="delivery-address__container">
      <voxel-select
        [selectId]="'tataCenterId'"
        [items]="tataCenterAvailable"
        [label]="'Sucursales disponibles'"
        placeholder="Seleccioná una opción"
        [itemSelectedValue]="tataCenterSelected"
        (selectChange)="changeTataCenter($event)">
      </voxel-select>
      <div class="pickupCenter__container" *ngIf="tataAvailability">
        <div class="delivery-address__direction">
            Dirección seleccionada:
        </div>
        <div class="pickupCenter__information">
          <div>{{tataCenterStreet}}</div>
          <div class="pickupCenter__availability">{{tataAvailability}}</div>
        </div>
      </div>
    </div>

  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    (hookNextAction)="continuarButton()"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="addressNavigationService">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>
