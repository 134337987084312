import { Component, OnInit } from '@angular/core';
import { GetReadyNavigationFullService } from '../../shared/get-ready-navigation.service';

import { GetReadyCommonFullService } from '../../shared/get-ready-common.service';
import { AdminListEnum } from '../../../shared/services/admin-lists/enum/admin-list.enum';

@Component({
  selector: 'error-page-create',
  templateUrl: './error-page-create.component.html',
  styleUrls: ['../shared/errors-style.scss', './error-page-create.component.scss']
})
export class ErrorPageCreateFullComponent implements OnInit {

  pageId: string;
  phone: number;
  urlOCA: string;

  constructor(
    public navigationService: GetReadyNavigationFullService,
    private getReadyCommonService: GetReadyCommonFullService
  ) {}

  ngOnInit() {
    const adminLinkURLs = this.getReadyCommonService.getAdminList(AdminListEnum.LIST_URLS);

    this.pageId = 'error_page_create';
    this.phone = 1730;
    this.urlOCA = adminLinkURLs.find(i => i.code.toUpperCase() === 'INSTITUTIONAL_SITE').description;
  }

}
