<div class="main-container content-with-filled-button">
  <div class="main-container__cover">
    <img class="main-container__image" src="assets/images/img_perm_getusermedia.svg" alt="incompatible">
  </div>
  <div class="main-container__content">
    <h1 class="title title-create">
      Necesitamos acceder a tu cámara
    </h1>

    <h2 class="sub-title sub-title-create">
      Para continuar con tu solicitud, permití el acceso a la cámara ingresando a las configuraciones de tu navegador.
    </h2>
  </div>
</div>

<footer>
  <voxel-button
    id="permission-required-try-again"
    label="reintentar"
    (click)="resolve()">
  </voxel-button>

  <!-- 
  <voxel-mb-button
    id="permission-required-try-again"
    label="reintentar"
    radioButton="true"
    (onClickButton)="resolve()">
  </voxel-mb-button>
  -->
</footer>
