<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='setAtual()'></header-oca>

  <main>

    <div>
      <h1 id="title" class="address-header">Adjuntá tu
        <strong>comprobante de domicilio</strong>
      </h1>

      <voxel-additional-information id='addtionalInformationId' [message]="hintObject.message" idHint="hintid" [reverseHint]="true">
      </voxel-additional-information>

      <div class="comprobante-subtitle">
        Aseguráte que tu nombre y dirección sean legibles
      </div>

      <div class="upload-component">
        <file-upload 
          [fileParameters]="uploadParams" 
          [isFileSelected]="isFileSelected" 
          [title]="'comprobante de domicilio'" 
          (fileSelectEvent)='fileSelected($event)'
          [accepted_extensions]="'image/*'">
        </file-upload>

        <div *ngIf="!isFileSelected && isFacebookAndroid" class="facebook-photo">
          <label [for]="'facebookPhotoId'">
            <img [src]="'assets/images/icono-tomar-foto.svg'" />
          </label>
          <button class="facebook-upload-button" [id]="'facebookPhotoId'" (click)="goToFacebookUpload()"></button>
        </div>
      </div>
    </div>

  </main>
</div>

<footer>
  <button-sidebyside [isBtnDisabled]="isBtnDisabled" nextHook="true" (hookNextAction)="continuarButton()" [navigationService]="addressNavigationService">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>