import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { MessageState } from './latam-error-message-state';
import { LatamErrorMessageService } from './latam-error-message.service';

@Component({
  selector: 'latam-error-message',
  templateUrl: './latam-error-message.component.html',
  styleUrls: ['./latam-error-message.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('500ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [animate('500ms ease-in', style({ transform: 'translateY(-100%)' }))]),
    ]),
  ],
})
export class LatamErrorMessageComponent implements OnInit, OnDestroy {
  show = false;
  errorMsg: string;
  newMessageId: number;
  timeoutInterval = 4500;

  private subscription: Subscription;

  constructor(private latamErrorMessageService: LatamErrorMessageService) {}

  ngOnInit() {
    this.errorMsg = 'Ocurrió un error inesperado. Intentá de nuevo en unos minutos.';
    this.subscription = this.latamErrorMessageService.messageState.subscribe((state: MessageState) => {
      if (state.show) {
        this.handleMessageExhibition();
        this.handleMessageLifetime();
      } else {
        this.show = false;
      }
    });
  }

  handleMessageExhibition() {
    this.newMessageId = Math.round(Math.random() * 1000000000);

    if (this.show) {
      this.timeoutInterval = 5000;
      this.show = false;

      setTimeout(() => {
        this.show = true;
      }, 500);
    } else {
      this.timeoutInterval = 4500;
      this.show = true;
    }
  }

  handleMessageLifetime() {
    const currentMessageId = this.newMessageId;

    setTimeout(() => {
      if (currentMessageId === this.newMessageId) {
        this.show = false;
      }
    }, this.timeoutInterval);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
