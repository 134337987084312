import { Component, OnInit } from '@angular/core';

import { ProgressBarService } from '../../progressBar.service';
import { ProspectService } from '../../shared/services/domains/prospect/prospect.service';
import { RoutesPldEnum } from '../shared/enums/routes-pld-enum';
import { PldFormService } from '../shared/pld-form.service';
import { PldNavigationService } from '../shared/pld-navigation.service';

@Component({
  selector: 'pld-home',
  templateUrl: './pld-home.component.html',
  styleUrls: ['../shared/pld-styles.scss', 'pld-home.component.scss']
})
export class PldHomeComponent implements OnInit {
  pageId = 'pld-home';
  name: string;

  constructor(
    private pldFormService: PldFormService,
    public pldNavigation: PldNavigationService,
    private progressBarService: ProgressBarService,
    private prospectService: ProspectService
  ) {}

  ngOnInit() {
    this.pldNavigation.setStage(RoutesPldEnum.PLD_HOME);
    this.setData();
    this.setProgressBar();
    this.pldFormService.setProperty('customer_type', 'PF');
  }

  setData() {
    this.name = this.prospectService.getFirstName();
  }

  /*
   * Gets personal address before changing to next screen
   */
  next() {
    this.pldNavigation.sendToNext();
  }

  setAmountProgressBar(): number {
    return 15;
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 4,
      amountBar: this.setAmountProgressBar(),
      currentBar: 1,
      labelName: 'datos finales'
    });
  }
}
