<div class="landscape">
  <img src="assets/images/group.svg" alt="" class="landscape-img">

  <h1 class="landscape-title">gira tu dispositivo</h1>

  <p class="landscape-text">para seguir con tu solicitud</p>
</div>

<div class="black-bg content-with-filled-button">
  <div id='{{pageId}}'></div>

    <header-oca [atual]='2'></header-oca>

    <main class="black-bg">

      <img src="assets/images/img_selfie_frente.svg" alt="" class="selfie-img">

      <h1 id="selfie-front-title" class="selfie-title">
        Selfie de frente
      </h1>

      <p class="selfie-text">
        Tomá la foto
        sin lentes ni gorro y en la misma posición de la imagen.
      </p>

    </main>
  </div>

  <footer>
    <button-sidebyside
      nextHook="true"
      label2="Activar cámara"
      [navigationService]="navigationService"
      (hookNextAction)="next()">
    </button-sidebyside>
  </footer>
  <facebook-pixel></facebook-pixel>
