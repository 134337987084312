/* istanbul ignore file */
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

@Injectable()
export class VoxelItSelfieService {
  hasPermission = false;

  constructor(private zone: NgZone, private router: Router, private latamItLoaderService: LatamItLoaderService) {}

  getUserMediaPermission(successRoute: any, permissionErrorRoute: any, errorRoute: any) {
    if (this.getUserMediaSuported()) {
      const constraints = {
        audio: false,
        video: {
          facingMode: 'user'
        }
      };

      this.latamItLoaderService.show();
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.hasPermission = true;
          this.latamItLoaderService.hide();
          // tslint:disable-next-line: early-exit
          if (!!successRoute) {
            this.zone.run(() => {
              this.router.navigate([successRoute]);
            });
          }
        })
        .catch(err => {
          this.latamItLoaderService.hide();
          if (err.name === 'NotAllowedError') {
            this.hasPermission = false;
            if (!!permissionErrorRoute) {
              this.zone.run(() => {
                this.router.navigate([permissionErrorRoute]);
              });
            }
          } else if (!!errorRoute) {
            this.zone.run(() => {
              this.router.navigate([errorRoute]);
            });
          }
        });
    } else {
      this.hasPermission = false;
      if (!!successRoute) {
        this.zone.run(() => {
          this.router.navigate([successRoute]);
        });
      }
    }
  }

  getUserMediaSuported() {
    return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
  }
}
