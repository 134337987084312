import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesAddressFullEnum } from 'src/app/address-full/shared/enums/routes-address-enum';
import { RoutesOffersFullEnum } from 'src/app/offers-full/shared/enums/routes-offers-enum';
import { OffersFullFormService } from 'src/app/offers-full/shared/offers-form.service';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';
import { RoutesPldFullEnum } from '../../pld-full/shared/enums/routes-pld-enum';
import { RoutesSelfieEnum } from './enums/routes-selfie-enum';



@Injectable()
export class SelfieNavigationService extends CommonNavigationService {

  constructor(
    route: Router,
    buttonSideBySideHelperService: ButtonSideBySideHelperService,
    private formService: OffersFullFormService,
  ) {
    super(route, buttonSideBySideHelperService);

    this.step = RoutesSelfieEnum.GET_READY;
    this.stepAmount = 1;
    this.flows = [
      {
        stage: RoutesSelfieEnum.GET_READY,
        previous: this.previousGetReady,
        next: RoutesSelfieEnum.FRONTAL_STAGE
      },
      {
        stage: RoutesSelfieEnum.FRONTAL_STAGE,
        previous: RoutesSelfieEnum.GET_READY,
        next: RoutesSelfieEnum.FRONTAL_PHOTO
      },
      {
        stage: RoutesSelfieEnum.FRONTAL_PHOTO,
        previous: RoutesSelfieEnum.FRONTAL_STAGE,
        next: RoutesSelfieEnum.INCLINED_STAGE
      },
      {
        stage: RoutesSelfieEnum.INCLINED_STAGE,
        previous: null,
        next: RoutesSelfieEnum.INCLINED_PHOTO
      },
      {
        stage: RoutesSelfieEnum.INCLINED_PHOTO,
        previous: RoutesSelfieEnum.INCLINED_STAGE,
        next: this.navigateNextInclinedPhoto
      },
    ];
  }

  previousGetReady() {
    return RoutesPldFullEnum.CREDITS;
  }


  navigateNextInclinedPhoto(): RoutesOffersFullEnum | RoutesAddressFullEnum {
    if (this.formService.getSelectedPackage().card_offers && this.formService.getSelectedPackage().card_offers.length > 0) {
      return RoutesOffersFullEnum.CREDITCARD;
    }
    return RoutesAddressFullEnum.DELIVERY_ADDRESS;
  }

}
