export class IdentityDocumentFullModel {
  number: string;
  series?: string;
  folder?: number;
  security_code?: string;
  type: string;

  constructor() {
    this.number = '';
    this.type = '';
  }
}
