export enum RoutesDocumentPhotosEnum {
  GET_READY = 'document-photos-get-ready' as any,
  DOCUMENT_FRONT_STAGE = 'document-front-stage' as any,
  DOCUMENT_FRONT_PHOTO = 'document-front-photo' as any,
  DOCUMENT_BACK_STAGE = 'document-back-stage' as any,
  DOCUMENT_BACK_PHOTO = 'document-back-photo' as any,
  DOCUMENT_PHOTOS_ERROR = 'document-photos-error' as any
}

export enum DocumentPhotoApproach {
  FILE = 'F',
  PHOTO = 'P'
}
