import { AddressFullModel } from './address-model';

export class ResidenceModel {

  personal_address: AddressFullModel;
  delivery_address: AddressFullModel;
  pickup_center?: string;
  availability?: string;
  delivery_choice_id?: string;

  constructor() {
    this.personal_address = new AddressFullModel();
    this.delivery_address = new AddressFullModel();
  }
}
