import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProgressBarService } from '../progressBar.service';
import { AbreCuentasPipe } from '../shared/pipes/pipe.modules';
import { SharedModule } from '../shared/shared.module';
import { ContactInfoFullComponent } from './contact-info/contact-info.component';
import { DocumentChipFullComponent } from './document-chip/document-chip.component';
import { DocumentNoChipFullComponent } from './document-no-chip/document-no-chip.component';
import { DocumentTypeFullComponent } from './document-type/document-type.component';
import { ErrorPageCreateFullComponent } from './errors/error-page-create/error-page-create.component';
import { ErrorPageDocumentRejectedFullComponent } from './errors/error-page-document-rejected/error-page-document-rejected.component';
import { ErrorPageDuplicateAccountFullComponent } from './errors/error-page-duplicate-account/error-page-duplicate-account.component';
import { ErrorPageSolicitationFullComponent } from './errors/error-page-solicitation/error-page-solicitation.component';
// import { PlatformService } from 'voxel-mb-services/platform.service';
// import { NativeCommunicationService } from 'voxel-mb-core/native-communication/native-communication.service';
import { ErrorsLinkFullService } from './errors/shared/errors-link.service';
import { GetReadyFullRoutingModule } from './get-ready.routes';
import { LoadingFullComponent } from './loading/loading.component';
import { MaritalStatusFullComponent } from './marital-status/marital-status.component';
import { OccupationFullComponent } from './occupation/occupation.component';
import { GetReadyCommonFullService } from './shared/get-ready-common.service';
import { GetReadyFormFullService } from './shared/get-ready-form.service';
import { GetReadyNavigationFullService } from './shared/get-ready-navigation.service';

export function initProgressBar(progressBarService: ProgressBarService) {
  return () => progressBarService.initializeProgressBar({
    amountLabel: 4,
    currentLabel: 1,
    amountBar: 9,
    currentBar: 1,
    labelName: 'inicio'
  });
}

@NgModule({
  imports: [
    GetReadyFullRoutingModule,
    SharedModule,
    CommonModule,
    AbreCuentasPipe,
    FormsModule
  ],
  exports: [],
  providers: [
    ProgressBarService,
        { provide: APP_INITIALIZER, useFactory: initProgressBar, deps: [ProgressBarService], multi: true },
    GetReadyCommonFullService,
    GetReadyFormFullService,
            // NativeCommunicationService,
            // PlatformService,
    ErrorsLinkFullService,
    GetReadyNavigationFullService,
    ProgressBarService,
  ],
  declarations: [
    DocumentTypeFullComponent,
    DocumentNoChipFullComponent,
    DocumentChipFullComponent,
    ContactInfoFullComponent,
    ErrorPageDocumentRejectedFullComponent,
    ErrorPageCreateFullComponent,
    ErrorPageDuplicateAccountFullComponent,
    ErrorPageSolicitationFullComponent,
    OccupationFullComponent,
    LoadingFullComponent,
    MaritalStatusFullComponent,
  ]
})

export class GetReadyFullModule {}
