import { Component, OnInit } from '@angular/core';
import { ProspectService } from 'src/app/shared/services/domains/prospect/prospect.service';

import { GenericModel } from '../../shared/models/generic-model';
import { ClientCommonService } from '../shared/client-common.service';
import { ClientFormService } from '../shared/client-form.service';
import { ClientNavigationService } from '../shared/client-navigation.service';
import { ClientAddressModel } from '../shared/models/client-address-model';
import { Analytics } from './../../shared/analytics';

@Component({
  selector: 'client-personal-address',
  templateUrl: './client-personal-address.component.html',
  styleUrls: ['./client-personal-address.component.scss']
})
export class ClientPersonalAddressComponent implements OnInit {
  static TRACKING_CLIENTE = 'TRACKING-CLIENTE-PANTALLA-6';

  pageId: string;
  isBtnDisabled: boolean;
  hintObject: any;

  existingPersonalAddress: any;
  states: GenericModel[];
  cities: GenericModel[];

  // Vars used on address form component
  stateSelected: GenericModel;
  citySelected: GenericModel;
  street: string;

  constructor(
    public clientNavigationService: ClientNavigationService,
    private commonService: ClientCommonService,
    private clientFormService: ClientFormService,
    public prospectService: ProspectService,
    private analyticsLib: Analytics
  ) {}

  ngOnInit(): void {
    this.pageId = 'address-home';
    this.hintObject = {};
    this.resolveHint();
    this.states = this.commonService.states;
    this.cities = this.commonService.cities;
    this.setData();

    this.analyticsLib.buildData('DomicilioParticular', this.pageId, '06', true);
    this.analyticsLib.trackState(null, null);
    this.doPatchProspectTracking();
  }

  analyticsVolver() {
    this.analyticsLib.trackAction({
      customCategory: `Footer | Button | Bottom`,
      customAction: `Click`,
      customLabel: `Volver`
    });
  }

  doPatchProspectTracking() {
    const clientFlowId = this.prospectService.getClientFlowId();
    // tslint:disable-next-line: early-exit
    if (clientFlowId) {
      const body: any = {
        prospect_id: clientFlowId,
        prospect_type: ClientPersonalAddressComponent.TRACKING_CLIENTE
      };
      this.commonService.doPatchProspectTracking(body);
    }
  }

  setData(): void {
    const model = this.clientFormService.getProperty('personal_address');
    this.createAddressFormModel(model);
  }

  createAddressFormModel(model: ClientAddressModel): void {
    this.stateSelected = new GenericModel();
    this.citySelected = new GenericModel();

    // tslint:disable-next-line: early-exit
    if (model) {
      this.stateSelected.label = model.state;
      this.citySelected.label = model.city;
      this.street = model.street;
    }
  }

  resolveHint(): void {
    // tslint:disable-next-line:max-line-length
    this.hintObject.message = `Si en alguna de las bases que consultamos está registrado tu domicilio, éste aparecerá más abajo. Actualizalo de ser necesario.`;
    this.hintObject.value = 'offer';
  }

  next(): void {
    this.clientNavigationService.sendToNext();
  }

  enableButton(addressModel: ClientAddressModel): void {
    this.isBtnDisabled = !addressModel.is_valid;
    this.clientFormService.setProperty('personal_address', addressModel);
    if (addressModel.is_key_enter) {
      this.next();
    }
  }
}
