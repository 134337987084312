import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConfirmationComponent } from './confirmation/confirmation.component';
import { EndComponent } from './end/end.component';

export const routes: Routes = [
  {
    path: 'confirmacion',
    component: ConfirmationComponent
  },
  {
    path: 'confirmacion-fim',
    component: EndComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class FinalDataRoutingModule {}
