import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NumberHelperService } from 'src/app/shared/helper/number-helper-service';
import { ProspectService } from 'src/app/shared/services/domains/prospect/prospect.service';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

import { ServiceCommon } from '../../shared/service.common';
import { ModuleLists } from '../../shared/services/admin-lists/const/module-lists.constant';
import { ListConversionService } from '../../shared/services/admin-lists/list-conversion-service/list-conversion.service';
import { OffersMethods } from '../../shared/services/domains/offers/offers-enum';
import { OffersService } from '../../shared/services/domains/offers/offers.service';
import { SolicitationMethods } from '../../shared/services/domains/solicitation/solicitation-enum';
import { SolicitationService } from '../../shared/services/domains/solicitation/solicitation.service';
import { LoginService } from '../../shared/services/login.service';
import { OffersFullFormService } from './offers-form.service';
import { OffersFullNavigationService } from './offers-navigation.service';

@Injectable()
export class OffersFullCommonService extends ServiceCommon {
  offerReady = false;
  offerResponseBackUp: any;

  constructor(
    private solicitationService: SolicitationService,
    private prospectService: ProspectService,
    private offersService: OffersService,
    public listConversionService: ListConversionService,
    private latamItLoaderService: LatamItLoaderService,
    private offersNavigationService: OffersFullNavigationService,
    loginService: LoginService,
    private formService: OffersFullFormService,
    private numberhelpService: NumberHelperService
  ) {
    super(listConversionService, loginService);
    this.onInit();
  }

  onInit() {
    this.setModuleLists(ModuleLists.offers);
  }

  doGetOffers(): Observable<any> {
    const bodyGet = {
      offers_id: this.solicitationService.getSolicitationId()
    };
    return this.offersService.offerRequest(bodyGet, OffersMethods.GET_OFFER);
  }

  restoreOfferResponse() {
    this.offersResponse = this.offerResponseBackUp;
  }

  doPatchSolicitationPackageSelected(callback) {
    this.latamItLoaderService.show();
    const bodyPatch = this.makeBody();
    this.solicitationService.solicitationRequest(bodyPatch, SolicitationMethods.PATCH_OFFERS, false, false).subscribe(
      res => {
        if (res.statusCode === 200) {
          this.offerResponseBackUp = this.offersResponse;
          this.offersResponse = res.data;
          if (callback) {
            callback();
          } else {
            this.offersNavigationService.sendToNext();
          }
        }
        this.latamItLoaderService.hide();
      },
      err => {
        this.handleSystemErrors(err);
        this.latamItLoaderService.hide();
      }
    );
  }

  calculateOverdraftAndCreditCard(overdraft: number, creditCard: number): number {
    return overdraft + creditCard;
  }

  makeBody(): any {
    const offersModel = this.formService.getForm();
    const selectedPackage = offersModel.packages[offersModel.selected_package];
    const bodyPatch: any = {
      solicitation_completed: false,
      solicitation_id: this.solicitationService.getSolicitationId(),
      prospect_id: [this.prospectService.getProspectId()],
      selected_package: {
        package_id: selectedPackage.package_id,
        package_title: selectedPackage.package_title,
        prices: selectedPackage.prices,
        account_offers: new Array<any>()
      }
    };

    selectedPackage.account_offers.forEach(item => {
      // tslint:disable-next-line: early-exit
      if (item.account_type === 'CC' || item.account_type === 'CA') {
        bodyPatch.selected_package.account_offers.push({
          general_info: {
            product_type: item.general_info.product_type
          },
          account_type: item.account_type,
          currency: item.currency
        });
      }
    });

    if (offersModel.accepted_credit_card) {
      bodyPatch.selected_package.card_offers = [];
      const cardCurrency = selectedPackage.card_offers[0].limit.currency;
      const cardOfferLimit = selectedPackage.card_offers[0].limit.amount;
      const cardCreditLimit = this.numberhelpService.numberFormat(
        cardOfferLimit,
        selectedPackage.card_offers[0].limit.decimals
      );
      const cardOffers = {
        general_info: {
          product_type: 'TC'
        },
        limit: {
          currency: cardCurrency,
          amount: parseInt(this.numberhelpService.onlyNumbers(cardCreditLimit), 10),
          decimals: 0
        },
        brand: {
          key: '2',
          name: 'OCA'
        },
        card_class: {
          key: '0',
          name: 'Internacional'
        },
        affinity_group: { key: '0', name: '' },
        due_date: parseInt(offersModel.closing_date, 10).toString()
      };
      bodyPatch.selected_package.card_offers.push(cardOffers);
    }
    return bodyPatch;
  }
}
