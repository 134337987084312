import { Component, OnInit } from '@angular/core';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';
import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyModel } from '../models/get-ready-model';
import { FormGroup, FormControl } from '@angular/forms';
import { Analytics } from '../../shared/analytics';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';


@Component({
  selector: 'document-chip',
  templateUrl: './document-chip.component.html',
  styleUrls: ['./document-chip.component.scss']
})
export class DocumentChipComponent implements OnInit {

  pageId = '03_DocumentChip';
  isBtnDisabled = false;
  securityCode = '';
  getReadyForm: GetReadyModel;
  modalShow = false;

  form = new FormGroup({
    securityCode: new FormControl(),
  });

  constructor(
    public navigationService: GetReadyNavigationService,
    private analyticsLib: Analytics,
    private getReadyFormService: GetReadyFormService) {
  }

  ngOnInit() {
    this.getReadyForm = this.getReadyFormService.getFormGetReady();

    if (this.getReadyForm.identity_documents[0].security_code) {
      this.securityCode = this.getReadyForm.identity_documents[0].security_code;
    }

    this.refreshButton();
    this.checkOpenModal();
  }

  refreshButton() {
    this.isBtnDisabled = !(this.securityCode && this.securityCode.length === 6);
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.doNext();
    }
  }

  changeCode() {
    this.getReadyForm.identity_documents[0].security_code = this.form.controls['securityCode'].value;
    this.securityCode = this.form.controls['securityCode'].value.toUpperCase();
    this.refreshButton();
  }

  doNext() {
    this.getReadyForm.identity_documents[0].security_code = this.securityCode;
    this.navigationService.sendToNext();
  }

  checkOpenModal() {
    if (this.navigationService.hasErrors()) {
      this.toggleModal();
    }
  }

  maskAsRead() {
    this.navigationService.cleanErrors();
    this.toggleModal();
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }


  setAtual() {
    return 3;
  }

  newInputChange(input: string) {
    if (input === 'securityCode') {
      this.changeCode();
    }
  }

}
