export class DeliveryChoiceModel {
  id: string;
  icon: string;
  selected: boolean;
  description: string;

  constructor(id: string, icon: string, selected: boolean, description: string) {
    this.id = id;
    this.icon = icon;
    this.selected = selected;
    this.description = description;
  }
}
