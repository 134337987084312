<div class="content-with-filled-button">
  <client-header-oca [atual]='5'></client-header-oca>

  <main>
    <div id="di23">
    <h1 id="titleId" class='title-container'>
      ¿Aproximadamente, cuánto dinero esperás
      recibir en tus cuentas mensualmente?
    </h1>

    <vcl-additional-info
      id='addtionalInformationId'
      [text]="hintObject.message"
      position="down-left"
    ></vcl-additional-info>
</div>
    <div class="radio-button">

      <voxel-radio-button-group>
        <voxel-radio-button
          *ngFor="let creditAmount of creditAmounts"
          id="creditAmount"
          [radioName]="' '"
          [value]="creditAmount.code"
          [checked]="creditAmount.checked"
          (radioButtonChange)='changeCreditAmount(creditAmount)'>
          {{creditAmount.label}}
        </voxel-radio-button>
      </voxel-radio-button-group>

    </div>
  </main>
</div>

<footer>
  <button-sidebyside
    [isBtnDisabled]="isBtnDisabled" 
    nextHook="true"
    (hookNextAction)="nextButtonPressed()"
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>