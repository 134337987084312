import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { OffersCommonService } from '../shared/offers-common.service';
import { OffersModel } from '../models/offers-model';
import { RoutesOffersEnum } from '../shared/enums/routes-offers-enum';
import { OffersFormService } from '../shared/offers-form.service';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { Analytics } from '../../shared/analytics';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';


@Component({
  selector: 'offers-benefits-comissions',
  templateUrl: './benefits-comissions.component.html',
  styleUrls: ['../shared/offers-styles.scss', './benefits-comissions.component.scss']
})
export class BenefitsComissionsComponent implements OnInit, OnDestroy {

  pageId = '02_benefits-comissions';
  offersModel: OffersModel;
  selectedOffer: number;
  externalUrl: string;
  body = document.querySelector('body');
  hyperlinkBehavior: string;
  modalShow = false;

  constructor(
    public offersCommonService: OffersCommonService,
    private offersFormService: OffersFormService,
    private route: Router) {
  }

  ngOnInit() {
    this.setData();
    this.offersCommonService.scrollToTop(document);
    this.hyperlinkBehavior = this.offersCommonService.getHyperlinkBehavior();


  }

  setData() {
    const adminLinkURLs = this.offersCommonService.getAdminList(AdminListEnum.LIST_URLS);

    this.selectedOffer = this.offersFormService.getProperty('selected_package') || 0;
    this.offersModel = this.offersCommonService.offersResponse;
    this.externalUrl = adminLinkURLs.find(i => i.code.toUpperCase() === 'ACCOUNT_TERMS').description;
  }

  previous() {
    this.route.navigate([RoutesOffersEnum.PLANS.toString()]);
  }

  category2icon(category) {
    const iconList = this.offersCommonService.getAdminList(AdminListEnum.ICONS);

    const icon = iconList.find(i => i.code === category);
    if (icon && icon.label) {
      return icon.label;
    }
    return 'icon-itaufonts_chat_enviado';
  }

  groupText(benefit) {
    return '<strong>'
      + benefit.title + '</strong> '
      + benefit.benefit_description;
  }

  ngOnDestroy() {
    this.body.setAttribute('style', 'background-color: white');
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }

}
