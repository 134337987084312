import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddressHomeFullComponent } from '../address-full/address-home/address-home.component';
import { ContactInfoFullComponent } from './contact-info/contact-info.component';
import { DocumentChipFullComponent } from './document-chip/document-chip.component';
import { DocumentNoChipFullComponent } from './document-no-chip/document-no-chip.component';
import { DocumentTypeFullComponent } from './document-type/document-type.component';
import { ErrorPageCreateFullComponent } from './errors/error-page-create/error-page-create.component';
import { ErrorPageDocumentRejectedFullComponent } from './errors/error-page-document-rejected/error-page-document-rejected.component';
import { ErrorPageDuplicateAccountFullComponent } from './errors/error-page-duplicate-account/error-page-duplicate-account.component';
import { ErrorPageSolicitationFullComponent } from './errors/error-page-solicitation/error-page-solicitation.component';
import { LoadingFullComponent } from './loading/loading.component';
import { MaritalStatusFullComponent } from './marital-status/marital-status.component';
import { OccupationFullComponent } from './occupation/occupation.component';


export const routes: Routes = [
  {
    path: 'preparate-tipo-de-cedula-full',
    component: DocumentTypeFullComponent
  },
  {
    path: 'preparate-documento-no-chip-full',
    component: DocumentNoChipFullComponent
  },
  {
    path: 'preparate-document-chip-full',
    component: DocumentChipFullComponent
  },
  {
    path: 'preparate-contactamos-full',
    component: ContactInfoFullComponent
  },
  {
    path: 'preparate-pagina-erro-identidad-full',
    component: ErrorPageDocumentRejectedFullComponent
  },
  {
    path: 'preparate-pagina-erro-crear-full',
    component: ErrorPageCreateFullComponent
  },
  {
    path: 'preparate-pagina-erro-cuenta-full',
    component: ErrorPageDuplicateAccountFullComponent
  },
  {
    path: 'preparate-pagina-erro-solicitud-full',
    component: ErrorPageSolicitationFullComponent
  },
  {
    path: 'preparate-loading-full',
    component: LoadingFullComponent
  },
  {
    path: 'domicilio-particular-full',
    component: AddressHomeFullComponent
  },
  {
    path: 'preparate-actividad-laboral-full',
    component: OccupationFullComponent
  },
  {
    path: 'preparate-estado-civil-full',
    component: MaritalStatusFullComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class GetReadyFullRoutingModule {}
