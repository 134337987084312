import { Component, OnInit } from '@angular/core';
import { ProspectService } from 'src/app/shared/services/domains/prospect/prospect.service';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ClientCommonService } from '../shared/client-common.service';
import { ClientFormService } from '../shared/client-form.service';
import { ClientNavigationService } from '../shared/client-navigation.service';
import { Analytics } from './../../shared/analytics';

@Component({
  selector: 'client-delivery-time',
  templateUrl: './client-delivery-time.component.html',
  styleUrls: ['../shared/client-address-styles.scss']
})
export class ClientDeliveryTimeComponent implements OnInit {
  static TRACKING_CLIENTE = 'TRACKING-CLIENTE-PANTALLA-8';

  pageId: string;
  isBtnDisabled: boolean;

  deliveryTimes: any[];

  constructor(
    private commonService: ClientCommonService,
    public prospectService: ProspectService,
    private formService: ClientFormService,
    public navigationService: ClientNavigationService,
    private analyticsLib: Analytics
  ) {}

  ngOnInit() {
    this.pageId = 'delivery_time';
    this.deliveryTimes = this.commonService.getAdminList(AdminListEnum.DELIVERY_AVAILABILITY);
    this.isBtnDisabled = !this.formService.getProperty('availability');

    this.analyticsLib.buildData('HorarioRecibirTD', this.pageId, '08', true);
    this.analyticsLib.trackState(null, null);
    this.doPatchProspectTracking();
  }

  analyticsVolver() {
    this.analyticsLib.trackAction({
      customCategory: `Footer | Button | Bottom`,
      customAction: `Click`,
      customLabel: `Volver`
    });
  }

  doPatchProspectTracking() {
    const clientFlowId = this.prospectService.getClientFlowId();
    // tslint:disable-next-line: early-exit
    if (clientFlowId) {
      const body: any = {
        prospect_id: clientFlowId,
        prospect_type: ClientDeliveryTimeComponent.TRACKING_CLIENTE
      };
      this.commonService.doPatchProspectTracking(body);
    }
  }

  changeDeliveryTime(event) {
    this.formService.setProperty('availability', event.value);
    this.isBtnDisabled = false;
  }

  continuarButton() {
    this.navigationService.sendToNext();
  }
}
