import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AppService } from 'src/app/app.service';

import { SolicitationMethods } from './solicitation-enum';

@Injectable()
export class SolicitationService {
  private id: string;

  constructor(private appService: AppService) {}

  setSolicitationId(solicitationId: string): void {
    this.id = solicitationId;
  }

  getSolicitationId(): string {
    return this.id;
  }

  solicitationRequest(requestBody: any, method: SolicitationMethods, showLoader: boolean, hideLoader: boolean): Observable<any> {
    return this.appService.doPost(requestBody, method, showLoader, hideLoader);
  }
}
