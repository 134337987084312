import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { IImage, ImageCompressService, ResizeOptions } from 'ng2-image-compress';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

@Component({
  selector: 'file-upload',
  styleUrls: ['./file-upload.component.scss'],
  templateUrl: './file-upload.component.html'
})
export class FileUploadComponent implements OnInit {
  @Input() fileParameters: [
    {
      max_file_size: number;
      max_upload_size: number;
      is_image: boolean;
      min_processing_size: number;
      resolution_size: number;
      extensions: string[];
    }
  ];

  @Input() accepted_extensions = 'application/*,image/*';
  @Input() title: string;
  @Input() isFileSelected = false;
  @Output() fileSelectEvent = new EventEmitter();
  @Input() fileInputId: string;
  @ViewChild('fileInput') fileInput;

  listIndex = -1;
  allExtentions: string[] = [];
  showModal = false;
  private shouldResetOrientation = false;

  constructor(private latamItLoaderService: LatamItLoaderService, private zone: NgZone) {}

  ngOnInit(): void {
    this.fileParameters.forEach(parameter => {
      const extUpper = parameter.extensions.map(x => {
        return x.toUpperCase();
      });
      parameter.extensions = extUpper;

      extUpper.forEach(extension => {
        this.allExtentions.push(extension);
      });
    });
  }

  fileEvent(event: any) {
    const files = event.target.files;
    // tslint:disable-next-line: early-exit
    if (files) {
      const extention = files[0].name
        .split('.')
        .pop()
        .toUpperCase();
      this.findListIndexByExt(extention);
      if (this.extensionExists() && this.validateMaxSize(files[0].size)) {
        this.zone.run(() => {
          this.latamItLoaderService.show();
        });
        if (this.fileParameters[this.listIndex].is_image && this.isToCompressImage(files[0])) {
          this.setOrientation(files);
        } else {
          this.sendFile(files);
        }
      } else {
        this.openModalWithError();
      }
    }
  }

  setOrientation(files) {
    const reader = new FileReader();
    const createdInLessThanOneMinute = new Date().getTime() - files[0].lastModified < 60000 ? true : false;

    reader.onload = () => {
      const img = new Image();
      let orientation = '';
      img.onload = () => {
        orientation = img.width > img.height ? 'landscape' : 'portrait';
        this.shouldResetOrientation = orientation === 'landscape' && createdInLessThanOneMinute;
        this.compressAndSendImage(files);
      };

      img.src = reader.result as string;
    };

    reader.readAsDataURL(files[0]);
  }

  isToCompressImage(file: File) {
    return file.size >= this.fileParameters[this.listIndex].min_processing_size * 1024;
  }

  findListIndexByExt(ext: string) {
    this.fileParameters.forEach((params, i) => {
      if (this.listIndex === -1 && params.extensions.indexOf(ext) > -1) {
        this.listIndex = i;
      }
    });
  }

  extensionExists(): boolean {
    return this.listIndex > -1;
  }

  sendFile(files: FileList) {
    const reader = new FileReader();

    reader.onloadend = e => {
      this.latamItLoaderService.hide();
      this.isFileSelected = true;
      this.fileSelectEvent.emit({
        isFileSelected: this.isFileSelected,
        file: files[0],
        base64: reader.result
      });
    };

    reader.readAsDataURL(files[0]);
  }

  compressAndSendImage(files: FileList) {
    const images: IImage[] = [];

    const options = new ResizeOptions();

    options.Resize_Quality = 99;
    options.Resize_Max_Height = this.fileParameters[this.listIndex].resolution_size;
    options.Resize_Max_Width = this.fileParameters[this.listIndex].resolution_size;

    ImageCompressService.filesToCompressedImageSourceEx(files, options).then(observableImages => {
      observableImages.subscribe(
        image => {
          images.push(image);
        },
        error => {
          console.log('Error while compressing', error);
        },
        () => {
          const compressedSize = Math.round((images[0].compressedImage.imageDataUrl.length * 3) / 4);
          // tslint:disable-next-line: early-exit
          if (compressedSize > this.fileParameters[this.listIndex].max_upload_size * 1024) {
            this.openModalWithError();
          } else {
            this.latamItLoaderService.hide();
            this.isFileSelected = true;
            if (this.shouldResetOrientation) {
              this.resetOrientation(this, images[0].compressedImage.imageDataUrl, 6, files[0], this.resetOrientationCallback);
            } else {
              this.fileSelectEvent.emit({
                isFileSelected: this.isFileSelected,
                file: files[0],
                base64: images[0].compressedImage.imageDataUrl
              });
            }
          }
        }
      );
    });
  }

  openModalWithError() {
    this.listIndex = -1;
    this.isFileSelected = false;
    this.toggleModal();
    this.fileInput.nativeElement.value = '';
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  validateMaxSize(size: number): boolean {
    return this.fileParameters[this.listIndex].max_file_size * 1024 >= size;
  }

  onCloseButtonClick() {
    this.listIndex = -1;
    this.isFileSelected = false;
    this.fileSelectEvent.emit({
      isFileSelected: this.isFileSelected,
      file: {
        name: null,
        size: null,
        type: null
      },
      base64: null
    });
  }

  resetOrientation(scope, srcBase64, srcOrientation, file, callback) {
    const img = new Image();

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(scope, file, canvas.toDataURL('image/jpeg'));
    };

    img.src = srcBase64;
  }

  resetOrientationCallback(scope, file, resetBase64Image) {
    scope.fileSelectEvent.emit({
      isFileSelected: scope.isFileSelected,
      file,
      base64: resetBase64Image
    });
  }
}
