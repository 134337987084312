import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressBarService } from '../progressBar.service';
import { SharedModule } from '../shared/shared.module';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { DocumentChipComponent } from './document-chip/document-chip.component';
import { DocumentNoChipComponent } from './document-no-chip/document-no-chip.component';
import { DocumentNumberComponent } from './document-number/document-number.component';
import { DocumentTypeComponent } from './document-type/document-type.component';
import { ErrorPageCreateComponent } from './errors/error-page-create/error-page-create.component';
import { ErrorPageDocumentRejectedComponent } from './errors/error-page-document-rejected/error-page-document-rejected.component';
import { ErrorPageDuplicateAccountComponent } from './errors/error-page-duplicate-account/error-page-duplicate-account.component';
import { ErrorPageSolicitationComponent } from './errors/error-page-solicitation/error-page-solicitation.component';
// import { PlatformService } from 'voxel-mb-services/platform.service';
// import { NativeCommunicationService } from 'voxel-mb-core/native-communication/native-communication.service';
import { ErrorsLinkService } from './errors/shared/errors-link.service';
import { GetReadyRoutingModule } from './get-ready.routes';
import { HomeGetReadyComponent } from './home-get-ready/home-get-ready.component';
import { IncomeComponent } from './income/income.component';
import { LoadingComponent } from './loading/loading.component';
import { MaritalStatusComponent } from './marital-status/marital-status.component';
import { OccupationComponent } from './occupation/occupation.component';
import { PartnerComponent } from './partner/partner.component';
import { SellerComponent } from './seller/seller.component';
import { GetReadyCommonService } from './shared/get-ready-common.service';
import { GetReadyFormService } from './shared/get-ready-form.service';
import { GetReadyNavigationService } from './shared/get-ready-navigation.service';


@NgModule({
  imports: [
    GetReadyRoutingModule,
    SharedModule,
    CommonModule,
  ],
  exports: [],
  providers: [
    ProgressBarService,
    GetReadyCommonService,
    GetReadyFormService,
        // NativeCommunicationService,
        // PlatformService,
    ErrorsLinkService,
    GetReadyNavigationService,
  ],
  declarations: [
    DocumentTypeComponent,
    DocumentNoChipComponent,
    DocumentChipComponent,
    ContactInfoComponent,
    DocumentNumberComponent,
    ErrorPageDocumentRejectedComponent,
    ErrorPageCreateComponent,
    ErrorPageDuplicateAccountComponent,
    ErrorPageSolicitationComponent,
    OccupationComponent,
    PartnerComponent,
    MaritalStatusComponent,
    HomeGetReadyComponent,
    SellerComponent,
    IncomeComponent,
    LoadingComponent
  ]
})

export class GetReadyModule {}
