<voxel-it-selfie
  *ngIf="choice === 'P'"
  id="back-photo-selfie"
  [routeToNavigate]="previousRoute"
  [repeatPictureLabel]="'Tomar otra'"
  [compressedResolutionHeight]="compressedResolutionHeight"
  [maxImageLenght]="maxImageLenght"
  headerTitle="Dorso de la cédula"
  headerSubTitle="Tomá la foto de forma legible."
  faceCam="false"
  [errorRoute]="errorRoute"
  [noPermissionRoute]="noPermissionRoute"
  [events]="eventsSubject.asObservable()"
  (sendImage)="sendImageEvent($event)"
  [synchronousSendImage]="false"
  analyticsPage = ""
  transactionStep = "">
</voxel-it-selfie>

<div class="content-with-filled-button" *ngIf="choice !== 'P'">
  <header-oca [atual]='3'></header-oca>
  <main>
    <div>
      <h1 class="cedula-photo-upload-title">Adjuntá la imagen de
        <strong>dorso de cédula</strong>
      </h1>

      <div class="upload-component">
        <file-upload 
          [fileParameters]="uploadParams" 
          [isFileSelected]="isFileSelected" 
          title="Dorso de cédula" 
          (fileSelectEvent)='fileSelected($event)'
          [accepted_extensions]="'image/*'">
        </file-upload>

        <div *ngIf="!isFileSelected && isFacebookAndroid" class="facebook-photo">
          <label for="facebookPhotoId">
            <img src="assets/images/icono-tomar-foto.svg" />
          </label>
          <button class="facebook-upload-button" id="facebookPhotoId" (click)="goToFacebookUpload()"></button>
        </div>
      </div>

    </div>
  </main>
</div>
<footer *ngIf="choice!='P'">
  <button-sidebyside
    [isBtnDisabled]="isBtnDisabled"
    nextHook="true"
    (hookNextAction)="continuarButton()"
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>

<facebook-pixel></facebook-pixel>
