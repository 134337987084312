import { Injectable } from '@angular/core';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';
import { ServiceCommon } from '../../shared/service.common';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ArchiveService } from '../../shared/services/domains/archive/archive.service';
import { DocumentPhotosNavigationService } from './document-photos-navigation.service';
import { DocumentPhotoApproach } from './enums/routes-document-photos-enum';


@Injectable()
export class DocumentPhotosCommonService {
  paramsUpload: any;
  choice = DocumentPhotoApproach.PHOTO;

  constructor(
    private archiveService: ArchiveService,
    private latamItLoaderService: LatamItLoaderService,
    private navigationService: DocumentPhotosNavigationService,
    private serviceCommon: ServiceCommon,
  ) {
    this.paramsUpload = this.serviceCommon.getAdminList(AdminListEnum.FILE_UPLOAD);
  }

  sendImage(base64, fileType) {
    this.latamItLoaderService.show();

    const imageJson = this.archiveService.createUploadFileJson(base64, 'jpeg', fileType);

    this.archiveService.doPatchSolicitationFileUpload(imageJson, false, false).subscribe(
      res => {
        if (res.statusCode === 200) {
          console.log('FileUpload post successfull', res);
          this.navigationService.sendToNext();
          this.latamItLoaderService.hide();
        } else {
          console.log('FileUpload post ERROR', res);
          this.latamItLoaderService.hide();
          this.sendToPrevious(res.errorCount);
        }
      },
      err => {
        console.log('POST error', err);
        this.latamItLoaderService.hide();
        this.sendToPrevious(err.count);
      },
    );
  }

  private sendToPrevious(errorCount: number) {
    if (errorCount < 11 && (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia)) {
      this.navigationService.sendToPrevious();
    }
  }
}
