import { Injectable } from '@angular/core';

import { PldModel } from './models/pld-model';

@Injectable()
export class PldFormService {

  pldForm: PldModel;

  constructor() {
    this.pldForm = new PldModel();
  }

  getPldForm() {
    return this.pldForm;
  }

  getProperty(property: any) {
    return this.pldForm[property];
  }

  setProperty(property: string, value: any) {
    this.pldForm[property] = value;
  }

}

