import { Component, Input, OnInit } from '@angular/core';

import { AccountOffer } from '../../offers/models/account-offers.model';
import { CardBrand } from '../../offers/models/card-brand.model';
import { OfferPackage } from '../../offers/models/offer-package-model';
import { OffersModel } from '../../offers/models/offers-model';
import { NumberHelperService } from '../helper/number-helper-service';
import { SummaryConfirmationIconEnum } from './shared/enum/summary-confirmation-icon-enum';

@Component({
  selector: 'summary-confirmation',
  templateUrl: './summary-confirmation.component.html',
  styleUrls: ['./summary-confirmation.component.scss']
})
export class SummaryConfirmationComponent implements OnInit {
  @Input() icons: any[];

  @Input() overdraftLimit: string;

  @Input() offersModel: OffersModel;

  @Input() terms: boolean;
  @Input() urlTerms: string;

  @Input() cardType: string;
  @Input() cardBrand: CardBrand;
  @Input() cardCreditLimit: string;
  @Input() hyperlinkBehavior: string;
  @Input() isFacebookBrowserOniOS: boolean;

  cardCurrency: string;
  accounts: AccountOffer[];
  accountOffer: AccountOffer;
  offerPackage: OfferPackage;
  modalShow = false;
  debitCard: AccountOffer;

  constructor(private numberhelpService: NumberHelperService) {}

  ngOnInit() {
    this.setData();
  }

  setData() {
    // tslint:disable-next-line: early-exit
    if (this.offersModel && (this.offersModel.selected_package !== null || this.offersModel.selected_package !== undefined)) {
      this.offerPackage = this.offersModel.packages[this.offersModel.selected_package];
      if (this.offerPackage) {
        this.accounts = this.offerPackage.account_offers.filter(x => x.account_type.length === 2);
        this.debitCard = this.offerPackage.account_offers.find(x => x.account_type.length > 2);
        this.accountOffer = this.offerPackage.account_offers.find(
          x => x.account_type === 'CC' && x.overdraft_limit !== undefined
        );
        if (this.accountOffer) {
          this.overdraftLimit = this.setMask(this.overdraftLimit, this.accountOffer.overdraft_limit.decimals);
        }
        if (this.offerPackage.card_offers) {
          this.cardCurrency = this.offerPackage.card_offers[0].limit.currency;
          this.cardCreditLimit = this.setMask(this.cardCreditLimit, this.offerPackage.card_offers[0].limit.decimals);
        }
        this.categoryIcon(this.accounts);
        this.cardIcon(this.debitCard);
      }
    }
  }

  setMask(value: string, decimals: number): string {
    let result = null;
    if (value && decimals >= 0) {
      result = this.numberhelpService.numberFormat(value, decimals);
    }
    return result;
  }

  categoryIcon(accountList) {
    const icon = this.icons.find(i => i.code === SummaryConfirmationIconEnum.CA.toString());
    // tslint:disable-next-line: early-exit
    if (icon) {
      for (const account of accountList) {
        account.icon = icon.label;
      }
    }
  }
  cardIcon(debitCard) {
    // tslint:disable-next-line: early-exit
    if (debitCard) {
      const icon = this.icons.find(i => i.code === 'tarjeta');
      if (icon) {
        debitCard.icon = icon.label;
      }
    }
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }

  getIcon() {
    return {
      'icon-itaufonts_aplicacao': !this.isFacebookBrowserOniOS,
      'icon-itaufonts_fatura': this.isFacebookBrowserOniOS
    };
  }
}
