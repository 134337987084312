import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CohabitantModel } from '../models/cohabitant-model';
import { GetReadyModel } from '../models/get-ready-model';
import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';
import { validateDocument } from './../document-number/document-number.custom-validator';

@Component({
  selector: 'get-ready-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit, OnDestroy {
  pageId: string;
  conyugeType: string;
  partner: CohabitantModel;
  getReadyForm: GetReadyModel;

  form = new FormGroup({
    partner: new FormControl('', [Validators.required, validateDocument])
  });

  @ViewChild('documentInput', { static: true }) documentInput: ElementRef;

  private subscription = new Subscription();
  private previousStatus: string;

  constructor(
    public navigationService: GetReadyNavigationService,
    private formService: GetReadyFormService,
    private getReadyFormService: GetReadyFormService
  ) {}

  isEnter($event) {
    if ($event && $event.key === 'Enter' && !this.form.invalid) {
      this.nextActionButton();
    }
  }

  nextActionButton() {
    this.partner.document.number = this.form.controls['partner'].value;
    this.formService.setProperty('cohabitant', this.partner);
    this.navigationService.sendToNext();
  }

  ngOnInit() {
    this.pageId = 'partner';
    this.watchFormStatusChanges();
    this.setData();
    this.resolveConyugeType();
  }

  setData() {
    this.getReadyForm = this.formService.getFormGetReady();
    this.partner = this.getReadyForm.cohabitant;
    if (!this.partner) {
      this.partner = new CohabitantModel();
      this.getReadyForm.cohabitant = this.partner;
      this.form.controls['partner'].setValue(this.partner.document.number);
    }
    this.form.controls['partner'].setValue(this.partner.document.number);
  }

  resolveConyugeType() {
    this.conyugeType = 'concubino(a)';
    if (this.getReadyForm.marital_status.toUpperCase() === 'C') {
      this.conyugeType = 'cónyuge';
    }
  }

  setAtual() {
    return 5;
  }

  createInputMask = (rawValue: string): (string | RegExp)[] => {
    return rawValue.replace(/[^0-9]/g, '').length <= 7
      ? [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/]
      : [/[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/];
  }

  watchFormStatusChanges() {
    this.subscription.add(
      this.form.statusChanges.subscribe(status => {
        if (!this.previousStatus) {
          this.previousStatus = status;
        }
        // Had to do this because the input was not updating its status from invalid to valid
        // When combined with a mask, only when losing focus
        // tslint:disable-next-line: early-exit
        if (this.previousStatus !== status) {
          this.documentInput.nativeElement.blur();
          this.documentInput.nativeElement.focus();
          this.previousStatus = status;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
