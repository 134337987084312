import { Injectable } from '@angular/core';

@Injectable()
export class ErrorTryLaterService {
  errorMessage: string;

  getErrorMessage() {
    this.errorMessage = '';
  }
}
