<div class="main-container content-with-filled-button">
  <div class="main-container__cover">
    <img class="main-container__image" src="assets/images/img_perm_getusermedia.svg" alt="incompatible">
  </div>
  <div class="main-container__content">
    <h1 class="title title-create">
      Hemos detectado un problema con tu cámara
    </h1>

    <h2 class="sub-title sub-title-create">
      Por favor reinciá tu teléfono y volvé a intentarlo.
    </h2>
  </div>
</div>

<footer>
  <voxel-button
    id="camera-error"
    label="aceptar"
    (click)="sendToLandingPage()">
  </voxel-button>
  <!--
  <voxel-mb-button
    id="camera-error"
    label="aceptar"
    radioButton="true"
    (onClickButton)="sendToLandingPage()">
  </voxel-mb-button>
  -->
</footer>
