import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentTypeComponent } from './document-type/document-type.component';
import { DocumentNoChipComponent } from './document-no-chip/document-no-chip.component';
import { DocumentChipComponent } from './document-chip/document-chip.component';
import { ErrorPageCreateComponent } from './errors/error-page-create/error-page-create.component';
import { ErrorPageDuplicateAccountComponent } from './errors/error-page-duplicate-account/error-page-duplicate-account.component';
import { ErrorPageSolicitationComponent } from './errors/error-page-solicitation/error-page-solicitation.component';
import { OccupationComponent } from './occupation/occupation.component';

import { LoadingComponent } from './loading/loading.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { MaritalStatusComponent } from './marital-status/marital-status.component';
import { PartnerComponent } from './partner/partner.component';
import { DocumentNumberComponent } from './document-number/document-number.component';
import { HomeGetReadyComponent } from './home-get-ready/home-get-ready.component';
import { SellerComponent } from './seller/seller.component';
import { ErrorPageDocumentRejectedComponent } from './errors/error-page-document-rejected/error-page-document-rejected.component';
import { IncomeComponent } from './income/income.component';

export const routes: Routes = [
  {
    path: 'preparate-bienvenida-vendor',
    component: HomeGetReadyComponent
  },
  {
    path: 'ingresos-liquidos',
    component: IncomeComponent
  },
  {
    path: 'sucursales-vendedor',
    component: SellerComponent
  },
  {
    path: 'preparate-tipo-de-cedula',
    component: DocumentTypeComponent
  },
  {
    path: 'preparate-numero-documento',
    component: DocumentNumberComponent
  },
  {
    path: 'preparate-documento-no-chip',
    component: DocumentNoChipComponent
  },
  {
    path: 'preparate-document-chip',
    component: DocumentChipComponent
  },
  {
    path: 'preparate-contactamos',
    component: ContactInfoComponent
  },
  {
    path: 'preparate-pagina-erro-identidad',
    component: ErrorPageDocumentRejectedComponent
  },
  {
    path: 'preparate-pagina-erro-crear',
    component: ErrorPageCreateComponent
  },
  {
    path: 'preparate-pagina-erro-cuenta',
    component: ErrorPageDuplicateAccountComponent
  },
  {
    path: 'preparate-pagina-erro-solicitud',
    component: ErrorPageSolicitationComponent
  },
  {
    path: 'preparate-loading',
    component: LoadingComponent
  },
  {
    path: 'preparate-actividad-laboral',
    component: OccupationComponent
  },
  {
    path: 'preparate-estado-civil',
    component: MaritalStatusComponent
  },
  {
    path: 'preparate-conyuge',
    component: PartnerComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class GetReadyRoutingModule {}
