import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';

import { SelfieNavigationService } from '../shared/selfie-navigation.service';
import { RoutesSelfieEnum } from '../shared/enums/routes-selfie-enum';
import { Analytics } from '../../shared/analytics';
import { AbreCuentasRoutesEnum } from 'src/app/shared/enum/abre-cuentas-routes.enum';
import { ProgressBarService } from 'src/app/progressBar.service';
import { SelfieCommonService } from '../shared/selfie-common.service';
import { VoxelItSelfieService } from 'src/latam-it-camera-selfie/voxel-it-selfie.service';


@Component({
  selector: 'selfie-inclined-stage',
  templateUrl: './inclined-stage.component.html',
  styleUrls: ['../shared/selfie-styles.scss']
})
export class InclinedStageComponent implements OnInit, OnDestroy {

  pageId = 'inclined-stage';
  private successRoute = RoutesSelfieEnum.INCLINED_PHOTO.toString();
  private permissionDeniedRoute = AbreCuentasRoutesEnum.PERMISSION_REQUIRED.toString();
  private errorRoute = AbreCuentasRoutesEnum.CAMERA_ERROR.toString();


  constructor(
    private analyticsLib: Analytics,
    public navigationService: SelfieNavigationService,
    private elementRef: ElementRef,
    private progressBarService: ProgressBarService,
    public selfieCommonService: SelfieCommonService,
    private voxelItSelfieService: VoxelItSelfieService) {}

  ngOnInit() {
    this.navigationService.setStage(RoutesSelfieEnum.INCLINED_STAGE);
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#eaf1f7';
    this.setProgressBar();

    this.analyticsLib.buildData('SelfieInclinada', this.pageId, '17', false);
    this.analyticsLib.trackState(null, null);
    this.navigationService.setStage(RoutesSelfieEnum.INCLINED_STAGE);
    this.selfieCommonService.scrollToTop(document);

  }

  ngOnDestroy() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
  }

  next() {
    this.voxelItSelfieService.getUserMediaPermission(this.successRoute, this.permissionDeniedRoute, this.errorRoute);
  }

  previous() {
    this.navigationService.navigateTo(RoutesSelfieEnum.FRONTAL_STAGE);
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 3,
      amountBar: 3,
      currentBar: 3,
      labelName: 'Comprobar identidad',
      nextLabel: 'Siguiente: 4/4 Envío y confirmación'
    });
  }
}
