export class CohabitantModel {
  document: {
    number: string;
  };

  constructor() {
    this.document = {
      number: '',
    };
  }
}
