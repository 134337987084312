<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <header-oca [atual]='4'></header-oca>

  <main>
    <h1 id="titleId" class="title">
        ¿Cuál es el saldo promedio mensual esperado en tus cuentas?
    </h1>

    <voxel-additional-information
      id='addtionalInformationId'
      [message]="hintObject.message"
      idHint="hintid"
      [reverseHint]="true">
    </voxel-additional-information>

    <div class="radio-button">
      <voxel-radio-button-group>
        <voxel-radio-button
          *ngFor="let expectedBalance of expectedBalances"
          id="expectedBalance"
          [radioName]="' '"
          [value]="expectedBalance.code"
          [checked]="expectedBalance.checked"
          (radioButtonChange)='changeAmount($event)'>
          {{expectedBalance.label}}
        </voxel-radio-button>
      </voxel-radio-button-group>
    </div>

  </main>
</div>

<footer>
  <button-sidebyside [isBtnDisabled]="isBtnDisabled" [navigationService]="navigationService"></button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>