<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <client-header-oca [atual]='9'></client-header-oca>

  <main>
    <div class="header-container2">
      <h1>
          <strong>¡Excelente!</strong> Revisá los productos que seleccionaste antes de confirmar tu solicitud.
      </h1>
    </div>

  <client-summary-confirmation class="info-final"
    [icons]="icons"
    [overdraftLimit]="overdraftLimit"
    [offersModel]="offersModel"
    [cardType]="cardType"
    [cardBrand]="cardBrand"
    [cardCreditLimit]="cardCreditLimit"
    [terms]="false"
    [urlTerms]="urlTerms"
    [hyperlinkBehavior]="hyperlinkBehavior"
    [isFacebookBrowserOniOS]="false">
  </client-summary-confirmation>

  <div class="terms-container">
    <div>
      <span>
          Al confirmar, aceptás que no moverás fondos de terceros ni recibirás donaciones en tus cuentas,
          que sos el dueño de los fondos,
          que la fuente principal de los mismos es tu actividad principal y que no sos PEP (Persona Expuesta Políticamente).
      </span>
    </div>

    <div class="terms-container__checkbox">
      <voxel-checkbox
        checkboxId="acceptTermsId"
        [label]="'Acepto términos y condiciones'"
        checkboxName="acceptTerms"
        [checked]="acceptTerms"
        value="acceptTerms"
        (checkboxChange)="checkboxChange($event)">
      </voxel-checkbox>
    </div>

    <div class="terms-container__checkbox container-us">

      <div class="checkbox-us">
        <voxel-checkbox
          checkboxId="americanId"
          [label]="'No soy ciudadano de los Estados Unidos (US Person)'"
          checkboxName="usPerson"
          [checked]="usPerson"
          value="usPerson"
          (checkboxChange)="checkboxAmericanChange($event)">
        </voxel-checkbox>
      </div>

      <div class="info-us">
        <vcl-additional-info
          id='addtionalInformationId'
          text="{{hintObject.message}}"
          position="left">
        </vcl-additional-info>
      </div>

    </div>
  </div>
  </main>
</div>

<footer>
  <button-sidebyside
    [isBtnDisabled]="!(acceptTerms && usPerson)"
    nextHook="true"
    (hookNextAction)="continuarButton()"
    [navigationService]="clientNavigationService">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>



