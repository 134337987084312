import { ClientSummaryConfirmationComponent } from './shared/client-summary-confirmation/client-summary-confirmation.component';
import { ClientAddressFormComponent } from './shared/address-form/client-address-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { ClientConfirmationComponent } from './client-confirmation/client-confirmation.component';
import { ProgressBarService } from '../progressBar.service';
import { ClientRoutingModule } from './client.routes';
import { ClientCommonService } from './shared/client-common.service';
import { ClientFormService } from './shared/client-form.service';
import { ClientNavigationService } from './shared/client-navigation.service';
import { ClientEndComponent } from './client-end/client-end.component';
import { ClientContactInfoComponent } from './client-contact-info/client-contact-info.component';
import { ClientPurposeComponent } from './client-purpose/client-purpose.component';
import { ClientMoneyComponent } from './client-money/client-money.component';
import { ClientCreditsComponent } from './client-credits/client-credits.component';
import { ClientDeliveryAddressComponent } from './client-delivery-address/client-delivery-address.component';
import { ClientDeliveryTimeComponent } from './client-delivery-time/client-delivery-time.component';
import { ClientHeaderOCAComponent } from './shared/client-latam-it-header/client-header-oca.component';
import { AbreCuentasPipe } from './../shared/pipes/pipe.modules';
import { ClientPlansComponent } from './client-plans/client-plans.component';
import { ClientBenefitsComissionsComponent } from './client-benefits-comissions/client-benefits-comissions.component';
import { ClientPersonalAddressComponent } from './client-personal-address/client-personal-address.component';

@NgModule({
  imports: [
    ClientRoutingModule,
    SharedModule,
    CommonModule,
    FormsModule,
    AbreCuentasPipe,
  ],
  providers: [
    ProgressBarService,
    ClientNavigationService,
    ClientCommonService,
    ClientFormService
  ],
  declarations: [
    ClientPlansComponent,
    ClientBenefitsComissionsComponent,
    ClientContactInfoComponent,
    ClientPurposeComponent,
    ClientMoneyComponent,
    ClientCreditsComponent,
    ClientAddressFormComponent,
    ClientPersonalAddressComponent,
    ClientDeliveryAddressComponent,
    ClientDeliveryTimeComponent,
    ClientConfirmationComponent,
    ClientEndComponent,
    ClientHeaderOCAComponent,
    ClientSummaryConfirmationComponent
  ]
})

export class ClientModule {}
