import { Component, OnDestroy, OnInit } from '@angular/core';
import { GetReadyFormFullService } from 'src/app/get-ready-full/shared/get-ready-form.service';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';

import { Analytics } from '../../shared/analytics';
import { NumberHelperService } from '../../shared/helper/number-helper-service';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { CardOfferFull } from '../models/card-offers-model';
import { OffersFullModel } from '../models/offers-model';
import { RoutesOffersFullEnum } from '../shared/enums/routes-offers-enum';
import { OffersFullCommonService } from '../shared/offers-common.service';
import { OffersFullFormService } from '../shared/offers-form.service';
import { OffersFullNavigationService } from '../shared/offers-navigation.service';

@Component({
  selector: 'offers-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardFullComponent implements OnInit, OnDestroy {
  pageId: string;
  siWhite: string;
  noWhite: string;
  externalUrl: string;
  creditCardLimit: string;
  cardOffer: CardOfferFull;
  offersModel: OffersFullModel;
  body = document.querySelector('body');
  hyperlinkBehavior: string;
  modalShow = false;

  constructor(
    public navigationService: OffersFullNavigationService,
    private formService: OffersFullFormService,
    private analyticsLib: Analytics,
    private numberHelperService: NumberHelperService,
    public offersCommonService: OffersFullCommonService,
    private getReadyFormService: GetReadyFormFullService,
    private sellerService: SellerService
  ) {
    this.pageId = 'credit_card';
    this.offersModel = formService.getForm();
    if (this.formService.getSelectedPackage().card_offers) {
      this.cardOffer = this.formService.getSelectedPackage().card_offers[0];
    }

    if (!this.sellerService.isSeller()) {
      this.analyticsLib.buildData('OfertaTarjCredito', this.pageId, '12', false);
      this.analyticsLib.trackState(null, null);
    }
  }

  analyticsTerminosYCond() {
    // tslint:disable-next-line: early-exit
    if (!this.sellerService.isSeller()) {
      this.analyticsLib.trackAction({
        customCategory: 'Body | Link | Bottom',
        customAction: 'Download',
        customLabel: 'TerminosyCond'
      });
    }
  }

  ngOnInit() {
    this.navigationService.setStage(RoutesOffersFullEnum.CREDITCARD);
    const adminLinkURLs = this.offersCommonService.getAdminList(AdminListEnum.LIST_URLS);

    this.externalUrl = adminLinkURLs.find(i => i.code.toUpperCase() === 'CARD_TERMS').description;
    this.offersModel.accepted_credit_card = this.formService.getProperty('accepted_credit_card');
    this.resolveButtons();
    if (this.cardOffer) {
      this.creditCalculator();
    }
    this.body.setAttribute('style', 'background-color: #EFE9E5');
    this.offersCommonService.scrollToTop(document);
    this.hyperlinkBehavior = this.offersCommonService.getHyperlinkBehavior();
  }

  resolveButtons() {
    if (this.offersModel.accepted_credit_card === undefined) {
      this.noWhite = 'secondary';
      this.siWhite = 'secondary';
    } else if (this.offersModel.accepted_credit_card === true) {
      this.noWhite = 'secondary';
      this.siWhite = 'primary';
    } else {
      this.noWhite = 'primary';
      this.siWhite = 'secondary';
    }
  }

  ngOnDestroy() {
    this.body.setAttribute('style', 'background-color: white');
  }

  clickSi() {
    this.offersModel.accepted_credit_card = true;
    this.formService.setProperty('accepted_credit_card', this.offersModel.accepted_credit_card);
    this.noWhite = 'secondary';
    this.siWhite = 'primary';

    setTimeout(() => {
      this.navigationService.sendToNext();
    }, 125);
  }

  clickNo() {
    this.offersModel.accepted_credit_card = false;
    this.formService.setProperty('accepted_credit_card', this.offersModel.accepted_credit_card);
    this.noWhite = 'primary';
    this.siWhite = 'secondary';

    setTimeout(() => {
      this.offersCommonService.doPatchSolicitationPackageSelected(undefined);
    }, 125);
  }

  hasOvedraft(): boolean {
    let result = false;
    const accountOffers = this.formService.getSelectedPackage().account_offers;

    if (accountOffers) {
      const hasOverdraftLimit = accountOffers.filter(item => item.overdraft_limit);
      if (hasOverdraftLimit.length > 0) {
        result = true;
      }
    }
    return result;
  }

  previous() {
    this.navigationService.sendToPrevious();
  }

  creditCalculator() {
    let creditCardLimit = this.numberHelperService.toCurrency('$', this.cardOffer.limit.amount.toString());
    this.creditCardLimit = creditCardLimit ? creditCardLimit.replace(' ', '') : creditCardLimit;
    this.formService.setProperty('credit_card_limit', this.cardOffer.limit.amount);

    if (
      this.offersModel.accepted_credit ||
      this.getReadyFormService.getProperty('solicitation_type') === 'aperturadecuentaspj'
    ) {
      creditCardLimit = this.numberHelperService.toCurrency('$', this.cardOffer.limit.amount.toString());
      this.creditCardLimit = creditCardLimit ? creditCardLimit.replace(' ', '') : creditCardLimit;
      this.formService.setProperty('credit_card_limit', this.cardOffer.limit.amount);
    } else {
      const accountOffers = this.offersModel.packages[this.offersModel.selected_package].account_offers[0];
      const overdraft = accountOffers ? (accountOffers.overdraft_limit ? accountOffers.overdraft_limit.amount : 0) : 0;
      const calc = this.offersCommonService.calculateOverdraftAndCreditCard(overdraft, this.cardOffer.limit.amount);
      creditCardLimit = this.numberHelperService.toCurrency('$', calc.toString());
      this.creditCardLimit = creditCardLimit ? creditCardLimit.replace(' ', '') : creditCardLimit;
      this.formService.setProperty('credit_card_limit', calc);
    }
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }

  isPJ() {
    return this.getReadyFormService.getProperty('solicitation_type') === 'aperturadecuentaspj';
  }
}
