<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <client-header-oca [atual]='8'></client-header-oca>

  <main>
 
    <h1 id="title" class="address-header title-container" >
      ¿A qué hora podés recibir tu tarjeta?
    </h1>

    <div class="radio-button">
      <voxel-radio-button-group>
        <voxel-radio-button
          *ngFor="let deliveryTime of deliveryTimes"
          id="deliveryTime"
          [radioName]="' '"
          [value]="deliveryTime.code"
          [checked]="deliveryTime.checked"
          (radioButtonChange)='changeDeliveryTime($event)'>
          {{deliveryTime.label}}
        </voxel-radio-button>
      </voxel-radio-button-group>
    </div>
  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    (hookNextAction)="continuarButton()"
    (hookPreviousAction)="analyticsVolver()"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>