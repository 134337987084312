import { Injectable } from '@angular/core';
import { OffersModel } from '../models/offers-model';
import { OfferPackage } from '../models/offer-package-model';

@Injectable()
export class OffersFormService {

  private form: OffersModel;

  constructor() {
    this.form = new OffersModel();
  }

  getForm() {
    return this.form;
  }

  setForm(form: OffersModel) {
    this.form = form;
  }

  getProperty(property: any) {
    return this.form[property];
  }

  setProperty(property: string, value: any) {
    this.form[property] = value;
  }

  getSelectedPackage(): OfferPackage {
    return this.form.packages[this.form.selected_package];
  }

}
