<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='2'></header-oca>
  <main>
    <div class="header-container">
      <h1>
        También tenés una
        <strong>tarjeta de crédito OCA</strong> con un límite de {{creditCardLimit}}.
      </h1>
    </div>
    <div class="credit-card__container">
      <div class="credit-card__container__img">
        <img src="assets/images/img_tarjeta_visa.png" class="credit-card__container__img__size">
      </div>
      <div class="credit-card__container__item">
        <div class="credit-card__container__title">
          <i class="icon credit-card__container__icons icon-itaufonts_curtir"></i>
          <div class="credit-card__container__text">
            ¡El primer año es sin costo!
          </div>
        </div>
        <div class="credit-card__container__title">
          <i class="icon credit-card__container__icons icon-itaufonts_mundo_mapa"></i>
          <div class="credit-card__container__text">
            Participá en promociones y sorteos que constantemente se renuevan.
          </div>
        </div>
        <div class="credit-card__container__title">
          <i class="icon credit-card__container__icons icon-itaufonts_exclusivo_beneficios"></i>
          <div class="credit-card__container__text">
            Acumulá Metros con tus compras y cambialos por cientos de artículos.
          </div>
        </div>
        <div class="credit-card__container__title">
          <div class="credit-card__container__text" style="margin-left:3.6rem">
            <div *ngIf="!offersCommonService.isFacebookBrowserOniOS()" class="tyc-1">
              <a [href]="externalUrl" (click)="analyticsTerminosYCond()" [target]="hyperlinkBehavior"> Ver términos y condiciones</a>
            </div>

            <div *ngIf="offersCommonService.isFacebookBrowserOniOS()">
                <a (click)="toggleModal(); analyticsTerminosYCond()"> Ver términos y condiciones</a>
            </div>
          </div>
        </div>
        
        <div *ngIf="isPJ()" >
          <hr class="credit-card__container__hr">
          <div class="credit-card__container__title">
          <i class="icon credit-card__container__icons icon-itaufonts_exclamacao"></i>
          <div class="credit-card__container__text">
             Las tarjetas de crédito son emitidas a título personal, sí necesitas una tarjeta para tu empresa llamános al 1730 luego de abrir la cuenta.
          </div>
        </div>
      </div>
        <hr class="credit-card__container__hr" />

        <div class="credit-card__container__padding">
            <span class="credit-card__container__span">
              <strong>¿Querés la tarjeta de crédito?</strong>
            </span>

              <div class="credit-card__container__button">
                <div class="fl ">
                  <voxel-button
                    class="btn-radio"
                    id="btnSi"
                    label="Sí"
                    [typeButton]="siWhite"
                    (click)='clickSi()'>
                  </voxel-button>
                </div>
                <div class="fr ">
                  <voxel-button
                    class="btn-radio"
                    id="btnNo"
                    label="No"
                    [typeButton]="noWhite"
                    (click)='clickNo()'>
                  </voxel-button>
                </div>
              </div>
        </div>
      </div>
    </div>
  </main>
</div>
<footer>
  <voxel-button
    id="buttonVolver"
    label="Volver"
    typeButton="secondary"
    (click)='previous()'>
  </voxel-button>
</footer>

<div *ngIf="offersCommonService.isFacebookBrowserOniOS()">
  <pdf-viewer [externalUrl]="externalUrl" [(showModal)]="modalShow" title="Tarifas y comisiones"></pdf-viewer>
</div>

<facebook-pixel></facebook-pixel>
