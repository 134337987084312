import { Component, OnInit } from '@angular/core';

import { DocumentPhotosNavigationService } from '../shared/document-photos-navigation.service';
import { ProgressBarService } from '../../progressBar.service';
import { DocumentPhotosCommonService } from '../shared/document-photos-common.service';
import { DocumentPhotoApproach, RoutesDocumentPhotosEnum } from '../shared/enums/routes-document-photos-enum';
import { DocumentPhoto } from '../shared/document-photos.common';

@Component({
  selector: 'document-photos-get-ready',
  templateUrl: './document-photos-get-ready.component.html',
  styleUrls: ['./document-photos-get-ready.component.scss']
})
export class DocumentPhotosGetReadyComponent implements OnInit {

  pageId = 'document-photos-get-ready';
  selectedSegment = { label: 'varejo', value: 'varejo' };
  choices: DocumentPhoto[] = [];
  selectedChoiceCode: DocumentPhotoApproach;

  constructor(
    public navigationService: DocumentPhotosNavigationService,
    private progressBarService: ProgressBarService,
    public commonService: DocumentPhotosCommonService) {}

  ngOnInit() {
    this.navigationService.setStage(RoutesDocumentPhotosEnum.GET_READY);
    this.initializeData();
    this.setProgressBar();
  }

  next() {
    this.navigationService.sendToNext();
  }

  initializeData() {
    this.choices = [
      { label: 'Adjunta archivos', checked: false, elementId: 1, code: DocumentPhotoApproach.FILE },
      { label: 'Tomar fotos', checked: false, elementId: 2, code: DocumentPhotoApproach.PHOTO }
    ];

    this.selectedChoiceCode = this.commonService.choice;
    const selectedChoice = this.choices.find(choice => choice.code === this.selectedChoiceCode);

    if (selectedChoice) {
      selectedChoice.checked = true;
    }
  }

  changeChoice(choice: DocumentPhoto) {
    this.selectedChoiceCode = choice.code;
    this.commonService.choice = this.selectedChoiceCode;
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 3,
      amountBar: 3,
      currentBar: 1,
      labelName: 'document photos'
    });
  }

}
