<div class="content-with-filled-button">
    <div [id]="pageId"></div>
    <header-oca [atual]='2'></header-oca>

    <main>
        <h1 id="titleId" class="paddingless">
            ¿Que tipo de cédula tenés?

        </h1>

        <div>
            <div *ngIf="showChip" id="chip" #chip [ngClass]="{'card-document-type-selected': hasChip}"
                class="card-document-type" style="margin-top: 10%;" (click)="changeType(chip)">
                <img class="logo-document-type" src="assets/images/nueva_cedula_icono.svg" />
                <span>
                    Con chip
                </span>
            </div>
            <div *ngIf="showNotChip" id="notChip" #notChip [ngClass]="{'card-document-type-selected': notHasChip}"
                class="card-document-type" style="margin-top: 5%;" (click)="changeType(notChip)">
                <img class="logo-document-type" src="assets/images/antigua_cedula_icono.svg" />
                <span>
                    Sin chip
                </span>
            </div>
        </div>

        <br>
        <div *ngIf="showDocumentNumber" style="margin-top: 20px">
            <div class='input-container' style="margin-top: 10px;">
                <voxel-form-field 
                    id="document_number"
                    [formGroup]="formDocumentNumber" 
                    label="Número de cédula" 
                    [msgErr]="documentInvalid">
                    <input 
                        voxelInput 
                        id="codigoId" 
                        formControlName="docNumber" 
                        [value]="documentNumber"
                        (ngModelChange)="changeNumber($event)"
                        (keyup.enter)="isEnter($event)"
                        voxelMask="9.999.999-9">
                </voxel-form-field>

            </div>
        </div>

    </main>
</div>

<footer>
    <button-sidebyside 
        previousHook="true"
        [isBtnDisabled]="isBtnDisabled" 
        [navigationService]="navigationService"
        (hookPreviousAction)="doPrevious()"
    ></button-sidebyside>
</footer>

<facebook-pixel></facebook-pixel>