import { ClientModel } from '../models/client-model';

export class ClientFormService {

  private form: ClientModel;
  private previousStage: string;

  constructor() {
    this.form = new ClientModel();
  }

  getForm() {
    return this.form;
  }

  setForm(form: ClientModel) {
    this.form = form;
  }

  getProperty(property: any) {
    return this.form[property];
  }

  setProperty(property: string, value: any) {
    this.form[property] = value;
  }

  setPreviousStage(stage: string) {
    this.previousStage = stage;
  }

  getPreviousStage() {
    return this.previousStage;
  }
}
