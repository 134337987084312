import { Component, OnInit } from '@angular/core';
import { ProspectService } from 'src/app/shared/services/domains/prospect/prospect.service';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ClientCommonService } from '../shared/client-common.service';
import { ClientFormService } from '../shared/client-form.service';
import { ClientNavigationService } from '../shared/client-navigation.service';
import { ClientCurrencyValueModel } from '../shared/models/client-currency-value';
import { Analytics } from './../../shared/analytics';

@Component({
  selector: 'client-money',
  templateUrl: './client-money.component.html',
  styleUrls: ['../shared/client-pld-styles.scss']
})
export class ClientMoneyComponent implements OnInit {
  static TRACKING_CLIENTE = 'TRACKING-CLIENTE-PANTALLA-4';

  pageId = 'pld-money';
  expectedBalances: any[] = [];
  isBtnDisabled = false;
  hintObject: any;

  constructor(
    private pldFormService: ClientFormService,
    private commonService: ClientCommonService,
    public navigationService: ClientNavigationService,
    public prospectService: ProspectService,
    private analyticsLib: Analytics
  ) {
    this.hintObject = {};
  }

  ngOnInit() {
    this.expectedBalances = this.commonService.getAdminList(AdminListEnum.AVG_BALANCE);
    this.resolveHint();
    this.refreshButton();
    this.pldFormService.setPreviousStage('ClientMoney');
    this.analyticsLib.buildData('SaldoPromedioMensualPLD', this.pageId, '04', true);
    this.analyticsLib.trackState(null, null);
    this.doPatchProspectTracking();

    // tslint:disable-next-line: early-exit
    if (this.pldFormService.getProperty('avg_balance')) {
      this.expectedBalances.forEach(element => {
        const amount = this.pldFormService.getProperty('avg_balance').amount;
        element.checked = !!(amount === element.code);
      });
    }
  }

  resolveHint() {
    this.hintObject.message = `Es la cantidad de dinero que esperas mantener depositada en tus cuentas mensualmente.`;
    this.hintObject.value = `offer`;
  }

  doPatchProspectTracking() {
    const clientFlowId = this.prospectService.getClientFlowId();
    // tslint:disable-next-line: early-exit
    if (clientFlowId) {
      const body: any = {
        prospect_id: clientFlowId,
        prospect_type: ClientMoneyComponent.TRACKING_CLIENTE
      };
      this.commonService.doPatchProspectTracking(body);
    }
  }

  refreshButton() {
    this.isBtnDisabled =
      !this.pldFormService.getProperty('avg_balance') || !this.pldFormService.getProperty('avg_balance').amount;
  }

  changeAmount(event: any) {
    const balance = new ClientCurrencyValueModel();
    balance.amount = event.value;
    this.pldFormService.setProperty('avg_balance', balance);
    this.refreshButton();
  }
}
