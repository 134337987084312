import { Component } from '@angular/core';

import { DocumentPhotosNavigationService } from '../shared/document-photos-navigation.service';

@Component({
  selector: 'document-photos-error',
  templateUrl: './document-photos-error.component.html'
})
export class DocumentPhotosErrorComponent {
  pageId = 'document-photos-error';

  errorMsg = 'Es posible que tu foto no este centrada o haya salido borrosa.';

  modalShow = false;

  constructor(public navigationService: DocumentPhotosNavigationService) {}

  mainButtonClick() {
    return;
  }

  modalEnable() {
    this.modalShow = !this.modalShow;
  }
}
