import { Component, OnInit } from '@angular/core';
import { OffersFormService } from 'src/app/offers/shared/offers-form.service';

import { RoutesFinalDataEnum } from '../../final-data/shared/enums/routes-final-data-enum';
import { GenericModel } from '../../shared/models/generic-model';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { AddressModel } from '../models/address-model';
import { DeliveryChoiceModel } from '../models/delivery-choice-model';
import { AddressCommonService } from '../shared/address-common.service';
import { AddressFormService } from '../shared/address-form.service';
import { AddressNavigationService } from '../shared/address-navigation.service';
import { RoutesAddressEnum } from '../shared/enums/routes-address-enum';


@Component({
  selector: 'delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss'],
})
export class DeliveryAddressComponent implements OnInit {
  pageId: string;
  isBtnDisabled: boolean;

  pickUpAvailability: string;
  ocaAvailability: string;
  tataAvailability: string;
  pickUpCenterStreet: string;
  ocaCenterStreet: string;
  tataCenterStreet: string;

  states: GenericModel[];
  cities: GenericModel[];

  oldSelect: DeliveryChoiceModel;
  deliveryChoiceModel: DeliveryChoiceModel[];

  // Personal Address
  optionPersonalAddress: boolean;
  personalAddress: AddressModel;

  // Pickup
  pickupCenter: any[];
  pickupCenterAvailable: GenericModel[];
  optionPickupCenter: boolean;
  pickupCenterSelected: GenericModel;

  pickupCenterState: GenericModel[];
  pickupCenterStateSelected: GenericModel;

  pickupCenterLabel: string;

  // OCA Center
  ocaCenter: any[];
  ocaCenterAvailable: GenericModel[];
  optionOcaCenter: boolean;
  ocaCenterSelected: GenericModel;

  ocaCenterState: GenericModel[];
  ocaCenterStateSelected: GenericModel;

  // TA-TA Center
  tataCenter: any[];
  tataCenterAvailable: GenericModel[];
  optionTataCenter: boolean;
  tataCenterSelected: GenericModel;

  tataCenterState: GenericModel[];
  tataCenterStateSelected: GenericModel;

  // Other Address
  deliveryAddress: AddressModel;
  optionOtherAddress: boolean;

  deliveryStateSelected: GenericModel;
  deliveryCitySelected: GenericModel;

  cardSelected: boolean;

  constructor(
    public addressNavigationService: AddressNavigationService,
    private addressFormService: AddressFormService,
    private addressCommonService: AddressCommonService,
    private offersFormService: OffersFormService,
  ) {
    this.pageId = 'delivery_address';
    this.pickupCenter = this.addressCommonService.getAdminList(AdminListEnum.PICKUP_CENTER);
    this.addressCommonService.pickupCenterState.sort(this.addressCommonService.compareItemsAlphabetically);
    this.pickupCenterState = this.addressCommonService.pickupCenterState;

    if (this.pickupCenterState && this.pickupCenterState.length > 0) {
      this.pickupCenterStateSelected = !addressFormService.getProperty('pickup_center')
        ? this.findMontevideoCenter(this.pickupCenterState)
        : this.getStateByCenterId(addressFormService.getProperty('pickup_center'), this.pickupCenter, this.pickupCenterState);
      this.pickupCenterSelected = new GenericModel();
      this.pickupCenterAvailable = !addressFormService.getProperty('pickup_center')
        ? [
          this.pickupCenterSelected,
          ...this.filterCenterByStateAlphabetically(this.pickupCenterStateSelected.id, this.pickupCenter),
        ]
        : this.filterCenterByStateAlphabetically(this.pickupCenterStateSelected.id, this.pickupCenter);
    } else {
      this.pickupCenterStateSelected = new GenericModel();
      this.pickupCenterSelected = new GenericModel();
      this.pickupCenterAvailable = [];
    }
    this.ocaCenter = this.addressCommonService.getAdminList(AdminListEnum.OCA_CENTER);
    this.addressCommonService.ocaCenterState.sort(this.addressCommonService.compareItemsAlphabetically);
    this.ocaCenterState = this.addressCommonService.ocaCenterState;

    if (this.ocaCenterState && this.ocaCenterState.length > 0) {
      this.ocaCenterStateSelected = !addressFormService.getProperty('oca_center')
        ? this.findMontevideoCenter(this.ocaCenterState)
        : this.getStateByCenterId(addressFormService.getProperty('oca_center'), this.ocaCenter, this.ocaCenterState);


      this.ocaCenterSelected = new GenericModel();

      this.ocaCenterAvailable = !addressFormService.getProperty('oca_center')
        ? [this.ocaCenterSelected, ...this.filterCenterByStateAlphabetically(this.ocaCenterStateSelected.id, this.ocaCenter)]
        : this.filterCenterByStateAlphabetically(this.ocaCenterStateSelected.id, this.ocaCenter);

    } else {
      this.ocaCenterStateSelected = new GenericModel();
      this.ocaCenterSelected = new GenericModel();
      this.ocaCenterAvailable = [];
    }

    this.tataCenter = this.addressCommonService.getAdminList(AdminListEnum.TATA_CENTER);
    this.addressCommonService.tataCenterState.sort(this.addressCommonService.compareItemsAlphabetically);
    this.tataCenterState = this.addressCommonService.tataCenterState;

    if (this.tataCenterState && this.tataCenterState.length > 0) {
      this.tataCenterStateSelected = !addressFormService.getProperty('tata_center')
        ? this.tataCenterState[0]
        : this.getStateByCenterId(addressFormService.getProperty('tata_center'), this.tataCenter, this.tataCenterState);

      this.tataCenterSelected = new GenericModel();

      this.tataCenterAvailable = !addressFormService.getProperty('tata_center')
        ? [this.tataCenterSelected, ...this.filterCenterByStateAlphabetically(this.tataCenterStateSelected.id, this.tataCenter)]
        : this.filterCenterByStateAlphabetically(this.tataCenterStateSelected.id, this.tataCenter);

    } else {
      this.tataCenterStateSelected = new GenericModel();
      this.tataCenterSelected = new GenericModel();
      this.tataCenterAvailable = [];
    }
  }

  ngOnInit() {
    this.addressNavigationService.setStage(RoutesAddressEnum.DELIVERY_ADDRESS);

    this.cardSelected = this.offersFormService.getSelectedPackage().card_offers &&
      this.offersFormService.getSelectedPackage().card_offers.length > 0 &&
      this.offersFormService.getForm().accepted_credit_card ? true : false;

    this.deliveryChoiceModel = this.getDeliveryChoiceModel();
    this.states = this.addressCommonService.states;
    this.cities = this.addressCommonService.cities;
    this.deliveryStateSelected = new GenericModel();
    this.deliveryCitySelected = new GenericModel();
    this.personalAddress = new AddressModel();
    this.deliveryAddress = new AddressModel();
    this.isBtnDisabled = true;
    this.setDate();
    this.setPersonalAddress();
    this.setDeliveryAddress();

  }

  setDate() {
    const choice = this.addressFormService.getProperty('delivery_choice_id');

    // tslint:disable-next-line: early-exit
    if (choice) {
      for (const value of this.deliveryChoiceModel) {
        // tslint:disable-next-line: early-exit
        if (value.id === choice) {
          this.oldSelect = value;
          value.selected = true;
          this.setDeliveryChoice(value.id);
        }
      }
    }
  }

  setPersonalAddress() {
    this.personalAddress = this.addressFormService.getProperty('personal_address');
  }

  setDeliveryAddress() {
    this.deliveryAddress = this.addressFormService.getProperty('delivery_address');
    this.deliveryStateSelected.label = this.deliveryAddress.state;
    this.deliveryCitySelected.label = this.deliveryAddress.city;
  }

  setPickupCenter() {
    const id = this.addressFormService.getProperty('pickup_center');
    if (id) {
      this.pickupCenterSelected = this.pickupCenter.find(x => x.id === id);
    } else {
      this.pickupCenterSelected = new GenericModel();
    }
    this.changePickupCenter({ itemSelectedValue: this.pickupCenterSelected });
  }

  setOcaCenter() {
    const id = this.addressFormService.getProperty('oca_center');
    if (id) {
      this.ocaCenterSelected = this.ocaCenter.find(x => x.id === id);
    } else {
      this.ocaCenterSelected = new GenericModel();
    }
    this.changeOcaCenter({ itemSelectedValue: this.ocaCenterSelected });
  }

  setTataCenter() {
    const id = this.addressFormService.getProperty('tata_center');
    if (id) {
      this.tataCenterSelected = this.tataCenter.find(x => x.id === id);
    } else {
      this.tataCenterSelected = new GenericModel();
    }
    this.changeTataCenter({ itemSelectedValue: this.tataCenterSelected });
  }

  changeDeliveryChoice(deliveryChoice: DeliveryChoiceModel): void {
    // tslint:disable-next-line: early-exit
    if (deliveryChoice !== this.oldSelect) {
      deliveryChoice.selected = true;
      this.addressFormService.setProperty('delivery_choice_id', deliveryChoice.id);
      this.setDeliveryChoice(deliveryChoice.id);
      if (this.oldSelect) {
        this.oldSelect.selected = !this.oldSelect.selected;
      }
      this.oldSelect = deliveryChoice;
    }
  }

  setDeliveryChoice(id: string): void {
    if (id === 'private_home_choice') {
      this.optionPersonalAddress = true;
      this.optionPickupCenter = this.isBtnDisabled = this.optionOtherAddress = this.optionOcaCenter = this.optionTataCenter = !this
        .optionPersonalAddress;
      this.addressFormService.setProperty('pickup_center', '');
      this.addressFormService.setProperty('oca_center', '');
      this.addressFormService.setProperty('tata_center', '');
      if (this.personalAddress.city === '') {
        this.setPersonalAddress();
      }
      this.addressFormService.setProperty('delivery_address', this.personalAddress);
    } else if (id === 'other_address_choice') {
      this.optionOtherAddress = this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionPickupCenter = this.optionOcaCenter = this.optionTataCenter = !this
        .optionOtherAddress;
      this.addressFormService.setProperty('pickup_center', '');
      this.addressFormService.setProperty('oca_center', '');
      this.addressFormService.setProperty('tata_center', '');
    } else if (id === 'oca_center_choice') {
      this.addressFormService.setProperty('pickup_center', '');
      this.addressFormService.setProperty('tata_center', '');
      this.optionOcaCenter = true;
      this.ocaCenterStateSelected =
        this.ocaCenterState && this.ocaCenterState.length > 0
          ? this.findMontevideoCenter(this.ocaCenterState)
          : new GenericModel();
      this.ocaCenterAvailable = this.filterCenterByStateAlphabetically(this.ocaCenterStateSelected?.id, this.ocaCenter);
      this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionOtherAddress = this.optionPickupCenter = this.optionTataCenter = !this
        .optionOcaCenter;
      this.setOcaCenter();
    } else if (id === 'tata_center_choice') {
      this.addressFormService.setProperty('pickup_center', '');
      this.addressFormService.setProperty('oca_center', '');
      this.optionTataCenter = true;
      this.tataCenterStateSelected =
        this.tataCenterState && this.tataCenterState.length > 0 ? this.tataCenterState[0] : new GenericModel();
      this.tataCenterAvailable = this.filterCenterByStateAlphabetically(this.tataCenterStateSelected?.id, this.tataCenter);
      this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionOtherAddress = this.optionPickupCenter = this.optionOcaCenter = !this
        .optionTataCenter;
      this.setTataCenter();
    } else {
      this.addressFormService.setProperty('oca_center', '');
      this.addressFormService.setProperty('tata_center', '');
      this.optionPickupCenter = true;
      this.pickupCenterStateSelected =
        this.pickupCenterState && this.pickupCenterState.length > 0
          ? this.findMontevideoCenter(this.pickupCenterState)
          : new GenericModel();
      this.pickupCenterAvailable = this.filterCenterByStateAlphabetically(this.pickupCenterStateSelected?.id, this.pickupCenter);
      this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionOtherAddress = this.optionOcaCenter = this.optionTataCenter = !this
        .optionPickupCenter;
      this.pickupCenterLabel = id === 'pickup_center_choice' ? 'Centros de entrega disponibles' : 'Sucursales disponibles';
      this.setPickupCenter();
    }
  }

  findMontevideoCenter(list) {
    const defaultCenter = list.find(elem => {
      return elem.label && elem.label.toUpperCase() === 'MONTEVIDEO';
    });
    if (!defaultCenter) {
      return list[0];
    }
    return defaultCenter;
  }

  getDeliveryChoiceModel() {
    if (this.cardSelected) {
      return [
        new DeliveryChoiceModel('private_home_choice', 'icon-itaufonts_residencia', false, 'Domicilio particular'),
        new DeliveryChoiceModel('oca_center_choice', 'oca-icon icon-itaufonts_oca', false, 'Sucursales OCA'),
      ];
    }
    return [
      new DeliveryChoiceModel('private_home_choice', 'icon-itaufonts_residencia', false, 'Domicilio particular'),
      new DeliveryChoiceModel('pickup_center_choice', 'icon-itaufonts_processos', false, 'Pickup center'),
      new DeliveryChoiceModel('other_address_choice', 'icon-itaufonts_agencia', false, 'Otra dirección'),
      new DeliveryChoiceModel('oca_center_choice', 'oca-icon icon-itaufonts_oca', false, 'Sucursales OCA'),
      new DeliveryChoiceModel('tata_center_choice', 'oca-icon icon-itaufonts_tata', false, 'Sucursales TaTa'),
    ];

  }

  enableButton(addressModel: AddressModel) {
    this.isBtnDisabled = !addressModel.is_valid;
    this.addressFormService.setProperty('delivery_address', addressModel);
    if (addressModel.is_key_enter) {
      this.continuarButton();
    }
  }

  changePickupCenter(event: any) {
    this.pickupCenterSelected = event.itemSelectedValue;
    if (event.itemSelectedValue?.value || event.itemSelectedValue?.id) {
      const availability = event.itemSelectedValue.availability;
      this.pickUpCenterStreet = availability.slice(0, availability.indexOf('('));
      this.pickUpAvailability = availability.slice(availability.indexOf('('), availability.length);
      this.isBtnDisabled = false;
    } else {
      this.pickUpCenterStreet = '';
      this.pickUpAvailability = '';
      this.isBtnDisabled = true;
    }
    this.addressFormService.setProperty('pickup_center', event.itemSelectedValue?.id);
  }

  changeOcaCenter(event: any) {
    this.ocaCenterSelected = event.itemSelectedValue;
    if (event.itemSelectedValue?.value || event.itemSelectedValue?.id) {
      const availability = event.itemSelectedValue.availability;
      this.ocaCenterStreet = availability.slice(0, availability.indexOf('('));
      this.ocaAvailability = availability.slice(availability.indexOf('('), availability.length);
      this.isBtnDisabled = false;
    } else {
      this.ocaCenterStreet = '';
      this.ocaAvailability = '';
      this.isBtnDisabled = true;
    }
    this.addressFormService.setProperty('oca_center', event.itemSelectedValue?.id);
  }

  changeTataCenter(event: any) {
    this.tataCenterSelected = event.itemSelectedValue;
    if (event.itemSelectedValue?.value || event.itemSelectedValue?.id) {
      const availability = event.itemSelectedValue.availability;
      this.tataCenterStreet = availability.slice(0, availability.indexOf('('));
      this.tataAvailability = availability.slice(availability.indexOf('('), availability.length);
      this.isBtnDisabled = false;
    } else {
      this.tataCenterStreet = '';
      this.tataAvailability = '';
      this.isBtnDisabled = true;
    }
    this.addressFormService.setProperty('tata_center', event.itemSelectedValue?.id);
  }

  continuarButton() {
    if (
      this.addressFormService.getProperty('pickup_center') ||
      this.addressFormService.getProperty('oca_center') ||
      this.addressFormService.getProperty('tata_center')
    ) {
      this.addressNavigationService.navigateTo(RoutesFinalDataEnum.CONFIRMATION);
    } else {
      this.addressNavigationService.sendToNext();
    }
  }

  changePickupCenterState(event: any) {
    this.pickupCenterStateSelected = event.itemSelectedValue;
    const filteredPickupCenters = this.filterCenterByStateAlphabetically(event.itemSelectedValue?.id, this.pickupCenter);
    this.pickupCenterAvailable = filteredPickupCenters;
    this.changePickupCenter({ itemSelectedValue: undefined });
  }

  changeOcaCenterState(event: any) {
    this.ocaCenterStateSelected = event.itemSelectedValue;
    const filteredOcaCenters = this.filterCenterByStateAlphabetically(event.itemSelectedValue?.id, this.ocaCenter);
    this.ocaCenterAvailable = filteredOcaCenters;
    this.changeOcaCenter({ itemSelectedValue: undefined });
  }

  changeTataCenterState(event: any) {
    this.tataCenterStateSelected = event.itemSelectedValue;
    const filteredTataCenters = this.filterCenterByStateAlphabetically(event.itemSelectedValue?.id, this.tataCenter);
    this.tataCenterAvailable = filteredTataCenters;
    this.changeTataCenter({ itemSelectedValue: undefined });
  }

  filterCenterByStateAlphabetically(state: string, center: any) {
    return center
      .filter(elem => !state || elem.state === state)
      .sort(this.addressCommonService.compareItemsAlphabetically);
  }

  getStateByCenterId(centerId: string, center: any, centerState: any) {
    const chosenCenter = center.find(x => x.id === centerId);
    return centerState.find(x => x.id === chosenCenter.state);
  }

  setAtual() {
    return 13;
  }
}
