<div *ngIf="!isFileSelected" class="file-upload">
  <label [for]="fileInputId">
    <img [src]="'assets/images/add_icone.svg'"/>
  </label>
  <input #fileInput [id]="fileInputId" type="file" [accept]="accepted_extensions" (change)="fileEvent($event)">
</div>

<div *ngIf="isFileSelected" class="file-selected">
  
  <button class="close-button" (click)="onCloseButtonClick()"></button>

  <label class="in-selected">
    <img class="check-icon" [src]="'assets/images/icone_upload_sucesso.svg'"/>
    <p class="selected-text">archivo<br/>recibido</p>
  </label>
</div>

<p class="title">{{ title }}</p>

<voxel-modal modalId="errorModal" [(showModal)]="showModal">
  <ng-template #modalContent>
    <h2 class="voxel-text-xl modal-title">Archivo no aceptado. Intentá nuevamente :(</h2>

    <p id="textLine1" class="modal-text">El archivo que ha enviado no fue aceptado.</p>
    <p id="textLine2" class="modal-text">
      Por favor, garantizá que no tenga más que {{ fileParameters[0].max_file_size / 1024 }}MB y que sea uno de los formatos
      disponibles:
    </p>
    <p id="textExtensions" class="modal-text">{{ allExtentions.join(', ') }}.</p>
  </ng-template>
  <ng-template #modalActions>
    <voxel-button class="voxel-mb-s" label="intentar nuevamente" (click)="toggleModal()"></voxel-button>
  </ng-template>
</voxel-modal>
