import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { SelfieRountingModule } from './selfie.routes';

import { SelfieNavigationService } from './shared/selfie-navigation.service';

import { SelfieGetReadyComponent } from './selfie-get-ready/selfie-get-ready.component';
import { FrontalStageComponent } from './frontal-stage/frontal-stage.component';
import { FrontalPhotoComponent } from './frontal-photo/frontal-photo.component';
import { InclinedStageComponent } from './inclined-stage/inclined-stage.component';
import { InclinedPhotoComponent } from './inclined-photo/inclined-photo.component';
import { SelfieErrorComponent } from './selfie-errors/selfie-error.component';
import { SelfieCommonService } from './shared/selfie-common.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SelfieRountingModule
  ],
  providers: [
    SelfieNavigationService,
    SelfieCommonService
  ],
  declarations: [
    SelfieGetReadyComponent,
    FrontalStageComponent,
    FrontalPhotoComponent,
    InclinedStageComponent,
    InclinedPhotoComponent,
    SelfieErrorComponent
  ]
})
export class SelfieModule {}
