export default function detectSupportsPassive() {
  let supportsPassive = false;

  try {
    const opts = Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true;
      }
    });

    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
    // tslint:disable-next-line: no-empty
  } catch (e) {}

  return supportsPassive;
}
