import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { DocumentPhotosRoutingModule } from './document-photos.routes';

import { DocumentPhotosNavigationService } from './shared/document-photos-navigation.service';

import { DocumentPhotosGetReadyComponent } from './document-photos-get-ready/document-photos-get-ready.component';
import { DocumentFrontStageComponent } from './document-front-stage/document-front-stage.component';
import { DocumentFrontPhotoComponent } from './document-front-photo/document-front-photo.component';
import { DocumentBackStageComponent } from './document-back-stage/document-back-stage.component';
import { DocumentBackPhotoComponent } from './document-back-photo/document-back-photo.component';
import { DocumentPhotosErrorComponent } from './document-photos-errors/document-photos-error.component';
import { DocumentPhotosCommonService } from './shared/document-photos-common.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DocumentPhotosRoutingModule
  ],
  providers: [
    DocumentPhotosNavigationService,
    DocumentPhotosCommonService
  ],
  declarations: [
    DocumentPhotosGetReadyComponent,
    DocumentFrontStageComponent,
    DocumentFrontPhotoComponent,
    DocumentBackStageComponent,
    DocumentBackPhotoComponent,
    DocumentPhotosErrorComponent
  ]
})
export class DocumentPhotosModule {}
