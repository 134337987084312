import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OffersFullCommonService } from 'src/app/offers-full/shared/offers-common.service';
import { OffersFullFormService } from 'src/app/offers-full/shared/offers-form.service';
import { RoutesOffersEnum } from 'src/app/offers/shared/enums/routes-offers-enum';
import { RoutesPldEnum } from 'src/app/pld/shared/enums/routes-pld-enum';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';
import { CommonNavigationService } from './../../shared/services/common-navigation.service';
import { RoutesDocumentPhotosEnum } from './enums/routes-document-photos-enum';



@Injectable()
export class DocumentPhotosNavigationService extends CommonNavigationService {

  constructor(
    route: Router,
    buttonSideBySideHelperService: ButtonSideBySideHelperService,
    private offerCommonService: OffersFullCommonService,
    private formService: OffersFullFormService
  ) {
    super(route, buttonSideBySideHelperService);

    this.step = RoutesDocumentPhotosEnum.GET_READY;
    this.stepAmount = 1;
    this.flows = [
      {
        stage: RoutesDocumentPhotosEnum.GET_READY,
        previous: this.previousGetReady,
        next: RoutesDocumentPhotosEnum.DOCUMENT_FRONT_STAGE
      },
      {
        stage: RoutesDocumentPhotosEnum.DOCUMENT_FRONT_STAGE,
        previous: RoutesDocumentPhotosEnum.GET_READY,
        next: RoutesDocumentPhotosEnum.DOCUMENT_FRONT_PHOTO
      },
      {
        stage: RoutesDocumentPhotosEnum.DOCUMENT_FRONT_PHOTO,
        previous: RoutesDocumentPhotosEnum.DOCUMENT_FRONT_STAGE,
        next: RoutesDocumentPhotosEnum.DOCUMENT_BACK_STAGE
      },
      {
        stage: RoutesDocumentPhotosEnum.DOCUMENT_BACK_STAGE,
        previous: null,
        next: RoutesDocumentPhotosEnum.DOCUMENT_BACK_PHOTO
      },
      {
        stage: RoutesDocumentPhotosEnum.DOCUMENT_BACK_PHOTO,
        previous: RoutesDocumentPhotosEnum.DOCUMENT_BACK_STAGE,
        next: RoutesPldEnum.PLD_HOME
      },
    ];
  }

  previousGetReady() {
    this.offerCommonService.restoreOfferResponse();
    if (this.formService.getSelectedPackage().card_offers && this.formService.getSelectedPackage().card_offers.length > 0) {
      if (this.formService.getForm().accepted_credit_card) {
        return RoutesOffersEnum.CLOSINGDATE;

      }
      return RoutesOffersEnum.CREDITCARD;

    }
    return RoutesOffersEnum.PLANS;

  }
}
