import { NgModule } from '@angular/core';
import { AddressFullRountingModule } from './address.routes';
import { ProgressBarService } from '../progressBar.service';
import { AddressHomeFullComponent } from './address-home/address-home.component';
import { AddressFullNavigationService } from './shared/address-navigation.service';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { AddressFullCommonService } from './shared/address-common.service';
import { AddressFullFormService } from './shared/address-form.service';
import { AddressFullFormComponent } from './shared/address-form/address-form.component';
import { AddressProofFullComponent } from './address-proof/address-proof.component';
import { DeliveryAddressFullComponent } from './delivery-address/delivery-address.component';
import { FormsModule } from '@angular/forms';
import { AddressFacebookProofFullComponent } from './address-facebook-proof/address-facebook-proof.component';

@NgModule({
  imports: [
    AddressFullRountingModule,
    SharedModule,
    CommonModule,
    FormsModule
  ],
  providers: [
    ProgressBarService,
    AddressFullNavigationService,
    AddressFullCommonService,
    AddressFullFormService
  ],
  declarations: [
    AddressHomeFullComponent,
    AddressFullFormComponent,
    AddressProofFullComponent,
    DeliveryAddressFullComponent,
    AddressFacebookProofFullComponent,
  ]
})

export class AddressFullModule {}
