import { Injectable } from '@angular/core';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

import { ServiceCommon } from '../../shared/service.common';
import { ModuleLists } from '../../shared/services/admin-lists/const/module-lists.constant';
import { ListConversionService } from '../../shared/services/admin-lists/list-conversion-service/list-conversion.service';
import { ProspectMethods } from '../../shared/services/domains/prospect/prospect-enum';
import { ProspectService } from '../../shared/services/domains/prospect/prospect.service';
import { LoginService } from '../../shared/services/login.service';
import { CurrencyValueModel } from './models/currency-value';
import { PldModel } from './models/pld-model';
import { PldNavigationService } from './pld-navigation.service';

@Injectable()
export class PldCommonService extends ServiceCommon {
  constructor(
    public pldNavigation: PldNavigationService,
    private prospectService: ProspectService,
    private latamItLoaderService: LatamItLoaderService,
    public listConversionService: ListConversionService,
    loginService: LoginService,
  ) {
    super(listConversionService, loginService);

    this.onInit();
  }

  onInit() {
    this.setModuleLists(ModuleLists.pld);
  }

  postPld(pldModel: PldModel) {
    this.latamItLoaderService.show();
    const body = this.buildPldRequest(pldModel);
    body.prospect_id = this.prospectService.getProspectId();
    this.prospectService.prospectRequest(body, ProspectMethods.PATCH_PROSPECT_PLD, false, false).subscribe(
      res => {
        console.log('POST PLDModel successful', res);
        if (res.statusCode === 200) {
          this.pldNavigation.sendToNext();
        }
        this.latamItLoaderService.hide();
      },
      err => {
        console.log('POST PLDModel error', err);
        this.latamItLoaderService.hide();
      },
    );
  }

  buildPldRequest(pldModel: PldModel) {
    const body: any = {};

    // Account porpuse
    if (pldModel.account_purpose) {
      body.account_purpose = [
        {
          id: pldModel.account_purpose[0].id,
        },
      ];
      if (body.account_purpose[0].id === 'otro motivo') {
        body.account_purpose[0].specifics = pldModel.account_purpose[0].specifics;
      }
    }

    // Currencies

    if (pldModel.avg_balance !== null) {
      body.avg_balance = this.toCurrency(pldModel.avg_balance, 'UYU');
    }
    if (pldModel.amount_quantity_per_month !== null) {
      body.amount_quantity_per_month = this.toCurrency(pldModel.amount_quantity_per_month, 'UYU');
    }
    if (pldModel.last_year_incomes !== null) {
      body.last_year_incomes = pldModel.last_year_incomes;
    }
    if (pldModel.amount_quantity_per_month_transfers !== null) {
      body.amount_quantity_per_month_transfers = this.toCurrency(pldModel.amount_quantity_per_month_transfers, 'UYU');
    }
    if (pldModel.amount_quantity_per_month_checks !== null) {
      body.amount_quantity_per_month_checks = this.toCurrency(pldModel.amount_quantity_per_month_checks, 'UYU');
    }
    if (pldModel.amount_quantity_per_month_cash !== null) {
      body.amount_quantity_per_month_cash = this.toCurrency(pldModel.amount_quantity_per_month_cash, 'UYU');
    }

    // Others
    body.taxpayer_type = pldModel.taxpayer_type;
    body.customer_type = pldModel.customer_type;
    body.txn_count_per_month = pldModel.txn_count_per_month;

    // Fiscal Residence
    body.has_other_fiscal_residence = false;
    body.fiscal_residence_attributes = [];

    return body;
  }

  toCurrency(object: CurrencyValueModel, currency) {
    object.decimals = 0;
    object.currency = currency;
    return object;
  }
}
