import { AdminListEnum } from '../enum/admin-list.enum';
import { ConversionTypeEnum } from '../enum/conversion-type.enum';

export const AdminListConversionType = {
  [AdminListEnum.PRINCIPAL_ACTIVITY]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.MARITAL_STATUS]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.MONTHLY_INCOME]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.ACCOUNT_PURPOSE]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.AMOUNT_QUANTITY_PER_MONTH]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.TXN_COUNT_PER_MONTH]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.AVG_BALANCE]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.COUNTRY]: ConversionTypeEnum.VOXEL_SELECT,
  [AdminListEnum.ICONS]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.AFFINITY]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.CARD_CLASS]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.CARD_BRAND]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.ACCOUNT_TYPE]: ConversionTypeEnum.NONE,
  [AdminListEnum.CURRENCY_DESCRIPTION]: ConversionTypeEnum.NONE,
  [AdminListEnum.CARD_TYPE]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.STATE_CITY]: ConversionTypeEnum.NONE,
  [AdminListEnum.PICKUP_CENTER]: ConversionTypeEnum.PICKUP_CENTER_VOXEL_SELECT,
  [AdminListEnum.FILE_UPLOAD]: ConversionTypeEnum.NONE,
  [AdminListEnum.DELIVERY_AVAILABILITY]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.BRANCH_ID]: ConversionTypeEnum.VOXEL_SELECT,
  [AdminListEnum.HOME_OFFERS]: ConversionTypeEnum.NONE,
  [AdminListEnum.CARD_INFO]: ConversionTypeEnum.NONE,
  [AdminListEnum.LIST_URLS]: ConversionTypeEnum.NONE,
  [AdminListEnum.PRINCIPAL_ACTIVITY_PJ]: ConversionTypeEnum.VOXEL_SELECT,
  [AdminListEnum.ACCOUNT_PURPOSE_PJ]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.AVG_BALANCE_PJ]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.AMOUNT_QUANTITY_PER_MONTH_PJ]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.AMOUNT_QUANTITY_PER_MONTH_CHECKS_PJ]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.AMOUNT_QUANTITY_PER_MONTH_TRANSFERS_PJ]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.TAXATION_TYPE_PJ]: ConversionTypeEnum.VOXEL_RADIO,
  [AdminListEnum.OCA_CENTER]: ConversionTypeEnum.PICKUP_CENTER_VOXEL_SELECT,
  [AdminListEnum.TATA_CENTER]: ConversionTypeEnum.PICKUP_CENTER_VOXEL_SELECT,
  [AdminListEnum.ACCOUNT_BENEFITS]: ConversionTypeEnum.NONE,
  [AdminListEnum.ACCOUNT_COMMISSIONS]: ConversionTypeEnum.NONE
};

