import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Analytics } from '../../shared/analytics';
import { GetReadyModelFull } from '../models/get-ready-model';
import { GetReadyCommonFullService } from '../shared/get-ready-common.service';
import { GetReadyFormFullService } from '../shared/get-ready-form.service';
import { GetReadyNavigationFullService } from '../shared/get-ready-navigation.service';

@Component({
  selector: 'document-no-chip',
  templateUrl: './document-no-chip.component.html',
  styleUrls: ['./document-no-chip.component.scss'],
})
export class DocumentNoChipFullComponent implements OnInit {
  @ViewChild('pageNumberInput') pageNumberInput: ElementRef;

  form = new FormGroup({
    docSerie: new FormControl(),
    pageNumber: new FormControl(),
  });

  pageId = 'document-no-chip';
  isBtnDisabled = false;
  modalShow = false;

  docSerie: string;
  pageNumber: number;
  getReadyForm: GetReadyModelFull;
  serieCustomErrorMessage = '';

  constructor(
    public getReadyNavigationService: GetReadyNavigationFullService,
    private getReadyFormService: GetReadyFormFullService,
    private analyticsLib: Analytics,
    private commonService: GetReadyCommonFullService) {}

  ngOnInit() {

    this.getReadyForm = this.getReadyFormService.getFormGetReady();
    this.docSerie = this.getReadyForm.identity_documents[0].series;
    this.form.controls['docSerie'].setValue(this.docSerie || '');
    if (this.getReadyForm.identity_documents[0].folder > 0) {
      this.pageNumber = this.getReadyForm.identity_documents[0].folder;
      this.form.controls['pageNumber'].setValue(this.pageNumber || '');
    }
    this.refreshButton();
    this.checkOpenModal();
    this.analyticsLib.buildData('DatosCedulaNoChip', this.pageId, '4', false);
    this.analyticsLib.trackState(null, null);
    this.getReadyFormService.setPreviousStage('DocumentNoChip');
    this.commonService.scrollToTop(document);
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.doNext();
    }
  }

  refreshButton() {
    this.isBtnDisabled = !(this.docSerie && this.docSerie.length === 1
      && this.pageNumber && this.pageNumber.toString().length === 6);
  }


  changeSerie(serieNumber: string) {
    this.serieCustomErrorMessage = '';
    const onlyLetter = /^[a-zA-Z]*$/;
    if (onlyLetter.test(serieNumber)) {
      this.docSerie = serieNumber;
    } else {
      this.serieCustomErrorMessage = 'ingresá una letra';
      this.form.controls['docSerie'].setErrors({ incorrect: true });
      this.docSerie = '';
    }
    this.refreshButton();
  }

  changeNumber(number) {
    this.pageNumber = number;
    this.refreshButton();
  }

  doNext() {
    this.getReadyForm.identity_documents[0].series = this.docSerie;
    this.getReadyForm.identity_documents[0].folder = this.pageNumber;
    this.getReadyNavigationService.sendToNext();
  }

  maskAsRead() {
    this.getReadyNavigationService.cleanErrors();
    this.toggleModal();
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }

  checkOpenModal() {
    if (this.getReadyNavigationService.hasErrors()) {
      this.toggleModal();
    }
  }

  setAtual() {
    let atual = 3;
    if (this.getReadyFormService.getProperty('solicitation_type') === 'aperturadecuentaspj') {
      atual = 6;
    }
    return atual;
  }

  newInputChange(input: string) {
    if (input === 'docSerie') {
      this.getReadyForm.identity_documents[0].series = this.form.controls['docSerie'].value;
      this.changeSerie(this.form.controls[input].value);
    } else if (input === 'pageNumber') {
      this.getReadyForm.identity_documents[0].folder = this.form.controls['pageNumber'].value;
      this.changeNumber(this.form.controls[input].value);
    }
  }

  onPageNumberBlur() {
    if (!this.pageNumber || this.pageNumber.toString().length < 6) {
      this.form.controls['pageNumber'].setErrors({ incorrect: true });
    }
  }
}
