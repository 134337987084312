export class GenericModel {
  id: string;
  label: string;
  value: string;
  itemSelectedValue: any;

  constructor() {
    this.id = '';
    this.label = '';
    this.value = '';
    this.itemSelectedValue = { value: null };
  }
}
