export class OfferBenefitFull {
  id: string;
  title: string;
  benefit_description: string;
  category_id: string;
  type: string;

  constructor() {
    this.id = '';
    this.title = '';
    this.benefit_description = '';
    this.category_id = '';
    this.type = '';
  }
}
