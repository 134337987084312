export class DeviceInformation {
  browser_name: string;
  browser_version: string;
  operation_system: string;

  constructor() {
    this.browser_name = '';
    this.browser_version = '';
    this.operation_system = '';
  }
}
