import { Injectable } from '@angular/core';
import { OffersMethods } from './offers-enum';
import { AppService } from 'src/app/app.service';

@Injectable()
export class OffersService {

  constructor(private appService: AppService) {}

  offerRequest(requestBody: any, method: OffersMethods) {
    return this.appService.doPost(requestBody, method);
  }

}
