/**
 *
 * Analytics Lib (GA) for Angular CLI v4
 * @version 1.0.0 (2019-08-29)
 *
 * @author Diego Riccio (Accenture Interactive)
 * @author Lucas Domingues (Accenture Interactive)
 *
 */
import { Injectable } from '@angular/core';
import { ClientFormService } from 'src/app/client/shared/client-form.service';

import { LoginService } from './services/login.service';

@Injectable()
export class Analytics {
  remoteConfig: any;
  enabled = true;
  debug = false;
  expose = false;
  gtmContainerId: string;
  autoTrackState = false;
  adobeDataLayerName = 'analyticsData';
  debugColor = 'font-weight: bold; color: red';
  environment: string;
  channel: string;

  listClassification = [
    'site.platformdetails',
    'site.applicationdetails',
    'visitor.customerdetails',
    'visitor.elegibility',
    'transaction.details',
    'custom.logintype',
    'custom.surveyscore',
    'custom.whatevervariable'
  ];

  adobeToGoole = {
    'site.vrsid': 'site.channel',
    'site.assessibility': 'site.accessibility',
    'site.assessibilitystatus': 'site.accessibility',
    'site.activatedpermissions': 'site.permissions',
    'site.platform': 'site.developmenttype',
    'site.platformdetails.AM': 'site.environment',
    'site.platformdetails.VA': 'site.environment',
    'site.platformdetails.UA': 'site.useragent',
    'site.applicationdetails.AG': 'visitor.agencystoreid',
    'visitor.customerid': 'visitor.client.idpf',
    'visitor.companyid': 'visitor.client.idpjcompany',
    'visitor.companyoperator': 'visitor.client.idpjoperator',
    'visitor.customerdetails.TP': 'visitor.client.accounttype',
    'visitor.customerdetails.AG': 'visitor.client.agency',
    'visitor.customersegment': 'visitor.client.commercialsegment',
    'visitor.customerdetails.FF': 'visitor.client.declaredincomerange',
    'visitor.customerdetails.LM': 'visitor.client.limitusage',
    'visitor.customerdetails.PPC': 'visitor.client.loannearduedate',
    'visitor.customerdetails.RA': 'visitor.client.occupation',
    'visitor.customerdetails.PERF': 'visitor.client.profile',
    'visitor.customerdetails.PP': 'visitor.client.rewardsprogram',
    'visitor.customerdetails.SCR': 'visitor.client.score',
    'visitor.customerdetails.EST': 'visitor.client.stars',
    'visitor.customerdetails.CTT': 'visitor.client.titularitycode',
    'visitor.customerdetails.SE': 'visitor.client.type',
    'visitor.elegibility.PA': 'visitor.client.elegibility',
    'visitor.storeid': 'visitor.agencystoreid',
    'transaction.details.CTB': 'transaction.details.bonusinstallments',
    'transaction.details.VR': 'transaction.details.cardcategory',
    'transaction.details.LMT': 'transaction.details.cardlimitrange',
    'transaction.details.BD': 'transaction.details.cardnetwork',
    'transaction.details.PT': 'transaction.details.cardpartner',
    'transaction.details.DCP': 'transaction.details.cardpurchaselimitrange',
    'transaction.details.DEF': 'transaction.details.cardwithdrawallimitrange',
    'transaction.details.CAN': 'transaction.details.chosenchannel',
    'transaction.details.PTS': 'transaction.details.clientrewardsvaluerange',
    'transaction.details.CUR': 'transaction.details.currency',
    'transaction.details.ACR': 'transaction.details.daystoinvoiceclosure',
    'transaction.details.TCD': 'transaction.details.destinationaccounttype',
    'transaction.details.BCD': 'transaction.details.destinationbank',
    'transaction.details.PLZ': 'transaction.details.installmentsterm',
    'transaction.details.CT': 'transaction.details.installmentsterm',
    'transaction.details.LMP': 'transaction.details.loanlimitrange',
    'transaction.details.PAO': 'transaction.details.offeredlimitincreaserange',
    'transaction.details.TCO': 'transaction.details.originaccounttype',
    'transaction.details.MDP': 'transaction.details.paymentmethod',
    'transaction.details.CAT': 'transaction.details.productcategory',
    'transaction.details.DP': 'transaction.details.productdetails',
    'transaction.details.PAS': 'transaction.details.requestedlimitincreaserange',
    'transaction.details.TS': 'transaction.details.tax',
    'transaction.details.INST': 'transaction.details.transactioninstitution',
    'transaction.details.STS': 'transaction.details.transactionstatus',
    'transaction.details.PVJ': 'transaction.details.travelnoticedestinationcountry',
    'transaction.details.FVI': 'transaction.details.travelnoticeinbounddate',
    'transaction.details.FVR': 'transaction.details.travelnoticeoutbounddate',
    'custom.partnerid': 'custom.confirmationid',
    'custom.internalid': 'custom.leadid',
    'custom.logintype.TA': 'custom.login.authenticationtype',
    'custom.logintype.TL': 'custom.login.type',
    'custom.surveyscore.RE': 'custom.survey.results',
    'custom.surveyscore.TG': 'custom.survey.tag',
    'custom.surveyscore.TP': 'custom.survey.type',
    'custom.whatevervariable.MSG': 'custom.message'
  };

  constructor(public loginService: LoginService, private clientFormService: ClientFormService) {
    this.init();
  }

  init() {
    const self = this;

    self.remoteConfig = self.loginService.analyticsInfo;

    if (self.remoteConfig) {
      self.enabled = self.remoteConfig.enabled;
      self.debug = self.remoteConfig.console_log;
      self.expose = self.remoteConfig.expose;
      self.gtmContainerId = self.remoteConfig.gtmcontainerid;
      self.adobeDataLayerName = self.remoteConfig.adobe_data_layer_name;
      self.autoTrackState = self.remoteConfig.auto_trackstate;
      self.environment = self.remoteConfig.environment;
      self.channel = self.remoteConfig.channel;
    }

    if (!self.enabled) {
      return;
    }

    try {
      if (self.gtmContainerId) {
        // Google Tag Manager
        ((w, d, s, l, i) => {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          const f = d.getElementsByTagName(s)[0];
          const j = d.createElement(s);
          const dl = l !== 'dataLayer' ? '&l=' + l : '';
          j['async'] = true;
          j['src'] = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          if (f.parentNode) {
            f.parentNode.insertBefore(j, f);
          }
        })(window, document, 'script', 'dataLayer', self.gtmContainerId);
      }
      if (self.debug && !self.gtmContainerId) {
        console.log('Container do GTM não informado!');
      }

      // DataLayer padrão Adobe
      window[self.adobeDataLayerName] = window[self.adobeDataLayerName] || {};

      // DataLayer padrão Google
      window['dataLayer'] = window['dataLayer'] || [];

      // Disparo do pageView
      if (self.autoTrackState) {
        self.trackState();
      }

      if (self.debug) {
        console.log('%cAnalytics - Constructor: ', self.debugColor, 'DataLayer built!');
      }
      if (self.expose) {
        window['analyticsLib'] = self;
      } else {
        window['analyticsLib'] = () => {
          // ignore
        };
      }

      // Precisa sempre star exposto no Window para funcionamento da variáveis no GTM
      window['analyticsLib'].adobeDataLayerName = self.adobeDataLayerName;
    } catch (e) {
      if (self.debug) {
        console.log('%cAnalytics - init - Error', self.debugColor, e);
      }
    }
  }

  pageStart() {
    const self = this;
    if (!self.enabled) {
      return;
    }

    try {
      const selfTry = this;
      selfTry.setData('page.loadStart', new Date().getTime());
      selfTry.setData('custom', {});
      if (selfTry.debug) {
        console.log('%cAnalytics - pageStart: ', selfTry.debugColor, window[selfTry.adobeDataLayerName].page.loadStart);
      }
    } catch (e) {
      if (self.debug) {
        console.log('%cAnalytics - pageStart - Error', self.debugColor, e);
      }
    }
  }

  trackState(pageName?: string, custom?: object) {
    const self = this;

    if (!self.enabled) {
      return;
    }

    try {
      if (pageName) {
        self.setData('page.name', pageName);
      }
      if (custom) {
        self.setData('custom', custom);
      }
      if (!window[self.adobeDataLayerName].page.url) {
        self.setData('page.url', document.location.href);
      }
      self.setData('rule', 'pageLoad');
      self.setData('page.loadEnd', new Date().getTime());
      self.setData(
        'page.loadTime',
        window[self.adobeDataLayerName].page.loadEnd - window[self.adobeDataLayerName].page.loadStart
      );
      self.dataLayerParser();

      if (self.debug) {
        console.log('%cAnalytics - trackState - pageName: ', self.debugColor, window[self.adobeDataLayerName].page.name);
        console.log('%cAnalytics - trackState - pageUrl: ', self.debugColor, window[self.adobeDataLayerName].page.url);
        console.log('%cAnalytics - trackState - custom: ', self.debugColor, window[self.adobeDataLayerName].custom);
        console.log('%cAnalytics - trackState - loadEnd: ', self.debugColor, window[self.adobeDataLayerName].page.loadEnd);
        console.log('%cAnalytics - trackState - loadTime: ', self.debugColor, window[self.adobeDataLayerName].page.loadTime);
      }

      // Reset Data Layer
      if (window['google_tag_manager']) {
        window['google_tag_manager'][self.gtmContainerId].dataLayer.reset();
      }

      // Trigger do GTM
      window['dataLayer'].push({
        event: 'async_content_load',
        analyticsData: JSON.parse(JSON.stringify(window[self.adobeDataLayerName]))
      });

      // Clean not Required dataLayer Variables
      self.setData('transaction', {});
      self.setData('custom', {});
      self.setData('ecommerce', {});
    } catch (e) {
      if (self.debug) {
        console.log('%cAnalytics - trackState - Error', self.debugColor, e);
      }
    }
  }

  trackAction(custom?: object) {
    const self = this;

    if (!self.enabled) {
      return;
    }

    try {
      if (custom) {
        self.setData('custom', custom);
      }
      self.setData('rule', 'customLink');
      if (self.debug) {
        console.log('%cAnalytics - trackAction: ', self.debugColor, window[self.adobeDataLayerName].custom);
      }

      // Fallback para os Eventos
      const componentclicked =
        window[self.adobeDataLayerName].custom.componentclicked ||
        window[self.adobeDataLayerName].custom.ComponentClicked ||
        window[self.adobeDataLayerName].custom.actioninfo;

      if (componentclicked) {
        self.setData(
          'custom.customCategory',
          componentclicked.match(/(MO:+)([^|]*)/)[2] +
            '|' +
            componentclicked.match(/(TP:+)([^|]*)/)[2] +
            '|' +
            componentclicked.match(/(PO:+)([^|]*)/)[2]
        );
        self.setData('custom.customAction', componentclicked.match(/(AC:+)([^|]*)/)[2]);
        self.setData('custom.customLabel', componentclicked.match(/(ID:+)([^|]*)/)[2]);
      }

      // Define se não é um hit de interação
      if (custom && !custom.hasOwnProperty('noninteractionhit') && self.isNonInteractionHit(custom['customAction'])) {
        self.setData('custom.events.noninteractionhit', 1);
      }

      // Reset Data Layer
      if (window['google_tag_manager']) {
        window['google_tag_manager'][self.gtmContainerId].dataLayer.reset();
      }

      // Trigger do GTM
      window['dataLayer'].push({
        event: 'async_content_load',
        analyticsData: JSON.parse(JSON.stringify(window[self.adobeDataLayerName]))
      });

      // Clean not Required dataLayer Variables
      self.setData('transaction', {});
      self.setData('custom', {});
      self.setData('ecommerce', {});
    } catch (e) {
      if (self.debug) {
        console.log('%cAnalytics - trackAction - Error', self.debugColor, e);
      }
    }
  }

  /* ============================================================================= */
  /*** Funções auxiliares
  /* ============================================================================= */

  isNonInteractionHit(action: string) {
    /**
     * Identifica se o Evento deve ser considerado interação ou não
     * @param action: Ação do evento
     */

    const self = this;

    try {
      // Lista de ações que não são consideredados por padrão interação
      const listNonInteractionHit = ['Show'];

      return listNonInteractionHit.indexOf(action) !== -1 ? true : false;
    } catch (e) {
      if (self.debug) {
        console.log('%cAnalytics - trackAction - Error', self.debugColor, e);
      }
    }
  }

  getTimeStamp() {
    /**
     * Gera um timeStamp no formado ISO string
     */

    const self = this;

    try {
      const now = new Date();
      const tzo = -now.getTimezoneOffset();
      const dif = tzo >= 0 ? '+' : '-';

      const pad = num => {
        const norm = Math.abs(Math.floor(num));
        return (norm < 10 ? '0' : '') + norm;
      };

      return (
        now.getFullYear() +
        '-' +
        pad(now.getMonth() + 1) +
        '-' +
        pad(now.getDate()) +
        'T' +
        pad(now.getHours()) +
        ':' +
        pad(now.getMinutes()) +
        ':' +
        pad(now.getSeconds()) +
        '.' +
        pad(now.getMilliseconds()) +
        dif +
        pad(tzo / 60) +
        ':' +
        pad(tzo % 60)
      );
    } catch (e) {
      if (self.debug) {
        console.log('%cAnalytics - getTimeStamp - Error', self.debugColor, e);
      }
    }
  }

  hasProp(obj: object, _propPath: any, _i?: number) {
    let propPath = _propPath;
    let i = _i;
    /**
     * Verifica se uma propriedade ou propriedades filhas existem
     */

    const self = this;

    try {
      // tslint:disable-next-line: no-conditional-assignment
      if (typeof i === 'undefined' && !(i = 0)) {
        propPath = propPath.split('.');
      }
      if (typeof obj[propPath[i]] !== 'undefined') {
        return ++i && i !== propPath.length ? this.hasProp(obj[propPath[i - 1]], propPath, i) : true;
      }
      return false;
    } catch (e) {
      if (self.debug) {
        console.log('%cAnalytics - hasProp - Error', self.debugColor, e);
      }
    }
  }

  dataLayerParser() {
    /**
     * Realiza todas conversões para deixar as implementações Adobe no formato Google configurado no GTM
     * e faz o push no dataLayer
     */

    const self = this;

    try {
      // Transforma o array dos eventos Adobe (chave 'events') em parâmetros de um objeto (chave: valor)
      try {
        const arrayEvents = window[self.adobeDataLayerName].custom.events;

        if (arrayEvents && arrayEvents.length > 0) {
          const objEvents: object = {};

          arrayEvents.forEach((e: string) => {
            objEvents[e.split('=')[0]] = e.split('=')[1] || '1';
          });

          self.setData('custom.events', objEvents);
        }
      } catch (e) {
        if (self.debug) {
          console.log('Ocorreu um erro na conversão dos Eventos: ' + e);
        }
      }

      // Criar novos parâmetros no dataLayer a partir dos classifications
      let classificationValue: string;
      let classPairArr: string[];

      try {
        self.listClassification.forEach(classification => {
          try {
            // tslint:disable-next-line:no-eval
            classificationValue = eval(self.adobeDataLayerName + '.' + classification).replace(/(^\|)|(\|$)/g, '');
            // tslint:disable-next-line: no-empty
          } catch (e) {}

          if (classificationValue) {
            self.setData(classification, {});

            classificationValue.split('|').forEach((classPairStr: string) => {
              classPairArr = classPairStr.split(':');
              // tslint:disable-next-line: no-unused-expression
              self.adobeToGoole[classification + '.' + classPairArr[0]] &&
                self.setData(self.adobeToGoole[classification + '.' + classPairArr[0]], classPairArr[1]);
            });
          }
          classificationValue = undefined;
        });
      } catch (e) {
        if (self.debug) {
          console.log('Ocorreu um erro ao converter as dimensões antigas com classifications: ' + e);
        }
      }

      for (const prop in self.adobeToGoole) {
        // tslint:disable-next-line: early-exit
        if (self.adobeToGoole) {
          try {
            if (self.adobeToGoole.hasOwnProperty(prop)) {
              // tslint:disable-next-line:no-eval tslint:disable-next-line: no-unused-expression
              eval(self.adobeDataLayerName + '.' + prop) &&
                // tslint:disable-next-line:no-eval
                self.setData(self.adobeToGoole[prop], eval(self.adobeDataLayerName + '.' + prop));
            }
          } catch (e) {
            // Não ativar LOG, pois sempre vai ter um 'erro' na função eval
            // console.log(e)
          }
        }
      }

      // Fallback para antigo classification Survey Response (Feedback/Avaliação)
      if (self.hasProp(window[self.adobeDataLayerName], 'custom.surveyresponse')) {
        try {
          self.setData(
            'custom.survey.feedbackname',
            window[self.adobeDataLayerName].custom.surveyresponse.match(/(NM:+)([^|]*)/)[2]
          );
          self.setData(
            'custom.survey.questionsandanswers',
            window[self.adobeDataLayerName].custom.surveyresponse.match(/(Q([^;]*):+)([^|]*)/)[0]
          );
        } catch (e) {
          if (self.debug) {
            console.log('Ocorreu um erro na conversão de Survey Response: ' + e);
          }
        }
      }

      // Fallback para Google Hit Timestamp
      // tslint:disable-next-line: no-unused-expression
      self.hasProp(window[self.adobeDataLayerName], 'custom.hittimestamp') ||
        self.setData('custom.hittimestamp', self.getTimeStamp());

      // Fallback para GTM Container ID
      // tslint:disable-next-line: no-unused-expression
      self.hasProp(window[self.adobeDataLayerName], 'site.gtmcontainerid') ||
        self.setData('site.gtmcontainerid', self.gtmContainerId);

      // Fallback para Google Session ID
      // Ou usar outra lógica para geração do identificador
      // tslint:disable-next-line: no-unused-expression
      self.hasProp(window[self.adobeDataLayerName], 'visitor.sessionid') ||
        self.setData('visitor.sessionid', Date.now() + Math.round(Math.random() * 100000000));

      // Fallback para Google ID (Client ID)
      // Custom Task via GTM ???

      // Video Tracking vai ficar na LIB ou no GTM ?
    } catch (e) {
      if (self.debug) {
        console.log('%cAnalytics - dataLayerParser - Error', self.debugColor, e);
      }
    }
  }

  buildData(page: string, pageId: string, transactionstep: string, client: boolean) {
    let data;
    let transactionstepfinal;
    const environment = this.environment;
    const channel = this.channel;

    // pageName
    const auxPageName = client ? 'LG' : 'NL';
    const pageName = 'AC:OCA:PF:' + auxPageName + ':AreaComun:Registro:Agregar:' + page;

    data = {
      site: {
        developmenttype: 'Web',
        environment,
        useragent: navigator.userAgent,
        permissions: '',
        accessibility: '',
        channel
      },
      visitor: {
        login: {
          type: '',
          authenticationtype: ''
        },
        geolocation: ''
      },
      page: {
        name: pageName,
        family: 'AreaComun',
        subfamily: 'Registro',
        action: 'Agregar',
        pageid: pageId
      },
      custom: {
        events: {},
        internaltracking: '',
        errorname: ''
      },
      toolkit: {
        versao: '1.6.3',
        data: client ? '02/07/2020' : '28/10/2020'
      },
      transaction: {
        name: client ? 'AbreCuentasClientesApp' : 'AbreCuentasFull'
      },
      rule: 'pageLoad'
    };

    if (client) {
      const clientId = this.clientFormService.getProperty('client_id');
      data.visitor.client = {
        idpf: clientId,
        accounttype: '',
        agency: '',
        commercialsegment: '',
        declaredincomerange: '',
        limitusage: '',
        loannearduedate: '',
        occupation: '',
        profile: '',
        rewardsprogram: 'Metraje',
        score: '',
        stars: '',
        titularitycode: '',
        type: 'PF',
        clientrewardsvaluerange: '',
        contracteddigitalproducts: ''
      };
    }

    if (transactionstep) {
      transactionstepfinal = 'transactionstep' + transactionstep;
      data.custom.events.transactionstepfinal = '1';
      if (transactionstep === 'Complete') {
        data.transaction.details = {
          transactionstep,
          transactionstatus: transactionstep
        };
      } else if (transactionstep === 'Checkout') {
        data.transaction.details = {
          transactionstep
        };
      } else if (client && transactionstep === '02') {
        data.transaction.details = {
          transactionstep,
          productdetails: this.clientFormService.getProperty('phone') + ' ' + this.clientFormService.getProperty('email')
        };
      } else {
        data.transaction.details = {
          transactionstep: transactionstep[0] === '0' ? transactionstepfinal[1] : transactionstepfinal
        };
      }
    }

    this.setData('analyticsData', data);
  }

  setData(param: string, value: any) {
    const self = this;

    // tslint:disable-next-line: early-exit
    if (self.enabled) {
      try {
        if (self.adobeDataLayerName === param) {
          window[self.adobeDataLayerName] = value;
          // tslint:disable-next-line: no-unused-expression
          self.debug && console.log('%cAnalytics - setData: ', self.debugColor, self.adobeDataLayerName + ' = ', value);

          return true;
        }

        let analyticsParam = window[self.adobeDataLayerName];
        let i: number;
        const params = param.split('.');

        try {
          for (i = 0; i < params.length - 1; i++) {
            if (analyticsParam[params[i]] === undefined) {
              analyticsParam[params[i]] = {};
            }
            analyticsParam = analyticsParam[params[i]];
          }
          analyticsParam[params[i]] = value;

          if (self.debug) {
            console.log(
              '%cAnalytics - setData: ',
              self.debugColor,
              self.adobeDataLayerName + '.' + param + ' = ',
              analyticsParam[params[i]]
            );
          }
          // tslint:disable-next-line: no-empty
        } catch (e) {}
      } catch (e) {
        if (self.debug) {
          console.log('%cAnalytics - setData - Error', self.debugColor, e);
        }
      }
    }
  }
}
