import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ClientNavigationService } from 'src/app/client/shared/client-navigation.service';
import { RoutesClientEnum } from 'src/app/client/shared/enums/routes-client-enum';
import { RoutesGetReadyFullEnum } from 'src/app/get-ready-full/shared/enums/routes-get-ready-enum';
import { GetReadyNavigationFullService } from 'src/app/get-ready-full/shared/get-ready-navigation.service';
import { RoutesGetReadyEnum } from 'src/app/get-ready/shared/enums/routes-get-ready-enum';
import { GetReadyNavigationService } from 'src/app/get-ready/shared/get-ready-navigation.service';

import { SellerService } from '../services/domains/seller/seller.service';
import { LoginService } from '../services/login.service';
import { Info } from './const/information.constant';

@Component({
  selector: 'voxel-error-incompatible-browser',
  templateUrl: './error-incompatible-browser.component.html',
  styleUrls: ['./error-incompatible-browser.component.scss']
})
export class ErrorIncompatibleBrowserComponent implements OnInit {
  info: any;

  constructor(
    private loginService: LoginService,
    private getReadyNavigationService: GetReadyNavigationService,
    private getReadyFullNavigationService: GetReadyNavigationFullService,
    private clientNavigationService: ClientNavigationService,
    private sellerService: SellerService,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.defineComponentBehavior();
  }

  defineComponentBehavior() {
    if (this.loginService.incompatibleBrowser) {
      this.info = Info.incompatibleBrowser;
    } else if (this.loginService.safariSuggestion) {
      this.info = Info.safariSuggestion;
    }
  }

  redirect() {
    // tslint:disable-next-line: early-exit
    if (this.loginService.incompatibleBrowser) {
      window.location.href = this.loginService.accessDeniedUrl;
    } else if (
      this.loginService.safariSuggestion &&
      (this.appService.getClientSessionId() || this.appService.getClientToken())
    ) {
      this.clientNavigationService.navigateTo(RoutesClientEnum.PLANS);
    } else if (this.sellerService.getSeller()) {
      this.getReadyNavigationService.navigateTo(RoutesGetReadyEnum.SALES);
    } else {
      this.getReadyFullNavigationService.navigateTo(RoutesGetReadyFullEnum.START);
    }
  }
}
