export class AddressModel {

  street: string;
  state: string;
  city: string;
  is_valid: boolean;
  is_key_enter: boolean;

  constructor() {
    this.street = '';
    this.state = '';
    this.city = '';
    this.is_valid = false;
    this.is_key_enter = false;
  }
}
