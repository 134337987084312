import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';

import { RoutesDocumentPhotosEnum } from '../../document-photos/shared/enums/routes-document-photos-enum';
import { GetReadyFormService } from '../../get-ready/shared/get-ready-form.service';
import { RoutesSelfieEnum } from '../../selfie/shared/enums/routes-selfie-enum';
import { RoutesOffersEnum } from './enums/routes-offers-enum';
import { OffersFormService } from './offers-form.service';

@Injectable()
export class OffersNavigationService extends CommonNavigationService {
  constructor(
    route: Router,
    private formService: OffersFormService,
    private getReadyFormService: GetReadyFormService,
    buttonSideBySideHelperService: ButtonSideBySideHelperService
  ) {
    super(route, buttonSideBySideHelperService);

    this.step = RoutesOffersEnum.PLANS;
    this.stepAmount = 3;
    this.flows = [
      {
        stage: RoutesOffersEnum.PLANS,
        previous: null,
        next: this.navigateNextPlans
      },
      {
        stage: RoutesOffersEnum.CREDITCARD,
        previous: RoutesOffersEnum.PLANS,
        next: this.navigateNextCreditCard
      },
      {
        stage: RoutesOffersEnum.CLOSINGDATE,
        previous: RoutesOffersEnum.CREDITCARD,
        next: this.navigateNextClosingDate
      }
    ];
  }

  navigateNextPlans(): RoutesOffersEnum | RoutesSelfieEnum | RoutesDocumentPhotosEnum {
    if (this.formService.getSelectedPackage().card_offers && this.formService.getSelectedPackage().card_offers.length > 0) {
      return RoutesOffersEnum.CREDITCARD;
    }
    if (this.getReadyFormService.getProperty('seller')) {
      return RoutesDocumentPhotosEnum.GET_READY;
    }
    return RoutesSelfieEnum.GET_READY;
  }

  navigateNextCreditCard(): RoutesOffersEnum | RoutesSelfieEnum | RoutesDocumentPhotosEnum {
    if (
      this.formService.getSelectedPackage().card_offers &&
      this.formService.getSelectedPackage().card_offers.length > 0 &&
      this.formService.getForm().accepted_credit_card
    ) {
      return RoutesOffersEnum.CLOSINGDATE;
    }
    if (this.getReadyFormService.getProperty('seller')) {
      return RoutesDocumentPhotosEnum.GET_READY;
    }
    return RoutesSelfieEnum.GET_READY;
  }

  navigateNextClosingDate(): RoutesSelfieEnum | RoutesDocumentPhotosEnum {
    if (this.getReadyFormService.getProperty('seller')) {
      return RoutesDocumentPhotosEnum.GET_READY;
    }
    return RoutesSelfieEnum.GET_READY;
  }
}
