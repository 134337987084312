import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';

export interface FlowType {
  stage: any;
  previous: any;
  next: any;
}

@Injectable()
export class CommonNavigationService {
  step: any;
  stepAmount: number;
  flows: FlowType[];

  private errors;

  constructor(private route: Router, private buttonSideBySideHelperService: ButtonSideBySideHelperService) {
    this.errors = [];
  }

  hasErrors() {
    return this.errors && this.errors.length > 0;
  }

  setStage(stage: any) {
    this.step = stage;
  }

  getStep(): any {
    return this.step;
  }

  getStepAmount() {
    return this.stepAmount;
  }

  sendToNext() {
    const flow = this.findFlow(this.step);
    if (flow) {
      this.navigateTo(flow.next);
    }
  }

  sendToPrevious() {
    const flow = this.findFlow(this.step);
    if (flow) {
      this.navigateTo(flow.previous);
    }
  }

  navigateTo(route: any) {
    this.buttonSideBySideHelperService.changeButtonsFlag();
    let calculatedRoute;
    if (route instanceof Function) {
      calculatedRoute = route.apply(this);
    }

    calculatedRoute = calculatedRoute || route;

    this.setStage(calculatedRoute);
    if (calculatedRoute) {
      this.route.navigate([calculatedRoute.toString()]);
    }
  }

  findFlow(stage) {
    if (this.flows && this.flows.length > 0) {
      return this.flows.find(x => x.stage === stage);
    }
    return null;
  }

  addError(error) {
    if (!this.errors) {
      this.errors = [];
    }
    this.errors.push(error);
  }

  cleanErrors() {
    this.errors.length = 0;
  }
}
