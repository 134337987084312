import { Injectable } from '@angular/core';

@Injectable()
export class CommunicatorService {
  private FLOW = 'flow';

  saveOPs(flow: any[]) {
    localStorage.setItem(this.FLOW, JSON.stringify(flow));
  }

  getOP(key: string) {
    const flows = JSON.parse(localStorage.getItem(this.FLOW));
    const op = flows.find(x => x.target === key);
    if (op) {
      return op;
    }
    return null;
  }
}

/*
Tipos de métodos que podem ser utilizados na assinatura do request Http.
*/
export type WebRequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH';

/*
Interface para criação de assinatura de requests Http
*/
export interface WebRequestOptions {
  url?: string | null;
  method?: string;
  search?: string;
  params?: string | URLSearchParams;
  headers?: Headers | null;
  body?: any | {};
  withCredentials?: boolean | null;
  responseType?: XMLHttpRequestResponseType | null;
  op?: string;
}
