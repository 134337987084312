import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonSideBySideHelperService } from './../../../latam-it-buttons-side-by-side/button-sidebyside-helper-service';
import { CommonNavigationService } from './../../shared/services/common-navigation.service';
import { ClientFormService } from './client-form.service';
import { RoutesClientEnum } from './enums/routes-client-enum';


@Injectable()
export class ClientNavigationService extends CommonNavigationService {
  constructor(
    route: Router,
    private formService: ClientFormService,
    buttonSideBySideHelperService: ButtonSideBySideHelperService
  ) {
    super(route, buttonSideBySideHelperService);

    this.step = RoutesClientEnum.CONTACT;
    this.stepAmount = 10;
    this.flows = [
      {
        stage: RoutesClientEnum.CONTACT,
        previous: null,
        next: RoutesClientEnum.PERSONAL_ADDRESS
      },
      {
        stage: RoutesClientEnum.PERSONAL_ADDRESS,
        previous: RoutesClientEnum.CONTACT,
        next: RoutesClientEnum.PURPOSE
      },
      {
        stage: RoutesClientEnum.PURPOSE,
        previous: RoutesClientEnum.PERSONAL_ADDRESS,
        next: RoutesClientEnum.MONEY
      },
      {
        stage: RoutesClientEnum.MONEY,
        previous: RoutesClientEnum.PURPOSE,
        next: RoutesClientEnum.CREDITS
      },
      {
        stage: RoutesClientEnum.CREDITS,
        previous: RoutesClientEnum.MONEY,
        next: RoutesClientEnum.DELIVERY_ADDRESS
      },
      {
        stage: RoutesClientEnum.DELIVERY_ADDRESS,
        previous: RoutesClientEnum.CREDITS,
        next: RoutesClientEnum.CONFIRMATION
      },
      {
        stage: RoutesClientEnum.CONFIRMATION,
        previous: RoutesClientEnum.DELIVERY_ADDRESS,
        next: RoutesClientEnum.ULTIMATE
      },
      {
        stage: RoutesClientEnum.ULTIMATE,
        previous: RoutesClientEnum.CONFIRMATION,
        next: null
      }
    ];
  }
}
