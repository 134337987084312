import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { GetReadyModel } from '../models/get-ready-model';
import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';
import { Analytics } from '../../shared/analytics';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';

@Component({
  selector: 'document-no-chip',
  templateUrl: './document-no-chip.component.html',
  styleUrls: ['./document-no-chip.component.scss'],
})
export class DocumentNoChipComponent implements OnInit {
  @ViewChild('pageNumberInput') pageNumberInput: ElementRef;

  form = new FormGroup({
    docSerie: new FormControl(),
    pageNumber: new FormControl(),
  });

  pageId = '03_documentNoChip';
  isBtnDisabled = false;
  modalShow = false;

  docSerie = '';
  pageNumber: number;
  getReadyForm: GetReadyModel;
  serieCustomErrorMessage = '';

  constructor(
    private getReadyNavigationService: GetReadyNavigationService,
    public navigationService: GetReadyNavigationService,
    private getReadyFormService: GetReadyFormService,
    private analyticsLib: Analytics) {}

  ngOnInit() {
    this.getReadyForm = this.getReadyFormService.getFormGetReady();

    if (this.getReadyForm.identity_documents[0].series) {
      this.docSerie = this.getReadyForm.identity_documents[0].series;
      this.form.controls['docSerie'].setValue(this.getReadyForm.identity_documents[0].series);
    }

    if (this.getReadyForm.identity_documents[0].folder > 0) {
      this.form.controls['pageNumber'].setValue(this.getReadyForm.identity_documents[0].folder);
      this.pageNumber = this.getReadyForm.identity_documents[0].folder;
    }

    this.refreshButton();
    this.checkOpenModal();
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.doNext();
    }
  }

  refreshButton() {
    this.isBtnDisabled = !(
      this.docSerie &&
      this.docSerie.length === 1 &&
      this.pageNumber &&
      this.pageNumber.toString().length === 6
    );
  }

  changeSerie(event) {
    this.serieCustomErrorMessage = '';
    const onlyLetter = /^[a-zA-Z]*$/;
    if (onlyLetter.test(event)) {
      this.docSerie = event.toUpperCase();
    } else {
      this.serieCustomErrorMessage = 'ingresá una letra';
      this.form.controls['docSerie'].setErrors({ incorrect: true });
      this.docSerie = '';
    }
    this.refreshButton();
  }

  changeNumber(event) {
    this.pageNumber = event;

    if (this.pageNumber.toString().length === 6) {
      this.pageNumberInput.nativeElement.blur();
      this.pageNumberInput.nativeElement.focus();
    }
    this.refreshButton();
  }

  doNext() {
    this.getReadyForm.identity_documents[0].series = this.docSerie;
    this.getReadyForm.identity_documents[0].folder = this.pageNumber;
    this.getReadyNavigationService.sendToNext();
  }

  maskAsRead() {
    this.navigationService.cleanErrors();
    this.toggleModal();
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }

  checkOpenModal() {
    if (this.navigationService.hasErrors()) {
      this.toggleModal();
    }
  }

  setAtual() {
    return 3;
  }

  newInputChange(input: string) {
    if (input === 'docSerie') {
      this.getReadyForm.identity_documents[0].series = this.form.controls['docSerie'].value;
      this.changeSerie(this.form.controls[input].value);
    } else if (input === 'pageNumber') {
      this.getReadyForm.identity_documents[0].folder = this.form.controls['pageNumber'].value;
      this.changeNumber(this.form.controls[input].value);
    }
  }

  onPageNumberBlur() {
    if (!this.pageNumber || this.pageNumber.toString().length < 6) {
      this.form.controls['pageNumber'].setErrors({ incorrect: true });
    }
  }
}
