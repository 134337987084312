import { Injectable } from '@angular/core';

@Injectable()
export class NumberHelperService {
  toCurrency(currencySymbol, moneyValue) {
    let numeral;
    if (moneyValue) {
      const temp = this.onlyNumbers(moneyValue);
      if (temp) {
        numeral = this.numberFormat(temp, 0);
      }
    }
    return currencySymbol + ' ' + (numeral || '');
  }

  numberFormat(value, decimals) {
    let numeral;
    const decValue = value / Math.pow(10, decimals);
    const tempNum = decValue.toFixed(decimals).split('.');
    tempNum[0] = tempNum[0].split(/(?=(?:...)*$)/).join('.');
    if (tempNum[0].endsWith('.') || tempNum[0].endsWith(',')) {
      tempNum[0] = tempNum[0].slice(0, -1);
    }
    numeral = tempNum.join(',');

    return numeral;
  }

  onlyNumbers(value: string) {
    if (value) {
      return value.replace(/[^0-9]/g, '');
    }
    return value;
  }
}
