import { Component, OnInit } from '@angular/core';
import { GetReadyFormFullService } from 'src/app/get-ready-full/shared/get-ready-form.service';
import { OffersFullFormService } from 'src/app/offers-full/shared/offers-form.service';
import { ProgressBarService } from 'src/app/progressBar.service';

import { Analytics } from '../../shared/analytics';
import { GenericModel } from '../../shared/models/generic-model';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { AddressFullModel } from '../models/address-model';
import { DeliveryChoiceFullModel } from '../models/delivery-choice-model';
import { AddressFullCommonService } from '../shared/address-common.service';
import { AddressFullFormService } from '../shared/address-form.service';
import { AddressFullNavigationService } from '../shared/address-navigation.service';
import { RoutesAddressFullEnum } from '../shared/enums/routes-address-enum';

@Component({
  selector: 'delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss']
})
export class DeliveryAddressFullComponent implements OnInit {
  pageId: string;
  isBtnDisabled: boolean;

  pickUpAvailability: string;
  ocaAvailability: string;
  tataAvailability: string;
  pickUpCenterStreet: string;
  ocaCenterStreet: string;
  tataCenterStreet: string;

  states: GenericModel[];
  cities: GenericModel[];

  oldSelect: DeliveryChoiceFullModel;
  deliveryChoiceModel: DeliveryChoiceFullModel[];

  // Personal Address
  optionPersonalAddress: boolean;
  personalAddress: AddressFullModel;

  // Pickup
  pickupCenter: any[];
  pickupCenterAvailable: GenericModel[];
  optionPickupCenter: boolean;
  pickupCenterSelected: GenericModel;

  pickupCenterState: GenericModel[];
  pickupCenterStateSelected: GenericModel;

  // OCA Center
  ocaCenter: any[];
  ocaCenterAvailable: GenericModel[];
  optionOcaCenter: boolean;
  ocaCenterSelected: GenericModel;

  ocaCenterState: GenericModel[];
  ocaCenterStateSelected: GenericModel;

  // TA-TA Center
  tataCenter: any[];
  tataCenterAvailable: GenericModel[];
  optionTataCenter: boolean;
  tataCenterSelected: GenericModel;

  tataCenterState: GenericModel[];
  tataCenterStateSelected: GenericModel;

  // Other Address
  deliveryAddress: AddressFullModel;
  optionOtherAddress: boolean;

  deliveryStateSelected: GenericModel;
  deliveryCitySelected: GenericModel;

  cardSelected: boolean;

  showDeliveryTime: boolean;

  deliveryTimes: any[];

  constructor(
    private analyticsLib: Analytics,
    public addressNavigationService: AddressFullNavigationService,
    private addressFormService: AddressFullFormService,
    private addressCommonService: AddressFullCommonService,
    private getReadyFormService: GetReadyFormFullService,
    private offersFormService: OffersFullFormService,
    private progressBarService: ProgressBarService
  ) {
    this.pageId = 'delivery-address';
    this.pickupCenter = this.addressCommonService.getAdminList(AdminListEnum.PICKUP_CENTER);
    this.pickupCenterState = this.addressCommonService.pickupCenterState.sort(
      this.addressCommonService.compareItemsAlphabetically
    );
    if (this.pickupCenterState && this.pickupCenterState.length > 0) {
      this.pickupCenterStateSelected = !addressFormService.getProperty('pickup_center')
        ? this.findMontevideoCenter(this.pickupCenterState)
        : this.getStateByCenterId(addressFormService.getProperty('pickup_center'), this.pickupCenter, this.pickupCenterState);
      this.pickupCenterSelected = this.addressCommonService.getDefaultOption();
      this.pickupCenterAvailable = !addressFormService.getProperty('pickup_center')
        ? [
          this.pickupCenterSelected,
          ...this.filterCenterByStateAlphabetically(this.pickupCenterStateSelected.id, this.pickupCenter)
        ]
        : this.filterCenterByStateAlphabetically(this.pickupCenterStateSelected.id, this.pickupCenter);
    } else {
      this.pickupCenterStateSelected = new GenericModel();
      this.pickupCenterSelected = new GenericModel();
      this.pickupCenterAvailable = [];
    }
    this.ocaCenter = this.addressCommonService.getAdminList(AdminListEnum.OCA_CENTER);
    this.ocaCenterState = this.addressCommonService.ocaCenterState.sort(this.addressCommonService.compareItemsAlphabetically);
    if (this.ocaCenterState && this.ocaCenterState.length > 0) {
      this.ocaCenterStateSelected = !addressFormService.getProperty('oca_center')
        ? this.findMontevideoCenter(this.ocaCenterState)
        : this.getStateByCenterId(addressFormService.getProperty('oca_center'), this.ocaCenter, this.ocaCenterState);
      this.ocaCenterSelected = this.addressCommonService.getDefaultOption();
      this.ocaCenterAvailable = !addressFormService.getProperty('oca_center')
        ? [this.ocaCenterSelected, ...this.filterCenterByStateAlphabetically(this.ocaCenterStateSelected.id, this.ocaCenter)]
        : this.filterCenterByStateAlphabetically(this.ocaCenterStateSelected.id, this.ocaCenter);
    } else {
      this.ocaCenterStateSelected = new GenericModel();
      this.ocaCenterSelected = new GenericModel();
      this.ocaCenterAvailable = [];
    }
    this.tataCenter = this.addressCommonService.getAdminList(AdminListEnum.TATA_CENTER);
    this.tataCenterState = this.addressCommonService.tataCenterState.sort(this.addressCommonService.compareItemsAlphabetically);
    if (this.tataCenterState && this.tataCenterState.length > 0) {
      this.tataCenterStateSelected = !addressFormService.getProperty('tata_center')
        ? this.tataCenterState[0]
        : this.getStateByCenterId(addressFormService.getProperty('tata_center'), this.tataCenter, this.tataCenterState);
      this.tataCenterSelected = this.addressCommonService.getDefaultOption();
      this.tataCenterAvailable = !addressFormService.getProperty('tata_center')
        ? [this.tataCenterSelected, ...this.filterCenterByStateAlphabetically(this.tataCenterStateSelected.id, this.tataCenter)]
        : this.filterCenterByStateAlphabetically(this.tataCenterStateSelected.id, this.tataCenter);
    } else {
      this.tataCenterStateSelected = new GenericModel();
      this.tataCenterSelected = new GenericModel();
      this.tataCenterAvailable = [];
    }
    this.setProgressBar();
  }

  ngOnInit() {
    this.addressNavigationService.setStage(RoutesAddressFullEnum.DELIVERY_ADDRESS);
    this.cardSelected =
      this.offersFormService.getSelectedPackage().card_offers &&
      this.offersFormService.getSelectedPackage().card_offers.length > 0 &&
      this.offersFormService.getForm().accepted_credit_card
        ? true
        : false;
    this.deliveryChoiceModel = this.getDeliveryChoiceModel();
    this.states = this.addressCommonService.states;
    this.cities = this.addressCommonService.cities;
    this.deliveryStateSelected = new GenericModel();
    this.deliveryCitySelected = new GenericModel();
    this.personalAddress = new AddressFullModel();
    this.deliveryAddress = new AddressFullModel();
    this.deliveryTimes = this.addressCommonService.getAdminList(AdminListEnum.DELIVERY_AVAILABILITY);
    this.isBtnDisabled = true;
    this.showDeliveryTime = false;
    this.checkPreviousStage();
    this.analyticsLib.buildData('ComoRecibirLaTarjeta', this.pageId, '20', false);
    this.analyticsLib.trackState(null, null);
    this.addressCommonService.scrollToTop(document);

    const availability = this.addressFormService.getProperty('availability');

    // tslint:disable-next-line: early-exit
    if (availability) {
      this.deliveryTimes.forEach(element => {
        element.checked = !!(availability === element.code);
      });
    }
  }

  setDate() {
    const choice = this.addressFormService.getProperty('delivery_choice_id');

    // tslint:disable-next-line: early-exit
    if (choice) {
      for (const value of this.deliveryChoiceModel) {
        // tslint:disable-next-line: early-exit
        if (value.id === choice) {
          this.oldSelect = value;
          this.deliveryChoiceModel = [this.oldSelect];
          value.selected = true;
          this.setDeliveryChoice(value.id);
        }
      }
    }
  }

  setPersonalAddress() {
    this.personalAddress = this.addressFormService.getProperty('personal_address');
  }

  setDeliveryAddress() {
    this.deliveryAddress = this.addressFormService.getProperty('delivery_address');
    this.deliveryStateSelected.label = this.deliveryAddress.state;
    this.deliveryCitySelected.label = this.deliveryAddress.city;
  }

  setPickupCenter() {
    const id = this.addressFormService.getProperty('pickup_center');
    if (id) {
      this.pickupCenterSelected = this.pickupCenter.find(x => x.id === id);
    } else {
      this.pickupCenterSelected = this.addressCommonService.getDefaultOption();
    }
    this.changePickupCenter({ itemSelectedValue: this.pickupCenterSelected });
  }

  setOcaCenter() {
    const id = this.addressFormService.getProperty('oca_center');
    if (id) {
      this.ocaCenterSelected = this.ocaCenter.find(x => x.id === id);
    } else {
      this.ocaCenterSelected = this.addressCommonService.getDefaultOption();
    }
    this.changeOcaCenter({ itemSelectedValue: this.ocaCenterSelected });
  }

  setTataCenter() {
    const id = this.addressFormService.getProperty('tata_center');
    if (id) {
      this.tataCenterSelected = this.tataCenter.find(x => x.id === id);
    } else {
      this.tataCenterSelected = this.addressCommonService.getDefaultOption();
    }
    this.changeTataCenter({ itemSelectedValue: this.tataCenterSelected });
  }

  changeDeliveryChoice(deliveryChoice: DeliveryChoiceFullModel): void {
    if (deliveryChoice !== this.oldSelect) {
      deliveryChoice.selected = true;
      this.addressFormService.setProperty('delivery_choice_id', deliveryChoice.id);
      this.deliveryChoiceModel = [deliveryChoice];
      this.setDeliveryChoice(deliveryChoice.id);
      if (this.oldSelect) {
        this.oldSelect.selected = !this.oldSelect.selected;
      }
      this.oldSelect = deliveryChoice;
    }
    if (this.showDeliveryTime) {
      this.isBtnDisabled =
        !this.addressFormService.getProperty('delivery_address') ||
        !this.addressFormService.getProperty('delivery_address').is_valid ||
        !this.addressFormService.getProperty('availability');
    }
    this.analyticsChoice(deliveryChoice.id);
  }

  analyticsChoice(idChoice) {
    if (idChoice === 'private_home_choice') {
      this.analyticsLib.setData('page.name', 'AC:OCA:PF:NL:AreaComun:Registro:Agregar:EnvioMiDomicilio');
    } else if (idChoice === 'other_address_choice') {
      this.analyticsLib.setData('page.name', 'AC:OCA:PF:NL:AreaComun:Registro:Agregar::EnvioOtraDireccion');
    } else if (idChoice === 'pickup_center_choice') {
      this.analyticsLib.setData('page.name', 'AC:OCA:PF:NL:AreaComun:Registro:Agregar:EnvioPickUp');
    } else if (idChoice === 'oca_center_choice') {
      this.analyticsLib.setData('page.name', 'AC:OCA:PF:NL:AreaComun:Registro:Agregar:EnvioOCA');
    } else if (idChoice === 'tata_center_choice') {
      this.analyticsLib.setData('page.name', 'AC:OCA:PF:NL:AreaComun:Registro:Agregar:EnvioTaTa');
    } else {
      return;
    }
    this.analyticsLib.setData('custom.events.transactionstep21', '1');
    this.analyticsLib.setData('transaction.details', { transactionstep: '21' });
    this.analyticsLib.trackState(null, null);
  }

  setDeliveryChoice(id: string): void {
    if (id === 'private_home_choice') {
      this.optionPersonalAddress = true;
      this.optionPickupCenter = this.optionOtherAddress = this.optionOcaCenter = this.optionTataCenter = !this
        .optionPersonalAddress;
      this.showDeliveryTime = true;

      const oldDeliveryTime = this.addressFormService.getProperty('availability');

      this.deliveryTimes.forEach(time => {
        time.checked = !!(oldDeliveryTime === time.code);
      });

      this.addressFormService.setProperty('pickup_center', '');
      this.addressFormService.setProperty('oca_center', '');
      this.addressFormService.setProperty('tata_center', '');
      if (this.personalAddress.city === '') {
        this.setPersonalAddress();
      }
      this.addressFormService.setProperty('delivery_address', this.personalAddress);
      this.isBtnDisabled = !this.addressFormService.getProperty('availability');
    } else if (id === 'other_address_choice') {
      this.optionOtherAddress = this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionPickupCenter = this.optionOcaCenter = this.optionTataCenter = !this
        .optionOtherAddress;
      this.showDeliveryTime = true;
      this.addressFormService.setProperty('pickup_center', '');
      this.addressFormService.setProperty('oca_center', '');
      this.addressFormService.setProperty('tata_center', '');
    } else if (id === 'pickup_center_choice') {
      this.addressFormService.setProperty('oca_center', '');
      this.addressFormService.setProperty('tata_center', '');
      this.optionPickupCenter = true;
      this.showDeliveryTime = false;
      this.pickupCenterStateSelected =
        this.pickupCenterState && this.pickupCenterState.length > 0
          ? this.findMontevideoCenter(this.pickupCenterState)
          : new GenericModel();
      this.pickupCenterAvailable = [
        this.addressCommonService.getDefaultOption(),
        ...this.filterCenterByStateAlphabetically(this.pickupCenterStateSelected.id, this.pickupCenter)
      ];
      this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionOtherAddress = this.optionOcaCenter = this.optionTataCenter = !this
        .optionPickupCenter;
      this.setPickupCenter();
    } else if (id === 'oca_center_choice') {
      this.addressFormService.setProperty('pickup_center', '');
      this.addressFormService.setProperty('tata_center', '');
      this.optionOcaCenter = true;
      this.showDeliveryTime = false;
      this.ocaCenterStateSelected =
        this.ocaCenterState && this.ocaCenterState.length > 0
          ? this.findMontevideoCenter(this.ocaCenterState)
          : new GenericModel();
      this.ocaCenterAvailable = [
        this.addressCommonService.getDefaultOption(),
        ...this.filterCenterByStateAlphabetically(this.ocaCenterStateSelected.id, this.ocaCenter)
      ];
      this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionOtherAddress = this.optionPickupCenter = this.optionTataCenter = !this
        .optionOcaCenter;
      this.setOcaCenter();
    } else if (id === 'tata_center_choice') {
      this.addressFormService.setProperty('pickup_center', '');
      this.addressFormService.setProperty('oca_center', '');
      this.optionTataCenter = true;
      this.showDeliveryTime = false;
      this.tataCenterStateSelected =
        this.tataCenterState && this.tataCenterState.length > 0 ? this.tataCenterState[0] : new GenericModel();
      this.tataCenterAvailable = [
        this.addressCommonService.getDefaultOption(),
        ...this.filterCenterByStateAlphabetically(this.tataCenterStateSelected.id, this.tataCenter)
      ];
      this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionOtherAddress = this.optionPickupCenter = this.optionOcaCenter = !this
        .optionTataCenter;
      this.setTataCenter();
    } else {
      return;
    }
  }

  findMontevideoCenter(list) {
    const defaultCenter = list.find(elem => elem.label && elem.label.toUpperCase() === 'MONTEVIDEO');
    if (!defaultCenter) {
      return list[0];
    }
    return defaultCenter;
  }

  getDeliveryChoiceModel() {
    if (this.cardSelected) {
      return [
        new DeliveryChoiceFullModel('private_home_choice', 'icon-itaufonts_residencia', false, 'Domicilio particular'),
        new DeliveryChoiceFullModel('oca_center_choice', 'icon-itaufonts_oca', false, 'Sucursales OCA')
      ];
    }
    return [
      new DeliveryChoiceFullModel('private_home_choice', 'icon-itaufonts_residencia', false, 'Domicilio particular'),
      new DeliveryChoiceFullModel('pickup_center_choice', 'icon-itaufonts_processos', false, 'Pickup center'),
      new DeliveryChoiceFullModel('other_address_choice', 'icon-itaufonts_agencia', false, 'Otra dirección'),
      new DeliveryChoiceFullModel('oca_center_choice', 'icon-itaufonts_oca', false, 'Sucursales OCA'),
      new DeliveryChoiceFullModel('tata_center_choice', 'icon-itaufonts_tata', false, 'Sucursales TaTa')
    ];
  }

  enableButton(addressModel: AddressFullModel) {
    this.addressFormService.setProperty('delivery_address', addressModel);
    this.isBtnDisabled = !addressModel.is_valid || !this.addressFormService.getProperty('availability');
  }

  changePickupCenter(event: any) {
    this.pickupCenterSelected = event.itemSelectedValue;
    if (event.itemSelectedValue.value || event.itemSelectedValue.id) {
      this.deleteDefaultCenter(this.pickupCenterAvailable);
      const availability = event.itemSelectedValue.availability;
      this.pickUpCenterStreet = availability.slice(0, availability.indexOf('('));
      this.pickUpAvailability = availability.slice(availability.indexOf('(') + 1, availability.length - 1);
      this.isBtnDisabled = false;
    } else {
      this.pickUpCenterStreet = '';
      this.pickUpAvailability = '';
      this.isBtnDisabled = true;
    }
    this.addressFormService.setProperty('pickup_center', event.itemSelectedValue.id);
  }

  changeOcaCenter(event: any) {
    this.ocaCenterSelected = event.itemSelectedValue;
    if (event.itemSelectedValue.value || event.itemSelectedValue.id) {
      this.deleteDefaultCenter(this.ocaCenterAvailable);
      const availability = event.itemSelectedValue.availability;
      this.ocaCenterStreet = availability.slice(0, availability.indexOf('('));
      this.ocaAvailability = availability.slice(availability.indexOf('(') + 1, availability.length - 1);
      this.isBtnDisabled = false;
    } else {
      this.ocaCenterStreet = '';
      this.ocaAvailability = '';
      this.isBtnDisabled = true;
    }
    this.addressFormService.setProperty('oca_center', event.itemSelectedValue.id);
  }

  changeTataCenter(event: any) {
    this.tataCenterSelected = event.itemSelectedValue;
    if (event.itemSelectedValue.value || event.itemSelectedValue.id) {
      this.deleteDefaultCenter(this.tataCenterAvailable);
      const availability = event.itemSelectedValue.availability;
      this.tataCenterStreet = availability.slice(0, availability.indexOf('('));
      this.tataAvailability = availability.slice(availability.indexOf('(') + 1, availability.length - 1);
      this.isBtnDisabled = false;
    } else {
      this.tataCenterStreet = '';
      this.tataAvailability = '';
      this.isBtnDisabled = true;
    }
    this.addressFormService.setProperty('tata_center', event.itemSelectedValue.id);
  }

  continuarButton() {
    this.addressFormService.setProperty('pickUpOrDeliveryMode', this.getPickUpOrDeliveryMode());
    this.addressFormService.setProperty('pickUpOrDeliveryAddressMode', this.getPickUpOrDeliveryAddressMode());
    this.addressFormService.setProperty('pickUpOrDeliveryAddress', this.getPickUpOrDeliveryAddress());
    this.addressFormService.setProperty('pickUpOrDeliveryHourMode', this.getPickUpOrDeliveryHourMode());
    this.addressFormService.setProperty('pickUpOrDeliveryHour', this.getPickUpOrDeliveryHour());
    this.addressNavigationService.sendToNext();
  }

  getPickUpOrDeliveryMode() {
    if (this.optionPersonalAddress || this.optionOtherAddress) {
      return 'Envío a domicilio';
    }
    if (this.optionPickupCenter) {
      return 'Pick Up Center';
    }
    if (this.optionOcaCenter) {
      return 'Sucursal OCA';
    }
    if (this.optionTataCenter) {
      return 'Sucursal TaTa';
    }
    return null;
  }

  getPickUpOrDeliveryAddressMode() {
    if (this.optionPersonalAddress || this.optionOtherAddress) {
      return 'Dirección de envío';
    }
    if (this.optionPickupCenter || this.optionOcaCenter || this.optionTataCenter) {
      return 'Dirección de retiro';
    }
    return null;
  }

  getPickUpOrDeliveryAddress() {
    if (this.optionPersonalAddress || this.optionOtherAddress) {
      return this.addressFormService.getProperty('delivery_address').street;
    }
    if (this.optionPickupCenter) {
      return this.pickupCenter.find(x => x.id === this.addressFormService.getProperty('pickup_center')).label;
    }
    if (this.optionOcaCenter) {
      return this.ocaCenter.find(x => x.id === this.addressFormService.getProperty('oca_center')).label;
    }
    if (this.optionTataCenter) {
      return this.tataCenter.find(x => x.id === this.addressFormService.getProperty('tata_center')).label;
    }
    return null;
  }

  getPickUpOrDeliveryHourMode() {
    if (this.optionPersonalAddress || this.optionOtherAddress) {
      return 'Horario de entrega';
    }
    if (this.optionPickupCenter || this.optionOcaCenter || this.optionTataCenter) {
      return 'Horario de retiro';
    }
    return null;
  }

  getPickUpOrDeliveryHour() {
    if (this.optionPersonalAddress || this.optionOtherAddress) {
      return this.deliveryTimes.find(x => x.code === this.addressFormService.getProperty('availability')).label;
    }
    if (this.optionPickupCenter) {
      return this.pickUpAvailability;
    }
    if (this.optionOcaCenter) {
      return this.ocaAvailability;
    }
    if (this.optionTataCenter) {
      return this.tataAvailability;
    }
    return null;
  }

  changePickupCenterState(event: any) {
    this.pickupCenterStateSelected = event.itemSelectedValue;
    const filteredPickupCenters = this.filterCenterByStateAlphabetically(event.itemSelectedValue.id, this.pickupCenter);
    const defaultPickupCenter = this.addressCommonService.getDefaultOption();
    this.pickupCenterAvailable = [defaultPickupCenter, ...filteredPickupCenters];
    this.changePickupCenter({ itemSelectedValue: defaultPickupCenter });
  }

  changeOcaCenterState(event: any) {
    this.ocaCenterStateSelected = event.itemSelectedValue;
    const filteredOcaCenters = this.filterCenterByStateAlphabetically(event.itemSelectedValue.id, this.ocaCenter);
    const defaultOcaCenter = this.addressCommonService.getDefaultOption();
    this.ocaCenterAvailable = [defaultOcaCenter, ...filteredOcaCenters];
    this.changeOcaCenter({ itemSelectedValue: defaultOcaCenter });
  }

  changeTataCenterState(event: any) {
    this.tataCenterStateSelected = event.itemSelectedValue;
    const filteredTataCenters = this.filterCenterByStateAlphabetically(event.itemSelectedValue.id, this.tataCenter);
    const defaultTataCenter = this.addressCommonService.getDefaultOption();
    this.tataCenterAvailable = [defaultTataCenter, ...filteredTataCenters];
    this.changeTataCenter({ itemSelectedValue: defaultTataCenter });
  }

  filterCenterByStateAlphabetically(state: string, center: any) {
    return center.filter(elem => elem.state === state).sort(this.addressCommonService.compareItemsAlphabetically);
  }

  getStateByCenterId(centerId: string, center: any, centerState: any) {
    const chosenCenter = center.find(x => x.id === centerId);
    return centerState.find(x => x.id === chosenCenter.state);
  }

  changeDeliveryTime(deliveryTime) {
    this.addressFormService.setProperty('availability', deliveryTime.code);
    this.isBtnDisabled = false;
  }

  doPrevious() {
    // tslint:disable-next-line: early-exit
    if (
      this.optionPersonalAddress ||
      this.optionOcaCenter ||
      this.optionOtherAddress ||
      this.optionPickupCenter ||
      this.optionTataCenter
    ) {
      this.optionPersonalAddress = false;
      this.optionOcaCenter = false;
      this.optionOtherAddress = false;
      this.optionPickupCenter = false;
      this.optionTataCenter = false;
      this.deliveryChoiceModel = this.getDeliveryChoiceModel();
      this.showDeliveryTime = false;
      this.isBtnDisabled = true;
    } else {
      this.addressNavigationService.sendToPrevious();
    }
  }

  checkPreviousStage() {
    if (this.getReadyFormService.getPreviousStage() === 'Confirmation') {
      this.setDate();
      this.setPersonalAddress();
      this.setDeliveryAddress();
    } else {
      this.setPersonalAddress();
      this.setDeliveryAddress();
    }
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 4,
      amountBar: 3,
      currentBar: 2,
      labelName: 'Envío y confirmación'
    });
  }

  private deleteDefaultCenter(centerAvailable: any) {
    const defaultCenter = this.addressCommonService.getDefaultOption();
    const found = centerAvailable.find(
      element =>
        element.label === defaultCenter.label && element.id === defaultCenter.id && element.value === defaultCenter.value
    );

    if (found) {
      centerAvailable.shift();
    }
  }
}
