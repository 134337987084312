import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { GetReadyFormFullService } from 'src/app/get-ready-full/shared/get-ready-form.service';
import { OffersFullCommonService } from 'src/app/offers-full/shared/offers-common.service';
import { OffersFullFormService } from 'src/app/offers-full/shared/offers-form.service';
import { LatamErrorMessageService } from 'src/app/shared/latam-error-message/latam-error-message.service';
import { AdminListEnum } from 'src/app/shared/services/admin-lists/enum/admin-list.enum';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

import { Analytics } from '../../shared/analytics';
import { AbreCuentasRoutesEnum } from '../../shared/enum/abre-cuentas-routes.enum';
import { RoutesSelfieEnum } from '../shared/enums/routes-selfie-enum';
import { SelfieCommonService } from '../shared/selfie-common.service';
import { SelfieNavigationService } from '../shared/selfie-navigation.service';

@Component({
  selector: 'selfie-inclined-photo',
  templateUrl: './inclined-photo.component.html',
  styleUrls: ['./inclined-photo.component.scss']
})
export class InclinedPhotoComponent implements OnInit, OnDestroy {
  pageId = 'inclined-photo';
  previousRoute = RoutesSelfieEnum.INCLINED_STAGE;
  loaderIcon: boolean;
  fileType = 'selfie_life_proof';
  eventsSubject: Subject<void> = new Subject<void>();
  errorRoute = AbreCuentasRoutesEnum.CAMERA_ERROR;
  noPermissionRoute = AbreCuentasRoutesEnum.PERMISSION_REQUIRED;

  // Params from admin
  maxImageLenght: number;
  compressedResolutionHeight: number;

  packages: any;
  body = document.querySelector('body');
  packageSelected: number;

  constructor(
    private analyticsLib: Analytics,
    private offersCommonService: OffersFullCommonService,
    private latamErrorMessageService: LatamErrorMessageService,
    private offersFormService: OffersFullFormService,
    private navigationService: SelfieNavigationService,
    public selfieCommonService: SelfieCommonService,
    private elementRef: ElementRef,
    private latamItLoaderService: LatamItLoaderService,
    private getReadyFormService: GetReadyFormFullService
  ) {}

  ngOnInit() {
    this.navigationService.setStage(RoutesSelfieEnum.INCLINED_PHOTO);
    this.setFileUploadParams();
    this.getReadyFormService.setPreviousStage('Selfie');
    this.analyticsLib.buildData('CameraSelfieInclinada', this.pageId, '18', false);
    this.analyticsLib.trackState(null, null);
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#eaf1f7';
    this.selfieCommonService.scrollToTop(document);
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
  }

  setFileUploadParams() {
    if (this.selfieCommonService.paramsUpload) {
      this.compressedResolutionHeight = this.selfieCommonService.paramsUpload[0].resolution_size;
      this.maxImageLenght = this.selfieCommonService.paramsUpload[0].max_file_size;
    }
  }

  sendImageEvent(base64) {
    const self: any = this;
    this.selfieCommonService.sendImage(base64, this.fileType, () => {
      self.initializeCreditCard(self);
    });
  }

  initializeCreditCard(self) {
    self.getOffers(() => {
      self.initializePage(self);
    });
  }

  initializePage(self) {
    self.packageSelected = self.offersFormService.getProperty('selected_package') || 0;
    const index = self.offersCommonService.offersResponse.packages.findIndex(elem => elem.package_id === '2');
    if (index > -1) {
      self.offersCommonService.offersResponse.packages = [self.offersCommonService.offersResponse.packages[index]];
    } else {
      self.offersCommonService.offersResponse.packages =
        self.offersCommonService.offersResponse.packages.length > 0
          ? [self.offersCommonService.offersResponse.packages[0]]
          : [];
    }
    self.packages = self.offersCommonService.offersResponse.packages;
    self.addDescription();
    self.checkSpecialOffers();
    self.pageReady = true;
    this.updatePackage();
    if (
      this.offersFormService.getSelectedPackage().card_offers &&
      this.offersFormService.getSelectedPackage().card_offers.length > 0
    ) {
      this.latamItLoaderService.hide();
      this.navigationService.sendToNext();
    } else {
      this.offersCommonService.doPatchSolicitationPackageSelected(() => {
        self.sendToNext(self);
      });
    }
  }

  getOffers(callback) {
    this.offersCommonService.doGetOffers().subscribe(
      res => {
        // tslint:disable-next-line: early-exit
        if (res.statusCode === 200) {
          this.offersCommonService.offersResponse = res.data;
          // tslint:disable-next-line:forin
          for (const packageIndex in this.offersCommonService.offersResponse.packages) {
            this.offersCommonService.offersResponse.packages[packageIndex].benefits = this.offersCommonService.adminlists[
              AdminListEnum.ACCOUNT_BENEFITS
            ];

            this.offersCommonService.offersResponse.packages[packageIndex].commissions = this.offersCommonService.adminlists[
              AdminListEnum.ACCOUNT_COMMISSIONS
            ];
          }

          this.offersFormService.setForm(this.offersCommonService.offersResponse);
          this.offersCommonService.offerReady = true;
          callback();
        } else {
          this.latamItLoaderService.hide();
        }
      },
      err => {
        this.latamItLoaderService.hide();
      }
    );
  }

  addDescription() {
    this.packages.forEach(p => {
      // tslint:disable-next-line: early-exit
      if (p.package_description && this.packageNotAdded(p)) {
        p.account_offers.push({
          account_type: p.package_description,
          general_info: {
            product_description: ''
          }
        });
      }
    });
  }

  packageNotAdded(p) {
    return p.account_offers.every(item => item.account_type !== p.package_description);
  }

  checkSpecialOffers() {
    this.packages.forEach(p => {
      p.benefits.forEach(benefit => {
        // tslint:disable-next-line: early-exit
        if (benefit.type === 'special' && this.benefitNotAdded(benefit, p.account_offers)) {
          p.account_offers.push({
            account_type: benefit.title,
            general_info: {
              product_description: ''
            }
          });
        }
      });
    });
  }

  benefitNotAdded(benefit, packageOffers) {
    return packageOffers.every(item => item.account_type !== benefit.title);
  }

  updatePackage() {
    this.offersFormService.getForm().accepted_credit = undefined;
    this.offersFormService.getForm().accepted_credit_card = undefined;
    this.offersFormService.getForm().selected_package = this.packageSelected;
  }

  sendToNext(self) {
    self.navigationService.sendToNext();
  }
}
