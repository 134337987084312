import 'rxjs/add/observable/from';

import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IImage, ImageCompressService, ResizeOptions } from 'ng2-image-compress';
import { Observable } from 'rxjs/Observable';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

import { Analytics } from '../app/shared/analytics';

@Component({
  selector: 'voxel-it-selfie',
  templateUrl: './voxel-it-selfie.component.html',
  styleUrls: ['./voxel-it-selfie.component.scss']
})
export class VoxelItSelfieComponent implements OnInit, OnDestroy {
  @Input() faceCam: string;
  @Input() headerTitle: string;
  @Input() headerSubTitle: string;
  @Input() errorMessage: string;

  // Analytics
  @Input() analyticsPage: string;
  @Input() transactionStep: string;

  // Route params
  @Input() routeToNavigate: any;
  @Input() noPermissionRoute: any;

  // Navigation service
  @Input() navigationService: CommonNavigationService;

  // Parameters to adjust image resolution
  @Input() maxImageLenght: number;
  @Input() compressedResolutionHeight: number;
  @Input() compressedResolutionWidth: number;
  @Input() fileExtentions: string[];
  @Input() useConstraint: boolean;

  // Buttons label before picture
  @Input() volverLabel = 'Volver';
  @Input() takePictureLabel = 'Tomar foto';

  // Buttons label after picture
  @Input() repeatPictureLabel = 'Repetir';
  @Input() usePictureLabel = 'Usar foto';

  // Envents send clean native photo variables
  @Input() events: Observable<void>;

  // Parameters to adjust image resolution
  @Output() sendImage: EventEmitter<any> = new EventEmitter();

  @Input() errorRoute: string;

  // Optional parameter to define if sendImage is synchronous or asynchronous in the calling component
  @Input() synchronousSendImage = true;

  videosrc: any;
  userMediaPictureTaken: boolean;
  img: any;
  ext: string[];
  nativeImage: any;
  cameraSide: string;

  constraints: any;
  suportGetUserMediaComponent: boolean;
  disableTakePicture = true;
  disableUsePicture = true;
  private eventsSubscription: any;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private zone: NgZone,
    private imgCompressService: ImageCompressService,
    private latamItLoaderService: LatamItLoaderService,
    private analyticsLib: Analytics
  ) {}

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe(() => this.clearNativePhoto());
    this.constraints = {
      audio: false,
      video: {
        facingMode: this.faceCam === 'true' ? 'user' : 'environment'
      }
    };
    if (this.useConstraint) {
      this.constraints.video.width = { exact: 1280 };
      this.constraints.video.height = { exact: 720 };
    }

    this.elementRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
    this.elementRef.nativeElement.ownerDocument.lastElementChild.style.overflow = 'hidden';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#000000';

    if (!this.maxImageLenght) {
      this.maxImageLenght = 100000;
    }

    if (!this.errorMessage) {
      this.errorMessage = 'Intentá nuevamente para continuar la solicitud.';
    }

    this.fileExtentions = this.fileExtentions ? this.fileExtentions : ['jpg', 'jpeg'];
    this.compressedResolutionHeight = this.compressedResolutionHeight || 720;

    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      this.nativeImage = 'true';
      this.suportGetUserMediaComponent = false;
      this.openNativeCam();
    } else {
      this.suportGetUserMediaComponent = true;
      this.showCam();
    }
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'auto';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#ffffff';
    this.eventsSubscription.unsubscribe();
  }

  clearNativePhoto() {
    this.nativeImage = null;
  }

  showCam() {
    navigator.mediaDevices
      .getUserMedia(this.constraints)
      .then(stream => {
        this.zone.run(() => {
          const video: any = document.querySelector('video');
          // stream.oninactive = ()  => {};
          video.srcObject = stream;
          this.disableTakePicture = false;
        });
      })
      .catch(err => {
        console.log(err.name + ': ' + err.message);
        this.nativeImage = 'true';
        this.suportGetUserMediaComponent = false;
        this.openNativeCam();
        /*if (err.name === 'NotAllowedError') {
          this.zone.run(() => {
            this.router.navigateByUrl(this.errorRoute);
          });
        } else {
          console.log(err);
          // navigate to another route
        }*/
      });
  }

  closeCam() {
    const video: any = document.querySelector('video');
    const stream = video.srcObject;
    const tracks = stream.getTracks()[0];
    tracks.stop();
  }

  analytics() {
    if (this.analyticsPage !== '' && this.transactionStep !== '') {
      this.analyticsLib.buildData(this.analyticsPage, this.analyticsPage, this.transactionStep, false);
      this.analyticsLib.trackState(null, null);
    }
  }

  snapshot() {
    this.userMediaPictureTaken = true;
    this.analytics();
    const video = document.getElementsByTagName('video')[0];
    const canvas = document.getElementsByTagName('canvas')[0] as any;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);
    // 1. Casting necessary because TypeScript doesn't
    // know object Type element.src.
    (document.querySelector('#imgSelfie') as HTMLImageElement).src = canvas.toDataURL('image/jpeg');
    this.img = canvas.toDataURL('image/jpeg');
  }

  savePhoto() {
    const fileImg = this.dataURLtoFile(this.img, 'base64Img');

    try {
      if (fileImg.size > this.maxImageLenght) {
        this.compressImage(fileImg);
      } else {
        this.sendImage.emit(this.img);
      }
      this.closeCam();
    } catch (e) {
      // Display error message
      alert('Neither createObjectURL or FileReader are supported');
      const error = document.querySelector('#error');
      if (error) {
        error.innerHTML = 'Neither createObjectURL or FileReader are supported';
      }
    }
  }

  compressImage(fileImg: any) {
    this.latamItLoaderService.show();
    const fileList: File[] = [];
    fileList.push(fileImg);

    const images: IImage[] = [];

    const options = new ResizeOptions();

    options.Resize_Quality = 99;
    options.Resize_Max_Height = this.compressedResolutionHeight;
    options.Resize_Max_Width = this.compressedResolutionWidth || this.compressedResolutionHeight;

    ImageCompressService.filesArrayToCompressedImageSourceEx(fileList, options).then(observableImages => {
      observableImages.subscribe(
        image => {
          images.push(image);
        },
        error => {
          console.error('Error while converting');
          console.error(error);
        },
        () => {
          const sendImage = this.sendImage;
          if (this.nativeImage) {
            this.resetOrientation(images[0].compressedImage.imageDataUrl, 6, resetBase64Image => {
              console.log(resetBase64Image);
              sendImage.emit(resetBase64Image);
            });
          } else {
            console.log(images[0].compressedImage.imageDataUrl);
            sendImage.emit(images[0].compressedImage.imageDataUrl);
          }
          if (this.synchronousSendImage) {
            this.latamItLoaderService.hide();
          }
        }
      );
    });
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  deletePhoto() {
    this.userMediaPictureTaken = false;
    this.img = null;
    this.showCam();
  }

  sendToPrevious() {
    this.closeCam();
    this.zone.run(() => {
      this.router.navigate([this.routeToNavigate]);
    });
  }

  uploadFile(event) {
    const scope = this;
    this.nativeImage = 'true';
    this.latamItLoaderService.show();
    setTimeout(() => {
      scope.readPhoto(event.target);
    }, 0);
  }

  openNativeCam() {
    const scope = this;
    const inputId = this.faceCam === 'true' ? 'native-front-input' : 'native-back-input';
    const nativeInput = document.getElementById(inputId);
    nativeInput.click();
    setTimeout(() => {
      scope.clearNativePhoto();
    }, 1000);
  }

  readPhoto(input) {
    // tslint:disable-next-line: early-exit
    if (input.files && input.files[0]) {
      this.latamItLoaderService.show();
      this.nativeImage = input.files[0];
      this.resolveNativeImage();
    }
  }

  resolveNativeImage() {
    if (this.nativeImage && this.suportFileType(this.nativeImage.type)) {
      if (this.nativeImage.size > this.maxImageLenght) {
        this.compressImage(this.nativeImage);
      } else {
        this.sendNativeImage(this.nativeImage);
      }
    } else {
      alert('Este no es un formato de imagen válido intente otra.');
      this.latamItLoaderService.hide();
    }
  }

  sendNativeImage(file) {
    const scope = this;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const sendImage = scope.sendImage;
      scope.resetOrientation(reader.result, 6, resetBase64Image => {
        // console.log(resetBase64Image);
        sendImage.emit(resetBase64Image);
        if (scope.synchronousSendImage) {
          scope.latamItLoaderService.hide();
        }
      });
    };
    reader.onerror = error => {
      console.log('Error: ', error);
    };
  }

  suportFileType(type) {
    let hasSupport = false;
    this.fileExtentions.forEach(ext => {
      if (type.indexOf(ext.toLowerCase()) > 0) {
        hasSupport = true;
      }
    });
    return hasSupport;
  }

  resetOrientation(srcBase64, srcOrientation, callback) {
    const img = new Image();

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL('image/jpeg'));
    };
    img.src = srcBase64;
  }
}
