<div class="content-with-filled-button">
  <header-oca [atual]='6'></header-oca>

  <main>
    <h1 id="titleId" class="title">
      Aproximadamente, ¿cuánto dinero esperás que ingrese en tus cuentas al mes?
    </h1>

    <voxel-additional-information
      id='addtionalInformationId'
      message="{{hintObject.message}}"
      idHint="hintid"
      [reverseHint]="true">
    </voxel-additional-information>

    <div class="radio-button">
      <voxel-radio-button-group>
        <voxel-radio-button
          *ngFor="let creditAmount of creditAmounts"
          id="creditAmount"
          [radioName]="' '"
          [value]="creditAmount.code"
          [checked]="creditAmount.checked"
          (radioButtonChange)='changeCreditAmount($event)'>
          {{creditAmount.label}}
        </voxel-radio-button>
      </voxel-radio-button-group>
    </div>

  </main>
</div>

<footer>
  <button-sidebyside [isBtnDisabled]="isBtnDisabled" 
    nextHook="true" 
    (hookNextAction)="nextButtonPressed()" 
    [navigationService]="navigationService"></button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>