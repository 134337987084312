import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { GenericModel } from '../../../shared/models/generic-model';
import { AddressFullModel } from '../../models/address-model';
import { AddressFullCommonService } from '../address-common.service';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFullFormComponent implements OnInit {
  @Input() street: string;
  @Input() stateSelected: GenericModel;
  @Input() citySelected: GenericModel;
  @Input() states: GenericModel[];
  @Input() cities: GenericModel[];

  @Output() validationForm = new EventEmitter(true);

  labelDistrict: string;
  citiesOrigin: GenericModel[];
  AddressFullModel: AddressFullModel;

  form = new FormGroup({
    street: new FormControl()
  });

  // tslint:disable-next-line: no-empty
  constructor(addressCommonService: AddressFullCommonService) {}

  ngOnInit(): void {
    this.AddressFullModel = new AddressFullModel();
    this.setData();
  }

  isEnter($event) {
    if ($event) {
      this.AddressFullModel.is_key_enter = true;
      this.enableNextButton();
    }
  }

  setData(): void {
    this.citiesOrigin = this.cities;
    this.setState(this.stateSelected);
    this.setCity(this.citySelected);
    this.setStreet(this.street);
    this.enableNextButton();
  }

  setLabel(): void {
    this.labelDistrict = 'Barrio';

    if (this.stateSelected ?.label.toLocaleLowerCase() !== 'montevideo') {
      this.labelDistrict = 'Localidad';
    }
  }

  changeStreet(event: any): void {
    this.AddressFullModel.street = event;
    this.street = event;
    this.enableNextButton();
  }

  changeState(event: any): void {
    this.stateSelected = event.itemSelectedValue;
    if (!!this.stateSelected ?.label) {
      this.AddressFullModel.state = this.stateSelected.label;
      this.cities = this.citiesOrigin.filter(x => x.id.toUpperCase() === this.stateSelected.label.toUpperCase());
    } else {
      this.cities = this.citiesOrigin;
    }
    this.cleanAllField();
    this.setLabel();
    this.enableNextButton();
  }

  cleanAllField(): void {
    this.street = null;
    this.AddressFullModel.street = null;
    this.citySelected = new GenericModel();
    this.AddressFullModel.city = null;
  }

  changeCity(event: any): void {
    this.citySelected = event.itemSelectedValue;
    this.AddressFullModel.city = this.citySelected ?.label;
    this.enableNextButton();
  }

  enableNextButton(): void {
    if (
      this.street &&
      this.street.length > 0 &&
      (this.street && this.street.length >= 4) &&
      (this.stateSelected && this.stateSelected.label) &&
      (this.citySelected && this.citySelected.value)
    ) {
      this.AddressFullModel.is_valid = true;
    } else {
      this.AddressFullModel.is_valid = false;
      this.AddressFullModel.is_key_enter = false;
    }
    this.validationForm.emit(this.AddressFullModel);
  }

  setState(selected: GenericModel): void {
    if (!!selected ?.label) {
      this.stateSelected = this.states.find(
        x =>
          this.stripAccents(x.label.toUpperCase().replace(/ /g, '')) ===
          this.stripAccents(selected.label.toUpperCase().replace(/ /g, '')),
      );
    }

    if (!this.stateSelected ?.label) {
      this.stateSelected = this.states[0];
    }
    this.AddressFullModel.state = this.stateSelected ?.label;

    this.setLabel();
  }

  setCity(selected: GenericModel): void {
    const filteredCities = this.citiesOrigin.filter(
      x =>
        this.stripAccents(x.id.toUpperCase().replace(/ /g, '')) ===
        this.stripAccents(this.stateSelected.label.toUpperCase().replace(/ /g, '')),
    );

    if (!!selected ?.label) {
      this.citySelected = filteredCities.find(
        x =>
          this.stripAccents(x.label.toUpperCase().replace(/ /g, '')) ===
          this.stripAccents(selected.label.toUpperCase().replace(/ /g, '')),
      );

      if (!!this.citySelected ?.label) {
        this.cities = filteredCities;
      } else {
        this.cities = filteredCities;
        this.citySelected = new GenericModel();
      }
    } else {
      this.cities = filteredCities;
    }
    this.AddressFullModel.city = this.citySelected ?.label;
  }

  setStreet(address: string): void {
    if (address) {
      this.AddressFullModel.street = address;
      this.street = address;
    }
  }

  stripAccents(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
