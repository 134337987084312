export enum AdminListEnum {
  PRINCIPAL_ACTIVITY = 'principal_activity_oca' as any,
  MARITAL_STATUS = 'marital_status_oca' as any,
  MONTHLY_INCOME = 'monthly_income_oca' as any,
  ACCOUNT_PURPOSE = 'account_purpose_oca' as any,
  AMOUNT_QUANTITY_PER_MONTH = 'amount_quantity_per_month_oca' as any,
  TXN_COUNT_PER_MONTH = 'txn_count_per_month_oca' as any,
  AVG_BALANCE = 'avg_balance_oca' as any,
  COUNTRY = 'country_oca' as any,
  ICONS = 'category_id_icons_oca' as any,
  AFFINITY = 'affinity_group_offer_oca' as any,
  CARD_CLASS = 'card_class_offer_oca' as any,
  CARD_BRAND = 'brand_offer_oca' as any,
  ACCOUNT_TYPE = 'account_type_offer_oca' as any,
  CURRENCY_DESCRIPTION = 'currency_oca' as any,
  CARD_TYPE = 'card_type_offer_oca' as any,
  STATE_CITY = 'state_city_oca' as any,
  PICKUP_CENTER = 'pickup_centers_oca' as any,
  FILE_UPLOAD = 'params_upload_oca' as any,
  DELIVERY_AVAILABILITY = 'delivery_info_availability_oca' as any,
  BRANCH_ID = 'branch_id_oca' as any,
  HOME_OFFERS = 'home_offers_oca' as any,
  CARD_INFO = 'card_info_oca' as any,
  LIST_URLS = 'list_urls_oca' as any,
  PRINCIPAL_ACTIVITY_PJ = 'principal_activity_pj_oca' as any,
  ACCOUNT_PURPOSE_PJ = 'account_purpose_pj_oca' as any,
  AVG_BALANCE_PJ = 'avg_balance_pj_oca' as any,
  AMOUNT_QUANTITY_PER_MONTH_PJ = 'amount_quantity_per_month_cash_pj_oca' as any,
  AMOUNT_QUANTITY_PER_MONTH_CHECKS_PJ = 'amount_quantity_per_month_checks_oca' as any,
  AMOUNT_QUANTITY_PER_MONTH_TRANSFERS_PJ = 'amount_quantity_per_month_transfers_oca' as any,
  TAXATION_TYPE_PJ = 'taxpayer_type_oca' as any,
  OCA_CENTER = 'oca_centers_oca' as any,
  TATA_CENTER = 'tata_centers_oca' as any,
  ACCOUNT_BENEFITS = 'benefits_oca' as any,
  ACCOUNT_COMMISSIONS = 'commissions_oca' as any
}
