import { Component, OnDestroy, OnInit } from '@angular/core';
import { GetReadyFormService } from 'src/app/get-ready/shared/get-ready-form.service';

import { NumberHelperService } from '../../shared/helper/number-helper-service';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { CardOffer } from '../models/card-offers-model';
import { OffersModel } from '../models/offers-model';
import { RoutesOffersEnum } from '../shared/enums/routes-offers-enum';
import { OffersCommonService } from '../shared/offers-common.service';
import { OffersFormService } from '../shared/offers-form.service';
import { OffersNavigationService } from '../shared/offers-navigation.service';

@Component({
  selector: 'offers-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit, OnDestroy {
  pageId: string;
  siWhite: string;
  noWhite: string;
  externalUrl: string;
  creditCardLimit: string;
  cardOffer: CardOffer;
  offersModel: OffersModel;
  body = document.querySelector('body');
  hyperlinkBehavior: string;
  modalShow = false;

  constructor(
    public navigationService: OffersNavigationService,
    private formService: OffersFormService,
    private numberHelperService: NumberHelperService,
    public offersCommonService: OffersCommonService,
    private getReadyFormService: GetReadyFormService
  ) {
    this.pageId = 'credit_card';
    this.offersModel = formService.getForm();
    if (this.formService.getSelectedPackage().card_offers) {
      this.cardOffer = this.formService.getSelectedPackage().card_offers[0];
    }
  }

  ngOnInit() {
    this.navigationService.setStage(RoutesOffersEnum.CREDITCARD);
    const adminLinkURLs = this.offersCommonService.getAdminList(AdminListEnum.LIST_URLS);

    this.externalUrl = adminLinkURLs.find(i => i.code.toUpperCase() === 'CARD_TERMS').description;
    this.offersModel.accepted_credit_card = this.formService.getProperty('accepted_credit_card');
    this.resolveButtons();
    if (this.cardOffer) {
      this.creditCalculator();
    }
    this.body.setAttribute('style', 'background-color: #EFE9E5');
    this.offersCommonService.scrollToTop(document);
    this.hyperlinkBehavior = this.offersCommonService.getHyperlinkBehavior();
  }

  resolveButtons() {
    if (this.offersModel.accepted_credit_card === undefined) {
      this.noWhite = 'secondary';
      this.siWhite = 'secondary';
    } else if (this.offersModel.accepted_credit_card === true) {
      this.noWhite = 'secondary';
      this.siWhite = 'primary';
    } else {
      this.noWhite = 'primary';
      this.siWhite = 'secondary';
    }
  }

  ngOnDestroy() {
    this.body.setAttribute('style', 'background-color: white');
  }

  clickSi() {
    this.offersModel.accepted_credit_card = true;
    this.formService.setProperty('accepted_credit_card', this.offersModel.accepted_credit_card);
    this.noWhite = 'secondary';
    this.siWhite = 'primary';

    setTimeout(() => {
      this.navigationService.sendToNext();
    }, 125);
  }

  clickNo() {
    this.offersModel.accepted_credit_card = false;
    this.formService.setProperty('accepted_credit_card', this.offersModel.accepted_credit_card);
    this.noWhite = 'primary';
    this.siWhite = 'secondary';

    setTimeout(() => {
      this.offersCommonService.doPatchSolicitationPackageSelected();
    }, 125);
  }

  hasOvedraft(): boolean {
    let result = false;
    const accountOffers = this.formService.getSelectedPackage().account_offers;

    if (accountOffers) {
      const hasOverdraftLimit = accountOffers.filter(item => item.overdraft_limit);
      if (hasOverdraftLimit.length > 0) {
        result = true;
      }
    }
    return result;
  }

  previous() {
    this.navigationService.sendToPrevious();
  }

  creditCalculator() {
    let creditCardLimit = this.numberHelperService.toCurrency('$', this.cardOffer.limit.amount.toString());
    this.creditCardLimit = creditCardLimit ? creditCardLimit.replace(' ', '') : creditCardLimit;
    this.formService.setProperty('credit_card_limit', this.cardOffer.limit.amount);

    const accountOffers = this.offersModel.packages[this.offersModel.selected_package].account_offers[0];
    const overdraft = accountOffers ? (accountOffers.overdraft_limit ? accountOffers.overdraft_limit.amount : 0) : 0;
    const calc = this.offersCommonService.calculateOverdraftAndCreditCard(overdraft, this.cardOffer.limit.amount);
    creditCardLimit = this.numberHelperService.toCurrency('$', calc.toString());
    this.creditCardLimit = creditCardLimit ? creditCardLimit.replace(' ', '') : creditCardLimit;
    this.formService.setProperty('credit_card_limit', calc);
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }
}
