<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <header-oca [atual]='setAtual()'></header-oca>

  <main>

    <h1 id="titleId" class="paddingless">
      Ingresá los
      <strong>datos</strong> de tu
      <strong>cédula</strong>
    </h1>

    <div class="container-serie">

      <voxel-form-field
        [formGroup]="form"
        label="Serie"
        [msgErr]="serieCustomErrorMessage"
        class="input-container">
        <input
          voxelInput
          id="serieId"
          [value]="docSerie"
          formControlName="docSerie"
          maxlength="1"
          (ngModelChange)="newInputChange('docSerie')">
      </voxel-form-field>

    </div>
    <div class="container-folio">

      <voxel-form-field
        [formGroup]="form"
        label="Número de folio"
        msgErr="Valor inválido"
        class="input-container">
      <input
        #pageNumberInput
        voxelInput
        id="mumberId"
        formControlName="pageNumber"
        voxelMask="999999"
        (blur)="onPageNumberBlur()"
        (ngModelChange)="newInputChange('pageNumber')">
      </voxel-form-field>

    </div>

    <div id="containetImage" class="container-image">
      <img class="old-document-image" [src]="'assets/images/cedula_antigua_dorso_01_v2.png'" />
    </div>

  </main>
</div>

<footer >

  <button-sidebyside
    nextHook="true"
    (hookNextAction)="doNext()"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="navigationService">
  </button-sidebyside>

</footer>

<voxel-modal [showModal]="modalShow">
  <ng-template #modalContent>
    <h2 class="voxel-text-xl">Los datos del documento no son correctos : (</h2>
    <div class='sub-text-14'>
      Por favor ingresá los datos de tu cédula nuevamente
    </div>
  </ng-template>
  <ng-template #modalActions>
    <voxel-button
      label="Reintentar"
      (click)="maskAsRead()">
    </voxel-button>
    <voxel-button
      label=" "
      (click)="toggleModal()"
      typeButton="secondary">
    </voxel-button>
  </ng-template>
</voxel-modal>

<facebook-pixel></facebook-pixel>
