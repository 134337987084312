import { Component, Input, OnInit } from '@angular/core';
import { AccountOfferFull } from 'src/app/offers-full/models/account-offers.model';
import { CardBrandFull } from 'src/app/offers-full/models/card-brand.model';
import { OfferPackageFull } from 'src/app/offers-full/models/offer-package-model';
import { OffersFullModel } from 'src/app/offers-full/models/offers-model';

import { NumberHelperService } from './../../../shared/helper/number-helper-service';
import { SummaryConfirmationIconEnum } from './../../../shared/summary-confirmation/shared/enum/summary-confirmation-icon-enum';

@Component({
  selector: 'client-summary-confirmation',
  templateUrl: './client-summary-confirmation.component.html',
  styleUrls: ['./client-summary-confirmation.component.scss']
})
export class ClientSummaryConfirmationComponent implements OnInit {
  @Input() icons: any[];

  @Input() overdraftLimit: string;

  @Input() offersModel: OffersFullModel;

  @Input() terms: boolean;
  @Input() urlTerms: string;

  @Input() cardType: string;
  @Input() cardBrand: CardBrandFull;
  @Input() cardCreditLimit: string;
  @Input() hyperlinkBehavior: string;
  @Input() isFacebookBrowserOniOS: boolean;

  cardCurrency: string;
  accounts: AccountOfferFull[];
  accountOffer: AccountOfferFull;
  offerPackage: OfferPackageFull;
  modalShow = false;
  debitCard: AccountOfferFull;

  constructor(private numberhelpService: NumberHelperService) {}

  ngOnInit() {
    this.setData();
  }

  setData() {
    // tslint:disable-next-line: early-exit
    if (this.offersModel && (this.offersModel.selected_package !== null || this.offersModel.selected_package !== undefined)) {
      this.offerPackage = this.offersModel.packages[this.offersModel.selected_package];
      if (this.offerPackage) {
        this.accounts = this.offerPackage.account_offers.filter(x => x.account_type.length === 2);
        this.debitCard = this.offerPackage.account_offers.find(x => x.account_type.length > 2);
        this.accountOffer = this.offerPackage.account_offers.find(
          x => x.account_type === 'CC' && x.overdraft_limit !== undefined
        );
        if (this.accountOffer) {
          this.overdraftLimit = this.setMask(this.overdraftLimit, this.accountOffer.overdraft_limit.decimals);
        }
        if (this.offerPackage.card_offers) {
          this.cardCurrency = this.offerPackage.card_offers[0].limit.currency;
          this.cardCreditLimit = this.setMask(this.cardCreditLimit, this.offerPackage.card_offers[0].limit.decimals);
        }
        this.categoryIcon(this.accounts);
        this.cardIcon(this.debitCard);
      }
    }
  }

  setMask(value: string, decimals: number): string {
    let result = null;
    if (value && decimals >= 0) {
      result = this.numberhelpService.numberFormat(value, decimals);
    }
    return result;
  }

  categoryIcon(accountList) {
    const icon = this.icons.find(i => i.code === SummaryConfirmationIconEnum.CA.toString());
    // tslint:disable-next-line: early-exit
    if (icon) {
      for (const account of accountList) {
        account.icon = icon.label;
      }
    }
  }
  cardIcon(debitCard) {
    // tslint:disable-next-line: early-exit
    if (debitCard) {
      const icon = this.icons.find(i => i.code === 'tarjeta');
      if (icon) {
        debitCard.icon = icon.label;
      }
    }
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }

  getIcon() {
    return {
      'icon-itaufonts_aplicacao': !this.isFacebookBrowserOniOS,
      'icon-itaufonts_fatura': this.isFacebookBrowserOniOS
    };
  }
}
