<div class="main-container content-with-filled-button">
  <div class="main-container__cover">
    <img src="assets/images/error-try-later-icon.svg" alt="TryLater">
  </div>
  <div class="main-container__content">
    <h1>Hubo un error inesperado : (</h1>
    <span [innerHTML]="errorMessage1" class="text-size"></span>
    <p style="margin-top: 0.5rem !important;"></p>
    <span [innerHTML]="errorMessage2" class="text-size"></span>
    <div class="error-try-later-content" *ngFor="let info of errorInfo index as i;">
      <div class="error-try-later-content__icon-div">
        <i class="icon {{info.icon}}"></i>
      </div>
      <div class="error-try-later-content__text-div">
        <div *ngIf="info.title">
          <span class="error-try-later-content__title">{{info.title}}</span>
          <br>
        </div>
        <span class="error-try-later-content__text">
          <a class="link-itau-font" [href]="info.href">{{info.subtitle}}</a>
        </span>
      </div>
    </div>
  </div>
</div>

<footer>
  <voxel-button
    id="errors-maintenance-volver"
    label="volver"
    typeButton="secondary"
    (click)="back()">
  </voxel-button>
</footer>
