import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { LoaderState } from './latam-it-loader-state';
import { LatamItLoaderService } from './latam-it-loader.service';

@Component({
  selector: 'latam-it-loader',
  templateUrl: './latam-it-loader.component.html',
  styleUrls: ['./latam-it-loader.component.scss']
})
export class LatamItLoaderComponent implements OnInit, OnDestroy {

  show = false;

  private subscription: Subscription;

  constructor(
    private latamItLoaderService: LatamItLoaderService
  ) {}

  ngOnInit() {
    this.subscription = this.latamItLoaderService.loaderState
    .subscribe((state: LoaderState) => {
      this.show = state.show;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
