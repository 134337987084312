import { Component, OnInit } from '@angular/core';

import { GetReadyModel } from '../models/get-ready-model';
import { DocumentTypeEnum } from '../shared/enums/document-type-enum';
import { RoutesGetReadyEnum } from '../shared/enums/routes-get-ready-enum';
import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';
import { Analytics } from '../../shared/analytics';
import { LatamItLoaderService } from '../../../latam-it-loader/latam-it-loader.service';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';

@Component({
  selector: 'preparate-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.scss'],
})
export class DocumentTypeComponent implements OnInit {
  pageId: string;
  isBtnDisabled: boolean;
  hasChip: boolean;
  notHasChip: boolean;
  getReadyForm: GetReadyModel;

  constructor(
    public navigationService: GetReadyNavigationService,
    private getReadyFormService: GetReadyFormService,
    private latamItLoaderService: LatamItLoaderService,
    private analyticsLib: Analytics,
    private sellerService: SellerService
  ) {}

  ngOnInit() {
    this.pageId = 'document_type';
    this.getReadyForm = this.getReadyFormService.getFormGetReady();
    this.refreshButton();
    this.checkType(this.getReadyForm.identity_documents[0].type);
    this.navigationService.setStage(RoutesGetReadyEnum.DOCUMENT_TYPE);

    if (!this.sellerService.isSeller()) {
      this.analyticsLib.buildData('TipoDeCedula', this.pageId, '02', false);
      this.analyticsLib.trackState(null, null);
    }


  }

  refreshButton() {
    this.isBtnDisabled = !this.getReadyForm.identity_documents[0].type;
  }

  changeType(value) {
    this.hasChip = value.id === 'chip';
    this.notHasChip = !this.hasChip;
    this.getReadyForm.identity_documents[0].type = this.setType();
    this.refreshButton();
  }

  setAtual() {
    let atual = 1;
    if (this.getReadyFormService.getProperty('solicitation_type') === 'aperturadecuentaspj') {
      atual = 4;
    }
    return atual;
  }

  private setType(): string {
    return this.hasChip ? DocumentTypeEnum.HASCHIP.toString() : DocumentTypeEnum.NOTHASCHIP.toString();
  }

  private checkType(type: string) {
    if (type) {
      type === DocumentTypeEnum.HASCHIP.toString() ? (this.hasChip = true) : (this.notHasChip = !this.hasChip);
    }
  }
}
