import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { AbreCuentasRoutesEnum } from 'src/app/shared/enum/abre-cuentas-routes.enum';
import { VoxelItSelfieService } from 'src/latam-it-camera-selfie/voxel-it-selfie.service';
import { DocumentPhotosCommonService } from '../shared/document-photos-common.service';

import { DocumentPhotosNavigationService } from '../shared/document-photos-navigation.service';
import { DocumentPhotoApproach, RoutesDocumentPhotosEnum } from '../shared/enums/routes-document-photos-enum';


@Component({
  selector: 'document-front-stage',
  templateUrl: './document-front-stage.component.html',
  styleUrls: ['../shared/document-photos-styles.scss']
})
export class DocumentFrontStageComponent implements OnInit, OnDestroy {

  pageId = 'document-front-stage';
  buttonText: string;
  private successRoute = RoutesDocumentPhotosEnum.DOCUMENT_FRONT_PHOTO;
  private permissionDeniedRoute = AbreCuentasRoutesEnum.PERMISSION_REQUIRED.toString();
  private errorRoute = AbreCuentasRoutesEnum.CAMERA_ERROR.toString();


  constructor(
    public navigationService: DocumentPhotosNavigationService,
    private elementRef: ElementRef,
    private commonService: DocumentPhotosCommonService,
    private voxelItSelfieService: VoxelItSelfieService
  ) {}

  ngOnInit() {
    this.buttonText = this.commonService.choice === DocumentPhotoApproach.PHOTO ? 'Activa cámara' : 'Continuar';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#353535';
    this.navigationService.setStage(RoutesDocumentPhotosEnum.DOCUMENT_FRONT_STAGE);
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
  }

  next() {
    if (this.commonService.choice === DocumentPhotoApproach.PHOTO) {
      this.voxelItSelfieService.getUserMediaPermission(this.successRoute, this.permissionDeniedRoute, this.errorRoute);
    } else {

      this.navigationService.sendToNext();
    }
  }

}
