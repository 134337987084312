import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';


@Component({
  selector: 'button-sidebyside',
  styleUrls: ['./button-sidebyside.component.scss'],
  templateUrl: './button-sidebyside.component.html'
})
export class ButtonSideBySideComponent implements OnInit, OnDestroy {

  @Input() isBtnDisabled;

  @Output() hookNextAction = new EventEmitter();
  @Input() nextHook: boolean;

  @Output() hookPreviousAction = new EventEmitter();
  @Input() previousHook: boolean;

  @Input() navigationService: CommonNavigationService;

  @Input() label1 = 'Volver';

  @Input() label2 = 'Continuar';

  isBothBtnDisabled;

  constructor(private buttonSideBySideHelperService: ButtonSideBySideHelperService) {
  }

  ngOnInit(): void {
    this.isBothBtnDisabled = false;
    this.buttonSideBySideHelperService.emitter.subscribe(event => {
      if (event === 'enableDisableButtons') {
        this.isBothBtnDisabled = !this.isBothBtnDisabled;
      }
    });
  }

  ngOnDestroy(): void {
    this.buttonSideBySideHelperService.emitter = new EventEmitter();
  }

  next() {
    if (this.nextHook) {
      this.hookNextAction.emit(null);
    } else {
      this.navigationService.sendToNext();
    }
  }

  previous() {
    if (this.previousHook) {
      this.hookPreviousAction.emit(null);
    } else {
      this.navigationService.sendToPrevious();
    }
  }

}
