import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';

import { Router } from '@angular/router';
import { RoutesFinalDataEnum } from '../../final-data/shared/enums/routes-final-data-enum';
import { AddressFormService } from './address-form.service';
import { RoutesAddressEnum } from './enums/routes-address-enum';
import { RoutesPldEnum } from './../../pld/shared/enums/routes-pld-enum';

@Injectable()
export class AddressNavigationService extends CommonNavigationService {
  constructor(
    route: Router,
    private addressFormService: AddressFormService,
    buttonSideBySideHelperService: ButtonSideBySideHelperService,
    private appService: AppService,
  ) {
    super(route, buttonSideBySideHelperService);

    this.step = RoutesAddressEnum.ADDRESS_HOME;
    this.stepAmount = 5;
    this.flows = [
      {
        stage: RoutesAddressEnum.ADDRESS_HOME,
        previous: RoutesPldEnum.CREDITS,
        next: this.resolveNextScreenPersonalAddress,
      },
      {
        stage: RoutesAddressEnum.ADDRESS_PROOF,
        previous: RoutesAddressEnum.ADDRESS_HOME,
        next: RoutesAddressEnum.DELIVERY_ADDRESS,
      },
      {
        stage: RoutesAddressEnum.DELIVERY_ADDRESS,
        previous: this.resolvePreviousScreenDeliveryAddress,
        next: this.navigateToDeliveryTime,
      },
      {
        stage: RoutesAddressEnum.DELIVERY_TIME,
        previous: RoutesAddressEnum.DELIVERY_ADDRESS,
        next: RoutesFinalDataEnum.CONFIRMATION,
      },
      {
        stage: RoutesAddressEnum.ADDRESS_PROOF_FACEBOOK,
        previous: RoutesAddressEnum.ADDRESS_PROOF,
        next: RoutesAddressEnum.DELIVERY_ADDRESS,
      },
    ];
  }

  resolveNextScreenPersonalAddress() {
    if (this.personalAddressNotChanged()) {
      return RoutesAddressEnum.DELIVERY_ADDRESS;
    }
    return RoutesAddressEnum.ADDRESS_PROOF;

  }

  resolvePreviousScreenDeliveryAddress() {
    if (this.personalAddressNotChanged()) {
      return RoutesAddressEnum.ADDRESS_HOME;
    }
    return RoutesAddressEnum.ADDRESS_PROOF;

  }

  navigateToDeliveryTime() {
    if (
      this.addressFormService.getProperty('pickup_center') ||
      this.addressFormService.getProperty('oca_center') ||
      this.addressFormService.getProperty('tata_center')
    ) {
      return RoutesFinalDataEnum.CONFIRMATION;
    }
    return RoutesAddressEnum.DELIVERY_TIME;

  }

  personalAddressNotChanged() {
    return (
      this.addressFormService.personalAddressProspect &&
      // tslint:disable-next-line:max-line-length
      this.addressFormService.getForm().personal_address.street.toUpperCase() ===
        this.addressFormService.personalAddressProspect.street.toUpperCase() &&
      this.addressFormService.getForm().personal_address.state.toUpperCase() ===
        this.addressFormService.personalAddressProspect.state.toUpperCase()
    );
  }
}
