import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressBarService } from '../progressBar.service';
import { SharedModule } from '../shared/shared.module';
import { CreditsFullComponent } from './credits/credits.component';
import { MoneyFullComponent } from './money/money.component';
import { PldFullRoutingModule } from './pld.routes';
import { PurposeFullComponent } from './purpose/purpose.component';
import { PldFullCommonService } from './shared/pld-common.service';
import { PldFullFormService } from './shared/pld-form.service';
import { PldFullNavigationService } from './shared/pld-navigation.service';


@NgModule({
  imports: [
    PldFullRoutingModule,
    SharedModule,
    CommonModule,
  ],
  providers: [
    PldFullNavigationService,
    PldFullCommonService,
    PldFullFormService,
    ProgressBarService
  ],
  declarations: [
    PurposeFullComponent,
    MoneyFullComponent,
    CreditsFullComponent,
  ],
})
export class PldFullModule {}
