import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorTryLaterComponent } from './shared/error-try-later/error-try-later.component';
import { ErrorIncompatibleBrowserComponent } from './shared/error-incompatible-browser/error-incompatible-browser.component';
import { PermissionRequiredComponent } from './shared/permission-required/permission-required.component';
import { CameraErrorComponent } from './shared/camera-error/camera-error.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'preparate-contactamos-full',
  },
  {
    path: 'preparate-bienvenida',
    pathMatch: 'full',
    redirectTo: 'preparate-contactamos-full',
  },
  {
    path: 'preparate-bienvenida-vendor',
    pathMatch: 'full',
    redirectTo: 'preparate-contactamos',
  },
  {
    path: 'cliente-oferta',
    pathMatch: 'full',
    redirectTo: 'cliente-datos-contacto',
  },
  {
    path: 'error-try-later',
    component: ErrorTryLaterComponent,
  },
  {
    path: 'error-navegador-incompatible',
    component: ErrorIncompatibleBrowserComponent
  },
  {
    path: 'permiso-requerido',
    component: PermissionRequiredComponent
  },
  {
    path: 'error-camara',
    component: CameraErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
