<div class="confirmation__container" [ngClass]="{'confirmation__container__border-color': !terms}" style="min-height: 260px">
  <h2 *ngIf="!terms">Cuentas</h2>
  <div class="confirmation__package" [ngClass]="{'confirmation__package__top': terms}">
    <div *ngFor="let account of accounts; let i = index">
      <i class="icon confirmation__package__icons oca-icon {{account.icon}}"></i>
      <div class="confirmation__information confirmation__information__less-margin">
        <div class="confirmation__information__margin">
          <div class="confirmation__information__title">
            {{account.account_type | accountType}} {{account.currency | currencyDescription: 'description'}}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="debitCard">
      <i class="icon confirmation__package__icons {{debitCard.icon}}"></i>
      <div class="confirmation__information confirmation__information__less-margin">
        <div class="confirmation__information__margin">
          <div class="confirmation__information__title">
            {{debitCard.account_type}} OCA Blue
          </div>
          <div class="confirmation__information__subtitle v-21">
            <img src="assets/images/visa.svg" alt="Visa" style="width: 50px; margin-top: 6px;">
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr *ngIf="offersModel.accepted_credit_card" class="confirmation__container__hr">

  <div class="confirmation__package" style="position:relative;" *ngIf="offersModel.accepted_credit_card">
    <h2>Tarjeta de crédito</h2>
    <i class="icon confirmation__package__icons icon-itaufonts_cartoes"></i>
    <div class="confirmation__information" style="height: 2rem">
      <div class="confirmation__information__title">
        Tarjeta OCA
      </div>
    </div>
    <div class="confirmation__information confirmation__information__margin">
      <div class="confirmation__information__title">
        <span style="font-size: 0.88rem;"><i class="icon icon-itaufonts_chat_enviado"></i></span> <span style="position: absolute;"> Límite de crédito</span>
      </div>
      <div class="confirmation__information__subtitle cc-55">
        <span style="margin-left: 7px">{{cardCurrency | currencyDescription: 'symbol'}}{{cardCreditLimit}}</span>
      </div>
    </div>
    <div class="confirmation__information confirmation__information__margin">
      <div class="confirmation__information__title">
        <span style="font-size: 0.88rem"><i class="icon icon-itaufonts_chat_enviado"></i></span> <span style="position: absolute;"> Fecha de cierre</span>
      </div>
      <div class="confirmation__information__subtitle cc-55">
        <span style="margin-left: 7px">En el día {{offersModel.closing_date}}</span>
      </div>
    </div>
  </div>

  <hr *ngIf="terms" class="confirmation__container__hr">
