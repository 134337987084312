import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressBarService } from 'src/app/progressBar.service';
import { LatamErrorMessageService } from 'src/app/shared/latam-error-message/latam-error-message.service';
import { AdminListEnum } from 'src/app/shared/services/admin-lists/enum/admin-list.enum';
import { ProspectService } from 'src/app/shared/services/domains/prospect/prospect.service';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

import { RoutesOffersEnum } from '../shared/enums/routes-offers-enum';
import { OffersCommonService } from '../shared/offers-common.service';
import { OffersFormService } from '../shared/offers-form.service';
import { OffersNavigationService } from '../shared/offers-navigation.service';

@Component({
  selector: 'offers-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['../shared/offers-styles.scss', './plans.component.scss', '../shared/offers-styles-carousel.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlansComponent implements OnInit, OnDestroy {
  pageId = '02_plans';

  packageDescription: any;
  packageSelected: number;
  packages: any;
  body = document.querySelector('body');
  name: string;
  pageReady = false;

  constructor(
    private offersCommonService: OffersCommonService,
    private offersFormService: OffersFormService,
    private navigationService: OffersNavigationService,
    private route: Router,
    private prospectService: ProspectService,
    private latamItLoaderService: LatamItLoaderService,
    private latamErrorMessageService: LatamErrorMessageService,
    private progressBarService: ProgressBarService
  ) {}

  ngOnInit() {
    this.navigationService.setStage(RoutesOffersEnum.PLANS);
    const self = this;
    // tslint:disable-next-line: early-exit
    if (self.offersCommonService.offerReady) {
      self.initializePage(self);
    } else {
      self.getOffers(() => {
        self.initializePage(self);
      });
    }
  }

  initializePage(self) {
    self.name = self.prospectService.getFirstName();
    self.packageSelected = self.offersFormService.getProperty('selected_package') || 0;
    const index = self.offersCommonService.offersResponse.packages.findIndex(elem => elem.package_id === '2');
    if (index > -1) {
      self.offersCommonService.offersResponse.packages = [self.offersCommonService.offersResponse.packages[index]];
    } else {
      self.offersCommonService.offersResponse.packages =
        self.offersCommonService.offersResponse.packages.length > 0
          ? [self.offersCommonService.offersResponse.packages[0]]
          : [];
      self.packages = self.offersCommonService.offersResponse.packages;
    }

    self.addDescription();
    self.checkSpecialOffers();
    self.body.setAttribute('style', 'background-color: #EFE9E5');
    self.offersCommonService.scrollToTop(document);
    self.pageReady = true;
  }

  getOffers(callback) {
    this.latamItLoaderService.show();
    this.offersCommonService.doGetOffers().subscribe(
      res => {
        // tslint:disable-next-line: early-exit
        if (res.statusCode === 200) {
          this.offersCommonService.offersResponse = res.data;
          for (const packageIndex in this.offersCommonService.offersResponse.packages) {
            // tslint:disable-next-line: early-exit
            if (packageIndex) {
              this.offersCommonService.offersResponse.packages[packageIndex].benefits = this.offersCommonService.adminlists[
                AdminListEnum.ACCOUNT_BENEFITS
              ];
              this.offersCommonService.offersResponse.packages[packageIndex].commissions = this.offersCommonService.adminlists[
                AdminListEnum.ACCOUNT_COMMISSIONS
              ];
            }
          }
          this.offersFormService.setForm(this.offersCommonService.offersResponse);
          this.offersCommonService.offerReady = true;
          this.latamItLoaderService.hide();
          callback();
        } else {
          this.handleRequestError(res.statusCode, res.data.mensagem, res.errorCount, callback);
        }
      },
      err => {
        this.handleRequestError(err.status, err.statusText, err.count, callback);
      }
    );
  }

  handleRequestError(errorCode: string, errorMessage: string, errorCount: number, callback: any) {
    this.latamErrorMessageService.hide();
    this.latamItLoaderService.hide();
    if (errorCount < 11) {
      this.getOffers(callback);
    }
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 2,
      amountBar: 4,
      currentBar: 1,
      labelName: 'productos'
    });
  }

  addDescription() {
    this.packages.forEach(p => {
      // tslint:disable-next-line: early-exit
      if (p.package_description && this.packageNotAdded(p)) {
        p.account_offers.push({
          account_type: p.package_description,
          general_info: {
            product_description: ''
          }
        });
      }
    });
  }

  packageNotAdded(p) {
    return p.account_offers.every(item => item.account_type !== p.package_description);
  }

  checkSpecialOffers() {
    this.packages.forEach(p => {
      p.benefits.forEach(benefit => {
        // tslint:disable-next-line: early-exit
        if (benefit.type === 'special' && this.benefitNotAdded(benefit, p.account_offers)) {
          p.account_offers.push({
            account_type: benefit.title,
            general_info: {
              product_description: ''
            }
          });
        }
      });
    });
  }

  benefitNotAdded(benefit, packageOffers) {
    return packageOffers.every(item => item.account_type !== benefit.title);
  }

  updatePackage() {
    this.offersFormService.getForm().accepted_credit = undefined;
    this.offersFormService.getForm().accepted_credit_card = undefined;
    this.offersFormService.getForm().selected_package = this.packageSelected;
  }

  openPackageDetails() {
    this.route.navigate([RoutesOffersEnum.BENEFITS_COMISSIONS.toString()]);
  }

  nextScreen() {
    this.updatePackage();

    if (
      this.offersFormService.getSelectedPackage().card_offers &&
      this.offersFormService.getSelectedPackage().card_offers.length > 0
    ) {
      this.navigationService.sendToNext();
    } else {
      this.offersCommonService.doPatchSolicitationPackageSelected();
    }
  }

  ngOnDestroy() {
    this.body.setAttribute('style', 'background-color: white');
  }

  slideEvent(slide) {
    this.packageSelected = slide;
    this.updatePackage();
  }

  withDecimals(object): boolean {
    let valid = false;
    if (object.decimals !== 0) {
      return (valid = true);
    }
    return valid;
  }

  format(object): string {
    let value = object.amount;
    value = object.amount.toString().slice(0, -object.decimals);
    return value;
  }

  decimals(object) {
    let decimals: any;
    decimals = object.amount.toString().slice(-object.decimals);
    return decimals;
  }
}
