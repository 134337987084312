import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { AppService } from '../../app.service';
import { ServiceCommon } from '../../shared/service.common';
import { ListConversionService } from '../../shared/services/admin-lists/list-conversion-service/list-conversion.service';
import { ProspectService } from '../../shared/services/domains/prospect/prospect.service';
import { SolicitationMethods } from '../../shared/services/domains/solicitation/solicitation-enum';
import { TermsMethods } from '../../shared/services/domains/terms/terms-enum';
import { TermsService } from '../../shared/services/domains/terms/terms.service';
import { LoginService } from '../../shared/services/login.service';
import { ClientModel } from '../models/client-model';
import { LatamItLoaderService } from './../../../latam-it-loader/latam-it-loader.service';
import { AbreCuentasRoutesEnum } from './../../shared/enum/abre-cuentas-routes.enum';
import { GenericModel } from './../../shared/models/generic-model';
import { ModuleLists } from './../../shared/services/admin-lists/const/module-lists.constant';
import { AdminListEnum } from './../../shared/services/admin-lists/enum/admin-list.enum';
import { ProspectMethods } from './../../shared/services/domains/prospect/prospect-enum';
import { ValidationService } from './../../shared/services/validation-service/validation.service';
import { ClientFormService } from './client-form.service';
import { ClientNavigationService } from './client-navigation.service';
import { ClientCurrencyValueModel } from './models/client-currency-value';

@Injectable()
export class ClientCommonService extends ServiceCommon {
  static PROSPECT_TYPE = 'TRACKING-CLIENTE-PANTALLA-3';

  states: any[];
  cities: any[];
  pickupCenterState: any[];
  ocaCenterState: any[];
  tataCenterState: any[];
  offersResponse: any;
  forbiddenChars: string[];

  constructor(
    private appService: AppService,
    private formService: ClientFormService,
    public listConversionService: ListConversionService,
    private termsService: TermsService,
    private latamItLoaderService: LatamItLoaderService,
    private navigationService: ClientNavigationService,
    private prospectService: ProspectService,
    loginService: LoginService,
    private validationService: ValidationService
  ) {
    super(listConversionService, loginService);
    this.cities = [];
    this.states = [];
    this.pickupCenterState = [];
    this.ocaCenterState = [];
    this.tataCenterState = [];
    this.onInit();
  }

  onInit() {
    this.setModuleLists(ModuleLists.client);
    this.createStateCitiesList();

    this.pickupCenterState = this.uniqueStatesList(
      this.listConversionService.convertListforVoxelSelect(this.getAdminList(AdminListEnum.PICKUP_CENTER), 'state', 'state')
    );

    this.ocaCenterState = this.uniqueStatesList(
      this.listConversionService.convertListforVoxelSelect(this.getAdminList(AdminListEnum.OCA_CENTER), 'state', 'state')
    );

    this.tataCenterState = this.uniqueStatesList(
      this.listConversionService.convertListforVoxelSelect(this.getAdminList(AdminListEnum.TATA_CENTER), 'state', 'state')
    );

    this.offersResponse = this.getOffer();
    this.formService.setProperty('packages', this.offersResponse.packages);
    this.forbiddenChars = ['á', 'à', 'â', 'ã', 'é', 'è', 'ê', 'í', 'ì', 'î', 'ó', 'ò', 'ô', 'õ', 'ú', 'ù', 'û', 'ñ'];
  }

  createStateCitiesList() {
    const listDepartments = this.listConversionService.convertListforVoxelSelect(
      this.getAdminList(AdminListEnum.STATE_CITY),
      'state',
      'state'
    );
    Observable.merge(listDepartments)
      .distinct(x => x.label)
      .subscribe(y => this.states.push(y));
    this.cities = this.listConversionService.convertListforVoxelSelect(
      this.getAdminList(AdminListEnum.STATE_CITY),
      'city',
      'state'
    );
  }

  getDefaultOption(): GenericModel {
    return { label: 'Seleccioná una opción', id: '', value: '', itemSelectedValue: { value: '' } };
  }

  doGetTerms(): Observable<any> {
    const bodyPatch = this.buildBodyGetTerms();
    return this.termsService.getTermsRequest(bodyPatch, TermsMethods.GET_TERMS, true);
  }

  buildBodyGetTerms() {
    return {
      product: 'aperturadecuentas',
      category: 'confirmation'
    };
  }

  doPostSolicitationComplete(): void {
    this.latamItLoaderService.show();
    const bodyPatch = this.buildPostSolicitationCompleteBody();
    this.appService.doPost(bodyPatch, SolicitationMethods.POST_COMPLETE, false, false).subscribe(
      res => {
        if (res.statusCode === 200) {
          this.formService.setProperty(
            'duplicate',
            res.data && res.data.solicitation_status && res.data.solicitation_status.status_code === 'rejected'
          );
          this.navigationService.sendToNext();
          console.log('doPostSolicitationComplete', res);
        } else {
          this.appService.resolveOperationError(SolicitationMethods.POST_COMPLETE);
        }
        this.latamItLoaderService.hide();
      },
      err => {
        this.handleSystemErrors(err);
        this.latamItLoaderService.hide();
      }
    );
  }

  buildPostSolicitationCompleteBody() {
    const body: any = {
      identity_documents: this.formService.getProperty('identity_documents'),
      solicitation_type: 'aperturadecuentas',
      phone: this.formService.getProperty('phone').replace(/\D/g, ''),
      email: this.formService.getProperty('email'),
      personal_address: this.formService.getProperty('personal_address'),
      account_purpose: this.formService.getProperty('account_purpose'),
      avg_balance: this.toCurrency(this.formService.getProperty('avg_balance'), 'UYU'),
      amount_quantity_per_month: this.toCurrency(this.formService.getProperty('amount_quantity_per_month'), 'UYU'),
      is_american: this.formService.getProperty('is_american'),
      channel_id: this.appService.getOrigin()
    };

    if (this.formService.getProperty('pickup_center')) {
      body['pickup_center'] = this.formService.getProperty('pickup_center');
    } else if (this.formService.getProperty('oca_center')) {
      body['pickup_center'] = this.formService.getProperty('oca_center');
    } else if (this.formService.getProperty('tata_center')) {
      body['pickup_center'] = this.formService.getProperty('tata_center');
    } else {
      body['delivery_address'] = {
        address: this.formService.getProperty('delivery_address'),
        availability: this.formService.getProperty('availability')
      };
    }

    return body;
  }

  toCurrency(object: ClientCurrencyValueModel, currency) {
    object.decimals = 0;
    object.currency = currency;
    return object;
  }

  getOffer() {
    return {
      packages: [
        {
          package_id: '1',
          package_title: 'Cuentas',
          package_description: 'Tarjeta de débito',
          account_offers: [
            {
              general_info: {
                product_type: 'C',
                product_description: 'sin promedio mínimo'
              },
              account_type: 'CA',
              currency: 'UYU'
            },
            {
              general_info: {
                product_type: 'C',
                product_description: 'sin promedio mínimo'
              },
              account_type: 'CA',
              currency: 'USD'
            }
          ],
          prices: [],
          commissions: this.getAdminList(AdminListEnum.ACCOUNT_COMMISSIONS),
          benefits: this.getAdminList(AdminListEnum.ACCOUNT_BENEFITS)
        }
      ]
    };
  }

  getClientData(callback) {
    let bodyGet;

    if (this.appService.getClientToken()) {
      bodyGet = { token: this.appService.getClientToken() };
    } else {
      bodyGet = { session: this.appService.getClientSessionId() };
    }

    this.prospectService.prospectRequest(bodyGet, ProspectMethods.GET_PROSPECT, true, true).subscribe(
      res => {
        // tslint:disable-next-line: early-exit
        if (res.statusCode === 200 && res.data) {
          this.setClientName(res.data);
          this.setClientDocument(res.data);
          this.setClientPhone(res.data);
          this.setClientEmail(res.data);
          this.setClientPersonalAddress(res.data);
          this.setClientId(res.data);
          this.formService.setProperty('client_loaded', true);
          callback();
        } else {
          this.navigationService.navigateTo(AbreCuentasRoutesEnum.TRY_AGAIN_LATER_ERROR);
        }
      },
      err => {
        this.navigationService.navigateTo(AbreCuentasRoutesEnum.TRY_AGAIN_LATER_ERROR);
      }
    );
  }

  setClientName(data) {
    // tslint:disable-next-line: early-exit
    if (data.first_name && data.first_name.trim()) {
      let firstName = data.first_name.trim().split(' ')[0];
      if (firstName) {
        firstName = firstName[0].toUpperCase() + firstName.substring(1).toLowerCase();
      }
      this.formService.setProperty('name', firstName);
    }
  }

  setClientDocument(data) {
    // tslint:disable-next-line: early-exit
    if (data.document && data.document[0] && data.document[0].number) {
      let fullNumber;
      for (let lastDigit = 0; lastDigit <= 9; lastDigit++) {
        const possibleNumber = data.document[0].number + lastDigit.toString();
        if (this.validationService.DocumentNumberValidation(possibleNumber)) {
          fullNumber = possibleNumber;
          break;
        }
      }
      if (fullNumber) {
        this.formService.setProperty('identity_documents', [{ number: fullNumber }]);
      }
    }
  }

  setClientPhone(data) {
    // tslint:disable-next-line: early-exit
    if (data.phone) {
      let phone = data.phone.replace(/ /g, '');

      if (phone.length === 9 && phone[2] !== '0') {
        phone = phone.slice(0, 3) + ' ' + phone.slice(3, 6) + ' ' + phone.slice(6, 9);
        this.formService.setProperty('phone', phone);
      }
    }
  }

  setClientId(data) {
    if (data.clientId) {
      const clientId = data.clientId.toString();
      this.formService.setProperty('client_id', clientId);
    }
  }

  setClientEmail(data) {
    if (data.email && this.isValidFormat(data.email) && !this.hasSpecialChars(data.email)) {
      this.formService.setProperty('email', data.email);
    }
  }

  setClientPersonalAddress(data) {
    // tslint:disable-next-line: early-exit
    if (data.personal_address) {
      const streetName = this.getFullStreet(data.personal_address);
      let stateName = this.getState(data.personal_address.state);
      let cityName;
      if (stateName) {
        cityName = this.getCity(stateName, data.personal_address.city);
        if (!cityName) {
          cityName = data.personal_address.city;
        }
      } else {
        stateName = data.personal_address.state;
        cityName = data.personal_address.city;
      }
      const address = {
        street: streetName,
        state: stateName,
        city: cityName
      };

      this.formService.setProperty('personal_address', address);
    }
  }

  getFullStreet(address) {
    let street = address.street;
    if (street) {
      if (address.street_number && address.street_number !== '0') {
        street = street + ' ' + address.street_number;
      }
      if (address.floor && address.floor !== '0') {
        street = street + ' ' + address.floor;
      }
      if (address.door && address.door !== '0') {
        street = street + ' ' + address.door;
      }
      street = street === '0' ? '' : street.substring(0, 40);
    }
    return street;
  }

  getState(stateName) {
    let res;
    if (stateName) {
      const self = this;
      const selectedOption = this.states.find(
        stateOption =>
          self.stripAccents(stateOption.label.toLowerCase().replace(/ /g, '')) ===
          self.stripAccents(stateName.toLowerCase().replace(/ /g, ''))
      );
      if (selectedOption) {
        res = selectedOption.label;
      }
    }
    return res;
  }

  getCity(stateName, cityName) {
    let res;
    if (stateName && cityName) {
      const self = this;
      const selectedOption = this.cities.find(
        cityOption =>
          cityOption.id === stateName &&
          self.stripAccents(cityOption.label.toLowerCase().replace(/ /g, '')) ===
            self.stripAccents(cityName.toLowerCase().replace(/ /g, ''))
      );
      if (selectedOption) {
        res = selectedOption.label;
      }
    }
    return res;
  }

  stripAccents(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  isValidFormat(email: string) {
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(email);
  }

  hasSpecialChars(email: string) {
    if (email) {
      const lowerCaseEmail = email.toLowerCase();
      for (const char of lowerCaseEmail) {
        if (this.forbiddenChars.indexOf(char) > -1) {
          return true;
        }
      }
    }
    return false;
  }

  postProspect(model: ClientModel): void {
    const bodyPost = {
      email: model.email,
      phone: model.phone.replace(/\D/g, ''),
      client_id: model.client_id,
      prospect_type: ClientCommonService.PROSPECT_TYPE
    };

    this.prospectService.prospectRequest(bodyPost, ProspectMethods.POST_PROSPECT_GET_READY, false, false).subscribe(
      res => {
        if (res.statusCode === 200) {
          this.prospectService.setClientFlowId(res.data.prospect_id);
        }
        console.log('Post Prospect Phone/Email', res);
      },
      err => {
        console.log('Ha ocurrido un error' + err);
      }
    );
    this.navigationService.sendToNext();
  }

  doPatchProspectTracking(body): void {
    this.appService.doPost(body, ProspectMethods.PATCH_PROSPECT_TRACKING, false, false).subscribe(
      res => {
        if (res.statusCode === 200) {
          console.log('doPatchProspectTracking', res);
        }
      },
      err => {
        console.log('Ha ocurrido un error', err);
      }
    );
  }

  private uniqueStatesList(listVoxelSelectState) {
    const states = new Set();

    return listVoxelSelectState.filter(pickupCenter => {
      if (states.has(pickupCenter.id)) {
        return false;
      }
      states.add(pickupCenter.id);
      return true;
    });
  }
}
