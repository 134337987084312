import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreditsFullComponent } from './credits/credits.component';
import { MoneyFullComponent } from './money/money.component';
import { PurposeFullComponent } from './purpose/purpose.component';


export const routes: Routes = [

  {
    path: 'pld-proposito-full',
    component: PurposeFullComponent
  },
  {
    path: 'pld-saldo-promedio-full',
    component: MoneyFullComponent
  },
  {
    path: 'pld-acreditaciones-mensuales-full',
    component: CreditsFullComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PldFullRoutingModule {}
