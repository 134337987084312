import { FinalDataFullModel } from '../models/final-data-model';

export class FinalDataFullFormService {

  private form: FinalDataFullModel;

  constructor() {
    this.form = new FinalDataFullModel();
  }

  getForm() {
    return this.form;
  }

  setForm(form: FinalDataFullModel) {
    this.form = form;
  }

  getProperty(property: any) {
    return this.form[property];
  }

  setProperty(property: string, value: any) {
    this.form[property] = value;
  }
}
