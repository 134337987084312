<div [id]="pageId"></div>

<div class="img-container">
  <img class="image" src="assets/images/AF_farol_amarelo.svg" alt="error_img">
</div>

<main>
  <h1 class="title title-duplicate-account">
    Ya tenés una cuenta en OCA ;)
  </h1>

  <h2 class="sub-title sub-title-duplicate-account">
    Si querés otra cuenta, dirigite a la sucursal OCA más cercana.
  </h2>

  <hr class="between" />

  <div class="normal-text">
    <div class="icon-div">
      <span>
        <i class="icon-itaufonts_celular"></i>
      </span>
    </div>
    <div class="text-div">
      <span>Accedé a tu cuenta OCA por la app.</span>
      <a class="link-itau-font" [href]="urlOCAApp">Descargala aquí</a>
    </div>
  </div>

</main>

<footer class="footer-page-error">
  <a class="link-itau" [href]="urlOCA">salir de apertura de cuentas</a>
</footer>

<facebook-pixel></facebook-pixel>
