import { Component, OnInit } from '@angular/core';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { RoutesPldEnum } from '../shared/enums/routes-pld-enum';
import { CurrencyValueModel } from '../shared/models/currency-value';
import { PldCommonService } from '../shared/pld-common.service';
import { PldFormService } from '../shared/pld-form.service';
import { PldNavigationService } from '../shared/pld-navigation.service';

@Component({
  selector: 'pld-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['../shared/pld-styles.scss']
})
export class CreditsComponent implements OnInit {
  pageId = 'pld-credits';
  creditAmounts: any[] = [];
  isBtnDisabled = false;
  hintObject: any;

  constructor(
    private pldFormService: PldFormService,
    private pldCommonService: PldCommonService,
    public navigationService: PldNavigationService
  ) {
    this.hintObject = {};
  }

  ngOnInit() {
    this.navigationService.setStage(RoutesPldEnum.CREDITS);
    this.creditAmounts = this.pldCommonService.getAdminList(AdminListEnum.AMOUNT_QUANTITY_PER_MONTH);
    this.resolveHint();
    this.refreshButton();

    // tslint:disable-next-line: early-exit
    if (this.pldFormService.getProperty('amount_quantity_per_month')) {
      this.creditAmounts.forEach(element => {
        const amount = this.pldFormService.getProperty('amount_quantity_per_month').amount;

        element.checked = !!(amount === element.code);
      });
    }
  }

  resolveHint() {
    this.hintObject.message = `Es la suma de acreditaciones/depósitos que esperás recibir generalmente en un mes en tu cuenta.
    Este valor debe incluir depósitos, transferencias, giros, etc, que realices vos, o terceros.`;
    this.hintObject.value = `offer`;
  }

  refreshButton() {
    this.isBtnDisabled =
      !this.pldFormService.getProperty('amount_quantity_per_month') ||
      !this.pldFormService.getProperty('amount_quantity_per_month').amount;
  }

  changeCreditAmount(event: any) {
    const balance = new CurrencyValueModel();
    balance.amount = event.value;
    this.pldFormService.setProperty('amount_quantity_per_month', balance);
    this.refreshButton();
  }

  nextButtonPressed() {
    this.pldCommonService.postPld(this.pldFormService.getPldForm());
  }
}
