import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProgressBarService } from 'src/app/progressBar.service';
import { ProspectService } from 'src/app/shared/services/domains/prospect/prospect.service';

import { ClientCommonService } from '../shared/client-common.service';
import { ClientFormService } from '../shared/client-form.service';
import { ClientNavigationService } from '../shared/client-navigation.service';
import { RoutesClientEnum } from '../shared/enums/routes-client-enum';
import { Analytics } from './../../shared/analytics';

@Component({
  selector: 'client-contact-info',
  templateUrl: './client-contact-info.component.html',
  styleUrls: ['./client-contact-info.component.scss']
})
export class ClientContactInfoComponent implements OnInit {
  private static TRACKING_CLIENTE = 'TRACKING-CLIENTE-PANTALLA-1';

  pageId = '04_contactInfo';
  isBtnDisabled = false;

  phoneNumber: string;
  email: string;
  emailErrorMessage: string;
  EmailErrorText: string;
  errorPhoneMessage: string;

  confirmEmail: string;
  confirmEmailErrorMessage: string;
  timeOutEmail;
  timeOutEmailConfirm;
  specialCharsErrorText;

  benefitCommissionsRoute = `/${RoutesClientEnum.BENEFITS_COMISSIONS.toString()}`;

  mask = [/[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/];

  form = new FormGroup({
    phoneNumber: new FormControl(),
    email: new FormControl(),
    confirmEmail: new FormControl()
  });

  constructor(
    private formService: ClientFormService,
    public navigationService: ClientNavigationService,
    private commonService: ClientCommonService,
    private prospectService: ProspectService,
    private analyticsLib: Analytics,
    private route: Router,
    private progressBarService: ProgressBarService
  ) {}

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.navigationService.sendToNext();
    }
  }

  ngOnInit() {
    if (!this.formService.getProperty('client_loaded')) {
      this.commonService.getClientData(() => {
        return;
      });
    }

    this.setProgressBarClient();
    this.phoneNumber = this.formService.getProperty('phone') || '';
    this.email = this.formService.getProperty('email') || '';
    this.confirmEmail = this.formService.getProperty('confirmEmail') || '';

    this.form.controls['email'].setValue(this.email);
    this.form.controls['confirmEmail'].setValue(this.confirmEmail);
    this.emailErrorMessage = '';
    this.confirmEmailErrorMessage = '';
    this.EmailErrorText = 'Ops, ingresá un e-mail válido para seguir';
    this.specialCharsErrorText = `Debés ingresar un e-mail válido para continuar. Verificá no incluir caracteres
                                  especiales, “ñ”  ni tildes`;

    this.timeOutEmail = null;
    this.timeOutEmailConfirm = null;
    this.refreshButton();

    this.formService.setProperty('accepted_credit', undefined);
    this.formService.setProperty('accepted_credit_card', undefined);
    this.formService.setProperty('selected_package', 0);

    this.analyticsLib.buildData('ComoTeContactamos', this.pageId, '02', true);

    this.analyticsLib.trackState(null, null);
    this.commonService.scrollToTop(document);

    if (this.formService.getPreviousStage() === 'ClientPersonalAddress') {
      this.doPatchProspectTracking();
    }

    this.formService.setPreviousStage('ClientContactInfo');
  }

  changePhone(event) {
    let result = event;
    result = this.form.controls['phoneNumber'].value;
    if (result.slice(0, 3) !== '090') {
      this.phoneNumber = result;
      this.formService.setProperty('phone', this.phoneNumber);

      if (result.length !== 9) {
        this.errorPhoneMessage = 'Número inválido. Intentá nuevamente, por favor.';
        this.form.controls['phoneNumber'].setErrors({ incorrect: true });
      }

      this.refreshButton();
      this.errorPhoneMessage = undefined;
    } else {
      this.errorPhoneMessage = 'No debe comenzar con 090';
      this.form.controls['phoneNumber'].setErrors({ incorrect: true });
    }
  }

  validateEmail(event, isOnChange: boolean) {
    if (isOnChange) {
      this.email = event ? event : null;
      this.refreshButton();
      this.emailErrorMessage = '';
      if (this.timeOutEmail) {
        clearTimeout(this.timeOutEmail);
      }

      this.timeOutEmail = setTimeout(() => {
        this.checkEmailError();
        this.refreshButton();
      }, 750);
    } else {
      this.refreshButton();
      this.checkEmailError();
    }
  }

  checkEmailError() {
    if (this.commonService.isValidFormat(this.email)) {
      if (this.commonService.hasSpecialChars(this.email)) {
        this.emailErrorMessage = this.specialCharsErrorText;
      } else {
        this.formService.setProperty('email', this.email);
        this.emailErrorMessage = '';
      }
    } else {
      this.emailErrorMessage = this.EmailErrorText;
    }
    if (this.confirmEmail) {
      this.checkConfirmationEmailError();
    }
  }

  checkConfirmationEmailError() {
    this.timeOutEmailConfirm = null;
    this.timeOutEmail = null;
    if (this.confirmEmail === this.email) {
      this.formService.setProperty('confirmEmail', this.confirmEmail);
      this.confirmEmailErrorMessage = '';
    } else {
      this.confirmEmailErrorMessage = 'Los emails no coinciden.';
      this.form.controls['confirmEmail'].setErrors({ incorrect: true });
    }
  }

  validateConfirmationEmail(event: any, isOnChange: boolean) {
    if (isOnChange) {
      this.confirmEmail = event ? event : null;
      this.refreshButton();
      this.confirmEmailErrorMessage = '';

      if (this.timeOutEmailConfirm) {
        clearTimeout(this.timeOutEmailConfirm);
      }

      this.timeOutEmailConfirm = setTimeout(() => {
        this.checkConfirmationEmailError();
        this.refreshButton();
      }, 750);
    } else {
      this.refreshButton();
      this.checkConfirmationEmailError();
    }
  }

  refreshButton() {
    this.isBtnDisabled =
      this.phoneNumber.length !== 9 ||
      !this.commonService.isValidFormat(this.email) ||
      this.commonService.hasSpecialChars(this.email) ||
      this.phoneNumber[0] !== '0' ||
      !this.formService.getProperty('confirmEmail') ||
      this.email !== this.confirmEmail;
  }

  doPost() {
    this.commonService.postProspect(this.formService.getForm());
  }

  setProgressBarClient() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 3,
      currentLabel: 1,
      amountBar: 3,
      currentBar: 2,
      labelName: 'Datos personales'
    });
  }

  openPackageDetails() {
    this.route.navigate([RoutesClientEnum.BENEFITS_COMISSIONS.toString()]);
  }

  doPatchProspectTracking() {
    const clientFlowId = this.prospectService.getClientFlowId();
    // tslint:disable-next-line: early-exit
    if (clientFlowId) {
      const body = {
        prospect_id: clientFlowId,
        prospect_type: ClientContactInfoComponent.TRACKING_CLIENTE
      };
      this.commonService.doPatchProspectTracking(body);
    }
  }
}
