<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <header-oca [atual]='setAtual()'></header-oca>

  <main>

    <h1 id="titleId" class='title-container'>
      ¿Cuál es tu <strong>número de documento?</strong>
    </h1>

    <div class='input-container'>
      <voxel-form-field
        [formGroup]="form"
        label="Número de documento"
        msgErr="Número inválido. Intentá nuevamente, por favor.">
        <input
          #documentInput
          voxelInput
          id="document_number"
          formControlName="documentNumber"
          [voxelMask]="createInputMask"
          (keyup) = "isEnter($event)">
      </voxel-form-field>
    </div>

  </main>
</div>

<footer>
  <button-sidebyside
    [isBtnDisabled]="form.invalid"
    [navigationService]="navigationService"
    nextHook="true"
    (hookNextAction)="nextActionButton()">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>
