import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkService } from '@latam/network';
import { throwError } from 'rxjs';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

import { RouterSignature } from './router.signature';
import { AbreCuentasRoutesEnum } from './shared/enum/abre-cuentas-routes.enum';
import { LatamErrorMessageService } from './shared/latam-error-message/latam-error-message.service';
import { CommunicatorService } from './shared/services/communicator.service';

@Injectable()
export class AppService {
  private lastOpCalled: string;
  private errorCount: number;
  options: RouterSignature;

  constructor(
    private communicatorService: CommunicatorService,
    private latamItLoaderService: LatamItLoaderService,
    private latamErrorMessageService: LatamErrorMessageService,
    private router: Router,
    private networkService: NetworkService
  ) {
    this.resetErrors();
  }

  doPost(body: any, op: any, showLoader: boolean = false, hideLoader: boolean = true): any {
    if (showLoader) {
      this.latamItLoaderService.show();
    }
    const objOP = this.communicatorService.getOP(op.toString());
    // tslint:disable-next-line: early-exit
    if (objOP) {
      this.options = new RouterSignature();
      this.options.op = objOP.op;
      this.options.body = {
        data: body
      };

      const handleError =
        objOP['target'] !== 'post_prospect_oca_get_ready' && objOP['target'] !== 'patch_prospect_oca_tracking';

      return this.networkService
        .post(this.options.op, this.options, { op: this.options.op })
        .map((res: any) => {
          // tslint:disable-next-line: early-exit
          if (res) {
            console.log('Error Handler success', res);
            if (res.statusCode === 200 && this.lastOpCalled === objOP.op) {
              this.resetErrors();
            } else if (handleError && res.statusCode !== 200 && objOP['target'] !== 'get_seller') {
              this.resolveError(objOP.op);
            } else if (handleError && res.statusCode !== 200 && res.statusCode !== 400 && objOP['target'] === 'get_seller') {
              this.resolveError(objOP.op);
            }
            if (hideLoader) {
              this.latamItLoaderService.hide();
            }
            res.errorCount = this.errorCount;
            return res;
          }
        })
        .catch(error => {
          console.log('Error Handler error', error);
          this.resolveError(objOP.op);

          if (handleError) {
            this.resolveError(objOP.op);
          }

          error.count = this.errorCount;
          this.latamItLoaderService.hide();
          return throwError(error);
        });
    }
  }

  resetErrors() {
    this.errorCount = 0;
    this.lastOpCalled = '';
  }

  resolveOperationError(op: any) {
    const objOP = this.communicatorService.getOP(op.toString());
    this.resolveError(objOP.op);
  }

  resolveError(op: string) {
    if (this.lastOpCalled !== op) {
      this.errorCount++;
      this.lastOpCalled = op;
      this.latamErrorMessageService.show();
    } else {
      this.errorCount++;
      if (this.errorCount >= 11) {
        this.latamErrorMessageService.hide();
        this.router.navigate([AbreCuentasRoutesEnum.TRY_AGAIN_LATER_ERROR.toString()]);
      } else {
        this.latamErrorMessageService.show();
      }
    }
  }

  setClientToken(token: string) {
    localStorage.setItem('token', token);
  }

  getClientToken(): string {
    return localStorage.getItem('token');
  }

  removeClientToken() {
    localStorage.removeItem('token');
  }

  setClientSessionId(sessionId: string) {
    localStorage.setItem('sessionId', sessionId);
  }

  getClientSessionId(): string {
    return localStorage.getItem('sessionId');
  }

  removeClientSessionId() {
    localStorage.removeItem('sessionId');
  }
  setOrigin(origin: string) {
    localStorage.setItem('origin', origin);
  }

  getOrigin(): string {
    return localStorage.getItem('origin');
  }

  removeOrigin() {
    localStorage.removeItem('origin');
  }
}
