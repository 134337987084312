import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Analytics } from '../../shared/analytics';
import { GetReadyModel } from '../models/get-ready-model';
import { GetReadyCommonService } from '../shared/get-ready-common.service';
import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';
import { validateDocument } from './document-number.custom-validator';

@Component({
  selector: 'document-number',
  templateUrl: './document-number.component.html',
  styleUrls: ['./document-number.component.scss']
})
export class DocumentNumberComponent implements OnInit, OnDestroy {
  pageId = '04_documentNumber';
  getReadyForm: GetReadyModel;

  form = new FormGroup({
    documentNumber: new FormControl('', [Validators.required, validateDocument])
  });

  @ViewChild('documentInput', { static: true }) documentInput: ElementRef;

  private subscription = new Subscription();
  private previousStatus: string;

  constructor(
    private getReadyFormService: GetReadyFormService,
    public navigationService: GetReadyNavigationService,
    public commonService: GetReadyCommonService,
    private analyticsLib: Analytics
  ) {}

  isEnter($event) {
    this.getReadyForm.identity_documents[0].number = this.form.controls['documentNumber'].value;
    if ($event && $event.key === 'Enter' && !this.form.invalid) {
      this.nextActionButton();
    }
  }

  ngOnInit() {
    this.watchFormStatusChanges();

    this.getReadyForm = this.getReadyFormService.getFormGetReady();
    this.form.controls.documentNumber.setValue(this.getReadyForm.identity_documents[0].number || '');
  }

  nextActionButton() {
    this.getReadyForm.identity_documents[0].number = this.form.controls.documentNumber.value;
    this.navigationService.sendToNext();
  }

  setAtual() {
    return 2;
  }

  createInputMask = (rawValue: string): (string | RegExp)[] => {
    return [/[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/];
  }

  watchFormStatusChanges() {
    this.subscription.add(
      this.form.statusChanges.subscribe(status => {
        if (!this.previousStatus) {
          this.previousStatus = status;
        }
        // Had to do this because the input was not updating its status from invalid to valid
        // When combined with a mask, only when losing focus
        // tslint:disable-next-line: early-exit
        if (this.previousStatus !== status) {
          this.documentInput.nativeElement.blur();
          this.documentInput.nativeElement.focus();
          this.previousStatus = status;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
