<div [id]="pageId"></div>

<div class="img-container">
  <img class="image" src="assets/images/AF_farol_roxo.svg" alt="error_img">
</div>

<main>
  <h1 class="title title-create">
    No fue posible crear tu cuenta ahora :(
  </h1>

  <h2 class="sub-title sub-title-create">
    El control realizado por OCA impide el otorgamiento de la cuenta por no cumplir las políticas aplicables.
  </h2>

  <hr class="between" />

  <div class="normal-text">
    <div class="icon-div">
      <span>
        <i class="icon-itaufonts_celular"></i>
      </span>
    </div>
    <div class="text-div">
      <span>Por cualquier consulta adicional
        <a class="link-itau-font" href="tel:{{phone}}">llamá al {{phone}}</a>.</span>
    </div>
  </div>

</main>

<footer class="footer-page-error">
  <a class="link-itau" [href]="urlOCA">salir de apertura de cuentas</a>
</footer>

<facebook-pixel></facebook-pixel>