import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { SelfieCommonService } from '../../selfie/shared/selfie-common.service';
import { AbreCuentasRoutesEnum } from '../../shared/enum/abre-cuentas-routes.enum';
import { ArchiveService } from '../../shared/services/domains/archive/archive.service';
import { AddressFullCommonService } from '../shared/address-common.service';
import { AddressFullNavigationService } from '../shared/address-navigation.service';
import { RoutesAddressFullEnum } from '../shared/enums/routes-address-enum';

@Component({
  selector: 'address-facebook-proof',
  templateUrl: './address-facebook-proof.component.html',
  styleUrls: ['./address-facebook-proof.component.scss'],
})
export class AddressFacebookProofFullComponent implements OnInit, OnDestroy {
  previousRoute = RoutesAddressFullEnum.ADDRESS_PROOF;
  eventsSubject: Subject<void> = new Subject<void>();
  compressedResolutionHeight: number;
  maxImageLenght: number;
  errorRoute = AbreCuentasRoutesEnum.CAMERA_ERROR;
  noPermissionRoute = AbreCuentasRoutesEnum.PERMISSION_REQUIRED;

  constructor(
    private elementRef: ElementRef,
    private selfieCommonService: SelfieCommonService,
    public addressNavigationService: AddressFullNavigationService,
    private archiveService: ArchiveService,
    private addressCommonService: AddressFullCommonService,
  ) {}

  ngOnInit() {
    this.compressedResolutionHeight = this.selfieCommonService.paramsUpload[0].resolution_size;
    this.maxImageLenght = this.selfieCommonService.paramsUpload[0].max_file_size;
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#353535';
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
  }

  sendImage(base64: any) {
    this.post(this.archiveService.createUploadFileJson(base64, 'jpg', 'address'));
    this.addressCommonService.setUploadedDocumentAddress();
  }

  post(fileJson: any) {
    this.archiveService.doPatchSolicitationFileUpload(fileJson, true, true).subscribe(res => {
      if (res.statusCode === 200) {
        this.addressNavigationService.sendToNext();
      }
    });
  }
}
