import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { ConfirmationFullComponent } from './confirmation/confirmation.component';
import { ProgressBarService } from '../progressBar.service';
import { FinalDataFullRountingModule } from './final-data.routes';
import { FinalDataFullCommonService } from './shared/final-data-common.service';
import { FinalDataFullFormService } from './shared/final-data-form.service';
import { FinalDataFullNavigationService } from './shared/final-data-navigation.service';
import { EndFullComponent } from './end/end.component';

@NgModule({
  imports: [
    FinalDataFullRountingModule,
    SharedModule,
    CommonModule,
    FormsModule
  ],
  providers: [
    ProgressBarService,
    FinalDataFullNavigationService,
    FinalDataFullCommonService,
    FinalDataFullFormService
  ],
  declarations: [
    ConfirmationFullComponent,
    EndFullComponent
  ]
})

export class FinalDataFullModule {}
