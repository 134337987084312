import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarService } from './../../../progressBar.service';
import { CommonNavigationService } from './../../../shared/services/common-navigation.service';

@Component({
  selector: 'client-header-oca',
  styleUrls: ['./client-header-oca.component.scss'],
  templateUrl: './client-header-oca.component.html'
})

export class ClientHeaderOCAComponent implements OnInit {

  @Input() atual;

  progressBar: {
    amountLabel: number,
    currentLabel: number,
    amountBar: number,
    currentBar: number,
    labelName: string,
  };

  constructor(
    private commonNavigation: CommonNavigationService,
    private progressBarService: ProgressBarService) {
  }

  ngOnInit(): void {
    this.progressBar = this.progressBarService.getProgressBarOptions();
    this.progressBar.currentBar = this.atual;
  }

  getEtapa() {
    return this.commonNavigation.getStep();
  }

  getTotalEtapas() {
    return this.commonNavigation.getStepAmount();
  }

}
