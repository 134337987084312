<div class="wrapper-btn">
    <div class="fl">
      <voxel-button
        id="btnVolver"
        [disabled]="isBothBtnDisabled"
        typeButton="secondary"
        label="{{label1}}"
        (click)='previous()'>
      </voxel-button>
    </div>
    <div class="fr">
      <voxel-button
        id="btnContinuar"
        [disabled]="isBtnDisabled || isBothBtnDisabled"
        label="{{label2}}"
        (click)='next()'>
      </voxel-button>
    </div>
</div>
