<!--
<latam-it-modal
  labelSecondary="cerrar"
  [showModal]="showModal"
  (onClose)="toggleModal()"
  (onConfirm)="toggleModal()"
  [title]="title">
  <div class="loader">
    <div class="loader-wrapper">
      <voxel-loading></voxel-loading>
      <voxel-mb-loading></voxel-mb-loading>
    </div>
  </div>
  <iframe #iframe (load)="onLoad()" id="pdf-file" class="pdf-file"></iframe>
</latam-it-modal>
-->

<voxel-modal [showModal]="showModal">
  <h2 class="voxel-text-xl">{{title}}</h2>
  <ng-template #modalContent>
    <div class="loader">
      <div class="loader-wrapper">
        <voxel-loading></voxel-loading>
      </div>
    </div>
    <iframe #iframe (load)="onLoad()" id="pdf-file" class="pdf-file"></iframe>
  </ng-template>
  <ng-template #modalActions>
    <voxel-button
      label="cerrar"
      (click)="toggleModal()"
      typeButton="secondary">
    </voxel-button>
  </ng-template>
</voxel-modal>
