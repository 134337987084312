<div [id]="pageId"></div>

<main>

  <div class="end__head-image">
    <img src="assets/images/ilustracion-correo.svg" alt="message_img" class="end__message-container">
  </div>

  <div class="end__container-information">
    <h1 class="end__title">
      {{name}}<span *ngIf="name">, </span>tu solicitud ya está en proceso : )
    </h1>

    <h2 class="end__sub-title">
      Enviaremos a tu Email una respuesta en las próximas horas.
    </h2>

  </div>

</main>

<div class="end__container-information">
  <div class="end__text-social-networks">
    <span>Conocé más de OCA</span>
  </div>
  <div class="end__social-networks" *ngFor="let socialNetworks of listSocialNetworks">
    <a [href]="socialNetworks.url">
      <img [src]="socialNetworks.source" [alt]="socialNetworks.alt" class="{{socialNetworks.style}}">
    </a>
  </div>
</div>

<div class="end__container-information" *ngIf="seller">
  <div class="end__back">
    <voxel-link (linkClick)="backToHome()" linkId="backHome" a11yLabel="Volver a la página inicial">
      Volver a la página inicial
    </voxel-link>
  </div>
</div>
<facebook-pixel></facebook-pixel>
