<div class="black-bg content-with-filled-button">
  <div id='{{pageId}}'></div>

  <header-oca [atual]='1'></header-oca>

  <main>

    <h1 id="get-ready-title" class="selfie-header">
      Por seguridad, necesitamos que tomes dos fotos de tu rostro
    </h1>

    <div class="instructions-container">
      <ul>
        <li class="content-icons icon-1"></li>
        <li class="content-text">
          No utilices accesorios como
          gorro o lentes
        </li>
      </ul>
      <ul>
        <li class="content-icons icon-2"></li>
        <li class="content-text">
          Tomá las fotos en un lugar
          bien iluminado
        </li>
      </ul>
    </div>

    <div class="instructions-text">
      Solo utilizaremos las fotos para validar tu identidad y asegurarnos que quien hace la apertura de cuentas sos vos.
    </div>

  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    [navigationService]="navigationService"
    (hookNextAction)="next()">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>