<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <header-oca [atual]='6'></header-oca>

  <main>
    <h1 id="titleId" style="font-size: 1.5rem; line-height: 1.75rem; padding: 0 0 4vh 0; font-weight: 300;">
      ¿Cuál es tu
      <strong>ingreso líquido mensual</strong>
      en pesos?
    </h1>

    <voxel-additional-information
      id='addtionalInformationId'
      message="{{hintObject.message}}"
      idHint="hintid"
      [reverseHint]="true">
    </voxel-additional-information>

    <div style="padding-bottom: 5vw; margin: 4vw 4vw 0 0;">

      <voxel-form-field
        [formGroup]="form"
        label="pesos"
        msgErr="Valor inválido">
      <input
        voxelInput
        id="radio1"
        [type]="'tel'"
        [value]="ingresoLiquido"
        formControlName="ingresoLiquido"
        voxelMask="currency"
        [config]="config"
        [maxLength]="8"
        (ngModelChange)="newInputChange('ingresoLiquido')">
      </voxel-form-field>

    </div>
  </main>
</div>
<footer>
    <button-sidebyside
    nextHook="true"
    (hookNextAction)="doPost()"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>
