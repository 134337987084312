import { Component, OnInit } from '@angular/core';
import { GetReadyNavigationFullService } from '../shared/get-ready-navigation.service';
import { GetReadyFormFullService } from '../shared/get-ready-form.service';
import { GetReadyModelFull } from '../models/get-ready-model';
import { FormGroup, FormControl } from '@angular/forms';
import { Analytics } from '../../shared/analytics';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';
import { GetReadyCommonFullService } from '../shared/get-ready-common.service';


@Component({
  selector: 'document-chip',
  templateUrl: './document-chip.component.html',
  styleUrls: ['./document-chip.component.scss']
})
export class DocumentChipFullComponent implements OnInit {

  pageId = 'document-chip';
  isBtnDisabled = false;
  securityCode = '';
  getReadyForm: GetReadyModelFull;
  modalShow = false;

  formField = new FormGroup({
    securityCode: new FormControl(''),
  });

  constructor(
    public navigationService: GetReadyNavigationFullService,
    private analyticsLib: Analytics,
    private getReadyFormService: GetReadyFormFullService,
    private commonService: GetReadyCommonFullService) {
  }

  ngOnInit() {

    this.getReadyForm = this.getReadyFormService.getFormGetReady();
    this.securityCode = this.getReadyForm.identity_documents[0].security_code;
    this.formField.controls['securityCode'].setValue(this.securityCode || '');
    this.refreshButton();
    this.checkOpenModal();
    this.analyticsLib.buildData('DatosCedulaChip', this.pageId, '3', false);
    this.analyticsLib.trackState(null, null);
    this.getReadyFormService.setPreviousStage('DocumentChip');
    this.commonService.scrollToTop(document);
  }

  refreshButton() {
    this.isBtnDisabled = !(this.securityCode && this.securityCode.length === 6);
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.doNext();
    }
  }

  changeCode(secturityCode: string) {
    this.securityCode = secturityCode.toUpperCase();
    this.refreshButton();
  }

  doNext() {
    this.getReadyForm.identity_documents[0].security_code = this.securityCode;
    this.navigationService.sendToNext();
  }

  checkOpenModal() {
    if (this.navigationService.hasErrors()) {
      this.toggleModal();
    }
  }

  maskAsRead() {
    this.navigationService.cleanErrors();
    this.toggleModal();
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }


  setAtual() {
    let atual = 3;
    if (this.getReadyFormService.getProperty('solicitation_type') === 'aperturadecuentaspj') {
      atual = 6;
    }
    return atual;
  }

}
