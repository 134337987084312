import { AbstractControl } from '@angular/forms';
import { Injector } from '@angular/core';
import { ValidationService } from './../../shared/services/validation-service/validation.service';

export function validateDocument(control: AbstractControl) {
  const injector = Injector.create({ providers: [ { provide: ValidationService, deps: [] }] });
  const service = injector.get(ValidationService);

  return service.DocumentNumberValidation(control.value) ? null : { documentInvalid: true };
}
