import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { PldNavigationService } from './shared/pld-navigation.service';
import { ProgressBarService } from '../progressBar.service';

import { PldRoutingModule } from './pld.routes';
import { PldCommonService } from './shared/pld-common.service';
import { PldFormService } from './shared/pld-form.service';

import { PldHomeComponent } from './pld-home/pld-home.component';
import { PurposeComponent } from './purpose/purpose.component';
import { MoneyComponent } from './money/money.component';
import { CreditsComponent } from './credits/credits.component';

@NgModule({
  imports: [
    PldRoutingModule,
    SharedModule,
    CommonModule,
  ],
  providers: [
    PldNavigationService,
    PldCommonService,
    PldFormService,
    ProgressBarService
  ],
  declarations: [
    PldHomeComponent,
    PurposeComponent,
    MoneyComponent,
    CreditsComponent,
  ],
})
export class PldModule {}
