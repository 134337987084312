import { Component, OnInit } from '@angular/core';

import { GenericModel } from '../../shared/models/generic-model';
import { AddressModel } from '../models/address-model';
import { AddressCommonService } from '../shared/address-common.service';
import { AddressFormService } from '../shared/address-form.service';
import { AddressNavigationService } from '../shared/address-navigation.service';
import { RoutesAddressEnum } from '../shared/enums/routes-address-enum';

@Component({
  selector: 'address-home',
  templateUrl: './address-home.component.html',
  styleUrls: ['./address-home.component.scss']
})
export class AddressHomeComponent implements OnInit {
  pageId: string;
  isBtnDisabled: boolean;
  hintObject: any;

  existingPersonalAddress: any;
  states: GenericModel[];
  cities: GenericModel[];

  // Vars used on address form component
  stateSelected: GenericModel;
  citySelected: GenericModel;
  street: string = null;

  constructor(
    public addressNavigationService: AddressNavigationService,
    private addressCommonService: AddressCommonService,
    private addressFormService: AddressFormService
  ) {}

  ngOnInit(): void {
    this.pageId = 'address-home';
    this.hintObject = {};
    this.resolveHint();
    this.states = this.addressCommonService.states;
    this.cities = this.addressCommonService.cities;
    this.setData();
  }

  setData(): void {
    this.addressNavigationService.setStage(RoutesAddressEnum.ADDRESS_HOME);

    const model = this.addressFormService.getForm().personal_address;
    this.existingPersonalAddress = this.addressCommonService.personalAddress;

    if (model.is_valid) {
      this.createAddressFormModel(model);
    } else {
      this.createAddressFormModel(this.existingPersonalAddress);
    }
  }

  createAddressFormModel(model: AddressModel): void {
    this.stateSelected = new GenericModel();
    this.citySelected = new GenericModel();

    // tslint:disable-next-line: early-exit
    if (model) {
      this.stateSelected.label = model.state;
      this.citySelected.label = model.city;
      this.street = model.street;
    }
  }

  resolveHint(): void {
    // tslint:disable-next-line:max-line-length
    this.hintObject.message = `Si en alguna de las bases que consultamos  registrado tu domicilio, éste aparecerá más abajo. Actualizalo de ser necesario.`;
    this.hintObject.value = 'offer';
  }

  next(): void {
    this.addressNavigationService.sendToNext();
  }

  enableButton(addressModel: AddressModel): void {
    this.isBtnDisabled = !addressModel.is_valid;
    this.addressFormService.setProperty('personal_address', addressModel);
    if (addressModel.is_key_enter) {
      this.next();
    }
  }

  setAtual() {
    return 11;
  }
}
