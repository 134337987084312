import { Component, OnInit } from '@angular/core';
import { ProgressBarService } from 'src/app/progressBar.service';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';
import { Analytics } from '../../shared/analytics';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { RoutesPldFullEnum } from '../shared/enums/routes-pld-enum';
import { CurrencyValueFullModel } from '../shared/models/currency-value';
import { PldFullCommonService } from '../shared/pld-common.service';
import { PldFullFormService } from '../shared/pld-form.service';
import { PldFullNavigationService } from '../shared/pld-navigation.service';


@Component({
  selector: 'pld-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['../shared/pld-styles.scss'],
})
export class CreditsFullComponent implements OnInit {
  pageId = 'pld-credits';
  creditAmounts: any[] = [];
  isBtnDisabled = false;
  hintObject: any;

  constructor(
    private analyticsLib: Analytics,
    private pldFormService: PldFullFormService,
    private pldCommonService: PldFullCommonService,
    public navigationService: PldFullNavigationService,
    private sellerService: SellerService,
    private progressBarService: ProgressBarService
  ) {
    this.hintObject = {};
  }

  ngOnInit() {
    this.navigationService.setStage(RoutesPldFullEnum.CREDITS);
    this.creditAmounts = this.pldCommonService.getAdminList(AdminListEnum.AMOUNT_QUANTITY_PER_MONTH);
    this.resolveHint();
    this.refreshButton();

    this.navigationService.setStage(RoutesPldFullEnum.CREDITS);
    this.creditAmounts = this.pldCommonService.getAdminList(AdminListEnum.AMOUNT_QUANTITY_PER_MONTH);
    this.resolveHint();
    this.refreshButton();

    if (this.pldFormService.getProperty('amount_quantity_per_month')) {
      this.creditAmounts.forEach(element => {

        const amount = this.pldFormService.getProperty('amount_quantity_per_month').amount;

        element.checked = !!(amount === element.code);

      });
    }

    this.setProgressBar();

    this.analyticsLib.buildData('CuantoDineroRecibisPLD', this.pageId, '12', false);
    this.analyticsLib.trackState(null, null);
    this.pldCommonService.scrollToTop(document);


  }

  resolveHint() {

    this.hintObject.message = `Es la suma de acreditaciones/depósitos que esperás recibir generalmente en un mes en tu cuenta.
    Este valor debe incluir depósitos, transferencias, giros, etc, que realices vos, o terceros.`;
    this.hintObject.value = `offer`;
  }

  refreshButton() {
    this.isBtnDisabled =
      !this.pldFormService.getProperty('amount_quantity_per_month') ||
      !this.pldFormService.getProperty('amount_quantity_per_month').amount;
  }

  changeCreditAmount(amount: any) {
    const balance = new CurrencyValueFullModel();
    balance.amount = amount.code;
    this.pldFormService.setProperty('amount_quantity_per_month', balance);
    this.refreshButton();
  }

  nextButtonPressed() {
    this.pldCommonService.postPld(this.pldFormService.getPldForm());
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 2,
      amountBar: 3,
      currentBar: 3,
      labelName: 'Uso de la cuenta',
      nextLabel: 'Siguiente: 3/4 Comprobar identidad'
    });
  }
}
