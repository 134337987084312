import { Pipe, PipeTransform } from '@angular/core';
import { OffersCommonService } from 'src/app/offers/shared/offers-common.service';

import { AdminListEnum } from '../services/admin-lists/enum/admin-list.enum';

@Pipe({ name: 'accountType' })
export class AccountTypePipe implements PipeTransform {
  constructor(public offersCommonService: OffersCommonService) {}

  transform(account_type: string): any {
    let label = account_type;
    const accountTypeList = this.offersCommonService.getAdminList(AdminListEnum.ACCOUNT_TYPE);
    // tslint:disable-next-line: early-exit
    if (accountTypeList) {
      accountTypeList.forEach(ac => {
        if (ac.code === account_type) {
          label = ac.description;
        }
      });
      return label;
    }
  }
}
