import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CurrencyValueFullModel } from 'src/app/pld-full/shared/models/currency-value';
import { ProgressBarService } from 'src/app/progressBar.service';

import { Analytics } from '../../shared/analytics';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { GetReadyModelFull } from '../models/get-ready-model';
import { RoutesGetReadyFullEnum } from '../shared/enums/routes-get-ready-enum';
import { GetReadyCommonFullService } from '../shared/get-ready-common.service';
import { GetReadyFormFullService } from '../shared/get-ready-form.service';
import { GetReadyNavigationFullService } from '../shared/get-ready-navigation.service';

@Component({
  selector: 'get-ready-occupation',
  templateUrl: './occupation.component.html',
  styleUrls: ['./occupation.component.scss']
})
export class OccupationFullComponent implements OnInit {
  pageId: string;
  activities: any[];
  otherActivity: string;
  isBtnDisabled: boolean;
  activityLabel: string;
  activityCode: string;
  getReadyForm: GetReadyModelFull;
  showIncome: boolean;
  ingresoLiquido = '';
  hintObject: any;

  readonly currencyConnfig = {
    allowNegative: false,
    precision: 0,
    thousands: '.',
    prefix: '$',
    sufix: ''
  };

  formIngresso = new FormGroup({
    ingreso: new FormControl('')
  });

  constructor(
    private formService: GetReadyFormFullService,
    public navigationService: GetReadyNavigationFullService,
    public getReadyCommonService: GetReadyCommonFullService,
    private analyticsLib: Analytics,
    private progressBarService: ProgressBarService
  ) {
    this.hintObject = {};
  }

  ngOnInit() {
    this.pageId = 'occupation';
    this.navigationService.setStage(RoutesGetReadyFullEnum.OCCUPATION);

    this.getReadyForm = this.formService.getFormGetReady();
    this.activityCode =
      this.getReadyForm.principal_activity && this.getReadyForm.principal_activity.id
        ? this.getReadyForm.principal_activity.id
        : '';

    this.activities = this.getReadyCommonService.getAdminList(AdminListEnum.PRINCIPAL_ACTIVITY);
    this.activityLabel = this.activityCode ? this.activities.find(x => x.code === this.activityCode).label : '';
    if (this.formService.getProperty('monthly_income')) {
      this.ingresoLiquido = this.formatIngresoLiquido(this.formService.getProperty('monthly_income').amount.toString());
      this.formIngresso.controls['ingreso'].setValue(this.ingresoLiquido);
    }

    this.resolveHint();
    this.showIncome = false;
    this.checkPreviousStage();
    this.refreshButton();
    this.setProgressBar();
    this.analyticsLib.buildData('DatosOcupacion', this.pageId, '6', false);
    this.analyticsLib.trackState(null, null);
    this.formService.setPreviousStage('Occupation');
    this.getReadyCommonService.scrollToTop(document);
  }

  changeActivityValue(event) {
    const selectedOption = event;
    this.activityCode = event.code;
    this.activityLabel = event.label;
    this.activities = [selectedOption];
    this.showIncome = true;
    this.refreshButton();
    this.analyticsOccupation();
    this.formIngresso.controls['ingreso'].setValue(this.ingresoLiquido);
  }

  analyticsOccupation() {
    this.analyticsLib.trackAction({
      customCategory: 'Body | Button | Center',
      customAction: 'Click',
      customLabel: 'Profesion',
      customValue: this.activityLabel
    });
  }

  refreshButton() {
    this.isBtnDisabled =
      !this.activityCode ||
      !this.formService.getProperty('monthly_income') ||
      !this.formService.getProperty('monthly_income').amount ||
      this.showIncome !== true;

    if (this.formService.getProperty('monthly_income')) {
      this.ingresoLiquido = this.formatIngresoLiquido(this.formService.getProperty('monthly_income').amount.toString());
    }

    this.getReadyForm.principal_activity = { id: this.activityCode, specifics: '' };
  }

  setCaracter() {
    return 140;
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.doPost();
    }
  }

  formatIngresoLiquido(amount) {
    let amountFormat = amount;
    const length = amount.length;

    if (length > 3) {
      if (length === 4) {
        amountFormat = amount[0] + '.' + amount.slice(1);
      } else if (length === 5) {
        amountFormat = amount.slice(0, 2) + '.' + amount.slice(2);
      } else if (length === 6) {
        amountFormat = amount.slice(0, 3) + '.' + amount.slice(3);
      }
    }
    return '$' + amountFormat;
  }

  resolveHint() {
    this.hintObject.message = `Es lo que efectivamente percibís por tu actividad laboral,
      luego de realizar deducciones por aportes e impuestos.`;
    this.hintObject.value = 'offer';
  }

  changeIngreso(event: any) {
    const balance = new CurrencyValueFullModel();
    const amount = event.replace('$', '');
    balance.amount = Number(amount);
    this.formService.setProperty('monthly_income', balance);
    this.refreshButton();
  }

  doPost() {
    this.getReadyCommonService.postSolicitation();
  }

  doPrevious() {
    // tslint:disable-next-line: early-exit
    if (this.showIncome) {
      this.showIncome = false;
      this.activities = this.getReadyCommonService.getAdminList(AdminListEnum.PRINCIPAL_ACTIVITY);
      this.refreshButton();
    } else {
      this.navigationService.sendToPrevious();
    }
  }

  checkActivitySelected() {
    this.activities = this.getReadyCommonService.getAdminList(AdminListEnum.PRINCIPAL_ACTIVITY);
    const activitySelected = this.activities.find(x => x.code === this.activityCode);
    if (activitySelected) {
      this.activities = [activitySelected];
      this.activityLabel = activitySelected.label;
    }
  }

  checkPreviousStage() {
    if (this.formService.getPreviousStage() !== 'MaritalStatus') {
      this.showIncome = true;
      this.checkActivitySelected();
    }
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 1,
      amountBar: 7,
      currentBar: 5,
      labelName: 'Datos personales'
    });
  }
}
