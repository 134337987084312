import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ProgressBarService } from '../progressBar.service';
import { FinalDataRoutingModule } from './final-data.routes';
import { FinalDataCommonService } from './shared/final-data-common.service';
import { FinalDataFormService } from './shared/final-data-form.service';
import { FinalDataNavigationService } from './shared/final-data-navigation.service';
import { EndComponent } from './end/end.component';

@NgModule({
  imports: [
    FinalDataRoutingModule,
    SharedModule,
    CommonModule,
    FormsModule
  ],
  providers: [
    ProgressBarService,
    FinalDataNavigationService,
    FinalDataCommonService,
    FinalDataFormService
  ],
  declarations: [
    ConfirmationComponent,
    EndComponent
  ]
})

export class FinalDataModule {}
