export class CurrencyValueFullModel {
  amount: number;
  decimals: number;
  currency: string;

  constructor() {
    this.amount = null;
    this.decimals = null;
    this.currency = '';
  }
}
