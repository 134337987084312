import { Component, OnInit } from '@angular/core';
import { GetReadyNavigationFullService } from '../../shared/get-ready-navigation.service';

import { GetReadyCommonFullService } from '../../shared/get-ready-common.service';
import { AdminListEnum } from '../../../shared/services/admin-lists/enum/admin-list.enum';

@Component({
  selector: 'error-page-document-rejected',
  templateUrl: './error-page-document-rejected.component.html',
  styleUrls: ['../shared/errors-style.scss', './error-page-document-rejected.component.scss']
})
export class ErrorPageDocumentRejectedFullComponent implements OnInit {

  pageId: string;
  urlBranch: string;
  urlOCA: string;

  constructor(
    public navigationService: GetReadyNavigationFullService,
    private getReadyCommonService: GetReadyCommonFullService
  ) {}

  ngOnInit() {
    const adminLinkURLs = this.getReadyCommonService.getAdminList(AdminListEnum.LIST_URLS);

    this.pageId = 'error_page_document_rejected';
    this.urlBranch = adminLinkURLs.find(i => i.code.toUpperCase() === 'INSTITUTIONAL_SITE_BRANCHES').description;
    this.urlOCA = adminLinkURLs.find(i => i.code.toUpperCase() === 'INSTITUTIONAL_SITE').description;
  }

}
