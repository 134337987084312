import { Component, OnInit } from '@angular/core';
import { ProgressBarService } from 'src/app/progressBar.service';
import { ProspectService } from 'src/app/shared/services/domains/prospect/prospect.service';

import { GenericModel } from '../../shared/models/generic-model';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ClientCommonService } from '../shared/client-common.service';
import { ClientFormService } from '../shared/client-form.service';
import { ClientNavigationService } from '../shared/client-navigation.service';
import { ClientAddressModel } from '../shared/models/client-address-model';
import { ClientDeliveryChoiceModel } from '../shared/models/client-delivery-choice-model';
import { Analytics } from './../../shared/analytics';


@Component({
  selector: 'client-delivery-address',
  templateUrl: './client-delivery-address.component.html',
  styleUrls: ['./client-delivery-address.component.scss']
})

export class ClientDeliveryAddressComponent implements OnInit {

  static TRACKING_CLIENTE = 'TRACKING-CLIENTE-PANTALLA-7';

  pageId: string;
  isBtnDisabled: boolean;

  pickUpAvailability: string;
  ocaAvailability: string;
  tataAvailability: string;
  pickUpCenterStreet: string;
  ocaCenterStreet: string;
  tataCenterStreet: string;

  states: GenericModel[];
  cities: GenericModel[];

  oldSelect: ClientDeliveryChoiceModel;
  deliveryChoiceModel: ClientDeliveryChoiceModel[];

  // Personal Address
  optionPersonalAddress: boolean;
  personalAddress: ClientAddressModel;

  // Pickup
  pickupCenter: any[];
  pickupCenterAvailable: GenericModel[];
  optionPickupCenter: boolean;
  pickupCenterSelected: GenericModel;

  pickupCenterState: GenericModel[];
  pickupCenterStateSelected: GenericModel;

  // OCA Center
  ocaCenter: any[];
  ocaCenterAvailable: GenericModel[];
  optionOcaCenter: boolean;
  ocaCenterSelected: GenericModel;

  ocaCenterState: GenericModel[];
  ocaCenterStateSelected: GenericModel;

  // TA-TA Center
  tataCenter: any[];
  tataCenterAvailable: GenericModel[];
  optionTataCenter: boolean;
  tataCenterSelected: GenericModel;

  tataCenterState: GenericModel[];
  tataCenterStateSelected: GenericModel;

  // Other Address
  deliveryAddress: ClientAddressModel;
  optionOtherAddress: boolean;

  deliveryStateSelected: GenericModel;
  deliveryCitySelected: GenericModel;

  showDeliveryTime: boolean;

  deliveryTimes: any[];

  constructor(
    public navigationService: ClientNavigationService,
    private formService: ClientFormService,
    private commonService: ClientCommonService,
    public prospectService: ProspectService,
    private analyticsLib: Analytics,
    private progressBarService: ProgressBarService) {
    this.pageId = 'delivery_address';
    this.pickupCenter = this.commonService.getAdminList(AdminListEnum.PICKUP_CENTER);
    this.pickupCenterState = this.commonService.pickupCenterState.sort(this.commonService.compareItemsAlphabetically);
    if (this.pickupCenterState && this.pickupCenterState.length > 0) {
      this.pickupCenterStateSelected = !formService.getProperty('pickup_center') ?
        this.findMontevideoCenter(this.pickupCenterState) :
        this.getStateByCenterId(formService.getProperty('pickup_center'), this.pickupCenter, this.pickupCenterState);
      this.pickupCenterSelected = this.commonService.getDefaultOption();
      this.pickupCenterAvailable = !formService.getProperty('pickup_center') ?
        [this.pickupCenterSelected, ...this.filterCenterByStateAlphabetically(this.pickupCenterStateSelected.id, this.pickupCenter)] :
        this.filterCenterByStateAlphabetically(this.pickupCenterStateSelected.id, this.pickupCenter);
    } else {
      this.pickupCenterStateSelected = new GenericModel();
      this.pickupCenterSelected = new GenericModel();
      this.pickupCenterAvailable = [];
    }
    this.ocaCenter = this.commonService.getAdminList(AdminListEnum.OCA_CENTER);
    this.ocaCenterState = this.commonService.ocaCenterState.sort(this.commonService.compareItemsAlphabetically);
    if (this.ocaCenterState && this.ocaCenterState.length > 0) {
      this.ocaCenterStateSelected = !formService.getProperty('oca_center') ?
        this.findMontevideoCenter(this.ocaCenterState) :
        this.getStateByCenterId(formService.getProperty('oca_center'), this.ocaCenter, this.ocaCenterState);
      this.ocaCenterSelected = this.commonService.getDefaultOption();
      this.ocaCenterAvailable = !formService.getProperty('oca_center') ?
        [this.ocaCenterSelected, ...this.filterCenterByStateAlphabetically(this.ocaCenterStateSelected.id, this.ocaCenter)] :
        this.filterCenterByStateAlphabetically(this.ocaCenterStateSelected.id, this.ocaCenter);
    } else {
      this.ocaCenterStateSelected = new GenericModel();
      this.ocaCenterSelected = new GenericModel();
      this.ocaCenterAvailable = [];
    }
    this.tataCenter = this.commonService.getAdminList(AdminListEnum.TATA_CENTER);
    this.tataCenterState = this.commonService.tataCenterState.sort(this.commonService.compareItemsAlphabetically);
    if (this.tataCenterState && this.tataCenterState.length > 0) {
      this.tataCenterStateSelected = !formService.getProperty('tata_center') ?
        this.tataCenterState[0] :
        this.getStateByCenterId(formService.getProperty('tata_center'), this.tataCenter, this.tataCenterState);
      this.tataCenterSelected = this.commonService.getDefaultOption();
      this.tataCenterAvailable = !formService.getProperty('tata_center') ?
        [this.tataCenterSelected, ...this.filterCenterByStateAlphabetically(this.tataCenterStateSelected.id, this.tataCenter)] :
        this.filterCenterByStateAlphabetically(this.tataCenterStateSelected.id, this.tataCenter);
    } else {
      this.tataCenterStateSelected = new GenericModel();
      this.tataCenterSelected = new GenericModel();
      this.tataCenterAvailable = [];
    }
  }

  ngOnInit() {
    this.deliveryChoiceModel = this.getDeliveryChoiceModel();
    this.states = this.commonService.states;
    this.cities = this.commonService.cities;
    this.deliveryStateSelected = new GenericModel();
    this.deliveryCitySelected = new GenericModel();
    this.personalAddress = new ClientAddressModel();
    this.deliveryAddress = new ClientAddressModel();
    this.deliveryTimes = this.commonService.getAdminList(AdminListEnum.DELIVERY_AVAILABILITY);
    this.isBtnDisabled = true;
    this.showDeliveryTime = false;
    this.checkPreviousStage();
    this.setProgressBarClient();
    this.analyticsLib.buildData('DondeRecibirTarjeta', this.pageId, '07', true);
    this.analyticsLib.trackState(null, null);
    this.commonService.scrollToTop(document);
    this.doPatchProspectTracking();
  }

  checkPreviousStage() {
    if (this.formService.getPreviousStage() === 'ClientConfirmation') {
      this.setDate();
      this.setPersonalAddress();
      this.setDeliveryAddress();
    } else {
      this.setPersonalAddress();
      this.setDeliveryAddress();
    }
  }

  analyticsVolver() {
    this.analyticsLib.trackAction({
      customCategory: `Footer | Button | Bottom`,
      customAction: `Click`,
      customLabel: `Volver`
    });
  }

  doPatchProspectTracking() {
    const clientFlowId = this.prospectService.getClientFlowId();
    // tslint:disable-next-line: early-exit
    if (clientFlowId) {
      const body: any = {
        prospect_id: clientFlowId,
        prospect_type: ClientDeliveryAddressComponent.TRACKING_CLIENTE
      };
      this.commonService.doPatchProspectTracking(body);
    }
  }


  setDate() {
    const choice = this.formService.getProperty('delivery_choice_id');

    // tslint:disable-next-line: early-exit
    if (choice) {
      for (const value of this.deliveryChoiceModel) {
        // tslint:disable-next-line: early-exit
        if (value.id === choice) {
          this.oldSelect = value;
          this.deliveryChoiceModel = [this.oldSelect];
          value.selected = true;
          this.setDeliveryChoice(value.id);
        }
      }
    }
  }

  setPersonalAddress() {
    this.personalAddress = this.formService.getProperty(`personal_address`);
  }

  setDeliveryAddress() {
    this.deliveryAddress = this.formService.getProperty(`delivery_address`);
    this.deliveryStateSelected.label = this.deliveryAddress.state;
    this.deliveryCitySelected.label = this.deliveryAddress.city;
  }

  setPickupCenter() {
    const id = this.formService.getProperty(`pickup_center`);
    if (id) {
      this.pickupCenterSelected = this.pickupCenter.find(x => x.id === id);
    } else {
      this.pickupCenterSelected = new GenericModel();
    }
    this.changePickupCenter({ itemSelectedValue: this.pickupCenterSelected });
  }

  setOcaCenter() {
    const id = this.formService.getProperty(`oca_center`);
    if (id) {
      this.ocaCenterSelected = this.ocaCenter.find(x => x.id === id);
    } else {
      this.ocaCenterSelected = new GenericModel();
    }
    this.changeOcaCenter({ itemSelectedValue: this.ocaCenterSelected });
  }

  setTataCenter() {
    const id = this.formService.getProperty(`tata_center`);
    if (id) {
      this.tataCenterSelected = this.tataCenter.find(x => x.id === id);
    } else {
      this.tataCenterSelected = new GenericModel();
    }
    this.changeTataCenter({ itemSelectedValue: this.tataCenterSelected });
  }

  changeDeliveryChoice(deliveryChoice: ClientDeliveryChoiceModel): void {
    if (deliveryChoice !== this.oldSelect) {
      deliveryChoice.selected = true;
      this.formService.setProperty('delivery_choice_id', deliveryChoice.id);
      this.deliveryChoiceModel = [deliveryChoice];
      this.setDeliveryChoice(deliveryChoice.id);
      if (this.oldSelect) {
        this.oldSelect.selected = !this.oldSelect.selected;
      }
      this.oldSelect = deliveryChoice;
    }
    // tslint:disable-next-line: early-exit
    if (this.showDeliveryTime) {
      this.isBtnDisabled = !this.formService.getProperty('delivery_address') ||
        !this.formService.getProperty('delivery_address').is_valid ||
        !this.formService.getProperty('availability');
    }
  }

  setDeliveryChoice(id: string): void {
    if (id === 'private_home_choice') {
      this.optionPersonalAddress = true;

      this.optionPickupCenter =
        this.isBtnDisabled = this.optionOtherAddress =
        this.optionOcaCenter = this.optionTataCenter = !this.optionPersonalAddress;

      const oldDeliveryTime = this.formService.getProperty('availability');

      this.deliveryTimes.forEach(time => {

        time.checked = !!(oldDeliveryTime === time.code);

      });

      this.showDeliveryTime = true;
      this.formService.setProperty('pickup_center', '');
      this.formService.setProperty('oca_center', '');
      this.formService.setProperty('tata_center', '');
      if (this.personalAddress.city === '') {
        this.setPersonalAddress();
      }
      this.formService.setProperty('delivery_address', this.personalAddress);
    } else if (id === 'other_address_choice') {
      this.optionOtherAddress = this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionPickupCenter = this.optionOcaCenter = this.optionTataCenter = !this.optionOtherAddress;
      this.showDeliveryTime = true;
      this.formService.setProperty('pickup_center', '');
      this.formService.setProperty('oca_center', '');
      this.formService.setProperty('tata_center', '');
    } else if (id === 'oca_center_choice') {
      this.formService.setProperty('pickup_center', '');
      this.formService.setProperty('tata_center', '');
      this.optionOcaCenter = true;
      this.showDeliveryTime = false;
      this.ocaCenterStateSelected = (this.ocaCenterState && this.ocaCenterState.length > 0) ?
        this.findMontevideoCenter(this.ocaCenterState) :
        new GenericModel();
      this.ocaCenterAvailable = [this.commonService.getDefaultOption(),
        ...this.filterCenterByStateAlphabetically(this.ocaCenterStateSelected.id, this.ocaCenter)];
      this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionOtherAddress = this.optionPickupCenter = this.optionTataCenter = !this.optionOcaCenter;
      this.setOcaCenter();
    } else if (id === 'tata_center_choice') {
      this.formService.setProperty('pickup_center', '');
      this.formService.setProperty('oca_center', '');
      this.optionTataCenter = true;
      this.showDeliveryTime = false;
      this.tataCenterStateSelected = (this.tataCenterState && this.tataCenterState.length > 0) ?
        this.tataCenterState[0] :
        new GenericModel();
      this.tataCenterAvailable = [this.commonService.getDefaultOption(),
        ...this.filterCenterByStateAlphabetically(this.tataCenterStateSelected.id, this.tataCenter)];
      this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionOtherAddress = this.optionPickupCenter = this.optionOcaCenter = !this.optionTataCenter;
      this.setTataCenter();
    } else if (id === 'pickup_center_choice') {
      this.formService.setProperty('oca_center', '');
      this.formService.setProperty('tata_center', '');
      this.optionPickupCenter = true;
      this.showDeliveryTime = false;
      this.pickupCenterStateSelected = (this.pickupCenterState && this.pickupCenterState.length > 0) ?
        this.findMontevideoCenter(this.pickupCenterState) :
        new GenericModel();
      this.pickupCenterAvailable = [this.commonService.getDefaultOption(),
        ...this.filterCenterByStateAlphabetically(this.pickupCenterStateSelected.id, this.pickupCenter)];
      this.isBtnDisabled = true;
      this.optionPersonalAddress = this.optionOtherAddress = this.optionOcaCenter = this.optionTataCenter = !this.optionPickupCenter;
      this.setPickupCenter();
    } else {
      return;
    }
  }

  findMontevideoCenter(list) {
    const defaultCenter = list.find(elem => elem.label && elem.label.toUpperCase() === 'MONTEVIDEO');
    if (!defaultCenter) {
      return list[0];
    }
    return defaultCenter;
  }

  getDeliveryChoiceModel() {
    return [
      new ClientDeliveryChoiceModel('private_home_choice', 'icon-itaufonts_residencia', false, 'Domicilio particular'),
      new ClientDeliveryChoiceModel('pickup_center_choice', 'icon-itaufonts_processos', false, 'Pickup center'),
      new ClientDeliveryChoiceModel('other_address_choice', 'icon-itaufonts_agencia', false, 'Otra dirección'),
      new ClientDeliveryChoiceModel('oca_center_choice', 'oca-icon icon-itaufonts_oca', false, 'Sucursales OCA'),
      new ClientDeliveryChoiceModel('tata_center_choice', 'oca-icon icon-itaufonts_tata', false, 'Sucursales TaTa')
    ];
  }

  enableButton(addressModel: ClientAddressModel) {
    this.isBtnDisabled = !addressModel.is_valid;
    this.formService.setProperty('delivery_address', addressModel);

    if (addressModel.is_key_enter) {
      this.continuarButton();
    }
  }

  changePickupCenter(event: any) {
    this.pickupCenterSelected = event.itemSelectedValue;
    if (event.itemSelectedValue?.value || event.itemSelectedValue?.id) {
      this.deleteDefaultCenter(this.pickupCenterAvailable);
      const availability = event.itemSelectedValue.availability;
      this.pickUpCenterStreet = availability.slice(0, availability.indexOf('('));
      this.pickUpAvailability = availability.slice(availability.indexOf('('), availability.length);
      this.isBtnDisabled = false;
    } else {
      this.pickUpCenterStreet = '';
      this.pickUpAvailability = '';
      this.isBtnDisabled = true;
    }
    this.formService.setProperty('pickup_center', event.itemSelectedValue?.id);
  }

  changeOcaCenter(event: any) {
    this.ocaCenterSelected = event.itemSelectedValue;
    if (event.itemSelectedValue?.value || event.itemSelectedValue?.id) {
      this.deleteDefaultCenter(this.ocaCenterAvailable);
      const availability = event.itemSelectedValue.availability;
      this.ocaCenterStreet = availability.slice(0, availability.indexOf('('));
      this.ocaAvailability = availability.slice(availability.indexOf('('), availability.length);
      this.isBtnDisabled = false;
    } else {
      this.ocaCenterStreet = '';
      this.ocaAvailability = '';
      this.isBtnDisabled = true;
    }
    this.formService.setProperty('oca_center', event.itemSelectedValue?.id);
  }

  changeTataCenter(event: any) {
    this.tataCenterSelected = event.itemSelectedValue;
    if (event.itemSelectedValue?.value || event.itemSelectedValue?.id) {
      this.deleteDefaultCenter(this.tataCenterAvailable);
      const availability = event.itemSelectedValue.availability;
      this.tataCenterStreet = availability.slice(0, availability.indexOf('('));
      this.tataAvailability = availability.slice(availability.indexOf('('), availability.length);
      this.isBtnDisabled = false;
    } else {
      this.tataCenterStreet = '';
      this.tataAvailability = '';
      this.isBtnDisabled = true;
    }
    this.formService.setProperty('tata_center', event.itemSelectedValue?.id);
  }

  continuarButton() {
    this.formService.setProperty('pickUpOrDeliveryMode', this.getPickUpOrDeliveryMode());
    this.formService.setProperty('pickUpOrDeliveryAddressMode', this.getPickUpOrDeliveryAddressMode());
    this.formService.setProperty('pickUpOrDeliveryAddress', this.getPickUpOrDeliveryAddress());
    this.formService.setProperty('pickUpOrDeliveryHourMode', this.getPickUpOrDeliveryHourMode());
    this.formService.setProperty('pickUpOrDeliveryHour', this.getPickUpOrDeliveryHour());
    this.navigationService.sendToNext();
  }

  getPickUpOrDeliveryMode() {
    if (this.optionPersonalAddress || this.optionOtherAddress) {
      return 'Envío a domicilio';
    }
    if (this.optionPickupCenter) {
      return 'Pick Up Center';
    }
    if (this.optionOcaCenter) {
      return 'Sucursal OCA';
    }
    if (this.optionTataCenter) {
      return 'Sucursal TaTa';
    }
    return null;
  }

  getPickUpOrDeliveryAddressMode() {
    if (this.optionPersonalAddress || this.optionOtherAddress) {
      return 'Dirección de envío';
    }
    if (this.optionPickupCenter || this.optionOcaCenter || this.optionTataCenter) {
      return 'Dirección de retiro';
    }
    return null;
  }

  getPickUpOrDeliveryAddress() {
    if (this.optionPersonalAddress || this.optionOtherAddress) {
      return this.formService.getProperty('delivery_address').street;
    }
    if (this.optionPickupCenter) {
      return this.pickupCenter.find(x => x.id === this.formService.getProperty('pickup_center')).label;
    }
    if (this.optionOcaCenter) {
      return this.ocaCenter.find(x => x.id === this.formService.getProperty('oca_center')).label;
    }
    if (this.optionTataCenter) {
      return this.tataCenter.find(x => x.id === this.formService.getProperty('tata_center')).label;
    }
    return null;
  }

  getPickUpOrDeliveryHourMode() {
    if (this.optionPersonalAddress || this.optionOtherAddress) {
      return 'Horario de entrega';
    }
    if (this.optionPickupCenter || this.optionOcaCenter || this.optionTataCenter) {
      return 'Horario de retiro';
    }
    return null;
  }


  getPickUpOrDeliveryHour() {
    if (this.optionPersonalAddress || this.optionOtherAddress) {
      return this.deliveryTimes.find(x => x.code === this.formService.getProperty('availability')).label;
    }
    if (this.optionPickupCenter) {
      return this.pickUpAvailability;
    }
    if (this.optionOcaCenter) {
      return this.ocaAvailability;
    }
    if (this.optionTataCenter) {
      return this.tataAvailability;
    }
    return null;
  }

  changePickupCenterState(event: any) {
    this.pickupCenterStateSelected = event.itemSelectedValue;
    const filteredPickupCenters = this.filterCenterByStateAlphabetically(event.itemSelectedValue?.id, this.pickupCenter);
    const defaultPickupCenter = new GenericModel();
    this.pickupCenterAvailable = [defaultPickupCenter, ...filteredPickupCenters];
    this.changePickupCenter({ itemSelectedValue: defaultPickupCenter });
  }

  changeOcaCenterState(event: any) {
    this.ocaCenterStateSelected = event.itemSelectedValue;
    const filteredOcaCenters = this.filterCenterByStateAlphabetically(event.itemSelectedValue?.id, this.ocaCenter);
    const defaultOcaCenter = new GenericModel();
    this.ocaCenterAvailable = [defaultOcaCenter, ...filteredOcaCenters];
    this.changeOcaCenter({ itemSelectedValue: defaultOcaCenter });
  }

  changeTataCenterState(event: any) {
    this.tataCenterStateSelected = event.itemSelectedValue;
    const filteredTataCenters = this.filterCenterByStateAlphabetically(event.itemSelectedValue?.id, this.tataCenter);
    const defaultTataCenter = new GenericModel();
    this.tataCenterAvailable = [defaultTataCenter, ...filteredTataCenters];
    this.changeTataCenter({ itemSelectedValue: defaultTataCenter });
  }

  filterCenterByStateAlphabetically(state: string, center: any) {
    return center.filter(elem => elem.state === state).sort(this.commonService.compareItemsAlphabetically);
  }

  getStateByCenterId(centerId: string, center: any, centerState: any) {
    const chosenCenter = center.find(x => x.id === centerId);
    return centerState.find(x => x.id === chosenCenter.state);
  }

  changeDeliveryTime(deliveryTime: any) {
    this.formService.setProperty('availability', deliveryTime.code);
    this.isBtnDisabled = false;
  }

  doPrevious() {
    if (this.optionPersonalAddress || this.optionOcaCenter || this.optionOtherAddress ||
      this.optionPickupCenter || this.optionTataCenter) {
      this.optionPersonalAddress = false;
      this.optionOcaCenter = false;
      this.optionOtherAddress = false;
      this.optionPickupCenter = false;
      this.optionTataCenter = false;
      this.deliveryChoiceModel = this.getDeliveryChoiceModel();
      this.showDeliveryTime = false;
      this.isBtnDisabled = true;
    } else {
      this.analyticsVolver();
      this.navigationService.sendToPrevious();
    }
  }

  setProgressBarClient() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 3,
      currentLabel: 3,
      amountBar: 3,
      currentBar: 2,
      labelName: 'Envío y confirmación'
    });
  }

  private deleteDefaultCenter(centerAvailable: any) {
    const defaultCenter = new GenericModel();
    const found = centerAvailable.find(element => element.label === defaultCenter.label &&
        element.id === defaultCenter.id &&
        element.value === defaultCenter.value);

    if (found) {
      centerAvailable.shift();
    }
  }

}
