<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <header-oca [atual]='7'></header-oca>

  <main>

    <h1 id="titleId" class='title-container'>
      Ingresá tus datos de <strong>contacto</strong>
    </h1>

    <form [formGroup]="form">
      <div class='input-container'>
        <voxel-form-field
          label="Celular"
          [msgErr]="errorPhoneMessage">
        <input
          voxelInput
          id="telefono_celular"
          [value]="'09' + phoneNumber.slice(2)"
          formControlName="phoneNumber"
          [voxelMask]="mask"
          (ngModelChange)="newInputChange('phoneNumber')">
        </voxel-form-field>
      </div>

      <div class='input-container'>

        <voxel-form-field
        label="Email"
        [msgErr]="emailErrorMessage">
        <input
        voxelInput
        id="e-mail"
        [type]="'text'"
          [value]="email"
          formControlName="email"
          maxlength="40"
          (blur)='validateEmail($event, false)'
          (ngModelChange)='validateEmail($event, true)'>
        </voxel-form-field>
      </div>

      <div class='input-container'>
        <voxel-form-field
          label="Confirmá tu email"
          [msgErr]="confirmEmailErrorMessage">
          <input
          voxelInput
          id="e-mail"
          [type]="'text'"
          [value]="confirmEmail"
          formControlName="confirmEmail"
          maxlength="40"
          (blur)="validateConfirmationEmail($event, false)"
          (ngModelChange)="validateConfirmationEmail($event, true)">
        </voxel-form-field>
      </div>
    </form>
  </main>

  <div class="important-content__main" style="background: white;">
    <div class="important-content__container">
      <div class="important-content__text-div">
        <span class="information-title">Importante:</span>
        <span class="information-title__text"> Este sitio utiliza cookies, al comenzar estarás aceptando su uso. <br /><br /> Esta cuenta estará sujeta a aprobación y deberás ser mayor de 18 años para solicitarla.</span>
      </div>
    </div>
  </div>

  <div class="info-data">
    <a [routerLink]="benefitCommissionsRoute" class="package-values__benefits" id="benefit0">
        Ver términos y condiciones
    </a>
  </div>

</div>

<footer>
  <button-sidebyside
    nextHook="true"
    (hookNextAction)="doPost()"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>

<facebook-pixel></facebook-pixel>
