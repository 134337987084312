import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';

import { LoginService } from './shared/services/login.service';

@Injectable()
export class AppLoadService {
  constructor(private loginService: LoginService) {}

  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loginService.doLogon(resolve);
    });
  }
}
