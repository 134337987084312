import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {
  private MAXDOCUMENTNUMBER = 6000000;

  DocumentNumberValidation(_documentNumber): boolean {
    let valid = false;
    let documentNumber = _documentNumber;
    documentNumber = documentNumber.replace(/[^0-9]/g, '');

    if (documentNumber) {
      if (documentNumber.length === 7) {
        documentNumber = '0' + documentNumber;
      }
      let chd1;
      let t1;
      let t2;
      let a1;
      let a2;
      let a3;
      let a4;
      let a5;
      let a6;
      let a7;
      let a8;
      let b1;
      let b2;
      let b3;
      let b4;
      let b5;
      let b6;
      let b7;
      if (documentNumber.length === 8) {
        a1 = Math.floor(documentNumber / 10000000);
        a2 = Math.floor(documentNumber / 1000000) - a1 * 10;
        a3 = Math.floor(documentNumber / 100000) - (a1 * 100 + a2 * 10);
        a4 = Math.floor(documentNumber / 10000) - (a1 * 1000 + a2 * 100 + a3 * 10);
        a5 = Math.floor(documentNumber / 1000) - (a1 * 10000 + a2 * 1000 + a3 * 100 + a4 * 10);
        a6 = Math.floor(documentNumber / 100) - (a1 * 100000 + a2 * 10000 + a3 * 1000 + a4 * 100 + a5 * 10);
        a7 = Math.floor(documentNumber / 10) - (a1 * 1000000 + a2 * 100000 + a3 * 10000 + a4 * 1000 + a5 * 100 + a6 * 10);
        a8 =
          Math.floor(documentNumber / 1) -
          (a1 * 10000000 + a2 * 1000000 + a3 * 100000 + a4 * 10000 + a5 * 1000 + a6 * 100 + a7 * 10);
        b1 = (a1 * 2) % 10;
        b2 = (a2 * 9) % 10;
        b3 = (a3 * 8) % 10;
        b4 = (a4 * 7) % 10;
        b5 = (a5 * 6) % 10;
        b6 = (a6 * 3) % 10;
        b7 = (a7 * 4) % 10;
        t1 = b1 + b2 + b3 + b4 + b5 + b6 + b7;
        t2 = t1 % 10;
        chd1 = Math.abs(t2 - 10);
        chd1 = chd1 % 10;
        valid = chd1 === a8;
      }
    }
    return valid;
  }

  verifyUnderAge(documentNumber: string): boolean {
    return Number(documentNumber.split('-')[0].replace(/[^0-9]/g, '')) <= this.MAXDOCUMENTNUMBER;
  }

  rutNumberValidation(rutNumber): boolean {
    if (rutNumber.length !== 12) {
      return false;
    }
    const multipliers = [4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let sum = 0;
    let i = 0;
    for (i = 0; i < 11; i++) {
      const numericChar = rutNumber[i];
      sum += multipliers[i] * parseInt(numericChar, 10);
    }
    sum = sum % 11;
    sum = 11 - sum;
    if (sum === 11) {
      sum = 0;
    }
    return sum === parseInt(rutNumber[11], 10);
  }
}
