import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { GenericModel } from '../../../shared/models/generic-model';
import { ClientCommonService } from '../client-common.service';
import { ClientAddressModel } from '../models/client-address-model';

@Component({
  selector: 'client-address-form',
  templateUrl: './client-address-form.component.html',
  styleUrls: ['./client-address-form.component.scss']
})

export class ClientAddressFormComponent implements OnInit {

  @Input() street: string;
  @Input() stateSelected: GenericModel;
  @Input() citySelected: GenericModel;
  @Input() states: GenericModel[];
  @Input() cities: GenericModel[];

  @Output() validationForm = new EventEmitter(true);

  labelDistrict: string;
  citiesOrigin: GenericModel[];
  addressModel: ClientAddressModel;

  form = new FormGroup({
    street: new FormControl()
  });

  constructor(private clientCommonService: ClientCommonService) {}

  ngOnInit(): void {
    this.addressModel = new ClientAddressModel();
    this.setData();
  }

  setData(): void {
    this.citiesOrigin = this.cities;
    this.setState(this.stateSelected);
    this.setCity(this.citySelected);
    this.setStreet(this.street);
    this.enableNextButton();
  }

  isEnter($event) {

    if ($event) {
      this.addressModel.is_key_enter = true;
      this.enableNextButton();
    }

  }

  setLabel(): void {
    this.labelDistrict = 'Barrio';

    if (this.stateSelected?.label.toLocaleLowerCase() !== 'montevideo') {
      this.labelDistrict = 'Localidad';
    }
  }

  changeStreet(event: any): void {
    this.addressModel.street = event;
    this.street = event;
    this.enableNextButton();
  }

  changeState(event: any): void {
    this.stateSelected = event.itemSelectedValue;
    if (!!this.stateSelected?.label) {
      this.addressModel.state = this.stateSelected.label;
      this.cities = this.citiesOrigin.filter(x => x.id.toUpperCase() === this.stateSelected.label.toUpperCase());
    } else {
      this.cities = this.citiesOrigin;
    }
    this.cleanAllField();
    this.setLabel();
    this.enableNextButton();
  }

  cleanAllField(): void {
    this.street = null;
    this.addressModel.street = null;
    this.citySelected = new GenericModel();
    this.addressModel.city = null;
  }

  changeCity(event: any): void {
    this.citySelected = event.itemSelectedValue;
    this.addressModel.city = this.citySelected?.label;
    this.enableNextButton();
  }

  enableNextButton(): void {
    if ((this.street && this.street.length > 0)
      && (this.street && this.street.length >= 4)
      && (this.stateSelected && this.stateSelected.label)
      && (this.citySelected && this.citySelected.value)) {
      this.addressModel.is_valid = true;
    } else {
      this.addressModel.is_valid = false;
      this.addressModel.is_key_enter = false;
    }
    this.validationForm.emit(this.addressModel);
  }

  setState(selected?: GenericModel): void {
    if (!!selected?.label) {
      this.stateSelected = this.states.find(x =>
        this.clientCommonService.stripAccents(x.label.toUpperCase().replace(/ /g, '')) ===
        this.clientCommonService.stripAccents(selected.label.toUpperCase().replace(/ /g, '')));
    }

    if (!this.stateSelected?.label) {
      this.stateSelected = this.states[0];
      this.addressModel.state = this.stateSelected.label;
    }

    this.addressModel.state = this.stateSelected?.label;
    this.setLabel();
  }

  setCity(selected?: GenericModel): void {
    const filteredCities = this.citiesOrigin?.filter(x =>
      this.clientCommonService.stripAccents(x.id.toUpperCase().replace(/ /g, '')) ===
      this.clientCommonService.stripAccents(this.stateSelected.label.toUpperCase().replace(/ /g, '')));

    // tslint:disable-next-line: early-exit
    if (!!selected?.label) {
      this.citySelected = filteredCities?.find(x =>
        this.clientCommonService.stripAccents(x.label.toUpperCase().replace(/ /g, '')) ===
        this.clientCommonService.stripAccents(selected?.label.toUpperCase().replace(/ /g, '')));

      if (!!this.citySelected?.label) {
        this.cities = filteredCities;
        this.addressModel.city = this.citySelected.label;
      } else {
        this.cities = filteredCities;
        this.citySelected = new GenericModel();
      }
    } else {
      this.cities = filteredCities;
    }
  }

  setStreet(address: string): void {
    if (address) {
      // tslint:disable-next-line: no-non-null-assertion
      this.addressModel!.street = address;
      this.street = address;
    }
  }

}
