import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProgressBarService } from 'src/app/progressBar.service';

import { GenericModel } from '../../shared/models/generic-model';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { SellerService } from '../../shared/services/domains/seller/seller.service';
import { SellerModel } from '../models/seller-model';
import { RoutesGetReadyEnum } from '../shared/enums/routes-get-ready-enum';
import { GetReadyCommonService } from '../shared/get-ready-common.service';
import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';

@Component({
  selector: 'get-ready-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss']
})
export class SellerComponent implements OnInit {
  pageId: string;
  isBtnDisabled: boolean;
  seller: SellerModel;
  branchList: GenericModel[];
  branchSelected: GenericModel;
  vendorMessageError: string;
  branchChangesDisabled = false;

  window = typeof window !== 'undefined' ? window : null;

  form = new FormGroup({
    seller: new FormControl()
  });

  constructor(
    private getReadyNavigationService: GetReadyNavigationService,
    private getReadyFormService: GetReadyFormService,
    private getReadyCommonService: GetReadyCommonService,
    private sellerService: SellerService,
    private progressBarService: ProgressBarService
  ) {
    this.pageId = 'get-ready-seller';
    this.branchList = [];
    this.branchSelected = new GenericModel();
    this.seller = new SellerModel();
    this.vendorMessageError = '';
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.getSeller();
    }
  }

  ngOnInit(): void {
    this.getReadyNavigationService.setStage(RoutesGetReadyEnum.SALES);
    this.sellerService.removeSeller();
    this.resetSeller();
    this.setData();
    this.resolveButton();
    this.setProgressBar();
  }

  resetSeller() {
    this.sellerService.removeSeller();
    this.seller = new SellerModel();
    this.getReadyFormService.setProperty('seller', '');
  }

  resolveButton() {
    this.isBtnDisabled =
      !this.seller.branch_id || (this.seller.branch_id === '9999' && !this.isTataSeller(this.seller.seller_id));
  }

  setData() {
    const self = this;
    const branches = this.getReadyCommonService.getAdminList(AdminListEnum.BRANCH_ID);
    const ocaCentersMontevideo = this.getReadyCommonService.getAdminList(AdminListEnum.OCA_CENTER).filter(elem => {
      return elem.state === 'Montevideo';
    });
    const branchesMontevideo = branches
      .filter(branch =>
        ocaCentersMontevideo.some(center => self.simplifyName(center.label) === self.simplifyName(branch.label))
      )
      .sort(this.getReadyCommonService.compareItemsAlphabetically);
    const branchesInterior = branches
      .filter(branch =>
        ocaCentersMontevideo.every(center => self.simplifyName(center.label) !== self.simplifyName(branch.label))
      )
      .sort(this.getReadyCommonService.compareItemsAlphabetically);
    this.branchList = [...branchesMontevideo, ...branchesInterior];
  }

  simplifyName(text) {
    return text
      .toLowerCase()
      .replace(/ /g, '')
      .replace('á', 'a')
      .replace('é', 'e')
      .replace('í', 'i')
      .replace('ó', 'o')
      .replace('ú', 'u');
  }

  setSeller() {
    if (this.getReadyFormService.getProperty('seller')) {
      this.seller = this.getReadyFormService.getProperty('seller');
    }
  }

  changeSeller(event: string): void {
    if (this.seller.seller_id !== event.toUpperCase()) {
      this.vendorMessageError = '';
    }

    this.seller.seller_id = event.toUpperCase();
    this.seller.seller_name = '';
    this.getReadyFormService.setProperty('seller', this.seller);

    if (this.isTataSeller(event)) {
      this.setTataBranch();
    } else if (this.isSellerGroup(event)) {
      this.setHeadOfficeBranch();
    } else {
      this.branchChangesDisabled = false;
      this.resolveButton();
    }
  }

  changeBranch(event): void {
    this.branchSelected = event.itemSelectedValue;
    this.seller.branch_id = this.branchSelected.value;
    this.sellerService.setSeller(this.seller);
    this.getReadyFormService.setProperty('seller', this.seller);
    this.resolveButton();
  }

  getSeller() {
    if (this.seller.seller_id) {
      this.getReadyCommonService.getSeller(this.seller.seller_id).subscribe(
        res => {
          if (res.statusCode === 200 && res.data.seller_name) {
            this.seller.seller_name = res.data.seller_name;
            this.getReadyFormService.setProperty('seller', this.seller);
            this.sellerService.setSeller(this.seller);
            this.next();
          } else {
            this.vendorMessageError = 'código inválido, intentá nuevamente por favor.';
            const sellerInput = document.querySelector('input[name="sellerId"]') as HTMLElement;
            if (sellerInput) {
              sellerInput.focus();
            }
          }
        },
        err => {
          this.getReadyCommonService.handleSystemErrors(err);
        }
      );
    } else {
      this.sellerService.setSeller(this.seller);
      this.next();
    }
  }

  next() {
    this.getReadyNavigationService.sendToNext();
  }

  newInputChange(input: string) {
    if (input === 'seller') {
      this.changeSeller(this.form.controls[input].value);
    }
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 1,
      amountBar: 8,
      currentBar: 1,
      labelName: 'datos personales'
    });
  }

  private isTataSeller(seller: string) {
    return seller && (/^T\d\d$/.test(seller.toUpperCase()) || /^X\d\d$/.test(seller.toUpperCase()));
  }

  private isSellerGroup(seller: string) {
    return seller && /^V\d\d$/.test(seller.toUpperCase());
  }

  private setTataBranch() {
    this.branchChangesDisabled = true;
    if (!this.seller.branch_id || this.seller.branch_id !== '9999') {
      const tataOption = this.branchList.find(elem => {
        return elem.value === '9999';
      });
      if (tataOption) {
        this.changeBranch({ itemSelectedValue: tataOption });
        return;
      }
    }
    this.resolveButton();
  }

  private setHeadOfficeBranch() {
    this.branchChangesDisabled = true;
    if (!this.seller.branch_id || this.seller.branch_id !== '10') {
      const headOfficeOption = this.branchList.find(elem => {
        return elem.value === '10';
      });
      if (headOfficeOption) {
        this.changeBranch({ itemSelectedValue: headOfficeOption });
        return;
      }
    }
    this.resolveButton();
  }
}
