import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DocumentPhotosGetReadyComponent } from './document-photos-get-ready/document-photos-get-ready.component';
import { DocumentFrontStageComponent } from './document-front-stage/document-front-stage.component';
import { DocumentFrontPhotoComponent } from './document-front-photo/document-front-photo.component';
import { DocumentBackStageComponent } from './document-back-stage/document-back-stage.component';
import { DocumentBackPhotoComponent } from './document-back-photo/document-back-photo.component';
import { DocumentPhotosErrorComponent } from './document-photos-errors/document-photos-error.component';

export const routes: Routes = [

  {
    path: 'document-photos-get-ready',
    component: DocumentPhotosGetReadyComponent
  },
  {
    path: 'document-front-stage',
    component: DocumentFrontStageComponent
  },
  {
    path: 'document-front-photo',
    component: DocumentFrontPhotoComponent
  },
  {
    path: 'document-back-stage',
    component: DocumentBackStageComponent
  },
  {
    path: 'document-back-photo',
    component: DocumentBackPhotoComponent
  },
  {
    path: 'document-photos-error',
    component: DocumentPhotosErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DocumentPhotosRoutingModule {}
