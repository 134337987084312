import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GetReadyFormService } from 'src/app/get-ready/shared/get-ready-form.service';

import { Analytics } from '../../shared/analytics';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { PldCommonService } from '../shared/pld-common.service';
import { PldFormService } from '../shared/pld-form.service';
import { PldNavigationService } from '../shared/pld-navigation.service';

@Component({
  selector: 'pld-purpose',
  templateUrl: './purpose.component.html',
  styleUrls: ['../shared/pld-styles.scss']
})
export class PurposeComponent implements OnInit {
  pageId = 'pld-purpose';
  showAnotherReason: boolean;
  reasons: any[] = [];
  otherReason: string;
  isBtnDisabled = false;
  hintObject: any;
  otherReasonId = 'otro motivo';
  otherReasonError: string;
  otherReasonInvalid: string;
  accPurpose = [
    {
      id: 'finanzas personales',
      specifics: ''
    }
  ];

  form = new FormGroup({
    otromotivo: new FormControl('')
  });

  constructor(
    private analyticsLib: Analytics,
    private pldFormService: PldFormService,
    private pldCommonService: PldCommonService,
    public navigationService: PldNavigationService,
    private getReadyFormService: GetReadyFormService
  ) {
    this.hintObject = {};
  }

  ngOnInit() {
    this.reasons = this.pldCommonService.getAdminList(this.setList());
    this.otherReason = '';
    this.showAnotherReason = false;

    this.resolveHint();
    this.setData();
    this.refreshButton();
    this.otherReasonError = 'Utilizá solo letras. Evitá caracteres del tipo: -, #, &.';
    this.otherReasonInvalid = '';

    if (this.pldFormService.getProperty('account_purpose')) {
      this.reasons.forEach(element => {
        const purposeId = this.pldFormService.getProperty('account_purpose')[0].id;
        element.checked = !!(purposeId === element.label);
      });
    } else {
      const reason = this.reasons.find(rs => rs.label.toLowerCase() === 'finanzas personales');
      if (reason) {
        reason.checked = true;
        this.setPurpose(reason.label);
      }
    }
  }

  resolveHint() {
    this.hintObject.message = `El propósito de la cuenta es el objetivo principal por el cuál estás abriendo tus cuentas con nosotros.`;
    this.hintObject.value = 'offer';
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.navigationService.sendToNext();
    }
  }

  refreshButton() {
    // tslint:disable-next-line: early-exit
    if (this.showAnotherReason) {
      this.isBtnDisabled =
        this.pldFormService.getProperty('account_purpose')[0].specifics.length < 4 ||
        !this.pldCommonService.validateUserInput(this.pldFormService.getProperty('account_purpose')[0].specifics);
    } else {
      this.isBtnDisabled = !this.pldFormService.getProperty('account_purpose');
    }
  }

  setData() {
    // tslint:disable-next-line: early-exit
    if (this.pldFormService.getProperty('account_purpose')) {
      this.accPurpose = this.pldFormService.getProperty('account_purpose');
      this.otherReason = this.pldFormService.getProperty('account_purpose')[0].specifics;
      this.form.controls['otromotivo'].setValue(this.otherReason);
      this.showAnotherReason = this.pldFormService.getProperty('account_purpose')[0].id === this.otherReasonId;
    }
  }

  changeReason(event: any) {
    this.showAnotherReason = event.value === this.otherReasonId;
    this.otherReason = '';
    this.setPurpose(event.value);
  }

  onInputValueChange(event: any) {
    this.setPurpose(this.otherReasonId, this.form.controls['otromotivo'].value);
    if (this.pldCommonService.validateUserInput(this.form.controls['otromotivo'].value)) {
      this.otherReasonInvalid = '';
    } else {
      this.otherReasonInvalid = this.otherReasonError;
      this.form.controls['otromotivo'].setErrors({ incorrect: true });
    }
  }

  setPurpose(id: string, reason?: string) {
    this.accPurpose = [
      {
        id: id || 'otro motivo',
        specifics: reason || ''
      }
    ];

    this.pldFormService.setProperty('account_purpose', this.accPurpose);
    this.refreshButton();
  }

  setAtual(): number {
    return 3;
  }

  setList(): AdminListEnum {
    return AdminListEnum.ACCOUNT_PURPOSE;
  }
}
