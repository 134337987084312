import { AdminListEnum } from '../enum/admin-list.enum';

export const ModuleLists = {
  getReady: [
    AdminListEnum.PRINCIPAL_ACTIVITY,
    AdminListEnum.MARITAL_STATUS,
    AdminListEnum.BRANCH_ID,
    AdminListEnum.OCA_CENTER,
    AdminListEnum.MONTHLY_INCOME,
    AdminListEnum.ACCOUNT_BENEFITS
  ],
  pld: [
    AdminListEnum.ACCOUNT_PURPOSE,
    AdminListEnum.AVG_BALANCE,
    AdminListEnum.TXN_COUNT_PER_MONTH,
    AdminListEnum.AMOUNT_QUANTITY_PER_MONTH,
    AdminListEnum.COUNTRY,
    AdminListEnum.PRINCIPAL_ACTIVITY_PJ,
    AdminListEnum.ACCOUNT_PURPOSE_PJ,
    AdminListEnum.AVG_BALANCE_PJ,
    AdminListEnum.AMOUNT_QUANTITY_PER_MONTH_PJ,
    AdminListEnum.AMOUNT_QUANTITY_PER_MONTH_CHECKS_PJ,
    AdminListEnum.AMOUNT_QUANTITY_PER_MONTH_TRANSFERS_PJ,
    AdminListEnum.TAXATION_TYPE_PJ
  ],
  offers: [
    AdminListEnum.ICONS,
    AdminListEnum.AFFINITY,
    AdminListEnum.CARD_CLASS,
    AdminListEnum.CARD_BRAND,
    AdminListEnum.ACCOUNT_TYPE,
    AdminListEnum.CURRENCY_DESCRIPTION,
    AdminListEnum.CARD_TYPE,
    AdminListEnum.HOME_OFFERS,
    AdminListEnum.CARD_INFO,
    AdminListEnum.ACCOUNT_BENEFITS,
    AdminListEnum.ACCOUNT_COMMISSIONS
  ],
  address: [
    AdminListEnum.PICKUP_CENTER,
    AdminListEnum.DELIVERY_AVAILABILITY,
    AdminListEnum.STATE_CITY,
    AdminListEnum.OCA_CENTER,
    AdminListEnum.TATA_CENTER
  ],
  common: [
    AdminListEnum.FILE_UPLOAD,
    AdminListEnum.LIST_URLS
  ],
  client: [
    AdminListEnum.ICONS,
    AdminListEnum.ACCOUNT_PURPOSE,
    AdminListEnum.AVG_BALANCE,
    AdminListEnum.AMOUNT_QUANTITY_PER_MONTH,
    AdminListEnum.PICKUP_CENTER,
    AdminListEnum.OCA_CENTER,
    AdminListEnum.TATA_CENTER,
    AdminListEnum.DELIVERY_AVAILABILITY,
    AdminListEnum.STATE_CITY,
    AdminListEnum.ACCOUNT_BENEFITS,
    AdminListEnum.ACCOUNT_COMMISSIONS
  ]
};
