import { Pipe, PipeTransform } from '@angular/core';
import { OffersCommonService } from 'src/app/offers/shared/offers-common.service';

import { AdminListEnum } from '../services/admin-lists/enum/admin-list.enum';

@Pipe({ name: 'currencyDescription' })
export class CurrencyDescriptionPipe implements PipeTransform {
  constructor(public offersCommonService: OffersCommonService) {}

  transform(currency: string, type: string): any {
    let label = currency;
    const currencyDescList = this.offersCommonService.getAdminList(AdminListEnum.CURRENCY_DESCRIPTION);

    // tslint:disable-next-line: early-exit
    if (currencyDescList) {
      currencyDescList.forEach(c => {
        // tslint:disable-next-line: early-exit
        if (c.code_iso === currency) {
          if (type === 'description') {
            label =
              c.description && c.description.length > 0 ? c.description.charAt(0).toUpperCase() + c.description.slice(1) : '';
          } else if (type === 'symbol') {
            label = c.symbol;
          }
        }
      });
      return label;
    }
  }
}
