import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ProspectMethods } from 'src/app/shared/services/domains/prospect/prospect-enum';
import { LatamItLoaderService } from 'src/latam-it-loader/latam-it-loader.service';

import { AddressFormService } from '../../address/shared/address-form.service';
import { AppService } from '../../app.service';
import { ServiceCommon } from '../../shared/service.common';
import { ListConversionService } from '../../shared/services/admin-lists/list-conversion-service/list-conversion.service';
import { ProspectService } from '../../shared/services/domains/prospect/prospect.service';
import { SolicitationMethods } from '../../shared/services/domains/solicitation/solicitation-enum';
import { SolicitationService } from '../../shared/services/domains/solicitation/solicitation.service';
import { TermsMethods } from '../../shared/services/domains/terms/terms-enum';
import { TermsService } from '../../shared/services/domains/terms/terms.service';
import { LoginService } from '../../shared/services/login.service';
import { FinalDataFormService } from './final-data-form.service';
import { FinalDataNavigationService } from './final-data-navigation.service';

@Injectable()
export class FinalDataCommonService extends ServiceCommon {
  constructor(
    private appService: AppService,
    private finalDataFormService: FinalDataFormService,
    private solicitationService: SolicitationService,
    public listConversionService: ListConversionService,
    private termsService: TermsService,
    private latamItLoaderService: LatamItLoaderService,
    private finalDataNavigationService: FinalDataNavigationService,
    private addressFormService: AddressFormService,
    private prospectService: ProspectService,
    loginService: LoginService,
  ) {
    super(listConversionService, loginService);
  }

  doGetTerms(): Observable<any> {
    const bodyPatch = this.buildBodyGetTerms();
    return this.termsService.getTermsRequest(bodyPatch, TermsMethods.GET_TERMS, true);
  }

  buildBodyGetTerms() {
    return {
      product: 'aperturadecuentas',
      category: 'confirmation',
    };
  }

  doPatchSolicitationProspectAddress(): void {
    this.latamItLoaderService.show();
    const bodyPatch = this.buildAddressPatchBody();
    this.appService.doPost(bodyPatch, SolicitationMethods.PATCH_ADDRESS, false, false).subscribe(
      res => {
        if (res.statusCode === 200) {
          this.finalDataNavigationService.sendToNext();
          console.log('doPatchSolicitationAddress', res);
        }
        this.latamItLoaderService.hide();
      },
      err => {
        this.handleSystemErrors(err);
        this.latamItLoaderService.hide();
      },
    );
  }


  buildAddressPatchBody() {
    const body: any = {
      prospect_id: [this.prospectService.getProspectId()],
      solicitation_completed: this.finalDataFormService.getProperty('solicitation_completed'),
      personal_address: this.addressFormService.getProperty('personal_address'),
      is_american: this.finalDataFormService.getProperty('is_american'),
    };

    if (this.addressFormService.getProperty('pickup_center')) {
      body['pickup_center'] = this.addressFormService.getProperty('pickup_center');
    } else if (this.addressFormService.getProperty('oca_center')) {
      body['pickup_center'] = this.addressFormService.getProperty('oca_center');
    } else if (this.addressFormService.getProperty('tata_center')) {
      body['pickup_center'] = this.addressFormService.getProperty('tata_center');
    } else {
      body['delivery_address'] = {
        address: this.addressFormService.getProperty('delivery_address'),
        availability: this.addressFormService.getProperty('availability'),
      };
    }

    return body;
  }
}
