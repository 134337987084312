import { Injectable } from '@angular/core';
import { OffersFullModel } from '../models/offers-model';
import { OfferPackageFull } from '../models/offer-package-model';

@Injectable()
export class OffersFullFormService {

  private form: OffersFullModel;

  constructor() {
    this.form = new OffersFullModel();
  }

  getForm() {
    return this.form;
  }

  setForm(form: OffersFullModel) {
    this.form = form;
  }

  getProperty(property: any) {
    return this.form[property];
  }

  setProperty(property: string, value: any) {
    this.form[property] = value;
  }

  getSelectedPackage(): OfferPackageFull {
    return this.form.packages[this.form.selected_package];
  }

}
