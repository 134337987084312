<div class="address-form__container">
  <voxel-select
    [selectId]="'stateId'"
    [items]="states"
    [label]="'Departamento'"
    placeholder="Seleccioná una opción"
    [itemSelectedValue]="stateSelected"
    (selectChange)="changeState($event)">
  </voxel-select>

  <voxel-select
    [selectId]="'cityId'"
    [items]="cities"
    [label]='[labelDistrict]'
    [itemSelectedValue]="citySelected"
    placeholder="Seleccioná una opción"
    (selectChange)="changeCity($event)">
  </voxel-select>

  <voxel-form-field
    [formGroup]="form"
    label="Dirección"
    [msgErr]="''">
    <input
      voxelInput
      id="streetId"
      type="text"
      [value]="street"
      formControlName="street"
      [maxlength]="40"
      placeholder="Ej. Av. Italia 1234, apto. 101"
      (keyup.Enter)="isEnter($event)"
      (ngModelChange)="changeStreet($event)">
  </voxel-form-field>
</div>
