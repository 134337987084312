export class SellerModel {
  seller_id: string;
  seller_name: string;
  branch_id: string;

  constructor() {
    this.seller_id = '';
    this.seller_name = '';
    this.branch_id = '';
  }
}
