import { Component, OnInit } from '@angular/core';
import { GetReadyFormFullService } from 'src/app/get-ready-full/shared/get-ready-form.service';
import { ProgressBarService } from 'src/app/progressBar.service';

import { Analytics } from '../../shared/analytics';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { CurrencyValueFullModel } from '../shared/models/currency-value';
import { PldFullCommonService } from '../shared/pld-common.service';
import { PldFullFormService } from '../shared/pld-form.service';
import { PldFullNavigationService } from '../shared/pld-navigation.service';

@Component({
  selector: 'pld-money',
  templateUrl: './money.component.html',
  styleUrls: ['../shared/pld-styles.scss']
})
export class MoneyFullComponent implements OnInit {
  pageId = 'money';
  expectedBalances: any[] = [];
  isBtnDisabled = false;
  hintObject: any;

  constructor(
    private analyticsLib: Analytics,
    private pldFormService: PldFullFormService,
    private pldCommonService: PldFullCommonService,
    public navigationService: PldFullNavigationService,
    private getReadyFormService: GetReadyFormFullService,
    private progressBarService: ProgressBarService
  ) {
    this.hintObject = {};
  }

  ngOnInit() {
    this.expectedBalances = this.pldCommonService.getAdminList(AdminListEnum.AVG_BALANCE);
    this.resolveHint();
    this.refreshButton();
    this.setProgressBar();

    this.getReadyFormService.setPreviousStage('PLDMoney');
    this.analyticsLib.buildData('SaldoPromedioMensualPLD', this.pageId, '11', false);
    this.analyticsLib.trackState(null, null);
    this.pldCommonService.scrollToTop(document);

    // tslint:disable-next-line: early-exit
    if (this.pldFormService.getProperty('avg_balance')) {
      this.expectedBalances.forEach(element => {
        const amount = this.pldFormService.getProperty('avg_balance').amount;
        element.checked = !!(amount === element.code);
      });
    }
  }

  resolveHint() {
    this.hintObject.message = `Es la cantidad de dinero que esperas mantener depositada en tus cuentas mensualmente.`;
    this.hintObject.value = `offer`;
  }

  refreshButton() {
    this.isBtnDisabled =
      !this.pldFormService.getProperty('avg_balance') || !this.pldFormService.getProperty('avg_balance').amount;
  }

  changeAmount(amount: any) {
    const balance = new CurrencyValueFullModel();
    balance.amount = amount.code;
    this.pldFormService.setProperty('avg_balance', balance);
    this.refreshButton();
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 2,
      amountBar: 3,
      currentBar: 2,
      labelName: 'Uso de la cuenta'
    });
  }
}
