import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProgressBarService } from '../progressBar.service';
import { SharedModule } from '../shared/shared.module';
import { AddressFacebookProofComponent } from './address-facebook-proof/address-facebook-proof.component';
import { AddressHomeComponent } from './address-home/address-home.component';
import { AddressProofComponent } from './address-proof/address-proof.component';
import { AddressRoutingModule } from './address.routes';
import { DeliveryAddressComponent } from './delivery-address/delivery-address.component';
import { DeliveryTimeComponent } from './delivery-time/delivery-time.component';
import { AddressCommonService } from './shared/address-common.service';
import { AddressFormService } from './shared/address-form.service';
import { AddressFormComponent } from './shared/address-form/address-form.component';
import { AddressNavigationService } from './shared/address-navigation.service';

@NgModule({
  imports: [
    AddressRoutingModule,
    SharedModule,
    CommonModule,
    FormsModule
  ],
  providers: [
    ProgressBarService,
    AddressNavigationService,
    AddressCommonService,
    AddressFormService
  ],
  declarations: [
    AddressHomeComponent,
    AddressFormComponent,
    AddressProofComponent,
    DeliveryAddressComponent,
    DeliveryTimeComponent,
    AddressFacebookProofComponent,

  ]
})

export class AddressModule {}
