<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='setAtual()'></header-oca>

  <main>
      <h1 id="titleId" class="paddingless">
          Seleccioná tu
          <strong>tipo de cédula</strong>

      </h1>

      <div>
          <div id="chip" #chip [ngClass]="{'card-document-type-selected': hasChip}" class="card-document-type" style="margin-top: 10%;"
              (click)="changeType(chip)">
              <img class="logo-document-type" src="assets/images/nueva_cedula_icono.svg" />
              <span class="card-text">
                  Con chip
              </span>
          </div>
          <div id="notChip" #notChip [ngClass]="{'card-document-type-selected': notHasChip}" class="card-document-type" style="margin-top: 5%;"
              (click)="changeType(notChip)">
              <img class="logo-document-type" src="assets/images/antigua_cedula_icono.svg" />
              <span class="card-text">
                  Sin chip
              </span>
          </div>
      </div>

  </main>
</div>

<footer>
    <button-sidebyside [isBtnDisabled]="isBtnDisabled" [navigationService]="navigationService"></button-sidebyside>
</footer>

<facebook-pixel></facebook-pixel>