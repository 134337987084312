import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { AppService } from './app.service';
import { RoutesClientEnum } from './client/shared/enums/routes-client-enum';
import { RoutesGetReadyFullEnum } from './get-ready-full/shared/enums/routes-get-ready-enum';
import { SellerModel } from './get-ready/models/seller-model';
import { RoutesGetReadyEnum } from './get-ready/shared/enums/routes-get-ready-enum';
import { Analytics } from './shared/analytics';
import { AbreCuentasRoutesEnum } from './shared/enum/abre-cuentas-routes.enum';
import { SellerService } from './shared/services/domains/seller/seller.service';
import { LoginService } from './shared/services/login.service';

@Component({
  selector: 'voxel-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  pageTitle = 'OCA - Apertura de Cuentas';
  selectedSegment = { label: 'varejo', value: 'varejo' };

  window = typeof window !== 'undefined' ? window : null;

  constructor(
    private route: Router,
    private sellerService: SellerService,
    private loginService: LoginService,
    private appService: AppService,
    public analyticsLib: Analytics
  ) {
    if (!environment.production) {
      console.warn('Environment:', environment.name);
    }
  }

  ngOnInit(): void {
    this.resolveInitialRoute();
    this.route.events.subscribe(evt => {
      if (evt instanceof NavigationStart) {
        this.analyticsLib.pageStart();
      }
    });
  }

  resolveInitialRoute() {
    this.parseOrigin();
    let initialRoute: any = RoutesGetReadyFullEnum.START;
    const index = window.location.hash.indexOf('/token/');
    if (index > -1) {
      const token = window.location.hash.slice(index + 7).split('?')[0];
      this.appService.setClientToken(token);
      this.appService.removeClientSessionId();
      this.route.navigate([RoutesClientEnum.PLANS]);
      this.sellerService.removeSeller();
      initialRoute = RoutesClientEnum.PLANS;
    } else {
      // tslint:disable-next-line:no-shadowed-variable
      const index = window.location.hash.indexOf('/session/');
      if (index > -1) {
        const sessionId = window.location.hash.slice(index + 9).split('?')[0];
        this.appService.setClientSessionId(sessionId);
        this.appService.removeClientToken();
        this.route.navigate([RoutesClientEnum.PLANS]);
        this.sellerService.removeSeller();
        initialRoute = RoutesClientEnum.PLANS;
      } else if (window.location.hash.indexOf(`/${RoutesGetReadyEnum.SALES}`) > -1) {
        this.appService.removeClientToken();
        this.appService.removeClientSessionId();
        this.route.navigate([RoutesGetReadyEnum.SALES]);
        this.sellerService.setSeller(new SellerModel());
        initialRoute = RoutesGetReadyEnum.SALES;
      } else if (window.location.hash.indexOf(`/${RoutesGetReadyFullEnum.START}`) > -1) {
        this.appService.removeClientToken();
        this.appService.removeClientSessionId();
        this.sellerService.removeSeller();
        initialRoute = RoutesGetReadyFullEnum.START;
      } else if (this.appService.getClientToken() || this.appService.getClientSessionId()) {
        this.sellerService.removeSeller();
        initialRoute = RoutesClientEnum.PLANS;
      } else if (this.sellerService.getSeller()) {
        this.sellerService.removeSeller();
        this.sellerService.setSeller(new SellerModel());
        this.appService.removeClientToken();
        this.appService.removeClientSessionId();
        initialRoute = RoutesGetReadyEnum.SALES;
      } else {
        this.appService.removeClientToken();
        this.appService.removeClientSessionId();
        this.sellerService.removeSeller();
        initialRoute = RoutesGetReadyFullEnum.START;
      }
    }

    if (this.loginService.incompatibleBrowser || this.loginService.safariSuggestion) {
      initialRoute = AbreCuentasRoutesEnum.ERROR_INCOMPATIBLE_BROWSER;
    } else if (this.loginService.maintenance) {
      initialRoute = AbreCuentasRoutesEnum.TRY_AGAIN_LATER_ERROR;
    }
    this.route.navigate([initialRoute]);

    this.route.navigate([initialRoute]);
  }

  parseOrigin() {
    const urlParts = this.window.location.href.split('?');
    if (urlParts.length > 1) {
      const queryString = urlParts[1].split('#')[0];
      const queryParams = queryString.split('&');
      const originParam = queryParams.find(elem => elem.indexOf('origin=') === 0);
      if (originParam) {
        this.appService.setOrigin(originParam.substring(7));
      } else if (!this.appService.getOrigin()) {
        this.appService.setOrigin('Cuenta');
      }
    } else if (!this.appService.getOrigin()) {
      this.appService.setOrigin('Cuenta');
    }
  }

  blockToPrevious() {
    history.forward();

    history.pushState(null, null, location.href);

    window.onpopstate = () => {
      history.go(1);
    };
  }
}
