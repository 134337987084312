import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation-service/validation.service';

import { Analytics } from '../../shared/analytics';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { CohabitantModelFull } from '../models/cohabitant-model';
import { GetReadyModelFull } from '../models/get-ready-model';
import { GetReadyCommonFullService } from '../shared/get-ready-common.service';
import { GetReadyFormFullService } from '../shared/get-ready-form.service';
import { GetReadyNavigationFullService } from '../shared/get-ready-navigation.service';

@Component({
  selector: 'get-ready-marital-status',
  templateUrl: './marital-status.component.html',
  styleUrls: ['./marital-status.component.scss']
})
export class MaritalStatusFullComponent implements OnInit {
  pageId: string;
  civilStates: any[];
  isBtnDisabled: boolean;
  showDocumentNumber: boolean;
  documentInvalid: string;
  documentErrorText: string;
  getReadyForm: GetReadyModelFull;
  partner: CohabitantModelFull;

  formDocumentNumber = new FormGroup({
    docNumber: new FormControl()
  });

  constructor(
    public navigationService: GetReadyNavigationFullService,
    public validationService: ValidationService,
    private getReadyCommonService: GetReadyCommonFullService,
    private getReadyFormService: GetReadyFormFullService,
    private analyticsLib: Analytics
  ) {}

  ngOnInit() {
    this.pageId = 'marital_status';
    this.isBtnDisabled = false;

    this.getReadyForm = this.getReadyFormService.getFormGetReady();
    this.setData();
    this.documentInvalid = '';
    this.documentErrorText = `Número inválido. Intentá nuevamente, por favor.`;

    this.setCivilStates();
    this.checkPreviousStage();
    this.analyticsLib.buildData('EstadoCivil', this.pageId, '5', false);
    this.analyticsLib.trackState(null, null);
    this.getReadyFormService.setPreviousStage('MaritalStatus');
    this.getReadyCommonService.scrollToTop(document);
  }

  setData() {
    this.getReadyForm = this.getReadyFormService.getFormGetReady();
    this.partner = this.getReadyForm.cohabitant;
    if (!this.partner) {
      this.partner = new CohabitantModelFull();
      this.getReadyForm.cohabitant = this.partner;
    }
  }

  setCivilStates() {
    const civilStateSelected = this.getReadyFormService.getProperty('marital_status');
    const allStates = this.getReadyCommonService.getAdminList(AdminListEnum.MARITAL_STATUS);

    if (civilStateSelected === 'C' || civilStateSelected === 'X') {
      const civilState = allStates.find(state => state.code === civilStateSelected);
      this.civilStates = [civilState];
      this.showDocumentNumber = true;
    } else {
      this.civilStates = allStates.map(state => ({ ...state, checked: state.code === civilStateSelected }));
      this.showDocumentNumber = false;
    }

    this.refreshButton();
  }

  changeCivilState(civilState: any) {
    const code = String(civilState.code);
    this.getReadyFormService.setProperty('marital_status', code);
    if (code === 'C' || code === 'X') {
      this.civilStates = [civilState];
      this.showDocumentNumber = true;
    }
    this.refreshButton();
    this.analyticsMaritalStatus(civilState.label);
  }

  analyticsMaritalStatus(maritalStatus) {
    this.analyticsLib.trackAction({
      customCategory: 'Body | Button | Center',
      customAction: 'Click',
      customLabel: 'EstadoCivil',
      customValue: maritalStatus
    });
  }

  changeNumber(documentNumber: string) {
    this.documentInvalid = '';
    this.partner.document.number = documentNumber;
    this.refreshButton();
  }

  validateDocument() {
    // tslint:disable-next-line: early-exit
    if (this.partner.document.number.length >= 8) {
      if (this.validationService.DocumentNumberValidation(this.partner.document.number)) {
        this.documentInvalid = '';
      } else {
        this.documentInvalid = this.documentErrorText;
      }
    }
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.nextActionButton();
    }
  }

  nextActionButton() {
    this.navigationService.sendToNext();
  }

  doPrevious() {
    // tslint:disable-next-line: early-exit
    if (this.showDocumentNumber) {
      this.showDocumentNumber = false;
      this.civilStates = this.getReadyCommonService.getAdminList(AdminListEnum.MARITAL_STATUS);
      this.refreshButton();
    } else {
      this.navigationService.sendToPrevious();
    }
  }

  checkPreviousStage() {
    if (this.getReadyFormService.getPreviousStage() !== 'Occupation') {
      this.showDocumentNumber = false;
    }
  }

  private refreshButton() {
    const code = this.getReadyFormService.getProperty('marital_status');
    if (code === 'C' || code === 'X') {
      this.isBtnDisabled =
        !code ||
        this.partner.document.number.length < 8 ||
        !this.validationService.DocumentNumberValidation(this.partner.document.number) ||
        this.showDocumentNumber !== true;
    } else {
      this.isBtnDisabled = !code;
    }
    this.getReadyFormService.setProperty('cohabitant', this.partner);
  }
}
