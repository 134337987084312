import { Component, OnInit } from '@angular/core';
import { GetReadyNavigationService } from '../../shared/get-ready-navigation.service';
import { ErrorsLinkService } from '../shared/errors-link.service';

import { GetReadyCommonService } from '../../shared/get-ready-common.service';
import { AdminListEnum } from '../../../shared/services/admin-lists/enum/admin-list.enum';

@Component({
  selector: 'error-page-duplicate-account',
  templateUrl: './error-page-duplicate-account.component.html',
  styleUrls: ['../shared/errors-style.scss', './error-page-duplicate-account.component.scss']
})
export class ErrorPageDuplicateAccountComponent implements OnInit {

  pageId: string;
  urlOCA: string;
  urlOCAApp: string;

  constructor(
    public navigationService: GetReadyNavigationService,
    private errorsLinkService: ErrorsLinkService,
    private getReadyCommonService: GetReadyCommonService
  ) {}

  ngOnInit() {
    const adminLinkURLs = this.getReadyCommonService.getAdminList(AdminListEnum.LIST_URLS);

    this.pageId = 'error_page_duplicate_account';
    this.urlOCA = adminLinkURLs.find(i => i.code.toUpperCase() === 'INSTITUTIONAL_SITE').description;
    this.urlOCAApp = this.errorsLinkService.getLink();
  }

}
