import { FinalDataModel } from '../models/final-data-model';

export class FinalDataFormService {

  private form: FinalDataModel;

  constructor() {
    this.form = new FinalDataModel();
  }

  getForm() {
    return this.form;
  }

  setForm(form: FinalDataModel) {
    this.form = form;
  }

  getProperty(property: any) {
    return this.form[property];
  }

  setProperty(property: string, value: any) {
    this.form[property] = value;
  }
}
