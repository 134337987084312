import { IdentityDocumentModel } from './identity-document.model';
import { ActivityModel } from './activity-model';
import { CohabitantModel } from './cohabitant-model';
import { SellerModel } from './seller-model';

export class GetReadyModel {
  seller?: SellerModel;
  solicitation_id: string;
  prospect_id: string;
  identity_documents: IdentityDocumentModel[];
  solicitation_type: string;
  phone: string;
  email: string;
  confirmEmail: string;
  start_date_activity: string;
  principal_activity: ActivityModel | any;
  marital_status: string;
  cohabitant: CohabitantModel;
  rut: string;
  monthly_income: any;

  constructor() {
    this.solicitation_id = '';
    this.prospect_id = '';
    this.identity_documents = [];
    this.identity_documents.push(new IdentityDocumentModel());
    this.solicitation_type = 'aperturadecuentas';
    this.phone = '';
    this.email = '';
    this.confirmEmail = '';
    this.marital_status = '';
    this.start_date_activity = '';
    this.principal_activity = new ActivityModel();
    this.cohabitant = new CohabitantModel();
    this.rut = '';
    this.monthly_income = null;
  }
}
