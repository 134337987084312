<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <div class="page-header-benefits">
    <div class="page-header-text">
      <br/><span class="page-header-text2">Cuentas</span>
    </div>
  </div>
  <main class="main-content">
    <div class="main-block">
      <span class="content-block-title">Beneficios OCA Blue</span>
    <hr class="content-line"/>

      <ul class="content-block oca-icons" *ngFor="let benefit of offersModel.packages[selectedOffer].benefits">
          <span *ngIf="benefit.type === 'common'">
            <i class="icon content-icons {{category2icon(benefit.category_id)}}"></i>
            <li class="content-text" [innerHtml]="groupText(benefit)"></li>
          </span>
      </ul>
    </div>
    <div class="main-block">
        <span class="content-block-title">Comisiones</span>
        <hr class="content-line"/>

        <ul class="content-block" *ngFor="let commission of offersModel.packages[selectedOffer].commissions">
            <li class="content-text-comission" [innerHtml]="commission.commission_description"></li>
        </ul>
        <hr class="content-line content-line--small"/>

        <div *ngIf="!offersCommonService.isFacebookBrowserOniOS()">
          <i class="icon icon-itaufonts_aplicacao descargar-icon"></i>
          <a class="descargar-text" [href]="externalUrl" [target]="hyperlinkBehavior">descargar términos y condiciones</a>
        </div>

        <div *ngIf="offersCommonService.isFacebookBrowserOniOS()">
          <i class="icon icon-itaufonts_fatura descargar-icon"></i>
          <a class="descargar-text" (click)="toggleModal()">ver términos y condiciones</a>
        </div>
    </div>

  </main>
</div>

<footer>
  <voxel-button
    id="buttonContinuar"
    label="Volver"
    typeButton="secondary"
    (click)='previous();'>
  </voxel-button>

</footer>

<div *ngIf="offersCommonService.isFacebookBrowserOniOS()">
  <pdf-viewer [externalUrl]="externalUrl" [(showModal)]="modalShow" title="tarifas y comisiones"></pdf-viewer>
</div>

<facebook-pixel></facebook-pixel>
