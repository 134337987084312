export class SocialNetworksModel {
  url: string;
  source: string;
  alt: string;
  style: string;

  constructor(url, source, alt, style) {
    this.url = url;
    this.source = source;
    this.alt = alt;
    this.style = style;
  }
}
