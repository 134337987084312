import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { DocumentPhotosNavigationService } from 'src/app/document-photos/shared/document-photos-navigation.service';
import { RoutesDocumentPhotosEnum } from 'src/app/document-photos/shared/enums/routes-document-photos-enum';
import { RoutesGetReadyFullEnum } from 'src/app/get-ready-full/shared/enums/routes-get-ready-enum';
import { GetReadyNavigationFullService } from 'src/app/get-ready-full/shared/get-ready-navigation.service';
import { RoutesGetReadyEnum } from 'src/app/get-ready/shared/enums/routes-get-ready-enum';
import { GetReadyNavigationService } from 'src/app/get-ready/shared/get-ready-navigation.service';
import { RoutesSelfieEnum } from 'src/app/selfie/shared/enums/routes-selfie-enum';

import { SelfieNavigationService } from '../../selfie/shared/selfie-navigation.service';
import { SellerService } from '../services/domains/seller/seller.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'permission-required',
  templateUrl: './permission-required.component.html',
  styleUrls: ['./permission-required.component.scss']
})
export class PermissionRequiredComponent implements OnInit {
  private reloadRoute: string;
  private safari: boolean;
  private chromeAndroid: boolean;
  private facebookBrowserAndroid: boolean;
  private frontalSelfieScreens = [RoutesSelfieEnum.FRONTAL_STAGE.toString(), RoutesSelfieEnum.FRONTAL_PHOTO.toString()];
  private inclinedSelfieScreens = [RoutesSelfieEnum.INCLINED_STAGE.toString(), RoutesSelfieEnum.INCLINED_PHOTO.toString()];
  private frontDocumentPhotosScreens = [
    RoutesDocumentPhotosEnum.DOCUMENT_FRONT_STAGE.toString(),
    RoutesDocumentPhotosEnum.DOCUMENT_FRONT_PHOTO.toString()
  ];
  private backDocumentPhotosScreens = [
    RoutesDocumentPhotosEnum.DOCUMENT_BACK_STAGE.toString(),
    RoutesDocumentPhotosEnum.DOCUMENT_BACK_PHOTO.toString()
  ];

  constructor(
    private getReadyNavigationService: GetReadyNavigationService,
    private getReadyFullNavigationService: GetReadyNavigationFullService,
    private selfieNavigationService: SelfieNavigationService,
    private documentPhotosNavigationService: DocumentPhotosNavigationService,
    private loginService: LoginService,
    private appService: AppService,
    private sellerService: SellerService
  ) {}

  ngOnInit() {
    this.reloadRoute = RoutesGetReadyFullEnum.START.toString();
    this.detectBrowser();
  }

  detectBrowser() {
    const deviceInformation = this.loginService.deviceInformation;

    this.safari = deviceInformation.browser_name.toUpperCase() === 'SAFARI';
    this.chromeAndroid =
      deviceInformation.operation_system.toUpperCase() === 'ANDROID' &&
      deviceInformation.browser_name.toUpperCase() === 'CHROME';
    this.facebookBrowserAndroid =
      deviceInformation.operation_system.toUpperCase() === 'ANDROID' &&
      deviceInformation.browser_name.toUpperCase() === 'FACEBOOK';
  }

  resolve() {
    let reloadRoute = '';
    // tslint:disable-next-line: early-exit
    if (this.chromeAndroid || this.facebookBrowserAndroid) {
      if (this.getReadyNavigationService.getStep() === this.reloadRoute) {
        this.getReadyNavigationService.navigateTo(this.reloadRoute);
        if (this.sellerService.getSeller()) {
          if (this.frontDocumentPhotosScreens.indexOf(this.documentPhotosNavigationService.getStep()) > -1) {
            this.documentPhotosNavigationService.navigateTo(RoutesDocumentPhotosEnum.DOCUMENT_FRONT_STAGE.toString());
          } else if (this.backDocumentPhotosScreens.indexOf(this.documentPhotosNavigationService.getStep()) > -1) {
            this.documentPhotosNavigationService.navigateTo(RoutesDocumentPhotosEnum.DOCUMENT_BACK_STAGE.toString());
          } else {
            this.getReadyNavigationService.navigateTo(RoutesGetReadyEnum.START.toString());
          }
        } else {
          this.selfieNavigationService.sendToPrevious();
          if (this.frontalSelfieScreens.indexOf(this.selfieNavigationService.getStep()) > -1) {
            this.selfieNavigationService.navigateTo(RoutesSelfieEnum.FRONTAL_STAGE.toString());
          } else if (this.inclinedSelfieScreens.indexOf(this.selfieNavigationService.getStep()) > -1) {
            this.selfieNavigationService.navigateTo(RoutesSelfieEnum.INCLINED_STAGE.toString());
          } else {
            this.getReadyFullNavigationService.navigateTo(RoutesGetReadyFullEnum.START.toString());
          }
        }
      } else {
        window.location.href = `?_reload&origin=${this.appService.getOrigin()}#/${this.reloadRoute}`;
        reloadRoute = this.sellerService.getSeller()
          ? RoutesGetReadyEnum.SALES.toString()
          : RoutesGetReadyFullEnum.START.toString();
        window.location.href = `?_reload&origin=${this.appService.getOrigin()}#/${reloadRoute}`;
      }
    }
  }
}
