import { Component, OnInit } from '@angular/core';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { GetReadyCommonService } from '../shared/get-ready-common.service';
import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';

@Component({
  selector: 'get-ready-marital-status',
  templateUrl: './marital-status.component.html',
  styleUrls: ['./marital-status.component.scss']
})
export class MaritalStatusComponent implements OnInit {
  pageId: string;
  civilStates: any[];
  isBtnDisabled: boolean;

  constructor(
    public navigationService: GetReadyNavigationService,
    private getReadyCommonService: GetReadyCommonService,
    private formService: GetReadyFormService,
    private getReadyFormService: GetReadyFormService
  ) {}

  ngOnInit() {
    this.pageId = 'marital_status';
    this.isBtnDisabled = false;
    this.refreshButton();
    this.civilStates = this.getReadyCommonService.getAdminList(AdminListEnum.MARITAL_STATUS);
    this.seeSelectedOption();
  }

  seeSelectedOption() {
    // tslint:disable-next-line: early-exit
    if (this.formService.getProperty('marital_status')) {
      this.civilStates.forEach(element => {
        if (this.formService.getProperty('marital_status') === element.code) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    }
  }

  changeCivilState(event: any) {
    this.formService.setProperty('marital_status', String(event.value));
    this.refreshButton();
  }

  setAtual() {
    return 4;
  }

  private refreshButton() {
    this.isBtnDisabled = !this.formService.getProperty('marital_status');
  }
}
