import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GetReadyFormFullService } from 'src/app/get-ready-full/shared/get-ready-form.service';
import { ProgressBarService } from 'src/app/progressBar.service';

import { Analytics } from '../../shared/analytics';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { RoutesPldFullEnum } from '../shared/enums/routes-pld-enum';
import { PldFullCommonService } from '../shared/pld-common.service';
import { PldFullFormService } from '../shared/pld-form.service';
import { PldFullNavigationService } from '../shared/pld-navigation.service';

@Component({
  selector: 'pld-purpose',
  templateUrl: './purpose.component.html',
  styleUrls: ['../shared/pld-styles.scss']
})
export class PurposeFullComponent implements OnInit {
  pageId = 'purpose';
  showAnotherReason: boolean;
  reasons: any[] = [];
  allReasons: any[] = [];
  otherReason: string;
  isBtnDisabled = false;
  hintObject: any;
  otherReasonId = 'otro motivo';
  otherReasonError: string;
  otherReasonInvalid: string;
  accPurpose = [
    {
      id: 'finanzas personales',
      specifics: ''
    }
  ];

  form = new FormGroup({
    otromotivo: new FormControl('')
  });

  constructor(
    private analyticsLib: Analytics,
    private pldFormService: PldFullFormService,
    private pldCommonService: PldFullCommonService,
    public navigationService: PldFullNavigationService,
    private progressBarService: ProgressBarService,
    private getReadyFormService: GetReadyFormFullService
  ) {
    this.hintObject = {};
  }

  ngOnInit() {
    this.navigationService.setStage(RoutesPldFullEnum.PURPOSE);
    this.reasons = this.allReasons = this.pldCommonService.getAdminList(this.setList());
    this.otherReason = '';
    this.showAnotherReason = false;

    this.pldFormService.setProperty('customer_type', 'PF');
    this.resolveHint();

    this.setData();

    this.refreshButton();

    this.otherReasonError = 'Utilizá solo letras. Evitá caracteres del tipo: -, #, &.';
    this.otherReasonInvalid = '';
    this.setProgressBar();
    this.analyticsLib.buildData('PropositoAperturaPLD', this.pageId, '10', false);
    this.analyticsLib.trackState(null, null);
    this.pldCommonService.scrollToTop(document);
  }

  resolveHint() {
    this.hintObject.message = `El propósito de la cuenta es el objetivo principal por el cuál estás abriendo tus cuentas con nosotros.`;
    this.hintObject.value = 'offer';
  }

  isEnter($event) {
    if ($event && !this.isBtnDisabled) {
      this.navigationService.sendToNext();
    }
  }

  refreshButton() {
    if (this.showAnotherReason) {
      this.isBtnDisabled =
        this.pldFormService.getProperty('account_purpose')[0].specifics.length < 4 ||
        !this.pldCommonService.validateUserInput(this.pldFormService.getProperty('account_purpose')[0].specifics);
    } else {
      this.isBtnDisabled =
        !this.pldFormService.getProperty('account_purpose') ||
        this.pldFormService.getProperty('account_purpose')[0].id === this.otherReasonId;
    }
  }

  setData() {
    const otherReasonId = this.otherReasonId;
    if (this.pldFormService.getProperty('account_purpose')) {
      this.accPurpose = this.pldFormService.getProperty('account_purpose');
      this.otherReason = this.pldFormService.getProperty('account_purpose')[0].specifics;

      if (
        this.getReadyFormService.getPreviousStage() === 'PLDMoney' &&
        this.pldFormService.getProperty('account_purpose')[0].id === otherReasonId
      ) {
        this.reasons = [this.allReasons.find(elem => elem.code.toLowerCase() === otherReasonId)];
        this.showAnotherReason = true;
      }
    } else {
      const reason = this.reasons.find(rs => rs.label.toLowerCase() === 'finanzas personales');
      if (reason) {
        this.accPurpose = [
          {
            id: reason.label,
            specifics: ''
          }
        ];

        reason.checked = true;

        this.pldFormService.setProperty('account_purpose', this.accPurpose);
      }
    }
  }

  changeReason(reason: any) {
    this.showAnotherReason = reason.code === this.otherReasonId;
    if (!this.showAnotherReason) {
      this.otherReason = '';
    }
    this.setPurpose(reason.code, this.otherReason);
  }

  onInputValueChange(event: string) {
    this.setPurpose(this.otherReasonId, event);
    if (this.pldCommonService.validateUserInput(event)) {
      this.otherReasonInvalid = '';
    } else {
      this.otherReasonInvalid = this.otherReasonError;
      this.form.controls['otromotivo'].setErrors({ incorrect: true });
    }
  }

  setPurpose(id: string, reason?: string) {
    this.accPurpose = [
      {
        id: id || this.otherReasonId,
        specifics: reason || ''
      }
    ];

    if (id && id.toLowerCase() === this.otherReasonId) {
      this.reasons = [this.allReasons.find(elem => elem.code.toLowerCase() === id.toLowerCase())];
    }

    this.pldFormService.setProperty('account_purpose', this.accPurpose);
    this.refreshButton();
  }

  setList(): AdminListEnum {
    return AdminListEnum.ACCOUNT_PURPOSE;
  }

  setProgressBar() {
    this.progressBarService.initializeProgressBar({
      amountLabel: 4,
      currentLabel: 2,
      amountBar: 3,
      currentBar: 1,
      labelName: 'Uso de la cuenta'
    });
  }

  doPrevious() {
    // tslint:disable-next-line: early-exit
    if (this.showAnotherReason) {
      this.showAnotherReason = false;
      this.reasons = this.allReasons;
      this.refreshButton();
    } else {
      this.navigationService.sendToPrevious();
    }
  }
}
