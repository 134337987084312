<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='3'></header-oca>

  <main>
    <h1 class="closing-date__header-container">
      ¿Qué
      <strong>día del mes</strong> querés que
      <strong>cierre</strong> tu tarjeta?
    </h1>

    <div class="closingdate__title">
        <div *ngFor="let closing of closingDateModel index as i;"
              [ngClass]="{'closing-date__button--selected': closing.ClosingDateSelected}"
              class="closing-date__button losing-date__button--margin"
              [id]="closing.ClosingDateId"
              (click)='changeClosingDate(closing)'>{{closing.ClosingDateNumber}}
        </div>
    </div>

      <div>
        <span class="closing-date__information">
          Tené en cuenta que la <strong>fecha de vencimiento</strong> será 10 días corridos después de la fecha de cierre.
        </span>
      </div>

  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    (hookNextAction)="continuarButton()"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>