<div class="landscape">
    <img src="assets/images/group.svg" alt="" class="landscape-img">

    <h1 class="landscape-title">gira tu dispositivo</h1>

    <p class="landscape-text">para seguir con tu solicitud</p>
</div>

<voxel-it-selfie
    id="address-facebook-selfie"
    [routeToNavigate]="previousRoute"
    [navigationService]="addressNavigationService"
    [repeatPictureLabel]="'Tomar otra'"
    [compressedResolutionHeight]="compressedResolutionHeight"
    [maxImageLenght]="maxImageLenght"
    headerTitle="Comprobante de domicilio"
    headerSubTitle="Tomá la foto con tu celular en la vertical."
    faceCam="false"
    [events]="eventsSubject.asObservable()"
    [errorRoute]="errorRoute"
    [noPermissionRoute]="noPermissionRoute"
    [synchronousSendImage]="false"
    (sendImage)="sendImage($event)"
    analiyticsPage = "FotoComp.Domicilio"
    transactionStep = "27">
</voxel-it-selfie>
    