<div class="black-bg content-with-filled-button">
  <div id='{{pageId}}'></div>

    <header-oca [atual]='2'></header-oca>

    <main class="black-bg">

      <img src="assets/images/img_cedula_frente.svg" alt="" class="document-photo-img">

      <h1 id="document-front-photo-title" class="document-photo-title">
        Frente de la cédula de identidad
      </h1>



    </main>
  </div>

  <footer>
    <button-sidebyside
      nextHook="true"
      [label2]="buttonText"
      [navigationService]="navigationService"
      (hookNextAction)="next()">
    </button-sidebyside>
  </footer>
  <facebook-pixel></facebook-pixel>