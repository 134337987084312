import { Injectable } from '@angular/core';

import { AdminListService } from '../admin-list-service/admin-list.service';
import { AdminListConversionType } from '../const/admin-list-conversion-type.constant';
import { AdminListEnum } from '../enum/admin-list.enum';
import { ConversionTypeEnum } from '../enum/conversion-type.enum';

@Injectable()
export class ListConversionService {
  constructor(private adminListService: AdminListService) {}

  getConvertedList(listName: AdminListEnum, separateBy: string = '', idName: string = ''): any[] {
    const conversionType = AdminListConversionType[listName];
    let responseArray: any[];

    if (conversionType === ConversionTypeEnum.VOXEL_RADIO) {
      responseArray = this.convertListforVoxelRadio(this.adminListService.getAdminList(listName));
    } else if (conversionType === ConversionTypeEnum.VOXEL_SELECT) {
      responseArray = this.convertListforVoxelSelect(this.adminListService.getAdminList(listName), separateBy, idName);
    } else if (conversionType === ConversionTypeEnum.PICKUP_CENTER_VOXEL_SELECT) {
      responseArray = this.convertPickupCenterforVoxelSelect(this.adminListService.getAdminList(listName));
    } else if (conversionType === ConversionTypeEnum.NONE) {
      responseArray = this.adminListService.getAdminList(listName);
    }

    return responseArray;
  }

  convertListforVoxelRadio(
    response: any[],
  ): {
    label: string;
    checked: boolean;
    elementId: string | number;
    code: string;
    code_iso: string;
  }[] {
    return response.map(item => {
      return {
        label: item.description,
        checked: false,
        elementId: item.id || item.code,
        code: item.code,
        code_iso: item.code_iso,
      };
    });
  }

  convertListforVoxelSelect(
    response: any[],
    separateBy: string = '',
    idName: string = '',
  ): {
    label: string;
    value: string;
    id?: string;
  }[] {
    if (!separateBy) {
      return response.map(item => {
        return {
          label: item.description,
          value: item.code,
        };
      });
    }
    return response.map((item, index) => {
      return {
        label: item[separateBy].toString(),
        value: index.toString(),
        id: item[idName].toString(),
      };
    });

  }

  convertPickupCenterforVoxelSelect(
    response: any[],
  ): {
    label: string;
    value: string;
    id: string;
    availability: string;
    state: string;
  }[] {
    return response.map((item, index) => {
      return {
        label: item.title,
        value: item.id,
        id: item.code,
        availability: item.description,
        state: item.state,
      };
    });
  }
}
