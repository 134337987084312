import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressBarService } from '../progressBar.service';
import { AbreCuentasPipe } from '../shared/pipes/pipe.modules';
import { SharedModule } from '../shared/shared.module';
import { BenefitsCommissionsFullComponent } from './benefits-comissions/benefits-comissions.component';
import { CreditCardFullComponent } from './credit-card/credit-card.component';
import { OffersFullRoutingModule } from './offers.routes';
import { OffersFullCommonService } from './shared/offers-common.service';
import { OffersFullFormService } from './shared/offers-form.service';
import { OffersFullNavigationService } from './shared/offers-navigation.service';


@NgModule({
  imports: [
    OffersFullRoutingModule,
    SharedModule,
    CommonModule,
    AbreCuentasPipe
  ],
  exports: [
  ],
  providers: [
    ProgressBarService,
    OffersFullCommonService,
    OffersFullFormService,
    OffersFullNavigationService,
  ],
  declarations: [
    BenefitsCommissionsFullComponent,
    CreditCardFullComponent,
  ]
})

export class OffersFullModule {}
