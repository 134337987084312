import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardBrandFull } from 'src/app/offers-full/models/card-brand.model';
import { OffersFullModel } from 'src/app/offers-full/models/offers-model';
import { ProspectService } from 'src/app/shared/services/domains/prospect/prospect.service';

import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ClientCommonService } from '../shared/client-common.service';
import { ClientFormService } from '../shared/client-form.service';
import { ClientNavigationService } from '../shared/client-navigation.service';
import { Analytics } from './../../shared/analytics';

@Component({
  selector: 'client-confirmation',
  templateUrl: './client-confirmation.component.html',
  styleUrls: ['./client-confirmation.component.scss']
})
export class ClientConfirmationComponent implements OnInit, OnDestroy {
  static TRACKING_CLIENTE = 'TRACKING-CLIENTE-PANTALLA-8';

  pageId: string;
  icons: any[];
  overdraftLimit: string;
  offersModel: OffersFullModel;
  cardType: string;
  cardBrand: CardBrandFull;
  cardCreditLimit: string;
  body = document.querySelector('body');
  acceptTerms: boolean;
  urlTerms: string;
  hyperlinkBehavior: string;
  usPerson: boolean;
  hintObject: any;

  name: string;
  pickUpOrDeliveryMode: string;
  pickUpOrDeliveryAddressMode: string;
  pickUpOrDeliveryAddress: string;
  pickUpOrDeliveryHourMode: string;
  pickUpOrDeliveryHour: string;

  constructor(
    public clientNavigationService: ClientNavigationService,
    public clientCommonService: ClientCommonService,
    private clientFormService: ClientFormService,
    private analyticsLib: Analytics,
    private prospectService: ProspectService
  ) {
    this.hintObject = {};
  }

  ngOnInit() {
    this.resolveHint();
    this.pageId = 'confirmation_final_data';
    this.setData();

    this.acceptTerms = this.clientFormService.getProperty('solicitation_completed') || false;
    this.usPerson = this.clientFormService.getProperty('is_american') === false ? true : false;
    this.hyperlinkBehavior = this.clientCommonService.getHyperlinkBehavior();

    this.clientFormService.setPreviousStage('ClientConfirmation');
    this.analyticsLib.buildData('Confirmacion', this.pageId, 'Checkout', true);
    this.analyticsLib.trackState(null, null);
    this.setPickUpOrDeliverySection();
    this.clientCommonService.scrollToTop(document);
    this.doPatchProspectTracking();
  }

  setPickUpOrDeliverySection() {
    this.pickUpOrDeliveryMode = this.clientFormService.getProperty('pickUpOrDeliveryMode');
    this.pickUpOrDeliveryAddressMode = this.clientFormService.getProperty('pickUpOrDeliveryAddressMode');
    this.pickUpOrDeliveryAddress = this.clientFormService.getProperty('pickUpOrDeliveryAddress');
    this.pickUpOrDeliveryHourMode = this.clientFormService.getProperty('pickUpOrDeliveryHourMode');
    this.pickUpOrDeliveryHour = this.clientFormService.getProperty('pickUpOrDeliveryHour');
  }

  ngOnDestroy() {
    this.body.setAttribute('style', 'background-color: white');
  }

  setData() {
    this.name = this.prospectService.getFirstName();
    const cardTypeList: any = this.clientCommonService.getAdminList(AdminListEnum.CARD_TYPE);
    const cardBrandList: any = this.clientCommonService.getAdminList(AdminListEnum.CARD_BRAND);
    this.icons = this.clientCommonService.getAdminList(AdminListEnum.ICONS);

    this.offersModel = this.getOffersModel();
    this.cardType = cardTypeList ? cardTypeList[0].label : null;
    this.cardBrand = new CardBrandFull();
    this.cardBrand.description = cardBrandList ? cardBrandList[0].label : null;
  }

  continuarButton() {
    this.clientCommonService.doPostSolicitationComplete();
  }

  checkboxChange(event: any) {
    this.acceptTerms = event.checked;
    this.clientFormService.setProperty('solicitation_completed', event.checked);
  }

  checkboxAmericanChange(event: any) {
    this.usPerson = event.checked;
    this.clientFormService.setProperty('is_american', !this.usPerson);
  }

  getOffersModel(): any {
    return {
      packages: this.clientCommonService.offersResponse.packages,
      selected_package: 0,
      accepted_credit: undefined,
      accepted_credit_card: undefined,
      credit_limit: NaN,
      credit_card_limit: NaN
    };
  }

  resolveHint() {
    // tslint:disable-next-line:max-line-length
    this.hintObject.message = `Por el momento no es posible realizar la apertura de cuenta a ciudadanos de Estados Unidos, o US Person. Son considerados US Person todos aquellos que tienen obligaciones de pagar impuestos en los EE.UU.`;
    this.hintObject.value = 'offer';
  }

  doPatchProspectTracking() {
    const clientFlowId = this.prospectService.getClientFlowId();
    // tslint:disable-next-line: early-exit
    if (clientFlowId) {
      const body: any = {
        prospect_id: clientFlowId,
        prospect_type: ClientConfirmationComponent.TRACKING_CLIENTE
      };

      this.clientCommonService.doPatchProspectTracking(body);
    }
  }
}
