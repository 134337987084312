import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit, OnChanges {
  private showModalValue: boolean;
  private iframeLoadedCorrectly = true;
  private timeout: NodeJS.Timer;
  private initialLoadingTime: number;
  private GOOGLE_DOC_VIEWER_URL = 'https://docs.google.com/gview?embedded=true&url=';

  @Input()
  get showModal() {
    return this.showModalValue;
  }

  set showModal(val) {
    this.showModalValue = val;

    // tslint:disable-next-line: no-accessor-recursion
    if (this.showModal) {
      this.setLoadingTimeout();
      this.initialLoadingTime = Date.now();
    } else {
      this.killTimeout();
    }

    this.showModalChange.emit(val);
  }

  @Input() externalUrl: string;

  @Input() title: string;

  @Input() loadInterval = 1500;

  @Output() showModalChange = new EventEmitter<boolean>();

  @ViewChild('iframe', { static: true }) iframe: ElementRef;

  ngOnInit() {
    // Below line was added due to a bug in Safari where it would not click outside the iframe
    document.addEventListener('touchstart', () => {
      return;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.externalUrl) {
      this.externalUrl = this.GOOGLE_DOC_VIEWER_URL + changes.externalUrl.currentValue;
      this.iframe.nativeElement.src = this.externalUrl;
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  // All methods below are here due to a bug where Google Docs Viewer responds with a 204 (no content) from time to time
  verifyLoading() {
    if (!this.iframeLoadedCorrectly) {
      this.iframe.nativeElement.src = this.externalUrl;
      this.initialLoadingTime = Date.now();
      this.setLoadingTimeout();
    }

    this.iframeLoadedCorrectly = true;
  }

  setLoadingTimeout() {
    this.timeout = setTimeout(() => {
      this.verifyLoading();
    }, 5000);
  }

  killTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.iframeLoadedCorrectly = true;
    }
  }

  // If iframe loads within the load interval, this means that Google responded with a 204, so iframe has to be reloaded
  onLoad() {
    const noContentResponse = Date.now() - this.initialLoadingTime < this.loadInterval;

    this.iframeLoadedCorrectly = !noContentResponse;
  }
}
