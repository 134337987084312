import { AccountOffer } from './account-offers.model';
import { CardOffer } from './card-offers-model';
import { Money } from './money-model';
import { OfferComission } from './offer-comission-model';
import { OfferBenefit } from './offer-benefit-model';

export class OfferPackage {
  package_id: string;
  package_title: string;
  package_description: string;
  account_offers: AccountOffer[];
  card_offers: CardOffer[];
  prices: Money[];
  commissions: OfferComission[];
  benefits: OfferBenefit[];

  constructor() {
    this.package_id = '';
    this.package_title = '';
    this.package_description = '';
    this.account_offers = [new AccountOffer()];
    this.card_offers = [new CardOffer()];
    this.prices = [new Money()];
    this.commissions = [new OfferComission()];
    this.benefits = [new OfferBenefit()];
  }
}
