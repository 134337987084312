import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddressHomeComponent } from './address-home/address-home.component';
import { AddressProofComponent } from './address-proof/address-proof.component';
import { DeliveryAddressComponent } from './delivery-address/delivery-address.component';
import { DeliveryTimeComponent } from './delivery-time/delivery-time.component';
import { AddressFacebookProofComponent } from './address-facebook-proof/address-facebook-proof.component';

export const routes: Routes = [
  {
    path: 'domicilio-particular',
    component: AddressHomeComponent
  },
  {
    path: 'comprobante-domicilio',
    component: AddressProofComponent
  },
  {
    path: 'domicilio-recibir-tarjeta',
    component: DeliveryAddressComponent
  },
  {
    path: 'hora-recibir',
    component: DeliveryTimeComponent
  },
  {
    path: 'comprobante-domicilio-facebook',
    component: AddressFacebookProofComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AddressRoutingModule {}
