import { VoxelPlatformService } from '@voxel/mobile';
import { Injectable } from '@angular/core';
import { GetReadyCommonService } from '../../shared/get-ready-common.service';
import { AdminListEnum } from '../../../shared/services/admin-lists/enum/admin-list.enum';

@Injectable()
export class ErrorsLinkService {
  ios: string;
  android: string;

  constructor(
      private platformService: VoxelPlatformService,
      private getReadyCommonService: GetReadyCommonService
    ) {
    this.setLinks();
  }

  setLinks() {
    const adminLinkURLs = this.getReadyCommonService.getAdminList(AdminListEnum.LIST_URLS);

    this.ios = adminLinkURLs.find(i => i.code.toUpperCase() === 'SUPER_APP_IOS').description;
    this.android = adminLinkURLs.find(i => i.code.toUpperCase() === 'SUPER_APP_ANDROID').description;
  }

  getLink(): string {
    return this.platformService.getPlatform() === 'iOS' ?
            this.ios : this.android;
  }
}
