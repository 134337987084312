import { Injectable } from '@angular/core';

import { AppService } from '../../../../app.service';
import { ProspectMethods } from './prospect-enum';

@Injectable()
export class ProspectService {
  private id: string;
  private first_name: string;
  private client_flow_id: string;

  constructor(private appService: AppService) {}

  setProspectId(prospectId: string): void {
    this.id = prospectId;
  }

  getProspectId(): string {
    return this.id;
  }

  setClientFlowId(clientFlowId: string): void {
    this.client_flow_id = clientFlowId;
  }

  getClientFlowId(): string {
    return this.client_flow_id;
  }


  setFirstName(first_name: string): void {
    this.first_name = first_name;
  }

  getFirstName(): string {
    return this.first_name;
  }

  prospectRequest(requestBody: any, method: ProspectMethods, showLoader: boolean, hideLoader: boolean) {
    return this.appService.doPost(requestBody, method, showLoader, hideLoader);
  }
}
