<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <header-oca [atual]='1'></header-oca>

  <div class="img-container">
    <img class="image" src="assets/images/Contacto.svg" alt="pld-home">
  </div>

  <main>
    <div class="text-container" id="titleId">
      <div class="name">
        <strong>{{name}}</strong><span *ngIf="name">,</span>
      </div>
      <div class="sub-text-1 pld-home-text">
        ya falta poco para terminar tu solicitud
      </div>
      <div class="sub-text-2">
        Ahora debemos hacerte algunas preguntas para cumplir con la normativa vigente.
        <br>
        <br>
        <strong>Serán sólo unos minutos.</strong>
      </div>
    </div>

  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    [navigationService]="pldNavigation"
    (hookNextAction)="next()">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>