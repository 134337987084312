import { NgModule } from '@angular/core';

import { CommonNavigationService } from './common-navigation.service';
import { CommunicatorService } from './communicator.service';
import { LoginService } from './login.service';
import { AdminListsModule } from './admin-lists/admin-lists.module';
import { ValidationService } from './validation-service/validation.service';
import { ArchiveService } from './domains/archive/archive.service';
import { OffersService } from './domains/offers/offers.service';
import { ProspectService } from './domains/prospect/prospect.service';
import { SellerService } from './domains/seller/seller.service';
import { SolicitationService } from './domains/solicitation/solicitation.service';
import { TermsService } from './domains/terms/terms.service';

@NgModule({
  imports: [
    AdminListsModule,
  ],
  providers: [
    CommonNavigationService,
    CommunicatorService,
    LoginService,
    ValidationService,
    ArchiveService,
    OffersService,
    ProspectService,
    SellerService,
    SolicitationService,
    TermsService,
  ],
  exports: [
    AdminListsModule,
  ]
})
export class ServicesModule {}
