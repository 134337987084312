import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BenefitsComissionsComponent } from './benefits-comissions/benefits-comissions.component';
import { ClosingDateComponent } from './closing-date/closing-date.component';
import { PlansComponent } from './plans/plans.component';
import { CreditCardComponent } from './credit-card/credit-card.component';

export const routes: Routes = [
  {
    path: 'oferta-plans',
    component: PlansComponent
  },
  {
    path: 'oferta-beneficios-comissiones',
    component: BenefitsComissionsComponent
  },
  {
    path: 'oferta-tarjeta-credito',
    component: CreditCardComponent
  },
  {
    path: 'oferta-fecha-cierre',
    component: ClosingDateComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class OffersRountingModule {}
