import { IdentityDocumentFullModel } from 'src/app/get-ready-full/models/identity-document.model';
import { SolicitationStatusModel } from './solicitation-status';

export class SolicitationResponseModel {
  solicitation_id: string;
  prospect_id?: string;
  solicitation_type: string;
  identity_documents: IdentityDocumentFullModel;
  solicitation_completed: boolean;
  solicitation_status: SolicitationStatusModel;
}
