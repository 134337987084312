<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <header-oca [atual]='8'></header-oca>

  <main>

    <h1 id="titleId" class="title-container">
      ¿Cuál es tu <strong>actividad laboral o profesión</strong>?
    </h1>

    <div class='input-container'>

      <span class="label-input">Seleccioná una actividad</span>
      <latam-it-search-input-select
        [label]="'seleccioná una actividad'"
        [items]="activities"
        [dropdownPosition]="'bottom'"
        (changeEvent)="changeActivityValue($event)"
        [filterValue]="activityLabel"
        notFoundText="Sin resultados">
      </latam-it-search-input-select>

      <br><br>
    </div>
  
  </main>
</div>

<footer>
  <button-sidebyside [isBtnDisabled]="isBtnDisabled" [navigationService]="navigationService">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>
