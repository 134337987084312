import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PldHomeComponent } from './pld-home/pld-home.component';
import { PurposeComponent } from './purpose/purpose.component';
import { MoneyComponent } from './money/money.component';
import { CreditsComponent } from './credits/credits.component';

export const routes: Routes = [

  {
    path: 'pld-comienzo',
    component: PldHomeComponent
  },
  {
    path: 'pld-proposito',
    component: PurposeComponent
  },
  {
    path: 'pld-saldo-promedio',
    component: MoneyComponent
  },
  {
    path: 'pld-acreditaciones-mensuales',
    component: CreditsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PldRoutingModule {}
