<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <header-oca [atual]='7'></header-oca>

  <main>

    <h1 id="titleId" class='title-container'>
      Ingresá tus datos de <strong>contacto</strong>
    </h1>

    <div class='input-container'>

      <voxel-form-field
        [formGroup]="form"
        label="Celular"
        [msgErr]="errorPhoneMessage">
      <input
        voxelInput
        id="telefono_celular"
        [value]="'09' + phoneNumber.slice(2)"
        formControlName="phoneNumber"
        [voxelMask]="mask"
        (ngModelChange)="newInputChange('phoneNumber')">
      </voxel-form-field>
    </div>

    <div class='input-container'>

      <voxel-form-field
        [formGroup]="form"
        label="Email"
        [msgErr]="emailErrorMessage">
      <input
        voxelInput
        id="e-mail"
        [type]="'text'"
        [value]="email"
        formControlName="email"
        maxlength="40"
        (blur)='validateEmail($event, false)'
        (ngModelChange)='validateEmail($event, true)'>
      </voxel-form-field>
    </div>

    <div class='input-container'>
      <voxel-form-field
        [formGroup]="form"
        label="Confirmá tu email"
        [msgErr]="confirmEmailErrorMessage">
      <input
        voxelInput
        id="e-mail"
        [type]="'text'"
        [value]="confirmEmail"
        formControlName="confirmEmail"
        maxlength="40"
        (blur)="validateConfirmationEmail($event, false)"
        (ngModelChange)="validateConfirmationEmail($event, true)">
      </voxel-form-field>
    </div>
  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    (hookNextAction)="doPost()"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>

<facebook-pixel></facebook-pixel>