import { Component, OnInit } from '@angular/core';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';

import { Analytics } from '../../shared/analytics';
import { ClosingDateModel } from '../models/closing-date.model';
import { RoutesOffersEnum } from '../shared/enums/routes-offers-enum';
import { OffersCommonService } from '../shared/offers-common.service';
import { OffersFormService } from '../shared/offers-form.service';
import { OffersNavigationService } from '../shared/offers-navigation.service';

@Component({
  selector: 'offers-closing-date',
  templateUrl: './closing-date.component.html',
  styleUrls: ['./closing-date.component.scss']
})
export class ClosingDateComponent implements OnInit {
  pageId: string;
  isBtnDisabled: boolean;
  oldSelect: ClosingDateModel;
  closingDateModel: ClosingDateModel[];

  constructor(
    private formService: OffersFormService,
    public navigationService: OffersNavigationService,
    private offersCommonService: OffersCommonService,
    private analyticsLib: Analytics,
    private sellerService: SellerService
  ) {
    this.pageId = 'closingDate';
    this.closingDateModel = this.getDate();
    this.setDate();
  }

  ngOnInit() {
    this.navigationService.setStage(RoutesOffersEnum.CLOSINGDATE);
    this.offersCommonService.scrollToTop(document);

    if (!this.sellerService.isSeller()) {
      this.analyticsLib.buildData('CierreTarjCredito', this.pageId, '13', false);
      this.analyticsLib.trackState(null, null);
    }
  }

  changeClosingDate(model: ClosingDateModel) {
    // tslint:disable-next-line: early-exit
    if (model !== this.oldSelect) {
      model.ClosingDateSelected = true;
      if (this.oldSelect) {
        this.oldSelect.ClosingDateSelected = !this.oldSelect.ClosingDateSelected;
      }
      this.isBtnDisabled = false;
      this.oldSelect = model;
      this.formService.setProperty('closing_date', model.ClosingDateNumber);
    }
  }

  setDate() {
    this.isBtnDisabled = true;
    const day = this.formService.getProperty('closing_date');

    // tslint:disable-next-line: early-exit
    if (day) {
      for (const value of this.closingDateModel) {
        if (value.ClosingDateNumber === day) {
          this.oldSelect = value;
          value.ClosingDateSelected = true;
        }
      }
      this.isBtnDisabled = false;
    }
  }

  getDate() {
    return [
      new ClosingDateModel('btnThree', '03', false),
      new ClosingDateModel('btnEleven', '11', false),
      new ClosingDateModel('btnEighteen', '18', false),
      new ClosingDateModel('btnTwentySix', '26', false)
    ];
  }

  continuarButton() {
    this.offersCommonService.doPatchSolicitationPackageSelected();
  }
}
