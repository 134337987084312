<div class="content-with-filled-button">
  <div [id]="pageId"></div>
  <client-header-oca [atual]='6'></client-header-oca>

  <main>
    <div id="di23">
    <h1 class="title-container">
      ¿Cuál es tu <strong>domicilio particular</strong>?
    </h1>

    <vcl-additional-info
      id='addtionalInformationId'
      [text]="hintObject.message"
      position="down-left"
    ></vcl-additional-info>
</div>
    <client-address-form
        [stateSelected]="stateSelected"
        [citySelected]="citySelected"
        [street]="street"
        [states]="states"
        [cities]="cities"
        (validationForm)="enableButton($event)">
    </client-address-form>
  </main>
</div>

<footer>
  <button-sidebyside
    nextHook="true"
    (hookNextAction)="next()"
    (hookPreviousAction)="analyticsVolver()"
    [isBtnDisabled]="isBtnDisabled"
    [navigationService]="clientNavigationService">
  </button-sidebyside>
</footer>