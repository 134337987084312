import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BenefitsCommissionsFullComponent } from './benefits-comissions/benefits-comissions.component';
import { CreditCardFullComponent } from './credit-card/credit-card.component';

export const routes: Routes = [

  {
    path: 'oferta-beneficios-comissiones-full',
    component: BenefitsCommissionsFullComponent
  },
  {
    path: 'oferta-tarjeta-credito-full',
    component: CreditCardFullComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class OffersFullRoutingModule {}
