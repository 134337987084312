import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { MessageState } from './latam-error-message-state';

@Injectable()
export class LatamErrorMessageService {
  private messageSubject = new Subject<MessageState>();
  messageState = this.messageSubject.asObservable();

  show() {
    this.messageSubject.next({ show: true } as MessageState);
  }

  hide() {
    this.messageSubject.next({ show: false } as MessageState);
  }
}
