import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesAddressFullEnum } from 'src/app/address-full/shared/enums/routes-address-enum';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';
import { RoutesOffersFullEnum } from './enums/routes-offers-enum';



@Injectable()
export class OffersFullNavigationService extends CommonNavigationService {

  constructor(
    route: Router,
    buttonSideBySideHelperService: ButtonSideBySideHelperService
  ) {
    super(route, buttonSideBySideHelperService);

    this.step = RoutesOffersFullEnum.CREDITCARD;
    this.stepAmount = 3;
    this.flows = [
      {
        stage: RoutesOffersFullEnum.CREDITCARD,
        previous: null,
        next: RoutesAddressFullEnum.DELIVERY_ADDRESS
      }
    ];
  }
}
