import { AddressFullModel } from '../models/address-model';
import { ResidenceModel } from '../models/residence-model';

export class AddressFullFormService {

  private form: ResidenceModel;

  personalAddressProspect = new AddressFullModel();

  constructor() {
    this.form = new ResidenceModel();
  }

  getForm() {
    return this.form;
  }

  setForm(form: ResidenceModel) {
    this.form = form;
  }

  getProperty(property: any) {
    return this.form[property];
  }

  setProperty(property: string, value: any) {
    this.form[property] = value;
  }
}
