import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AbreCuentasRoutesEnum } from 'src/app/shared/enum/abre-cuentas-routes.enum';
import { VoxelItSelfieService } from 'src/latam-it-camera-selfie/voxel-it-selfie.service';
import { DocumentPhotosCommonService } from '../shared/document-photos-common.service';
import { DocumentPhotosNavigationService } from '../shared/document-photos-navigation.service';
import { DocumentPhotoApproach, RoutesDocumentPhotosEnum } from '../shared/enums/routes-document-photos-enum';



@Component({
  selector: 'document-back-stage',
  templateUrl: './document-back-stage.component.html',
  styleUrls: ['../shared/document-photos-styles.scss']
})
export class DocumentBackStageComponent implements OnInit, OnDestroy {

  pageId = 'document-back-stage';
  buttonText: string;
  private successRoute = RoutesDocumentPhotosEnum.DOCUMENT_BACK_PHOTO;
  private permissionDeniedRoute = AbreCuentasRoutesEnum.PERMISSION_REQUIRED.toString();
  private errorRoute = AbreCuentasRoutesEnum.CAMERA_ERROR.toString();

  constructor(
    public navigationService: DocumentPhotosNavigationService,
    private elementRef: ElementRef,
    private commonService: DocumentPhotosCommonService,
    private voxelItSelfieService: VoxelItSelfieService) {}

  ngOnInit() {
    this.buttonText = this.commonService.choice === DocumentPhotoApproach.PHOTO ? 'Activa cámara' : 'Continuar';
    this.navigationService.setStage(RoutesDocumentPhotosEnum.DOCUMENT_BACK_STAGE);
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
  }

  next() {
    if (this.commonService.choice === DocumentPhotoApproach.PHOTO) {

      this.voxelItSelfieService.getUserMediaPermission(this.successRoute, this.permissionDeniedRoute, this.errorRoute);

    } else {

      this.navigationService.sendToNext();
    }
  }

  previous() {
    this.navigationService.navigateTo(RoutesDocumentPhotosEnum.DOCUMENT_FRONT_STAGE);
  }

}
