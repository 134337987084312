import 'rxjs/add/observable/timer';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { RoutesOffersEnum } from 'src/app/offers/shared/enums/routes-offers-enum';

import { OffersNavigationService } from '../../offers/shared/offers-navigation.service';
import { LatamErrorMessageService } from '../../shared/latam-error-message/latam-error-message.service';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { GetReadyModel } from '../models/get-ready-model';
import { GetReadyCommonService } from '../shared/get-ready-common.service';
import { GetReadyConstants } from '../shared/get-ready-constants';
import { GetReadyFormService } from '../shared/get-ready-form.service';
import { GetReadyNavigationService } from '../shared/get-ready-navigation.service';

@Component({
  selector: 'preparate-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  pageId = '03_loading';
  isBtnDisabled = false;
  getReadyForm: GetReadyModel;
  loaderIcon = true;
  percent = 0;
  cardOffers: any[];
  actualCardOffers = 0;
  timerOffers: Observable<any>;
  timerSolicitation: Observable<any>;
  subscriptionTimerOffers;
  subscriptionTimerSolicitation;
  getReadyCommonServiceSubscription: Subscription;

  mockCount = 0;
  getSolicitationCount = 0;
  getSolicitationMAX = 30;
  private hasSolicitationResponse = false;

  constructor(
    private getReadyCommonService: GetReadyCommonService,
    public navigationService: GetReadyNavigationService,
    private getReadyFormService: GetReadyFormService,
    private offersNavigationService: OffersNavigationService,
    private latamErrorMessageService: LatamErrorMessageService
  ) {
    this.timerOffers = Observable.timer(10000, 10000);
    this.timerSolicitation = Observable.timer(3000, 3000);
  }

  ngOnInit() {
    this.cardOffers = this.convertToCardList(this.getReadyCommonService.getAdminList(AdminListEnum.ACCOUNT_BENEFITS));
    this.getReadyForm = this.getReadyFormService.getFormGetReady();
    this.subscriptionTimerOffers = this.timerOffers.subscribe(t => {
      this.nextCard();
    });

    this.startGetSolicitationLoop();

    this.getReadyCommonServiceSubscription = this.getReadyCommonService.emissorEventos.subscribe(event =>
      this.handleEvent(event)
    );
  }

  convertToCardList(list) {
    const self = this;
    return list.map((elem, index) => ({
      id: index + 1,
      title: 'Beneficios',
      text: self.replaceAccents(elem.title) + ' ' + self.replaceAccents(elem.benefit_description),
      description: ''
    }));
  }

  replaceAccents(text) {
    return text
      .replace(/&aacute;/g, 'á')
      .replace(/&eacute;/g, 'é')
      .replace(/&iacute;/g, 'í')
      .replace(/&oacute;/g, 'ó')
      .replace(/&uacute;/g, 'ú');
  }

  startGetSolicitationLoop() {
    this.getSolicitationCount = 1;
    this.doGetSolicitation();

    this.subscriptionTimerSolicitation = this.timerSolicitation.subscribe(t => {
      if (this.getSolicitationCount < this.getSolicitationMAX) {
        if (this.hasSolicitationResponse) {
          this.hasSolicitationResponse = false;
          this.doGetSolicitation();
          this.getSolicitationCount++;
        }
      } else {
        this.destroyTimerSolicitation();
        this.navigationService.sendToPrevious();
        this.latamErrorMessageService.show();
      }
    });
  }

  handleEvent(event) {
    switch (event) {
      case GetReadyConstants.GET_SOLICITATION_SUCCESS: {
        this.hasSolicitationResponse = true;
        this.percent = this.getReadyCommonService.solicitationResponse.solicitation_status.progress_percentage;
        // tslint:disable-next-line: early-exit
        if (this.percent === 100) {
          this.loaderIcon = false;
          this.destroyTimerSolicitation();
          this.doPatchProspect();
        }
        break;
      }
      case GetReadyConstants.PATCH_PROSPECT_SUCCESS: {
        this.offersNavigationService.navigateTo(RoutesOffersEnum.PLANS);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyTimerSolicitation();
    this.destroyTimerOffers();
    this.destroyGetReadyCommonServiceSubscription();
  }

  doGetSolicitation() {
    this.getReadyCommonService.getSolicitation();
  }

  doPatchProspect() {
    this.getReadyCommonService.patchProspectGetReady();
  }

  destroyTimerOffers() {
    if (this.subscriptionTimerOffers) {
      this.subscriptionTimerOffers.unsubscribe();
      this.subscriptionTimerOffers = undefined;
    }
  }

  destroyTimerSolicitation(): void {
    if (this.subscriptionTimerSolicitation) {
      this.subscriptionTimerSolicitation.unsubscribe();
      this.subscriptionTimerSolicitation = undefined;
    }
  }

  destroyGetReadyCommonServiceSubscription() {
    if (this.getReadyCommonServiceSubscription) {
      this.getReadyCommonServiceSubscription.unsubscribe();
    }
  }

  nextCard(): void {
    this.actualCardOffers++;
    if (this.actualCardOffers >= this.cardOffers.length) {
      this.actualCardOffers = 0;
    }
  }
}
