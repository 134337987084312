import { Component } from '@angular/core';

import { SelfieNavigationService } from '../shared/selfie-navigation.service';

@Component({
  selector: 'selfie-error',
  templateUrl: './selfie-error.component.html'
})
export class SelfieErrorComponent {
  pageId = 'selfie-error';

  errorMsg = 'Es posible que tu foto no este centrada o haya salido borrosa.';

  modalShow = false;

  constructor(public navigationService: SelfieNavigationService) {}

  mainButtonClick() {
    return;
  }

  modalEnable() {
    this.modalShow = !this.modalShow;
  }
}
