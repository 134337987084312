import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OfferPackageFull } from 'src/app/offers-full/models/offer-package-model';
import { OffersFullModel } from 'src/app/offers-full/models/offers-model';
import { RoutesSelfieEnum } from 'src/app/selfie/shared/enums/routes-selfie-enum';
import { CommonNavigationService } from 'src/app/shared/services/common-navigation.service';
import { ButtonSideBySideHelperService } from 'src/latam-it-buttons-side-by-side/button-sidebyside-helper-service';
import { RoutesAddressFullEnum } from '../../address-full/shared/enums/routes-address-enum';
import { RoutesPldFullEnum } from './enums/routes-pld-enum';


@Injectable()
export class PldFullNavigationService extends CommonNavigationService {
  offersModel: OffersFullModel;
  selected_package: OfferPackageFull;

  constructor(
    route: Router,
    buttonSideBySideHelperService: ButtonSideBySideHelperService,
  ) {
    super(route, buttonSideBySideHelperService);
    this.step = RoutesPldFullEnum.PURPOSE;
    this.stepAmount = 9;
    this.flows = [
      {
        stage: RoutesPldFullEnum.PURPOSE,
        previous: RoutesAddressFullEnum.ADDRESS_HOME,
        next: RoutesPldFullEnum.MONEY
      },
      {
        stage: RoutesPldFullEnum.MONEY,
        previous: RoutesPldFullEnum.PURPOSE,
        next:  RoutesPldFullEnum.CREDITS
      },
      {
        stage: RoutesPldFullEnum.CREDITS,
        previous: RoutesPldFullEnum.MONEY,
        next: RoutesSelfieEnum.GET_READY
      },

    ];
  }
}
