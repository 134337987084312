import { Component, OnInit, ElementRef } from '@angular/core';

import { RoutesSelfieEnum } from '../shared/enums/routes-selfie-enum';
import { SelfieCommonService } from '../shared/selfie-common.service';
import { ArchiveService } from '../../shared/services/domains/archive/archive.service';
import { Subject } from 'rxjs/Subject';
import { AbreCuentasRoutesEnum } from '../../shared/enum/abre-cuentas-routes.enum';
import { Analytics } from '../../shared/analytics';
import { SelfieNavigationService } from '../shared/selfie-navigation.service';


@Component({
  selector: 'selfie-frontal-photo',
  templateUrl: './frontal-photo.component.html',
  styleUrls: ['./frontal-photo.component.scss']
})
export class FrontalPhotoComponent implements OnInit {

  pageId = 'frontal-photo';
  previousRoute = RoutesSelfieEnum.FRONTAL_STAGE;
  loaderIcon: boolean;
  fileType = 'selfie';
  eventsSubject: Subject<void> = new Subject<void>();

  // Params from admin
  maxImageLenght: number;
  compressedResolutionHeight: number;

  errorRoute = AbreCuentasRoutesEnum.CAMERA_ERROR;
  noPermissionRoute = AbreCuentasRoutesEnum.PERMISSION_REQUIRED;

  constructor(
    private analyticsLib: Analytics,
    private selfieCommonService: SelfieCommonService,
    private elementRef: ElementRef,
    private navigationService: SelfieNavigationService) {}

  ngOnInit() {
    this.navigationService.setStage(RoutesSelfieEnum.FRONTAL_PHOTO);
    this.setFileUploadParams();
    this.analyticsLib.buildData('CameraSelfieFrente', this.pageId, '15', false);
    this.analyticsLib.trackState(null, null);
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#eaf1f7';
    this.selfieCommonService.scrollToTop(document);
  }

  setFileUploadParams() {
    if (this.selfieCommonService.paramsUpload) {
      this.compressedResolutionHeight = this.selfieCommonService.paramsUpload[0].resolution_size;
      this.maxImageLenght = this.selfieCommonService.paramsUpload[0].max_file_size;
    }
  }

  sendImageEvent(base64) {
    this.selfieCommonService.sendImage(base64, this.fileType, undefined);
  }

}
