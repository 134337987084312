<div class="content-with-filled-button">
  <div id='{{pageId}}'></div>
  <client-header-oca [atual]='4'></client-header-oca>

  <main>
    <div id="di23">
    <h1 id="titleId" class="title-container">
     ¿Cuál es el saldo promedio mensual esperado en tus cuentas?
    </h1>

    <vcl-additional-info
      id='addtionalInformationId'
      [text]="hintObject.message"
      position="down-left"
    ></vcl-additional-info>
  </div>
    <div class="radio-button">
      <voxel-radio-button-group>
        <voxel-radio-button
          *ngFor="let expectedBalance of expectedBalances"
          id="expectedBalance"
          [radioName]="' '"
          [value]="expectedBalance.code"
          [checked]="expectedBalance.checked"
          (radioButtonChange)='changeAmount($event)'>
          {{expectedBalance.label}}
        </voxel-radio-button>
      </voxel-radio-button-group>
    </div>
  </main>
</div>

<footer>
  <button-sidebyside 
    [isBtnDisabled]="isBtnDisabled" 
    [navigationService]="navigationService">
  </button-sidebyside>
</footer>
<facebook-pixel></facebook-pixel>