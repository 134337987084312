import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { RoutesGetReadyFullEnum } from 'src/app/get-ready-full/shared/enums/routes-get-ready-enum';
import { GetReadyCommonFullService } from 'src/app/get-ready-full/shared/get-ready-common.service';
import { GetReadyFormFullService } from 'src/app/get-ready-full/shared/get-ready-form.service';
import { SellerService } from 'src/app/shared/services/domains/seller/seller.service';

import { Analytics } from '../../shared/analytics';
import { AdminListEnum } from '../../shared/services/admin-lists/enum/admin-list.enum';
import { ProspectService } from '../../shared/services/domains/prospect/prospect.service';
import { SocialNetworksFullModel } from '../models/social-networks-model';
import { FinalDataFullCommonService } from '../shared/final-data-common.service';

@Component({
  selector: 'confirmation-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss']
})
export class EndFullComponent implements OnInit {
  static TRACKING_PUBLICO_FIN = 'TRACKING-PUBLICO-FIN';

  pageId: string;
  name: string;
  listSocialNetworks: SocialNetworksFullModel[];
  seller: boolean;

  constructor(
    private analyticsLib: Analytics,
    private getReadyCommonService: GetReadyCommonFullService,
    private prospectService: ProspectService,
    private getReadyFormService: GetReadyFormFullService,
    private appService: AppService,
    private sellerService: SellerService,
    private finalDataCommonService: FinalDataFullCommonService
  ) {
    this.pageId = 'confirmation_end';
  }

  ngOnInit() {
    this.setData();
    this.getNetworks();
  }

  setData() {
    this.seller = this.getReadyFormService.getProperty('seller');
    this.name = this.prospectService.getFirstName();

    // tslint:disable-next-line: early-exit
    if (!this.sellerService.isSeller()) {
      this.analyticsLib.buildData('Exito', this.pageId, 'Complete', false);
      this.analyticsLib.trackState(null, null);
      this.doPatchProspectTracking();
    }
  }

  getNetworks() {
    const adminLinkURLs = this.getReadyCommonService.getAdminList(AdminListEnum.LIST_URLS);

    this.listSocialNetworks = [
      new SocialNetworksFullModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'INSTITUTIONAL_SITE').description,
        'assets/images/icon_web.png',
        'web_img',
        'end__size-img'
      ),
      new SocialNetworksFullModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'SOCIAL_FACEBOOK').description,
        'assets/images/icon_facebook.png',
        'facebook_img',
        'end__size-img end__margin-img'
      ),
      new SocialNetworksFullModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'SOCIAL_INSTAGRAM').description,
        'assets/images/icon_instagram.png',
        'instragram_img',
        'end__size-img end__margin-img'
      ),
      new SocialNetworksFullModel(
        adminLinkURLs.find(i => i.code.toUpperCase() === 'SOCIAL_LINKEDIN').description,
        'assets/images/icon_linkedin.png',
        'linkedin_img',
        'end__size-img end__margin-img'
      )
    ];
  }

  doPatchProspectTracking() {
    const clientFlowId = this.prospectService.getClientFlowId();
    // tslint:disable-next-line: early-exit
    if (clientFlowId) {
      const body: any = {
        prospect_id: clientFlowId,
        prospect_type: EndFullComponent.TRACKING_PUBLICO_FIN
      };
      this.finalDataCommonService.doPatchProspectTracking(body);
    }
  }

  backToHome() {
    if (typeof window !== 'undefined') {
      window.location.href = `?_reload&origin=${this.appService.getOrigin()}#/${RoutesGetReadyFullEnum.SALES}`;
    }
  }
}
