import { Injectable } from '@angular/core';

import { AppService } from '../../../../app.service';
import { Observable } from 'rxjs/Observable';
import { SellerMethods } from './seller-enum';
import { SellerModelFull } from 'src/app/get-ready-full/models/seller-model';

@Injectable()
export class SellerService {

  seller = 'seller';

  constructor(private appService: AppService) {}

  getSeller(): string {
    return localStorage.getItem(this.seller);
  }

  setSeller(seller: SellerModelFull): void {
    localStorage.setItem(this.seller, JSON.stringify(seller));
  }

  removeSeller() {
    localStorage.removeItem(this.seller);
  }

  isSeller(): string {
    const seller = JSON.parse(this.getSeller());

    return seller ? seller : null;
  }

  getSellerRequest(body): Observable<any> {
    return this.appService.doPost(body, SellerMethods.GET_SELLER, true);
  }
}
