import { Injectable } from '@angular/core';

import { AppService } from '../../../../app.service';
import { TermsMethods } from './terms-enum';

@Injectable()
export class TermsService {

  constructor(private appService: AppService) {}

  getTermsRequest(requestBody: any, method: TermsMethods, showLoader?: boolean) {
    return this.appService.doPost(requestBody, method, showLoader);
  }

}
